import React, { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { wwpAction, authAction } from "redux/modules";
import showMessage from "hooks/showMessage";
import showErrMessage from "hooks/showErrMessage";
import { Link, Redirect } from "react-router-dom";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";
import { IoArrowBack, IoArrowForward, IoArrowDown } from "react-icons/io5";

import {
  FaInfoCircle,
  FaCheckCircle,
  FaTimesCircle,
  FaStopwatch,
  FaPlayCircle
} from "react-icons/fa";

// Images
import BgOrange from "assets/images/new-design/game/card-icon-orange.png";
import Point from "assets/images/new-design/game/who-wants-points/point.png";
import Gift from "assets/images/new-design/game/who-wants-points/gift.png";
import Box from "assets/images/new-design/game/who-wants-points/box.png";

// Splash
import Splash from "assets/images/new-design/game/splash/splash-kim-1000-puan-ister.png";

// CountdownCircleTimer
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import { scroller } from "react-scroll";
import { animateScroll as scroll } from "react-scroll";

import VideoPopup from "../../../components/VideoPopup";

const WhoWantsPoints = () => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const wwpList = useSelector(store => store.wwp.wwpList);
  const bonus = useSelector(store => store.wwp.bonus);
  const controlWwp = useSelector(store => store.wwp.controlWwp);
  const isLoading = useSelector(store => store.wwp.isLoading);
  const selectedOption = useSelector(store => store.wwp.selectedOption);
  const answer = useSelector(store => store.wwp.answer);

  const [seconds, setSeconds] = useState();
  const [gameIsActive, setGameIsActive] = useState(false);
  const [timerError, setTimerError] = useState(false);
  const [answerIsWrong, setAnswerIsWrong] = useState(false);
  const [answerIsTrue, setAnswerIsTrue] = useState(false);

  const [startList, setStartList] = useState(null);
  const [selectedChoice, setSelectedChoice] = useState(null);

  const [isOpen, setIsOpen] = useState(false);

  const openPopup = e => {
    e.preventDefault();

    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const updatePoint = () => {
    // Score Update
    dispatch(
      authAction.returnNewScoreOfEmployee(employeeFirm.firm.id, employeeFirm.id)
    );
  };

  useEffect(() => {
    dispatch(wwpAction.getWwpList());
    dispatch(wwpAction.controlWwp(employeeFirm.id));
  }, []);

  useEffect(() => {
    if (wwpList?.id) {
      dispatch(wwpAction.getBonusWwp(employeeFirm.id, wwpList.id));
    }
  }, [wwpList]);

  // Control
  useEffect(() => {
    if (controlWwp && wwpList) {
      if (
        controlWwp.remainTime > 0 &&
        wwpList.remainTime > controlWwp.remainTime
      ) {
        dispatch(wwpAction.controlWwp(employeeFirm.id)).then(control => {
          if (control.data) {
            if (
              control.data.remainTime > 0 &&
              control.data.availableTodayQuestion === true
            ) {
              setSeconds(control.data.remainTime);
              setGameIsActive(true);
              dispatch(wwpAction.startWwp(employeeFirm.id)).then(start => {
                if (start.data) {
                  setStartList(start.data);
                }
              });
            } else {
              setGameIsActive(false);
            }
          }
        });
      }
    }
  }, [controlWwp && wwpList]);

  // Timout Callback
  useEffect(() => {
    if (timerError) {
      dispatch(
        wwpAction.saveFindQuestionAnswer(
          wwpList?.whoWantsScoreQuestionDTOList[0].id,
          employeeFirm.id
        )
      );
    }
  }, [timerError]);

  // Scroll to Top
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  // Render Time
  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return (
        <div className="timer text-[16px] lg:text-[24px] font-bold text-white">
          Süre Doldu!
        </div>
      );
    }

    return (
      <div className="timer text-center">
        <div className="value text-[36px] lg:text-[48px] lg:leading-[58px] leading-[44px] font-bold text-white">
          {remainingTime}
        </div>
        <div className="text text-[12px] font-bold text-white">SANİYE</div>
      </div>
    );
  };

  const handleStartWwp = e => {
    e.preventDefault();

    dispatch(wwpAction.controlWwp(employeeFirm.id)).then(control => {
      if (control.data) {
        if (
          control.data.remainTime > 0 &&
          control.data.availableTodayQuestion === true
        ) {
          setSeconds(control.data.remainTime);
          setGameIsActive(true);
          dispatch(wwpAction.startWwp(employeeFirm.id)).then(start => {
            if (start.data) {
              setStartList(start.data);
            }
          });
        } else {
          setGameIsActive(false);
        }
      }
    });
  };

  const showChoices = choicesList => {
    var questionChoices = null;

    questionChoices = choicesList.map((itemChoice, index) => (
      <div
        className="flex items-center mb-[16px] md:mb-[24px]"
        key={itemChoice.id}
      >
        {selectedOption === index + 1 ? (
          <input
            id={`radio${index + 1}`}
            type="radio"
            name="radio"
            className="hidden"
            checked
          />
        ) : (
          <input
            id={`radio${index + 1}`}
            type="radio"
            name="radio"
            className="hidden"
          />
        )}

        <label
          htmlFor={`radio${index + 1}`}
          onClick={() => onClickOption(index + 1, itemChoice.id)}
          className="flex items-center cursor-pointer md:text-[16px] text-[14px] font-medium"
        >
          <span className="w-6 h-6 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
          {itemChoice.description}
        </label>
      </div>
    ));
    return questionChoices;
  };

  const saveQuestion = (questionId, questionChoice, employeeFirmId, e) => {
    e.preventDefault();

    if (!questionChoice) {
      showErrMessage("Lütfen seçenek seçiniz.");
    } else {
      dispatch(
        wwpAction.saveFindQuestionAnswer(
          questionId,
          employeeFirmId,
          questionChoice
        )
      ).then(e => {
        if (wwpList?.id) {
          dispatch(wwpAction.getBonusWwp(employeeFirm.id, wwpList.id)).then(
            resp => {
              if (resp.data) {
                if (questionId) {
                  const filteredWeeks = resp.data.weeks.filter(week => {
                    return (
                      week.questionId !== undefined &&
                      week.questionId === questionId
                    );
                  });

                  if (filteredWeeks[0]?.isCorrectAnswer === true) {
                    updatePoint();
                    dispatch(wwpAction.changeAnswer(true));
                    //setAnswerIsTrue(true);
                    showMessage("Tebrikler 200 Puan Kazandınız!");
                    timerToZero();
                  }

                  if (filteredWeeks[0]?.isCorrectAnswer === false) {
                    setAnswerIsWrong(true);
                    timerToZero();
                  }
                }
              }
            }
          );
        }
      });
    }
  };

  const timerToZero = () => {
    setSeconds(0);
  };

  // CountdownTimer When Complete
  const handleTimerComplete = () => {
    setGameIsActive(false);
    setTimerError(true);
  };

  // Selected Option
  const onClickOption = (value, choiceId) => {
    setSelectedChoice(choiceId);
    dispatch(wwpAction.changeOption(value));
  };

  return (
    <section className="bg-[#f8fafc]">
      <GlobalLoader isLoading={isLoading} />
      <div
        className="container mx-auto py-20 px-6 md:px-0"
        id="wrapper-question"
      >
        <div className="flex gap-4 h-100 flex-col md:flex-row">
          {/* Left Content */}
          <div className="relative w-[308px] gap-20 mx-auto">
            {/* Top */}
            {answerIsWrong === false &&
              answer === false &&
              timerError === false && (
                <div className="mb-4 relative h-[161px] w-[308px] gap-20 bg-[url('assets/images/new-design/game/card-bg-orange-2.png')] bg-left-bottom bg-no-repeat mx-auto">
                  <h4 className="md:text-[16px] text-[16px] text-center px-4 py-4 text-white font-semibold">
                    Kazanılan Toplam Puan
                  </h4>

                  <div className="flex gap-4 flex-col md:flex-row justify-center items-center">
                    <img
                      src={Point}
                      alt=""
                      className="max-w-[34px] max-h-[34px]"
                    />
                    <p className="text-white text-center font-semibold md:text-[48px] text-[20px]">
                      {bonus?.totalPoint}
                    </p>
                  </div>
                </div>
              )}

            {/* Bottom */}
            {gameIsActive === false || answerIsWrong || answer ? (
              <>
                <div className="mb-4 relative h-[333px] w-[308px] gap-20 bg-[url('assets/images/new-design/game/card-bg-orange.png')] bg-left-bottom bg-no-repeat mx-auto">
                  <h2
                    className="md:text-[36px] text-[28px] text-center !px-4 md:!px-8 py-4 font-bold text-white"
                    dangerouslySetInnerHTML={{ __html: wwpList?.leftTitle }}
                  />
                  <img src={BgOrange} className="w-[220px] m-auto" alt="" />
                </div>
              </>
            ) : (
              <>
                {seconds && seconds > 0 ? (
                  <div className="relative lg:sticky lg:top-[150px] h-[333px] w-[308px] lg:min-w-[308px] lg:min-h-[333px] gap-20 bg-[url('assets/images/new-design/game/card-bg-orange.png')] bg-left-bottom bg-no-repeat mx-auto">
                    <h2 className="lg:text-[36px] text-[28px] text-center px-4 py-4 font-bold text-white">
                      Kalan Süre
                    </h2>

                    {seconds && wwpList?.remainTime && (
                      <div className="countdown flex justify-center">
                        <CountdownCircleTimer
                          isPlaying
                          size={156}
                          strokeLinecap="square"
                          duration={wwpList?.remainTime}
                          initialRemainingTime={seconds}
                          colors="#FFFFFF"
                          onComplete={e => handleTimerComplete()}
                        >
                          {renderTime}
                        </CountdownCircleTimer>
                      </div>
                    )}
                  </div>
                ) : (
                  "Süreniz Tamamlandı"
                )}
              </>
            )}
          </div>

          {/* Right Content */}
          <div className="flex-1 flex justify-center items-start rounded-[12px] flex-col w-100">
            {/* Gray Head Content */}

            {gameIsActive === false &&
              answer === false &&
              answerIsWrong === false &&
              timerError === false && (
                <div className="bg-slate-200 w-100 min-h-[333px] flex justify-center items-start rounded-[12px] flex-col py-8 md:px-12 px-8 relative">
                  <h3 className="mb-8 inline-flex items-center gap-4 text-2xl font-semibold text-slate-700">
                    <FaInfoCircle className="h-6 w-6" />
                    <span
                      dangerouslySetInnerHTML={{ __html: wwpList?.title }}
                    />
                  </h3>

                  {/* Normal Desc */}
                  {gameIsActive === false && (
                    <div
                      className="md:text-[16px] text-[14px] font-normal text-slate-500 mb-8"
                      dangerouslySetInnerHTML={{ __html: wwpList?.description }}
                    />
                  )}

                  {/* Ingame Description */}
                  {gameIsActive === true && (
                    <div
                      className="md:text-[16px] text-[14px] font-normal text-slate-500 mb-8"
                      dangerouslySetInnerHTML={{
                        __html: wwpList?.inGameDescription
                      }}
                    />
                  )}

                  {/* <div
                    className="flex flex-row justify-center items-center relative"
                  >
                    <a href="#"
                      onClick={openPopup}
                      className="relative w-[120px] h-[80px] block rounded-xl"
                    > 
                      <img src={Splash} className="rounded-xl" alt="" />
                      <FaPlayCircle className="h-8 w-8 text-white absolute top-[50%] left-[50%] mt-[-16px] ml-[-16px]" />
                    </a>

                    <h2 className="ml-2 text-slate-600 font-semibold">Nasıl Oynanır?</h2>
                  </div> */}

                  <div className="absolute bottom-0 left-1/2 flex h-12 w-12 -translate-x-1/2 translate-y-1/2 transform items-center justify-center rounded-full bg-white shadow-lg">
                    <IoArrowDown className="h-5 w-5" />
                  </div>

                  <div className="flex flex-col justify-center gap-6 !mt-4 items-center relative mx-auto">
                    <a
                      href="#"
                      onClick={openPopup}
                      className="relative w-[240px] h-[160px] block rounded-xl"
                    >
                      <img src={Splash} className="rounded-xl" alt="" />
                      <FaPlayCircle className="h-8 w-8 text-white absolute top-[50%] left-[50%] mt-[-16px] ml-[-16px]" />
                    </a>

                    <h2 className="text-slate-600 font-semibold text-xl">
                      İzlemek için videoya tıklayınız
                    </h2>
                  </div>
                </div>
              )}

            {timerError === false &&
              answer === false &&
              answerIsWrong === false && (
                <>
                  {/* White Middle Content */}
                  <div
                    className={
                      gameIsActive === false
                        ? "bg-white w-100 mt-16 flex-1 flex justify-start items-start rounded-[12px] flex-col py-[50px] md:px-4 px-4"
                        : "bg-white w-100 mt-0 flex-1 flex justify-start items-start rounded-[12px] flex-col py-[50px] md:px-4 px-4"
                    }
                  >
                    <div className="flex flex-row flex-wrap md:flex-row justify-between items-start w-100">
                      {bonus?.weeks?.length > 0 &&
                        bonus.weeks.map(item => (
                          <>
                            {typeof item.isCorrectAnswer !== "undefined" ? (
                              item.isCorrectAnswer === false ? (
                                <div className="relative m-auto grayscale">
                                  <img src={Point} alt="" />

                                  <div className="absolute top-0 left-0 w-100 h-100 flex flex-col justify-center items-center text-[#ac3300]">
                                    <div className="text-base font-semibold leading-relaxed">
                                      {item.title && item.title}
                                    </div>
                                    <div className="text-[32px] leading-tight	font-semibold">
                                      {item.score && item.score}
                                    </div>
                                    <div className="text-sm font-semibold">
                                      Puan
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="relative m-auto">
                                  <img src={Point} alt="" />

                                  <div className="absolute top-0 left-0 w-100 h-100 flex flex-col justify-center items-center text-[#ac3300]">
                                    <div className="text-base font-semibold leading-relaxed">
                                      {item.title && item.title}
                                    </div>
                                    <div className="text-[32px] leading-tight	font-semibold">
                                      {item.score && item.score}
                                    </div>
                                    <div className="text-sm font-semibold">
                                      Puan
                                    </div>
                                  </div>
                                </div>
                              )
                            ) : (
                              <div className="relative m-auto grayscale">
                                <img src={Point} alt="" />

                                <div className="absolute top-0 left-0 w-100 h-100 flex flex-col justify-center items-center text-[#ac3300]">
                                  <div className="text-base font-semibold leading-relaxed">
                                    {item.title && item.title}
                                  </div>
                                  <div className="text-[32px] leading-tight	font-semibold">
                                    {item.score && item.score}
                                  </div>
                                  <div className="text-sm font-semibold">
                                    Puan
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ))}

                      {bonus?.isBonus === "WON" ? (
                        <div className="relative m-auto">
                          <img src={Point} alt="" className="relative z-10" />

                          <div className="absolute top-0 left-0 w-100 h-100 flex flex-col justify-center items-center text-[#ac3300] z-10">
                            <div className="text-base font-semibold leading-relaxed">
                              Hediye
                            </div>
                            <div className="text-[32px] leading-tight	font-semibold">
                              +200
                            </div>
                            <div className="text-sm font-semibold">Puan</div>
                          </div>

                          <img
                            src={Gift}
                            alt=""
                            className="absolute top-[-50px]"
                          />
                          <img
                            src={Box}
                            alt=""
                            className="absolute left-[-36px] bottom-[-29px] z-0 min-w-[190px]"
                          />
                        </div>
                      ) : bonus?.isBonus === "NOTWON" ? (
                        <div className="relative m-auto">
                          <img
                            src={Point}
                            alt=""
                            className="relative z-10 grayscale"
                          />

                          <div className="absolute top-0 left-0 w-100 h-100 flex flex-col justify-center items-center text-[#ac3300] z-10 grayscale">
                            <div className="text-base font-semibold leading-relaxed">
                              Hediye
                            </div>
                            <div className="text-[32px] leading-tight	font-semibold">
                              +200
                            </div>
                            <div className="text-sm font-semibold">Puan</div>
                          </div>

                          <img
                            src={Gift}
                            alt=""
                            className="absolute top-[-50px]"
                          />
                          <img
                            src={Box}
                            alt=""
                            className="absolute left-[-36px] bottom-[-29px] z-0 min-w-[190px]"
                          />
                        </div>
                      ) : (
                        <div className="relative m-auto">
                          <img
                            src={Point}
                            alt=""
                            className="relative z-10 grayscale"
                          />

                          <div className="absolute top-0 left-0 w-100 h-100 flex flex-col justify-center items-center text-[#ac3300] z-10 grayscale">
                            <div className="text-base font-semibold leading-relaxed">
                              Hediye
                            </div>
                            <div className="text-[32px] leading-tight	font-semibold">
                              +200
                            </div>
                            <div className="text-sm font-semibold">Puan</div>
                          </div>

                          <img
                            src={Gift}
                            alt=""
                            className="absolute top-[-50px]"
                          />
                          <img
                            src={Box}
                            alt=""
                            className="absolute left-[-36px] bottom-[-29px] z-0 min-w-[190px]"
                          />
                        </div>
                      )}
                    </div>

                    <div className="flex justify-between items-start bg-slate-300 mt-12 w-100 h-[16px] relative rounded-[50px]">
                      {bonus?.weeks?.length > 0 &&
                        bonus.weeks.map(item => (
                          <>
                            {typeof item.isCorrectAnswer !== "undefined" ? (
                              item.isCorrectAnswer === false ? (
                                <div className="w-8 h-8 bg-danger rounded-full border-[2px #e2e8f0 solid] flex flex-col items-center justify-center text-white mx-auto mt-[-6px]">
                                  <FaTimesCircle className="h-5 w-5" />
                                </div>
                              ) : (
                                <div className="w-8 h-8 bg-[#00b185] rounded-full flex flex-col items-center justify-center text-white mx-auto mt-[-6px]">
                                  <FaCheckCircle className="h-5 w-5" />
                                </div>
                              )
                            ) : (
                              <div className="grayscale w-8 h-8 bg-[#ffffff] rounded-full flex flex-col items-center justify-center text-[#64748b] mx-auto mt-[-6px]">
                                <FaStopwatch className="h-5 w-5" />
                              </div>
                            )}
                          </>
                        ))}

                      {bonus?.isBonus === "WON" ? (
                        <div className="w-8 h-8 bg-[#00b185] rounded-full flex flex-col items-center justify-center text-white mx-auto mt-[-6px]">
                          <FaCheckCircle className="h-5 w-5" />
                        </div>
                      ) : bonus?.isBonus === "NOTWON" ? (
                        <div className="w-8 h-8 bg-danger rounded-full border-[2px #e2e8f0 solid] flex flex-col items-center justify-center text-white mx-auto mt-[-6px]">
                          <FaTimesCircle className="h-5 w-5" />
                        </div>
                      ) : (
                        <div className="grayscale w-8 h-8 bg-[#ffffff] rounded-full flex flex-col items-center justify-center text-[#64748b] mx-auto mt-[-6px]">
                          <FaStopwatch className="h-5 w-5" />
                        </div>
                      )}
                    </div>

                    {/* Question and Answers */}
                    {wwpList?.whoWantsScoreQuestionDTOList?.length > 0 &&
                      wwpList?.whoWantsScoreQuestionDTOList.map(
                        (item, index) => (
                          <>
                            {startList?.whoWantsScoreQuestionDTOList?.length >
                              0 &&
                              startList?.whoWantsScoreQuestionDTOList
                                .filter(answer => item.id === answer.id)
                                .map((answer, answerIndex) => (
                                  <form
                                    className="w-100"
                                    key={answer.id}
                                    onSubmit={e => {
                                      e.preventDefault();
                                    }}
                                  >
                                    <input
                                      type="hidden"
                                      name="cevap"
                                      value={selectedOption}
                                    />

                                    <div className="flex gap-4 flex-col md:flex-row">
                                      <div className="bg-white flex-1 flex justify-center items-start rounded-[12px] lg:flex-row flex-col md:py-12 py-8 md:px-0 px-0">
                                        <div className="left flex flex-col items-start lg:w-[40%] w-[100%]">
                                          <div className="question-count bg-[#f59e0b] rounded-[17px] px-[16px] py-[8px]">
                                            <p className="text-[12px] text-center font-bold text-white">
                                              {item.title && item.title}
                                            </p>
                                          </div>
                                          <div className="questions mt-[12px]">
                                            <p className="md:text-[24px] text-[18px] font-bold text-[#64748b]">
                                              {item.description &&
                                                item.description}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="right lg:w-[60%] w-[100%] lg:pl-[12%] pl-[0] lg:pt-[0] pt-[10%]">
                                          {showChoices(
                                            answer.whoWantsScoreChoiceDTOList
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="action flex justify-between w-full">
                                      <Link
                                        to="/userportal/competitions"
                                        className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px]"
                                      >
                                        <IoArrowBack className="h-5 w-5" />
                                        Geri Git{" "}
                                      </Link>
                                      <a
                                        href="#"
                                        className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[135px] min-w-[120px] max-h-[52px] rounded-full border-2 bg-[#00b185] border-[#00b185] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px] text-white"
                                        onClick={e =>
                                          saveQuestion(
                                            item.id,
                                            selectedChoice,
                                            employeeFirm.id,
                                            e
                                          )
                                        }
                                      >
                                        Cevapla{" "}
                                        <IoArrowForward className="h-5 w-5 text-white" />
                                      </a>
                                    </div>
                                  </form>
                                ))}
                          </>
                        )
                      )}
                  </div>

                  {/* White Bottom Action Buttons */}
                  <div className="w-100 mt-4 flex-1 flex justify-start items-start rounded-[12px] flex-col py-4 md:px-12 px-4">
                    {gameIsActive === false && (
                      <div className="action flex justify-between w-full">
                        <Link
                          to="/userportal/competitions"
                          className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px]"
                        >
                          <IoArrowBack className="h-5 w-5" />
                          Geri Git{" "}
                        </Link>

                        {controlWwp?.availableTodayQuestion === true &&
                          controlWwp?.remainTime > 0 && (
                            <a
                              href="#"
                              className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[135px] min-w-[120px] max-h-[52px] rounded-full border-2 bg-[#00b185] border-[#00b185] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px] text-white"
                              onClick={e => handleStartWwp(e)}
                            >
                              Başla{" "}
                              <IoArrowForward className="h-5 w-5 text-white" />
                            </a>
                          )}
                      </div>
                    )}

                    {controlWwp &&
                      controlWwp.length > 0 &&
                      controlWwp.map(item => {
                        return (
                          <div
                            className={
                              item.availableTodayQuestion && item.answerable
                                ? "w-full md:w-[calc(100%-324px)] flex-1 flex justify-between ml-auto items-start rounded-[12px] flex-col mt-4 py-6 md:mx-8 mx-4"
                                : ""
                            }
                            key={item.questionId}
                          >
                            {item.availableTodayQuestion && item.answerable && (
                              <div className="action flex justify-between w-full bg-white">
                                <Link
                                  to="/userportal/competitions"
                                  className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px]"
                                >
                                  <IoArrowBack className="h-5 w-5" />
                                  Geri Git{" "}
                                </Link>
                                <a
                                  href="#"
                                  className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[135px] min-w-[120px] max-h-[52px] rounded-full border-2 bg-[#00b185] border-[#00b185] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px] text-white"
                                  onClick={e => handleStartWwp(e)}
                                >
                                  Başla{" "}
                                  <IoArrowForward className="h-5 w-5 text-white" />
                                </a>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </>
              )}

            {/* Results */}
            {timerError === true && (
              <div className="flex gap-4 w-100 h-100 flex-col lg:flex-row">
                {/* {scrollToTop()} */}
                <div className="bg-slate-200 flex-1 flex justify-center rounded-[12px] flex-col py-8 px-8 min-h-[333px]">
                  <h4 className="lg:text-[48px] text-[24px] text-center font-semibold text-slate-700">
                    ⌛ Üzgünüz
                  </h4>
                  <p className="lg:text-[16px] text-[14px] text-center font-normal text-slate-500 lg:pt-[16px] pt-[12px]">
                    Size verilen süre içerisinde ({wwpList?.remainTime} saniye)
                    yanıt veremediniz ve puan kazanamadınız.
                  </p>
                  <Link
                    to="/userportal/competitions"
                    className="lg:mt-[24px] mt-[16px] font-medium mx-auto max-h-[52px] flex items-center gap-2 rounded-full border-2 border-slate-700 bg-white px-10 py-3  text-slate-700"
                  >
                    <IoArrowBack className="h-5 w-5" />
                    Yarışmalar{" "}
                  </Link>
                </div>
              </div>
            )}

            {answer === true && bonus?.isBonus !== "WON" && (
              <div className="flex gap-4 w-100 h-100 flex-col lg:flex-row">
                {scrollToTop()}
                <div className="bg-slate-200 flex-1 flex justify-center rounded-[12px] flex-col py-8 px-8 min-h-[333px]">
                  <h4 className="lg:text-[48px] text-[24px] text-center font-semibold text-slate-700">
                    🎉 Tebrikler
                  </h4>
                  <p className="lg:text-[16px] text-[14px] text-center font-normal text-slate-500 lg:pt-[16px] pt-[12px]">
                    Doğru cevapladınız ve 200 Puan kazandınız!
                  </p>
                  <Link
                    to="/userportal/competitions"
                    className="lg:mt-[24px] mt-[16px] font-medium mx-auto max-h-[52px] flex items-center gap-2 rounded-full border-2 border-slate-700 bg-white px-10 py-3  text-slate-700"
                  >
                    <IoArrowBack className="h-5 w-5" />
                    Yarışmalar{" "}
                  </Link>
                </div>
              </div>
            )}

            {answer === true && bonus?.isBonus === "WON" && (
              <div className="flex gap-4 w-100 h-100 flex-col lg:flex-row">
                {scrollToTop()}
                <div className="bg-slate-200 flex-1 flex justify-center rounded-[12px] flex-col py-8 px-8 min-h-[333px]">
                  <h4 className="lg:text-[48px] text-[24px] text-center font-semibold text-slate-700">
                    🎉 Tebrikler
                  </h4>
                  <p className="lg:text-[16px] text-[14px] text-center font-normal text-slate-500 lg:pt-[16px] pt-[12px]">
                    Doğru cevapladınız ve 200 puan kazandınız! Ayrıca 4 hafta
                    boyunca tüm soruları doğru cevapladığınız için de ekstra 200
                    puan hesabınıza yüklenmiştir.
                  </p>
                  <Link
                    to="/userportal/competitions"
                    className="lg:mt-[24px] mt-[16px] font-medium mx-auto max-h-[52px] flex items-center gap-2 rounded-full border-2 border-slate-700 bg-white px-10 py-3  text-slate-700"
                  >
                    <IoArrowBack className="h-5 w-5" />
                    Yarışmalar{" "}
                  </Link>
                </div>
              </div>
            )}

            {answerIsWrong === true && (
              <div className="flex gap-4 w-100 h-100 flex-col lg:flex-row">
                {scrollToTop()}
                <div className="bg-slate-200 flex-1 flex justify-center rounded-[12px] flex-col py-8 px-8 min-h-[333px]">
                  <h4 className="lg:text-[48px] text-[24px] text-center font-semibold text-slate-700">
                    😞 Üzgünüz
                  </h4>
                  <p className="lg:text-[16px] text-[14px] text-center font-normal text-slate-500 lg:pt-[16px] pt-[12px]">
                    Yanlış cevapladınız! Lütfen bir sonraki oyun gününde tekrar
                    deneyiniz!
                  </p>
                  <Link
                    to="/userportal/competitions"
                    className="lg:mt-[24px] mt-[16px] font-medium mx-auto max-h-[52px] flex items-center gap-2 rounded-full border-2 border-slate-700 bg-white px-10 py-3  text-slate-700"
                  >
                    <IoArrowBack className="h-5 w-5" />
                    Yarışmalar{" "}
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <VideoPopup
        videoUrl={
          process.env.REACT_APP_VIDEO_SHOW_URL + "JTI_BB_KIM1000_01.mp4"
        }
        isOpen={isOpen}
        onClose={closePopup}
      />
    </section>
  );
};

export default memo(WhoWantsPoints);
