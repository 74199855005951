import * as profileScoreHistory from "./profileScoreHistory.type";

const initialState = {
  orderList: [],
  error: null,
  isLoading: false,
  scoreTrxList: [],
  totalPages: null
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${profileScoreHistory.GET_SCORE_TRX_LIST_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${profileScoreHistory.GET_SCORE_TRX_LIST_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        scoreTrxList: payload.scoreTransactionDTOS,
        totalPages: payload.totalPages
      };
    case `${profileScoreHistory.GET_SCORE_TRX_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        scoreTrxList: null
      };

    default:
      return state;
  }
};

export default reducer;
