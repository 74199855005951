import * as profileScoreHistory from "./profileScoreHistory.type";

import { API } from "../../../../services/http.service";
const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getTrxList = (id, pageNumber) => async dispatch => {
  dispatch({ type: profileScoreHistory.GET_SCORE_TRX_LIST_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/scoreTransactions/${id}?page=${pageNumber}&size=10`,
      config
    );
    dispatch({
      type: profileScoreHistory.GET_SCORE_TRX_LIST_FULLFILLED,
      payload: response.data
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: profileScoreHistory.GET_SCORE_TRX_LIST_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};
