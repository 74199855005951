import * as brandTypes from "./brand.type";
import tr from "dayjs/locale/tr";

const initialState = {
  brandList: [],
  error: null,
  isLoading: false,
  actives: [],
  brandVideoList: [],
  footerFlag: true
};

const reducer = (
  state = initialState,
  { type, payload, actives, brandVideoList, footerFlag, ...params }
) => {
  switch (type) {
    case `${brandTypes.GET_BRAND_BY_ID_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${brandTypes.GET_BRAND_BY_ID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        brandList: payload.data,
        actives: actives
      };
    case `${brandTypes.GET_BRAND_BY_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        brandList: null
      };
    case `${brandTypes.SET_LIST_VIDEOS_FULFILLED}`:
      return {
        ...state,
        brandVideoList: payload
      };
    case `${brandTypes.SET_LIST_VIDEOS_PENDING}`:
      return {
        ...state,
        brandVideoList: false
      };
    case `${brandTypes.SET_LIST_VIDEOS_REJECTED}`:
      return {
        ...state,
        brandVideoList: false
      };
    case `${brandTypes.CLEAR_LIST_VIDEOS_FULFILLED}`:
      return {
        ...state,
        brandVideoList: payload
      };
    case `${brandTypes.CLEAR_LIST_VIDEOS_PENDING}`:
      return {
        ...state,
        brandVideoList: false
      };
    case `${brandTypes.CLEAR_LIST_VIDEOS_REJECTED}`:
      return {
        ...state,
        brandVideoList: false
      };
    case `${brandTypes.SET_FOOTER_FULFILLED}`:
      return {
        ...state,
        footerFlag: payload
      };
    case `${brandTypes.SET_FOOTER_REJECTED}`:
      return {
        ...state,
        footerFlag: false
      };
    default:
      return state;
  }
};

export default reducer;
