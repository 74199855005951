import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { weekStoreAction } from "redux/modules";
import { imageShowUrl } from "../../../config/api.config";
import HdIcon from "assets/images/new-design/hd-icon/hd-icon@2x.png";

const WeekWinner = () => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const weekWinnerList = useSelector(store => store.weekStore.weekWinnerList);

  useEffect(() => {
    dispatch(weekStoreAction.getWeekWinnerList(employeeFirm.firm.id));
  }, []);

  if (!weekWinnerList || weekWinnerList.length === 0) {
    return null;
  }

  return (
    <>
      <h4 className="text-center font-semibold text-slate-700 mb-5">
        Haftanın Kazananları
      </h4>
      <div className="row" style={{ maxWidth: "none" }}>
        {weekWinnerList.map((item, index) => {
          const weekNumber = weekWinnerList.length - index;
          const previousCompetitionId =
            index > 0 ? weekWinnerList[index - 1].hdCompetitionId : null;

          return (
            <div
              key={index}
              className="flex flex-col md:flex-row md:no-wrap w-100 justify-content-start lg:justify-content-between align-center overflow-hidden rounded-xl bg-white p-0 transition-all duration-300 hover:shadow-lg text-center mb-5"
            >
              <div className="min-w-[160px]">
                {item.employeeImageDTO ? (
                  <img
                    style={{ objectFit: "cover" }}
                    data-id={index}
                    src={imageShowUrl + item.employeeImageDTO.imageUrl}
                    className="!w-100 !h-100 md:!w-[160px] md:!h-[108px]"
                  />
                ) : (
                  <img
                    style={{ height: "108px", width: "160px" }}
                    data-id={index}
                    src=""
                    alt={item.employeeName}
                  />
                )}
              </div>

              <div className="flex flex-col justify-content-center text-start min-w-[160px] pl-6">
                <h3 className="mb-2 text-slate-700 font-semibold text-base">
                  {item.employeeName} {item.employeeSurname}
                </h3>

                <p className="text-gray2 text-xs">
                  {item.vendorName}{" "}
                  {item.addressDTO && item.addressDTO.provinceName}
                </p>
              </div>

              <div className="flex flex-col md:flex-row w-100 justify-content-end align-items-center mr-14">
                <div>
                  <img src={HdIcon} className="max-w-[72px]" />
                </div>
                <p className="mx-3 text-base text-orange font-semibold">
                  {weekNumber}. Hafta
                  <br />
                  Kazananı
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default WeekWinner;
