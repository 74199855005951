import * as scoreHuntTypes from "./scoreHunt.type";

const initialState = {
  scoreHuntList: null,
  controlScoreHunt: null,
  resultScoreHunt: null,
  error: null,
  isLoading: false
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${scoreHuntTypes.GET_AVAILABLESCOREHUNT_BY_FIRMID_PENDING}`:
    case `${scoreHuntTypes.GET_SCOREHUNTLIST_BY_FIRMID_PENDING}`:
    case `${scoreHuntTypes.SAVE_SCOREHUNT_ANSWER_PENDING}`:
    case `${scoreHuntTypes.GET_RESULT_SCOREHUNT_BY_FIRMID_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${scoreHuntTypes.GET_SCOREHUNTLIST_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        scoreHuntList: payload.data
      };

    case `${scoreHuntTypes.GET_SCOREHUNTLIST_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${scoreHuntTypes.GET_AVAILABLESCOREHUNT_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        controlScoreHunt: payload.data
      };

    case `${scoreHuntTypes.GET_AVAILABLESCOREHUNT_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${scoreHuntTypes.SAVE_SCOREHUNT_ANSWER_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };

    case `${scoreHuntTypes.SAVE_SCOREHUNT_ANSWER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${scoreHuntTypes.GET_RESULT_SCOREHUNT_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        resultScoreHunt: payload.data
      };

    case `${scoreHuntTypes.GET_RESULT_SCOREHUNT_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${scoreHuntTypes.SELECTED_OPTION}`:
      return {
        ...state,
        selectedOption: payload
      };

    default:
      return state;
  }
};

export default reducer;
