import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { aboutAction } from "redux/modules";
import JtAbout from "./JtAbout";
import JtiAbout from "./JtiAbout";
import JtiTurkeyAbout from "./JtiTurkeyAbout";
import IllegalTrade from "./IllegalTrade";
import SocietyInvestment from "./SocietyInvestment";
import HistoryTobacco from "./HistoryTobacco";

//import JtiSocietyInvest from "./JtSocietyInvest";
//import JtiTurkishSocietyInvest from "./JtTurkishTobaccoHistory";
//import { Link } from "react-router-dom";

const About = () => {
  const actives = useSelector(store => store.about.actives);
  const activeAboutMenu = useSelector(store => store.about.activeAboutMenu);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {};
  }, [actives, activeAboutMenu]);

  const onClick = selectedIndex => {
    const newActives = [];
    actives.map((item, index) => {
      selectedIndex === index ? newActives.push(true) : newActives.push(false);
    });
    dispatch(aboutAction.getActiveTab(newActives));
  };

  return (
    <section>
      <div className="container-fluid mx-auto text-center page-head">
        <h3>JTI Hakkında</h3>
      </div>

      <div className="container mx-auto my-20">
        <div className="flex flex-col md:flex-row w-100">
          <div className="basis-full md:basis-3/12">
            <div className="detail-page-left-menu">
              <ul>
                <li>
                  <a
                    onClick={() => onClick(0)}
                    className={actives[0] ? "about-active-tab" : ""}
                  >
                    JT Hakkında
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => onClick(1)}
                    className={actives[1] ? "about-active-tab" : ""}
                  >
                    JTI Hakkında
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => onClick(2)}
                    className={actives[2] ? "about-active-tab" : ""}
                  >
                    JTI Türkiye Hakkında
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => onClick(3)}
                    className={actives[3] ? "about-active-tab" : ""}
                  >
                    Yasa Dışı Ticaret
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => onClick(4)}
                    className={actives[4] ? "about-active-tab" : ""}
                  >
                    Topluma Yatırım
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => onClick(5)}
                    className={actives[5] ? "about-active-tab" : ""}
                  >
                    Türk Tütününün Tarihi
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="basis-full md:basis-9/12 pl-10">
            {actives[0] ? (
              <JtAbout />
            ) : actives[1] ? (
              <JtiAbout />
            ) : actives[2] ? (
              <JtiTurkeyAbout />
            ) : actives[3] ? (
              <IllegalTrade />
            ) : actives[4] ? (
              <SocietyInvestment />
            ) : (
              <HistoryTobacco />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
