import * as loginTypes from "./login.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json" }
};

export const checkLogin = (username, password) => async dispatch => {
  dispatch({ type: loginTypes.GET_LOGIN_PENDING });

  const data = { username, password };

  try {
    const response = await API.post(`${REQUEST_URL}/login`, data, config);
    dispatch({ type: loginTypes.GET_LOGIN_FULFILLED, payload: response });
  } catch (error) {
    dispatch({ type: loginTypes.GET_LOGIN_REJECTED, payload: error });
  }
};

export const saveNewPassword = (username, password) => async dispatch => {
  dispatch({ type: loginTypes.SET_CHANGE_PASSWORD_PENDING });

  const data = {
    username: username,
    password: password
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/public/saveNewPass`,
      data,
      config
    );
    dispatch({
      type: loginTypes.SET_CHANGE_PASSWORD_FULFILLED,
      payload: response
    });

    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: loginTypes.SET_CHANGE_PASSWORD_REJECTED,
      payload: error
    });

    return Promise.reject(error);
  }
};
