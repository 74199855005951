import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { leaderboardAction } from "redux/modules";
import { Link } from "react-router-dom";
import { imageShowUrl } from "../../../config/api.config";

const Leaderboard = () => {
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const leaderboardList = useSelector(
    store => store.leaderboard.leaderboardList
  );
  const leaderboardRegionList = useSelector(
    store => store.leaderboard.leaderboardRegionList
  );

  const getFirstTwoInitials = str => {
    if (str && str.length > 0) {
      const words = str.split(" ");

      if (words.length > 1) {
        const initials = words
          .slice(0, 2)
          .map(word => word.charAt(0).toUpperCase());
        return initials.join("");
      } else if (str.length > 0) {
        const initials = words
          .slice(0, 1)
          .map(word => word.charAt(0).toUpperCase());
        return initials.join("");
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (employeeFirm?.ercCode) {
      dispatch(leaderboardAction.getLeaderboard(employeeFirm?.ercCode));
      dispatch(leaderboardAction.getLeaderboardRegion(employeeFirm?.ercCode));
    }
  }, []);

  const handleChangeTab = (e, tabId) => {
    e.preventDefault();

    if (tabId !== activeTab) {
      if (tabId === 1) {
        setActiveTab(1);
      } else {
        setActiveTab(2);
      }
    }
  };

  return (
    <>
      {/* main */}
      <main className="bg-slate-50 pb-20">
        {/* title & tabs */}
        <section className="glaire bg-[#16435E]">
          <div className="container mx-auto px-12 pt-12 text-center leading-none">
            <h2 className="text-[40px] font-semibold text-white">
              Liderler Tablosu
            </h2>

            <div className="mt-12 flex items-center justify-center gap-2">
              <button
                className={
                  activeTab === 1
                    ? "active w-[200px] rounded-t-lg bg-slate-50 p-4 text-slate-500 [&.active]:text-primary-500"
                    : "w-[200px] rounded-t-lg bg-slate-50 p-4 text-slate-500 [&.active]:text-primary-500"
                }
                onClick={e => handleChangeTab(e, 1)}
              >
                Genel Sıralama
              </button>
              <button
                className={
                  activeTab === 2
                    ? "active w-[200px] rounded-t-lg bg-slate-50 p-4 text-slate-500 [&.active]:text-primary-500"
                    : "w-[200px] rounded-t-lg bg-slate-50 p-4 text-slate-500 [&.active]:text-primary-500"
                }
                onClick={e => handleChangeTab(e, 2)}
              >
                İle Göre
              </button>
            </div>
          </div>
        </section>

        <section>
          <div className="container mx-auto mt-20">
            <div className={activeTab === 1 ? "grid grid-cols-12" : "hidden"}>
              <div className="col-span-10 col-start-2 mb-2 flex items-center justify-around gap-12 rounded-lg py-6 pl-6 pr-12">
                <div className="w-[120px] text-center">Sıra</div>
                <div className="flex-1">Bayi</div>
                <div className="flex-1">Distribütör</div>
                <div className="w-[140px] text-right">Puan</div>
              </div>

              {leaderboardList &&
                leaderboardList.length > 0 &&
                leaderboardList.map((item, index) => {
                  return item.owner === true ? (
                    <div
                      key={item.indeks}
                      className="col-span-10 col-start-2 flex items-center justify-around gap-12 rounded-b-lg bg-primary-500 py-6 pl-6 pr-12"
                    >
                      <div className="w-[120px] text-center text-[24px] font-semibold leading-none text-white">
                        {item.indeks}
                      </div>
                      <div className="flex flex-1 items-center gap-5">
                        <div className="min-w-12 min-h-12 flex h-12 w-12 items-center justify-center rounded-full bg-primary-500 text-center text-white">
                          {item.imageUrl ? (
                            <img src={imageShowUrl + item.imageUrl} />
                          ) : (
                            getFirstTwoInitials(item.vendorName)
                          )}
                        </div>
                        <div>
                          <h3 className="text-white">{item.vendorName}</h3>
                          <p className="mt-1 text-xs text-white">
                            {item.subRegion} / {item.region}
                          </p>
                        </div>
                      </div>
                      <div className="flex-1 text-sm text-white">
                        {item.distributor}
                      </div>
                      <div className="w-[140px] text-right text-[24px] font-semibold leading-none text-white">
                        {item.score}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={
                        index === 2
                          ? "col-span-10 col-start-2 mb-14 flex items-center justify-around gap-12 rounded-lg bg-white py-6 pl-6 pr-12 shadow-md"
                          : "col-span-10 col-start-2 mb-2 flex items-center justify-around gap-12 rounded-lg bg-white py-6 pl-6 pr-12 shadow-md"
                      }
                      key={item.indeks}
                    >
                      <div className="w-[120px] text-center text-[24px] font-semibold leading-none text-slate-700">
                        {item.indeks}
                      </div>
                      <div className="flex flex-1 items-center gap-5">
                        <div className="min-w-12 min-h-12 flex h-12 w-12 items-center justify-center rounded-full bg-primary-500 text-center text-white">
                          {item.imageUrl ? (
                            <img
                              src={imageShowUrl + item.imageUrl}
                              className="rounded-full h-12"
                            />
                          ) : (
                            getFirstTwoInitials(item.vendorName)
                          )}
                        </div>
                        <div>
                          <h3 className="text-slate-700">{item.vendorName}</h3>
                          <p className="mt-1 text-xs text-slate-400">
                            {item.subRegion} / {item.region}
                          </p>
                        </div>
                      </div>
                      <div className="flex-1 text-sm text-slate-500">
                        {item.distributor}
                      </div>
                      <div className="w-[140px] text-right text-[24px] font-semibold leading-none text-slate-700">
                        {item.score}
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className={activeTab === 2 ? "grid grid-cols-12" : "hidden"}>
              <div className="col-span-10 col-start-2 mb-2 flex items-center justify-around gap-12 rounded-lg py-6 pl-6 pr-12">
                <div className="w-[120px] text-center">Sıra</div>
                <div className="flex-1">Bayi</div>
                <div className="flex-1">Distribütör</div>
                <div className="w-[140px] text-right">Puan</div>
              </div>

              {leaderboardRegionList &&
                leaderboardRegionList.length > 0 &&
                leaderboardRegionList.map((item, index) => {
                  return item.owner === true ? (
                    <div
                      key={item.indeks}
                      className="col-span-10 col-start-2 flex items-center justify-around gap-12 rounded-b-lg bg-primary-500 py-6 pl-6 pr-12"
                    >
                      <div className="w-[120px] text-center text-[24px] font-semibold leading-none text-white">
                        {item.indeks}
                      </div>
                      <div className="flex flex-1 items-center gap-5">
                        <div className="min-w-12 min-h-12 flex h-12 w-12 items-center justify-center rounded-full bg-primary-500 text-center text-white">
                          {getFirstTwoInitials(item.vendorName)}
                        </div>
                        <div>
                          <h3 className="text-white">{item.vendorName}</h3>
                          <p className="mt-1 text-xs text-white">
                            {item.subRegion} / {item.region}
                          </p>
                        </div>
                      </div>
                      <div className="flex-1 text-sm text-white">
                        {item.distributor}
                      </div>
                      <div className="w-[140px] text-right text-[24px] font-semibold leading-none text-white">
                        {item.score}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={
                        index === 2
                          ? "col-span-10 col-start-2 mb-14 flex items-center justify-around gap-12 rounded-lg bg-white py-6 pl-6 pr-12 shadow-md"
                          : "col-span-10 col-start-2 mb-2 flex items-center justify-around gap-12 rounded-lg bg-white py-6 pl-6 pr-12 shadow-md"
                      }
                      key={item.indeks}
                    >
                      <div className="w-[120px] text-center text-[24px] font-semibold leading-none text-slate-700">
                        {item.indeks}
                      </div>
                      <div className="flex flex-1 items-center gap-5">
                        <div className="min-w-12 min-h-12 flex h-12 w-12 items-center justify-center rounded-full bg-primary-500 text-center text-white">
                          {getFirstTwoInitials(item.vendorName)}
                        </div>
                        <div>
                          <h3 className="text-slate-700">{item.vendorName}</h3>
                          <p className="mt-1 text-xs text-slate-400">
                            {item.subRegion} / {item.region}
                          </p>
                        </div>
                      </div>
                      <div className="flex-1 text-sm text-slate-500">
                        {item.distributor}
                      </div>
                      <div className="w-[140px] text-right text-[24px] font-semibold leading-none text-slate-700">
                        {item.score}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Leaderboard;
