import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchFindAction } from "redux/modules";
import { Link } from "react-router-dom";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";
import { imageShowUrl } from "../../../config/api.config";

import ConversationIcon from "assets/portal-resource/img/games-icons/icon-conversation.png";
import CardIconGreen from "assets/images/new-design/game/card-icon-green.png";

import { IoArrowBack, IoArrowDown } from "react-icons/io5";
import { FaInfoCircle } from "react-icons/fa";

const SearchFindAnswer = props => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const resultSearchFind = useSelector(
    store => store.searchFind.resultSearchFind
  );
  var searchFindResult = null;
  const isLoading = useSelector(store => store.searchFind.isLoading);
  const [selectedItem, setSelectedItem] = useState("");
  const [nextBadge, setNextBadge] = useState({});
  const [questionId, setQuestionId] = useState("");

  // Set SelectedItem to State
  useEffect(() => {
    if (sessionStorage.getItem("selectedItem")) {
      setSelectedItem(JSON.parse(sessionStorage.getItem("selectedItem")));
    }

    if (sessionStorage.getItem("questionId")) {
      setQuestionId(parseInt(sessionStorage.getItem("questionId")));
    }

    if (sessionStorage.getItem("nextBadge")) {
      setNextBadge(JSON.parse(sessionStorage.getItem("nextBadge")));
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (selectedItem && selectedItem.id && questionId) {
      // Answer Request
      dispatch(
        searchFindAction.getResultSearchFind(
          selectedItem.id,
          employeeFirm.id,
          questionId
        )
      );
    }

    return () => {};
  }, [dispatch, employeeFirm.id, selectedItem, questionId]);

  if (resultSearchFind) {
    searchFindResult = resultSearchFind.map(item => {
      return (
        <div
          className="relative col-span-4 rounded-xl bg-slate-200 bg-opacity-75 p-6 text-sm leading-7 text-slate-500 lg:col-span-3 lg:px-12 lg:py-14 flex flex-col justify-center items-center"
          key={item}
        >
          {item.answerTrue ? (
            <>
              {item.wonBadge === false ? (
                <>
                  <h4 className="md:text-[48px] text-[24px] text-center font-semibold text-[#334155]">
                    🎉 Tebrikler
                  </h4>
                  <p className="md:text-[16px] text-[14px] text-center font-normal text-[#64748b] md:pt-[24px] pt-[12px]">
                    Doğru yanıtladınız. Bir sonraki rozeti kazanabilmeniz için{" "}
                    <strong>{item.countToNewBadge}</strong> soruya daha doğru
                    cevap vererek {nextBadge.badgeName} rozetini
                    kazanabilirsiniz. Soruyu doğru cevapladığınız için{" "}
                    <strong>{item.score}</strong> puan kazandınız.{" "}
                    {item.nextDayMessage}
                  </p>

                  <Link
                    to="/userportal/competitions"
                    className="font-medium inline-flex items-center justify-between mt-[24px] md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] py-[16px] pl-[24px] pr-[24px] md:pr-[36px]"
                  >
                    <IoArrowBack className="h-5 w-5" />
                    Yarışmalar{" "}
                  </Link>
                </>
              ) : (
                <>
                  <h4 className="md:text-[48px] text-[24px] text-center font-semibold text-[#334155]">
                    🎉 Tebrikler
                  </h4>

                  <p className="md:text-[16px] text-[14px] text-center font-normal text-[#64748b] md:pt-[24px] pt-[12px]">
                    <strong>{item.totalAnswer} </strong>soruya doğru cevap
                    vererek <strong>{nextBadge.badgeName} </strong> rozetini
                    kazandiniz. Soruyu doğru cevapladığınız için{" "}
                    <strong>{item.score}</strong> puan kazandınız.{" "}
                    {item.nextDayMessage}
                  </p>

                  <Link
                    to="/userportal/competitions"
                    className="font-medium inline-flex items-center justify-between mt-[24px] md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] py-[16px] pl-[24px] pr-[24px] md:pr-[36px]"
                  >
                    <IoArrowBack className="h-5 w-5" />
                    Yarışmalar{" "}
                  </Link>
                </>
              )}
            </>
          ) : null}

          {item.answerFalse ? (
            <>
              <h4 className="md:text-[48px] text-[24px] text-center font-semibold text-[#334155]">
                😔 Üzgünüz
              </h4>
              <p className="md:text-[16px] text-[14px] text-center font-normal text-[#64748b] md:pt-[24px] pt-[12px]">
                Yanlış yanıt verdiniz ve puan kazanamadınız. Bir sonraki rozeti
                kazanabilmeniz için <strong>{item.countToNewBadge} </strong>
                soruya daha doğru cevap vererek {nextBadge.badgeName} rozetini
                kazanabilirsiniz.{" "}
                {item.correctAnswer && <>{item.correctAnswer}</>}
                {item.nextDayMessage && <>{item.nextDayMessage}</>}
              </p>

              <Link
                to="/userportal/competitions"
                className="font-medium inline-flex items-center justify-between mt-[24px] md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] py-[16px] pl-[24px] pr-[24px] md:pr-[36px]"
              >
                <IoArrowBack className="h-5 w-5" />
                Yarışmalar{" "}
              </Link>
            </>
          ) : null}

          {item.answerTimeOut ? (
            <>
              <h4 className="md:text-[48px] text-[24px] text-center font-semibold text-[#334155]">
                😔 Üzgünüz
              </h4>
              <p className="md:text-[16px] text-[14px] text-center font-normal text-[#64748b] md:pt-[24px] pt-[12px]">
                Size verilen süre içerisinde (90 saniye) yanıt veremediniz ve
                puan kazanamadınız. Bir sonraki rozeti kazanabilmeniz için{" "}
                <strong>{item.countToNewBadge} </strong>
                soruya daha doğru cevap vererek {nextBadge.badgeName} rozetini
                kazanabilirsiniz.{" "}
                {item.correctAnswer && <>{item.correctAnswer}</>}
                {item.nextDayMessage && <>{item.nextDayMessage}</>}
              </p>

              <Link
                to="/userportal/competitions"
                className="font-medium inline-flex items-center justify-between mt-[24px] md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] py-[16px] pl-[24px] pr-[24px] md:pr-[36px]"
              >
                <IoArrowBack className="h-5 w-5" />
                Yarışmalar{" "}
              </Link>
            </>
          ) : null}

          {!item.answerTrue && !item.answerFalse && !item.answerTimeOut && (
            <div className="relative col-span-4 rounded-xl bg-opacity-75 p-6 text-sm leading-7 text-slate-500 lg:col-span-3 lg:px-12 lg:py-9 flex flex-col justify-center items-center">
              <h4 className="md:text-[24px] text-[18px] text-center font-semibold text-[#334155]">
                Rozet Macerası’na Hoşgeldiniz
              </h4>
              <p className="md:text-[16px] text-[14px] text-center font-normal text-[#64748b] md:pt-[16px] pt-[12px]">
                Rozet Macerası’nın yeni sorusunu Pazartesi, Çarşamba yada
                Perşembe günü yanıtlayabilirsiniz.
              </p>
              <Link
                to="/userportal/competitions"
                className="font-medium inline-flex items-center justify-between mt-[24px] md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] py-[16px] pl-[24px] pr-[24px] md:pr-[36px]"
              >
                <IoArrowBack className="h-4 w-4" />
                Yarışmalar{" "}
              </Link>
            </div>
          )}
        </div>
      );
    });
  }
  return (
    <main className="bg-slate-50 pb-20">
      <div className="mx-auto px-4 pt-4 xl:container lg:pt-20">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-4 mb-[16px]">
          <div className="col-span-4 flex flex-col justify-center bg-white px-[16px] pt-[34px] pb-[24px] text-center md:col-span-2 md:col-start-2 lg:col-span-1 rounded-[12px]">
            <img
              src={imageShowUrl + selectedItem.icon}
              className="mx-auto max-h-[66px] lg:max-h-[66px]"
              style={
                selectedItem.id === 149467
                  ? { height: "66px" }
                  : selectedItem.id === 149466
                  ? { height: "58px" }
                  : {}
              }
            />

            <div className="flex items-center justify-between mt-[48px]">
              <span className="text-primary-500 font-semibold text-[16px]">
                Rozet Durumu
              </span>
              <span className="text-primary-500 font-semibold text-[16px]">
                {selectedItem.employeeBadge}/{selectedItem.totalBadge}
              </span>
            </div>
            <div className="mt-3 h-2 w-full overflow-hidden rounded-lg bg-slate-200">
              <div
                className="h-full rounded-lg bg-primary-500"
                style={{
                  width:
                    (
                      (selectedItem.employeeBadge * 100) /
                      selectedItem.totalBadge
                    ).toFixed(2) + "%"
                }}
              ></div>
            </div>
          </div>
          <div className="relative col-span-4 bg-white flex md:flex-row flex-col justify-between items-center py-[40px] px-[48px] lg:col-span-3 rounded-[12px]">
            {selectedItem && selectedItem.id === 149464 && (
              <p className="md:text-[32px] text-[24px] text-[#334155] font-semibold max-w-[100%] lg:max-w-[200px]">
                Rozet Macerası Winston
              </p>
            )}

            {selectedItem && selectedItem.id === 149465 && (
              <p className="md:text-[32px] text-[24px] text-[#334155] font-semibold max-w-[100%] lg:max-w-[200px]">
                Rozet Macerası Camel
              </p>
            )}

            {selectedItem && selectedItem.id === 149466 && (
              <p className="md:text-[32px] text-[24px] text-[#334155] font-semibold max-w-[100%] lg:max-w-[200px]">
                Rozet Macerası LD
              </p>
            )}

            {selectedItem && selectedItem.id === 149467 && (
              <p className="md:text-[32px] text-[24px] text-[#334155] font-semibold max-w-[100%] lg:max-w-[200px]">
                Rozet Macerası Monte Carlo
              </p>
            )}

            {selectedItem.employeeBadge !== selectedItem.totalBadge && (
              <div className="flex items-center">
                <p className="md:text-[16px] text-[14px] text-[#64748b] font-normal pr-4">
                  Sıradaki Rozet:
                </p>
                <img
                  src={imageShowUrl + nextBadge.icon}
                  className="mx-auto max-h-[70px] lg:max-h-[125px]"
                  alt=""
                />
              </div>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
          <div className="col-span-4 flex flex-col max-h-[270px] md:max-h-[333px] h-[100%] md:h-[333px] justify-center rounded-xl bg-[url('assets/images/new-design/game/card-bg-green.png')] bg-no-repeat mx-auto w-full bg-cover p-6 text-center md:col-span-2 md:col-start-2 lg:col-span-1 lg:rounded-bl-[100px] rounded-bl-[22px]">
            <h1 className="mb-4 text-2xl font-bold text-white md:mb-6 lg:text-4xl">
              Rozet Macerası
            </h1>
            <img
              src={CardIconGreen}
              className="mx-auto max-h-[120px] lg:max-h-[200px]"
              alt=""
            />
          </div>

          {/* Rozet Macerası Sonuç */}
          {searchFindResult && searchFindResult}
        </div>
      </div>
    </main>
  );
};
export default SearchFindAnswer;
