import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lookFindAction } from "redux/modules";
import { Redirect } from "react-router-dom";
import { imageShowUrl } from "../../../config/api.config";
import { Link } from "react-router-dom";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";

import TimerIcon from "assets/portal-resource/img/games-icons/icon-timer.png";

const LookFindAnswer = props => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const resultLookFind = useSelector(store => store.lookFind.resultLookFind);
  var lookFindResult = null;
  const isLoading = useSelector(store => store.lookFind.isLoading);

  useEffect(() => {
    dispatch(
      lookFindAction.getResultLookFind(employeeFirm.firm.id, employeeFirm.id)
    );
    return () => {};
  }, []);

  if (resultLookFind) {
    lookFindResult = resultLookFind.map(item => {
      return (
        <div className="container-special-lookfind" key={item}>
          {item.answerTrue ? (
            <div className="row w-100">
              <div className="col-md-6 col-lg-4 d-flex align-items-center mb-3 mb-md-0">
                <img
                  src={imageShowUrl + item.iconResult}
                  style={{ maxWidth: "200px", margin: "0 auto" }}
                />
              </div>

              <div className="col-md-6 col-lg-8 d-flex align-items-start">
                <div className="search-find-result search-find-success">
                  <h2>🎉 Tebrikler,</h2>
                  <p className="description">
                    Doğru yanıt verdiniz ve <strong>{item.score}</strong> puan
                    kazandınız.
                    <br />
                    <br />
                    {item.nextDayMessage}
                  </p>
                </div>
              </div>
            </div>
          ) : null}

          {item.answerFalse ? (
            <div className="row w-100">
              <div className="col-md-6 col-lg-4 d-flex align-items-center mb-3 mb-md-0">
                <img
                  src={imageShowUrl + item.iconResult}
                  style={{ maxWidth: "200px", margin: "0 auto" }}
                />
              </div>

              <div className="col-md-6 col-lg-8 d-flex align-items-center">
                <div className="search-find-result search-find-wrong">
                  <h2>😔 Üzgünüz,</h2>
                  <p className="description">
                    Yanlış yanıt verdiniz ve puan kazanamadınız.
                    <br />
                    <br />
                    {item.correctAnswer}
                    <br />
                    <br />
                    {item.nextDayMessage}
                  </p>
                </div>
              </div>
            </div>
          ) : null}

          {item.answerTimeOut ? (
            <div className="row w-100">
              <div className="col-md-6 col-lg-4 d-flex align-items-center mb-3 mb-md-0">
                <img
                  src={imageShowUrl + item.iconResult}
                  style={{ maxWidth: "200px", margin: "0 auto" }}
                />
              </div>
              <div className="col-md-6 col-lg-8 d-flex align-items-center">
                <div className="search-find-result search-find-timeout p-0">
                  <h2>😔 Üzgünüz,</h2>
                  <p className="description">
                    Size verilen süre içerisinde (90 saniye) yanıt veremediniz
                    ve puan kazanamadınız.
                    <br />
                    <br />
                    {item.correctAnswer} <br />
                    <br />
                    {item.nextDayMessage}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      );
    });
  }
  return (
    <div className="root-page-content">
      <GlobalLoader isLoading={isLoading} />

      <div className="games games-detail-page w-100">
        <div className="container-fluid">
          <div className="wrapper-games-content row justify-content-center">
            <div className="col-12 col-md-4 col-lg-3 look-find d-flex flex-column">
              <div className="games-content">
                <div className="games-content-item">
                  <div className="games-content-header">
                    <img src={TimerIcon} className="img-fluid" />
                  </div>

                  <div className="games-content-body games-content-badge-body">
                    <h3>BUL KAZAN</h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-8 col-lg-9 d-flex flex-column justify-content-center">
              <div className="games-brands games-badges">{lookFindResult}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LookFindAnswer;
