import * as userTypes from "./user.type";

function returnCatList(text) {
  return text;
}

export const setLoginedUser = text => async dispatch => {
  dispatch({
    type: userTypes.SET_LOGINED_USER_PENDING
  });

  try {
    const returnComponent = returnCatList(text);
    dispatch({
      type: userTypes.SET_LOGINED_USER_FULFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: userTypes.SET_LOGINED_USER_REJECTED,
      payload: error
    });
  }
};
