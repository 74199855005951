import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { weekStoreAction } from "redux/modules";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";

const WeekStoreLeftMenu = () => {
  const dispatch = useDispatch();
  const [showWinner, setShowWinner] = useState(null);
  const selectedWeekStoreCandidate = useSelector(
    store => store.weekStore.selectedWeekStoreCandidate
  );
  const isLoading = useSelector(store => store.weekStore.isLoading);

  useEffect(() => {
    return () => {};
  }, [showWinner]);

  function setClicked(key) {
    dispatch(weekStoreAction.setWeekStoreContent(key));
  }
  return (
    <div className="detail-page-left-menu">
      <GlobalLoader isLoading={isLoading} />
      {selectedWeekStoreCandidate ? null : (
        <ul>
          <li className={`${showWinner ? "d-flex" : "d-flex active"}`}>
            <a
              onClick={() => {
                setClicked("competition");
                setShowWinner(null);
              }}
            >
              Haftanın Dükkanı
            </a>
          </li>

          <li className={`${showWinner ? "d-flex active" : "d-flex"}`}>
            <a
              onClick={() => {
                setClicked("winner");
                setShowWinner("show");
              }}
            >
              Haftanın Kazananları
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export default WeekStoreLeftMenu;
