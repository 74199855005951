import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import AboutBottomIcon from "assets/portal-resource/img/about-bottom-img.jpg";
import KTIcon from "assets/portal-resource/img/KT-2.png";

const JtiTurkeyAbout = props => {
  return (
    <section className="about-page about-3-page bbd-menu-active">
      <div className="page-container">
        <div className="about-text">
          <h2>JTI Türkiye Hakkında</h2>
          <div className="about-bottom-img">
            <img src={AboutBottomIcon} alt="" />
          </div>
          <p>
            JTI Türkiye, JTI Tütün Ürünleri Pazarlama A.Ş., JTI Tütün Ürünleri
            Sanayi A.Ş. ve JTI Yaprak Tütün A.Ş şirketlerinden oluşmaktadır.
          </p>
          <br />
          <p>
            JTI Türkiye operasyonlarını, Torbalı’daki yönetim ve üretim merkezi,
            Ankara ve İstanbul irtibat büroları ve tüm Türkiye’ye yayılmış
            toplam 7 bölge satış ofisi ile sürdürmektedir. Şirket, Türkiye tütün
            ürünleri pazarının ikinci büyük tütün şirketidir.
          </p>
          <br />
          <p>
            İç pazarda portföyünde 4 marka ve 38 çeşit bulunan JTI Türkiye,
            40'dan fazla ülkeye 16 farklı marka ihraç ederek yıllık ortalama 100
            milyon Amerikan doları tutarında ihracat gerçekleştirmektedir.
            Türkiye'den tütün ürünleri ihraç eden ilk şirket olan JTI
            Türkiye’nin ihracata başladığı 1994 yılından bu yana
            gerçekleştirdiği toplam ihracat tutarı 3 milyar Amerikan Dolarını
            geçmiştir. 2022 yılında 1.7 milyar Amerikan Doları vergi ödeyen JTI
            Türkiye, iç pazardan aldığı yıllık 67 Milyon Amerikan Doları şark
            tipi tütün ile Türk çiftçisine de destek olmaktadır.
          </p>
        </div>
        <div className="about-img">
          <img src={KTIcon} alt="" />
        </div>
      </div>
    </section>
  );
};
export default JtiTurkeyAbout;
