import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homeAction, userAction } from "redux/modules";
import { imageShowUrl, videoShowUrl } from "../../config/api.config";
import { Link } from "react-router-dom";
import { API } from "../../services/http.service";
import Modal from "react-responsive-modal";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

import "./HomeSlider.scss";

// import Swiper core and required modules
import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const HomeAnnouncement = props => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const announcementList = useSelector(store => store.home.announcementList);

  // Focus States
  const [open, setOpen] = useState(true);
  const [modalImg, setModalImg] = useState(null);
  const [web, setWeb] = useState();
  const [mob, setMob] = useState();

  let announcements = null;
  window.$.fancybox.defaults.hash = false;

  useEffect(() => {
    let abortController = new AbortController();

    dispatch(
      homeAction.getAnnouncementByFirmId(employeeFirm.firm.id, employeeFirm.id)
    );

    return () => {
      abortController.abort();
    };
  }, [
    dispatch,
    homeAction,
    employeeFirm,
    employeeFirm.id,
    employeeFirm.firm,
    employeeFirm.firm.id
  ]);

  useEffect(() => {
    let abortController = new AbortController();

    const asyncPopupImg = async () => {
      const { data } = await API.get("focus/General");
      setModalImg(data);
      if (
        data &&
        data[0].webPic != undefined &&
        data[0].mobilPic != undefined
      ) {
        setWeb(data[0].webPic);
        setMob(data[0].mobilPic);
      }
    };
    asyncPopupImg();

    return () => {
      abortController.abort();
    };
  }, [modalImg === null]);

  function closeModal(params) {
    setOpen(!open);
    dispatch(userAction.setLoginedUser("logined"));
  }

  if (announcementList) {
    announcements = announcementList.map(item => {
      return (
        <SwiperSlide key={item.id}>
          {item.newTab ? (
            <a href={item.url} target="_blank">
              <div className="main-slider-bg main-slider-desktop">
                <img
                  src={imageShowUrl + item.icon}
                  className="img-fluid"
                  alt={item.name}
                />
              </div>
              <div className="main-slider-bg main-slider-mobil">
                <img
                  src={imageShowUrl + item.iconPhone}
                  className="img-fluid"
                  alt={item.name}
                />
              </div>
            </a>
          ) : (
            <div>
              {item.videoUrl ? (
                <a href={videoShowUrl + item.videoUrl} data-fancybox="video">
                  <div className="main-slider-bg main-slider-desktop">
                    <img
                      src={imageShowUrl + item.icon}
                      className="img-fluid"
                      alt={item.name}
                    />
                  </div>
                  <div className="main-slider-bg main-slider-mobil">
                    <img
                      src={imageShowUrl + item.iconPhone}
                      className="img-fluid"
                      alt={item.name}
                    />
                  </div>
                </a>
              ) : item.brandId && item.brandStyleIndex === undefined ? (
                <Link
                  to={{
                    pathname: "/userportal/brand",
                    brandId: item.brandId,
                    brandIndex: item.brandIndex,
                    handleImgClick: true
                  }}
                >
                  <div className="main-slider-bg main-slider-desktop">
                    <img
                      src={imageShowUrl + item.icon}
                      className="img-fluid"
                      alt={item.name}
                    />
                  </div>
                  <div className="main-slider-bg main-slider-mobil">
                    <img
                      src={imageShowUrl + item.iconPhone}
                      className="img-fluid"
                      alt={item.name}
                    />
                  </div>
                </Link>
              ) : item.brandId && item.brandStyleIndex ? (
                <Link
                  to={{
                    pathname: "/userportal/brand",
                    brandId: item.brandId,
                    brandIndex: item.brandIndex,
                    brandStyleIndex: item.brandStyleIndex,
                    handleImgClick: true
                  }}
                >
                  <div className="main-slider-bg main-slider-desktop">
                    <img
                      src={imageShowUrl + item.icon}
                      className="img-fluid"
                      alt={item.name}
                      data-brand={item.brandStyleIndex}
                    />
                  </div>

                  <div className="main-slider-bg main-slider-mobil">
                    <img
                      src={imageShowUrl + item.iconPhone}
                      className="img-fluid"
                      alt={item.name}
                      data-brand={item.brandStyleIndex}
                    />
                  </div>
                </Link>
              ) : item.popup === true ? (
                <a
                  data-fancybox="gallery2"
                  href={imageShowUrl + item.popupIcon}
                >
                  <div className="main-slider-bg main-slider-desktop">
                    <img
                      src={imageShowUrl + item.icon}
                      className="img-fluid"
                      alt={item.name}
                    />
                  </div>
                  <div className="main-slider-bg main-slider-mobil">
                    <img
                      src={imageShowUrl + item.iconPhone}
                      className="img-fluid"
                      alt={item.name}
                    />
                  </div>
                </a>
              ) : (
                <a href={item.url}>
                  <div className="main-slider-bg main-slider-desktop">
                    <img
                      src={imageShowUrl + item.icon}
                      className="img-fluid"
                      alt={item.name}
                    />
                  </div>
                  <div className="main-slider-bg main-slider-mobil">
                    <img
                      src={imageShowUrl + item.iconPhone}
                      className="img-fluid"
                      alt={item.name}
                    />
                  </div>
                </a>
              )}
            </div>
          )}
        </SwiperSlide>
      );
    });
  }

  return (
    <section
      className="main-slider main-slider-homepage w-full rounded-br-[100px] max-w-[1920px] m-auto overflow-hidden"
      id="main-slider-announcement"
    >
      <div className="main-slider-container">
        <Swiper
          // install Swiper modules
          modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={1}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next"
          }}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
        >
          {announcements}

          <div className="swiper-button-prev">
            <IoChevronBack className="text-white h-10 w-10" />
          </div>
          <div className="swiper-button-next">
            <IoChevronForward className="text-white h-10 w-10" />
          </div>
        </Swiper>
      </div>

      {web != undefined && mob != undefined ? (
        <Modal
          open={!open}
          onClose={() => {
            closeModal();
          }}
          role="dialog"
          closeOnEsc={false}
          showCloseIcon={true}
          closeOnOverlayClick={false}
          closeIconId="focus-close-icon"
        >
          <div className="d-none d-sm-block web">
            <img src={imageShowUrl + web} alt="" />
          </div>
          <div className="d-block d-sm-none mobile">
            <img src={imageShowUrl + mob} alt="" />
          </div>
        </Modal>
      ) : null}
    </section>
  );
};
export default HomeAnnouncement;
