import * as loginTypes from "./employeeFirm.type";

const initialState = {
  employeeFirm: null,
  employeeLoginDetails: null,
  securityStatus: null,
  safeDevice: null,
  error: null,
  isLoading: false
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${loginTypes.GET_EMPLOYEE_FIRM_BY_ID_PENDING}`:
    case `${loginTypes.GET_EMPLOYEE_FIRM_LOGIN_DETAILS_PENDING}`:
    case `${loginTypes.SET_SECURITY_STATUS_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${loginTypes.GET_EMPLOYEE_FIRM_BY_ID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        employeeFirm: payload.data
      };
    case `${loginTypes.GET_EMPLOYEE_FIRM_BY_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        employeeFirm: null
      };

    case `${loginTypes.GET_EMPLOYEE_FIRM_LOGIN_DETAILS_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        employeeLoginDetails: payload.data
      };
    case `${loginTypes.GET_EMPLOYEE_FIRM_LOGIN_DETAILS_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        employeeLoginDetails: null
      };

    case `${loginTypes.SET_SECURITY_STATUS_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        securityStatus: payload.data
      };
    case `${loginTypes.SET_SECURITY_STATUS_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        securityStatus: null
      };

    case `${loginTypes.SET_SAFE_DEVICE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        safeDevice: payload.data
      };
    case `${loginTypes.SET_SAFE_DEVICE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        safeDevice: null
      };

    default:
      return state;
  }
};

export default reducer;
