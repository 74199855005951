import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { homeAction } from "redux/modules";

const UserPortalInfo = () => {
  const dispatch = useDispatch();
  const homeOrderInfo = useSelector(store => store.home.homeOrderInfo);
  const employeeFirm = useSelector(store => store.auth.employeeFirm);

  useEffect(() => {
    dispatch(homeAction.getHomeOrderInfo(employeeFirm.id));

    return () => {};
  }, []);

  return (
    <div className="flex items-center justify-end gap-6 bg-white px-6 md:px-12 py-4 text-[12px] md:text-[14px] text-slate-700 shadow-md">
      <div className="flex items-center gap-2">
        Kalan Sipariş Hakkı:
        <span className="text[13px] md:text-[15px] text-primary-500">
          {homeOrderInfo?.orderCount && homeOrderInfo?.orderCount}
        </span>
      </div>
      <div className="flex items-center gap-2">
        Kalan Süre:
        <span className="ms-1 text-slate-500">
          {homeOrderInfo?.remainTime && homeOrderInfo.remainTime}
        </span>
      </div>
    </div>
  );
};

export default UserPortalInfo;
