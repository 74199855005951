import * as orderTypes from "./order.type";

const initialState = {
  orderList: [],
  error: null,
  isLoading: false,
  orderLogo: null,
  orderListHistory: [],
  address: ""
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${orderTypes.GET_ORDERS_BY_ID_PENDING}`:
    case `${orderTypes.GET_ORDER_NUMBER_PENDING}`:
    case `${orderTypes.SET_ORDER_LOGO_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${orderTypes.GET_ORDERS_BY_ID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        orderList: payload.data
      };
    case `${orderTypes.GET_ORDERS_BY_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        orderList: null
      };

    case `${orderTypes.GET_ORDER_NUMBER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        orderList: payload.data
      };
    case `${orderTypes.GET_ORDER_NUMBER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        orderList: null
      };

    case `${orderTypes.SET_ORDER_LOGO_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        orderLogo: payload.data
      };
    case `${orderTypes.SET_ORDER_LOGO_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        orderLogo: null
      };

    case `${orderTypes.SET_ORDER_LIST_FULFILLED}`:
      return {
        ...state,
        orderListHistory: payload
      };
    case `${orderTypes.SET_ORDER_LIST_PENDING}`:
      return {
        ...state,
        orderListHistory: false
      };
    case `${orderTypes.SET_ORDER_LIST_REJECTED}`:
      return {
        ...state,
        orderListHistory: false
      };
    case `${orderTypes.SET_ADDRESS_FULFILLED}`:
      return {
        ...state,
        address: payload
      };
    case `${orderTypes.SET_ADDRESS_PENDING}`:
      return {
        ...state,
        address: false
      };
    case `${orderTypes.SET_ADDRESS_REJECTED}`:
      return {
        ...state,
        address: false
      };

    default:
      return state;
  }
};

export default reducer;
