import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import showErrMessage from "hooks/showErrMessage";
import showMessage from "hooks/showMessage";
import { ourArenaAction } from "redux/modules";
import Picker from "emoji-picker-react";
import Pagination from "react-js-pagination";

import { IoArrowForward } from "react-icons/io5";

const SubjectOfWeek = () => {
  // Emoji Picker
  const [initialMessageText, setInitialMessageText] = useState("");
  const [chosenEmoji, setChosenEmoji] = useState(null);
  //const [message, setMessageForm] = useState("");
  const ref = useRef(null);
  const onEmojiClick = (event, emojiObject) => {
    const cursor = ref.current.selectionEnd;
    const text =
      initialMessageText.slice(0, cursor) +
      initialMessageText.slice(cursor) +
      emojiObject.emoji;
    setInitialMessageText(text);
  };

  // SubjectOfWeek
  const dispatch = useDispatch();
  const [keyDown, setKeyDown] = useState(false);
  const [remainingCharCount, setRemainingCharCount] = useState(500);
  //const [initialMessageText, setInitialMessageText] = useState("");
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const subjectList = useSelector(store => store.ourArena.subjectList);
  const isLoading = useSelector(store => store.ourArena.isLoading);
  const [activePage, setActivePage] = useState(1);
  const totalPages = useSelector(store => store.ourArena.totalPages);

  useEffect(() => {
    dispatch(ourArenaAction.getSubject(employeeFirm.id));
    return () => {};
  }, []);

  function wordCount(e) {
    var currentText = e.target.value;
    setInitialMessageText(currentText);
    var characterCount = currentText.length;
    setRemainingCharCount(500 - characterCount);
  }

  function checkRequiredFields(e) {
    if (initialMessageText.length <= 0) {
      showErrMessage("Mesaj Giriniz");
      e.preventDefault();
      setInitialMessageText("");
      setRemainingCharCount(500);
    } else if (!subjectList || subjectList.length < 1) {
      showErrMessage("Bu haftanın bir konusu henüz yoktur.");
      e.preventDefault();
      setInitialMessageText("");
      setRemainingCharCount(500);
    } else {
      saveInitialMessage(initialMessageText);
    }
  }

  function handlePageChange(pageNumber) {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    setActivePage(pageNumber);
    dispatch(ourArenaAction.getSubject(employeeFirm.id, pageNumber - 1));
  }

  function saveInitialMessage(message) {
    dispatch(
      ourArenaAction.saveInitialMessage(
        message,
        subjectList[0].id,
        employeeFirm.id
      )
    )
      .then(e => {
        showMessage("Yorumunuz onaylandıktan sonra görüntülenecektir.");
        setInitialMessageText("");
        setRemainingCharCount(500);
      })
      .catch(err => {
        showErrMessage("Mesajınız gönderilemedi");
      });
  }
  async function likePost(forumLikeId, messageId, subjectId) {
    await dispatch(
      ourArenaAction.saveLike(
        forumLikeId,
        messageId,
        employeeFirm.id,
        subjectId
      )
    ).catch(err => {
      showErrMessage("Daha önce bu yorumu beğendiniz.");
    });
    dispatch(ourArenaAction.getSubject(employeeFirm.id));
  }

  function toggleShowHide2(e) {
    e.preventDefault();

    e.target.nextElementSibling.classList.toggle("hide");
  }

  function commentPost(senderName) {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    setInitialMessageText("@" + senderName);
  }

  // UseRef
  const pickerWrapperRef = useRef(null);

  const handleClick = event => {
    if (
      pickerWrapperRef.current &&
      !pickerWrapperRef.current.contains(event.target) &&
      !event.target.closest(".picker-wrapper")
    ) {
      const pickerWrapper2Elements = document.querySelectorAll(
        ".picker-wrapper .pickerWrapper2"
      );
      pickerWrapper2Elements.forEach(element => {
        element.classList.add("hide");
      });
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <div>
      {subjectList && subjectList.length > 0 ? (
        <div className="message">
          <form
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <div className={`textarea ${keyDown ? "active" : ""}`}>
              {subjectList && subjectList.length > 0 ? (
                <div className="position-relative">
                  <div
                    className="position-absolute text-right picker-wrapper"
                    style={{ right: "0" }}
                    ref={pickerWrapperRef}
                  >
                    <div
                      className="emoji-button"
                      onClick={e => toggleShowHide2(e)}
                    >
                      😃
                    </div>
                    <div
                      className="hide pickerWrapper2"
                      style={{
                        position: "relative",
                        zIndex: "9999",
                        userSelect: "none"
                      }}
                    >
                      <Picker onEmojiClick={onEmojiClick} />
                    </div>
                  </div>
                  <textarea
                    id="benim-meydan-textarea"
                    name="mesaj"
                    placeholder="Yeni Mesaj"
                    maxLength="500"
                    ref={ref}
                    value={initialMessageText}
                    onKeyPress={e => {
                      if (e.key !== "Enter") return;
                    }}
                    onChange={e => setInitialMessageText(e.target.value)}
                  />
                </div>
              ) : (
                <textarea
                  id="benim-meydan-textarea"
                  name="mesaj"
                  placeholder="Yeni Mesaj"
                  maxLength="500"
                  disabled={true}
                ></textarea>
              )}
            </div>
            <div className="actions d-flex align-items-center">
              <div className="count">
                <span>{remainingCharCount} </span>
                karakter kaldı
              </div>
              {subjectList && subjectList.length > 0 ? (
                <div className="ml-auto">
                  <button
                    type="submit"
                    onClick={checkRequiredFields}
                    className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[135px] min-w-[120px] max-h-[52px] rounded-full border-2 bg-[#00b185] border-[#00b185] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px] text-white"
                  >
                    Gönder <IoArrowForward className="h-5 w-5 text-white" />
                  </button>
                </div>
              ) : (
                <div className="ml-auto">
                  <button
                    type="submit"
                    onClick={checkRequiredFields}
                    className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[135px] min-w-[120px] max-h-[52px] rounded-full border-2 bg-[#00b185] border-[#00b185] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px] text-white"
                    disabled={true}
                  >
                    Gönder <IoArrowForward className="h-5 w-5 text-white" />
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      ) : null}
      {subjectList && subjectList.length > 0 ? (
        subjectList.map((item, index) => {
          return (
            <div key={index} className="topic-box">
              <div className="topic">
                <div className="d-flex">
                  <div className="down-icon">
                    <div className="icon">
                      <div></div>
                    </div>
                  </div>
                  <div className="question flex-grow-1">
                    <p>{item.subject}</p>
                    <div className="sub d-flex">
                      <div>Kimden : {item.senderName}</div>
                      <div className="ml-auto">
                        {item.dateBegin.slice(0, 10)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="stats d-flex">
                  <div className="likes mr-2">
                    <div className="stat-box d-flex align-items-center">
                      <div
                        className={`${
                          item.forumMessageLikeStatus === 1
                            ? "icon heart-red"
                            : "icon heart"
                        }`}
                        onClick={() => {
                          likePost(null, null, item.id);
                        }}
                      ></div>
                      <div className="stat">{item.countLike}</div>
                    </div>
                  </div>
                  <div className="comments">
                    <div className="stat-box d-flex align-items-center">
                      <div className="icon comment"></div>
                      <div className="stat">{item.countComment}</div>
                    </div>
                  </div>
                </div>
              </div>
              {item.forumMessageList && item.forumMessageList.length > 0
                ? item.forumMessageList.map((item2, index2) => {
                    return (
                      <div key={index2} className="answers">
                        <div className="answer d-flex">
                          <div className="message">
                            <div className="text">{item2.message}</div>
                            <div className="from mt-3">
                              Kimden: {item2.senderName}
                            </div>
                          </div>
                          <div className="stats ml-auto">
                            <div className="d-flex">
                              <div className="stat-box d-flex align-items-center">
                                <div
                                  className={`${
                                    item2.forumMessageLikeStatus === 1
                                      ? "icon heart-red"
                                      : "icon heart"
                                  }`}
                                  onClick={() => {
                                    likePost(
                                      item2.forumMessageLikeId,
                                      item2.id,
                                      item2.subjectId
                                    );
                                  }}
                                ></div>
                                <div className="stat">{item2.countLike}</div>
                              </div>
                              <div className="stat-box d-flex align-items-center ml-2">
                                <div
                                  className="icon comment"
                                  onClick={() => {
                                    commentPost(item2.senderName);
                                  }}
                                ></div>
                                <div className="stat">{item2.countComment}</div>
                              </div>
                            </div>
                            <div className="date text-right">
                              {item2.dateBegin.slice(0, 10)}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
              {subjectList[0].forumMessageList &&
              subjectList[0].forumMessageList.length > 0 ? (
                <div className="wrapper-pagination">
                  <Pagination
                    activePage={activePage}
                    totalItemsCount={10 * totalPages}
                    itemsCountPerPage={10}
                    pageRangeDisplayed={
                      totalPages !== null && totalPages > 5 ? 5 : totalPages
                    }
                    itemClass="page-item"
                    linkClass="page-link"
                    onChange={handlePageChange.bind(this)}
                  />
                </div>
              ) : null}
            </div>
          );
        })
      ) : (
        <p>Gösterilecek bir konu yoktur.</p>
      )}
    </div>
  );
};
export default SubjectOfWeek;
