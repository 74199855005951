export const GET_AVAILABLEPICKFIND_BY_FIRMID_PENDING =
  "GET_AVAILABLEPICKFIND_BY_FIRMID_PENDING";
export const GET_AVAILABLEPICKFIND_BY_FIRMID_FULFILLED =
  "GET_AVAILABLEPICKFIND_BY_FIRMID_FULFILLED";
export const GET_AVAILABLEPICKFIND_BY_FIRMID_REJECTED =
  "GET_AVAILABLEPICKFIND_BY_FIRMID_REJECTED";

export const GET_PICKFINDLIST_BY_FIRMID_PENDING =
  "GET_PICKFINDLIST_BY_FIRMID_PENDING";
export const GET_PICKFINDLIST_BY_FIRMID_FULFILLED =
  "GET_PICKFINDLIST_BY_FIRMID_FULFILLED";
export const GET_PICKFINDLIST_BY_FIRMID_REJECTED =
  "GET_PICKFINDLIST_BY_FIRMID_REJECTED";

export const SAVE_PICKFIND_ANSWER_PENDING = "SAVE_PICKFIND_ANSWER_PENDING";
export const SAVE_PICKFIND_ANSWER_FULFILLED = "SAVE_PICKFIND_ANSWER_FULFILLED";
export const SAVE_PICKFIND_ANSWER_REJECTED = "SAVE_PICKFIND_ANSWER_REJECTED";
