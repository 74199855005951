import axios from "axios";
import { baseURL, isActiveHttpLogger } from "../config/api.config";
import { ERROR_MESSAGES } from "../constants/messages.const";

const API = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json"
  },
  timeout: 60000
});

API.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers = {
        Authorization: "Bearer " + token
      };
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  function(response) {
    return Promise.resolve({ ...response.data, isHttpSuccess: true });
  },
  function(error) {
    let status = (error.response && error.response.status) || 0;
    let message = error.response && error.response.data.message;

    // axios timeout
    if (error.code === "ECONNABORTED") {
      status = 504;
    }

    message = message || ERROR_MESSAGES[status] || ERROR_MESSAGES.default;

    if (isActiveHttpLogger) {
      console.error("Warning Check Services: ", {
        ...error
      });
      console.error(message, status);
    }

    return Promise.reject({ status, message, error, isHttpError: true });
  }
);

export { API };
