import * as weekStoreTypes from "./weekStore.type";

import { API } from "../../../services/http.service";
import WeekCompetition from "pages/UserPortal/WeekStore/WeekCompetition";
import WeekWinner from "pages/UserPortal/WeekStore/WeekWinner";
const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

function returnFromKey(key) {
  switch (key) {
    case `competition`:
      return WeekCompetition;

    case `winner`:
      return WeekWinner;

    default:
      return WeekCompetition;
  }
}

export const setWeekStoreContent = key => async dispatch => {
  dispatch({ type: weekStoreTypes.SET_WEEK_STORE_CONTENT_PENDING });

  try {
    const returnComponent = returnFromKey(key);
    dispatch({
      type: weekStoreTypes.SET_WEEK_STORE_CONTENT_FULFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: weekStoreTypes.SET_WEEK_STORE_CONTENT_REJECTED,
      payload: error
    });
  }
};

export const getWeekStoreToBeVotedList = firmId => async dispatch => {
  dispatch({ type: weekStoreTypes.GET_WEEK_STORE_TO_BE_VOTED_LIST_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/weekStoreToBeVotedList/${firmId}`,
      config
    );
    dispatch({
      type: weekStoreTypes.GET_WEEK_STORE_TO_BE_VOTED_LIST_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: weekStoreTypes.GET_WEEK_STORE_TO_BE_VOTED_LIST_REJECTED,
      payload: error
    });
  }
};

export const setSliderContent = slideNumber => {
  return {
    type: weekStoreTypes.SET_SELECTED_SLIDE_FULFILLED,
    payload: slideNumber
  };
};

export const getMyVotesByCompetition = (
  employeeFirmId,
  competitionId
) => async dispatch => {
  dispatch({ type: weekStoreTypes.GET_MY_VOTES_BY_COMPETITION_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/myVotes/${employeeFirmId}/${competitionId}`,
      config
    );
    dispatch({
      type: weekStoreTypes.GET_MY_VOTES_BY_COMPETITION_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: weekStoreTypes.GET_MY_VOTES_BY_COMPETITION_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const voteVendor = (
  firmId,
  employeeFirmId,
  competitionId,
  competitionToBeVotedId
) => async dispatch => {
  dispatch({ type: weekStoreTypes.VOTE_VENDOR_PENDING });

  const data = { employeeFirmId, competitionId, competitionToBeVotedId };
  try {
    const response = await API.post(`/doVote`, data);

    dispatch({
      type: weekStoreTypes.VOTE_VENDOR_FULFILLED,
      payload: response
    });
    dispatch(getWeekStoreToBeVotedList(firmId));
    dispatch(getMyVotesByCompetition(employeeFirmId, competitionId));
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: weekStoreTypes.VOTE_VENDOR_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getWeekWinnerList = firmId => async dispatch => {
  dispatch({ type: weekStoreTypes.GET_WEEK_WINNER_LIST_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/weekWinnerList/${firmId}`,
      config
    );
    dispatch({
      type: weekStoreTypes.GET_WEEK_WINNER_LIST_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: weekStoreTypes.GET_WEEK_WINNER_LIST_REJECTED,
      payload: error
    });
  }
};
