import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import JtiLogo from "assets/portal-resource/img/jtlogo.png";

const JtAbout = props => {
  return (
    <section className="about-page about-1-page bbd-menu-active">
      <div className="page-container">
        <div className="about-text">
          <h2>JT Hakkında</h2>
          <p>
            Tütün ürünleri sektöründe dünyanın üçüncü büyük şirketi JT, 1898
            yılında Japonya Maliye Bakanlığı bünyesinde Tekel İdaresi olarak
            kurulmuştur. 1985 yılında özelleştirilerek Japan Tobacco (JT) adıyla
            hizmet vermeye başlayan şirketin hisselerinin %33'ü Japon hükümetine
            aittir.
          </p>{" "}
          <br />
          <p>
            JT bugün tütün haricinde, tıbbi ürünler ve gıda sektörlerinde de
            faaliyet gösteren ve 53 bin çalışanı olan çok uluslu bir şirkettir.
            Şirketin uluslararası tütün iş kolu JTI, JT’nin büyümesinde
            lokomotif görevi görmektedir.
          </p>
        </div>
        <img src={JtiLogo} alt="" className="w-full max-w-[600px]" />
      </div>
    </section>
  );
};
export default JtAbout;
