import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { employeeFirmAction } from "redux/modules";

import Moment from "react-moment";

const LoginDetails = () => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const loginDetails = useSelector(
    store => store.employeeFirm.employeeLoginDetails
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(employeeFirmAction.getLoginDetails(employeeFirm.id));
  }, []);

  return (
    <div
      className="tab-pane fade"
      id="logininfo"
      role="tabpanel"
      aria-labelledby="logininfo-tab"
    >
      <div className="message-item-container">
        {loginDetails &&
          loginDetails.map((item, index) => {
            return (
              <div className="message-item" key={index}>
                <div className="message-item-content">
                  <div className="message-item-title">
                    <div className="message-item-flex">
                      <div className="message-item-title-text">
                        {item.device && <h2>{item.device}</h2>}
                        {item.ipAddress && <span>{item.ipAddress}</span>}
                      </div>

                      {item.isSuccess !== null && (
                        <div className="date">
                          Giriş: {item.isSuccess ? "Başarılı" : "Başarısız"}
                        </div>
                      )}
                      {item.visitDate && (
                        <div className="date">
                          <Moment format="DD-MM-YYYY HH:mm">
                            {item.visitDate}
                          </Moment>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default LoginDetails;
