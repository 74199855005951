import * as pickFindTypes from "./pickFind.type";

const initialState = {
  pickFindList: null,
  controlPickFind: null,
  error: null,
  isLoading: false /* ,
  activesTop: [],
  activesBottom: [] */
};

const reducer = (
  state = initialState,
  { type, payload, activesTop, activesBottom, ...params }
) => {
  switch (type) {
    case `${pickFindTypes.GET_AVAILABLEPICKFIND_BY_FIRMID_PENDING}`:
    case `${pickFindTypes.GET_PICKFINDLIST_BY_FIRMID_PENDING}`:
    case `${pickFindTypes.SAVE_PICKFIND_ANSWER_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${pickFindTypes.GET_PICKFINDLIST_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        pickFindList:
          payload.data /* ,
        activesTop: activesTop,
        activesBottom: activesBottom */
      };

    case `${pickFindTypes.GET_PICKFINDLIST_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${pickFindTypes.GET_AVAILABLEPICKFIND_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        controlPickFind: payload.data
      };

    case `${pickFindTypes.GET_AVAILABLEPICKFIND_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${pickFindTypes.SAVE_PICKFIND_ANSWER_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };

    case `${pickFindTypes.SAVE_PICKFIND_ANSWER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    default:
      return state;
  }
};

export default reducer;
