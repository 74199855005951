import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lookFindAction } from "redux/modules";
import showMessage from "hooks/showMessage";
import showErrMessage from "hooks/showErrMessage";
import { Link } from "react-router-dom";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import TimerIcon from "assets/portal-resource/img/games-icons/icon-timer.png";

const LookFind = props => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const controlLookFind = useSelector(store => store.lookFind.controlLookFind);
  var lookFindGame = null;
  const isLoading = useSelector(store => store.lookFind.isLoading);

  useEffect(() => {
    dispatch(
      lookFindAction.getControlLookFindByFirmId(
        employeeFirm.firm.id,
        employeeFirm.id
      )
    );
    return () => {};
  }, []);

  if (controlLookFind) {
    lookFindGame = controlLookFind.map((item, index) => {
      return (
        <div key={index}>
          {item.availableTodayQuestion &&
          !item.answeredTodayQuestion &&
          !item.answerTimeOut ? (
            <div className="mt-5">
              <p className="games-description">
                JTI ürünleri ile ilgili bilgi yarışmasıdır. Her hafta{" "}
                <strong>Salı, Çarşamba ve Cuma </strong>günleri karşınıza çıkar.
                Soruda verilen ürün özelliklerine bakarak{" "}
                <strong>90 saniye</strong> içinde bu ürünün hangisi olduğunu
                bulun ve <strong>10 puan</strong> kazanın.
                <br />
                <br />
                Bugünün Bul Kazan sorusunu görmek için <strong>
                  Başla
                </strong>{" "}
                butonuna tıklayınız.
                <br />
                <br />
                Başarılar!
              </p>

              <div className="mt-3 d-flex justify-content-end w-100">
                <Link
                  to={{
                    pathname: "/userportal/lookFindQuestion"
                  }}
                  className="btn-games btn-games-success"
                >
                  <span>BAŞLA</span>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{
                      fontSize: "16px",
                      color: "#fff"
                    }}
                  />
                </Link>
              </div>
            </div>
          ) : null}
          {item.answeredTodayQuestion ? (
            <div className="mt-3 search-find-result text-center">
              <p>{item.answeredNextDayMessage}</p>

              <Link
                to={{ pathname: "/userportal/competitions" }}
                className="btn-games btn-games-default ml-auto mr-auto mt-3"
                style={{ maxWidth: "204px" }}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  style={{ fontSize: "16px", color: "#04b98c" }}
                />
                <span>YARIŞMALAR</span>
              </Link>
            </div>
          ) : null}
          {!item.availableTodayQuestion ? (
            <div className="mt-3 search-find-result text-center">
              <p>{item.nextDayMessage}</p>

              <Link
                to={{ pathname: "/userportal/competitions" }}
                className="btn-games btn-games-default ml-auto mr-auto mt-3"
                style={{ maxWidth: "204px" }}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  style={{ fontSize: "16px", color: "#04b98c" }}
                />
                <span>YARIŞMALAR</span>
              </Link>
            </div>
          ) : null}
        </div>
      );
    });
  }

  return (
    <div className="games games-detail-page">
      <GlobalLoader isLoading={isLoading} />

      <div className="container-fluid">
        <div className="wrapper-games-content row justify-content-center">
          <div className="col-12 col-md-4 col-lg-3 look-find d-flex flex-column">
            <div className="games-content">
              <div className="games-content-item">
                <div className="games-content-header">
                  <img src={TimerIcon} className="img-fluid" />
                </div>

                <div className="games-content-body">
                  <h3>BUL KAZAN</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-8 col-lg-9 d-flex flex-column">
            <h1 className="games-title text-left mt-0">
              Bul Kazan'a Hoş Geldiniz!
            </h1>

            {lookFindGame}
          </div>
        </div>
      </div>
    </div>
  );
};
export default LookFind;
