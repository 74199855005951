import * as widgetTypes from "./widget.type";

const initialState = {
  widgetList: null,
  error: null,
  isLoading: false
};

const reducer = (
  state = initialState,
  { type, payload, widgetList, ...params }
) => {
  switch (type) {
    case `${widgetTypes.GET_WIDGET_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${widgetTypes.GET_WIDGET_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        widgetList: payload.data
      };
    case `${widgetTypes.GET_WIDGET_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        widgetList: null
      };

    default:
      return state;
  }
};

export default reducer;
