import * as cookieConsentTypes from "./cookieConsent.type";

const initialState = {
  cookiesAll: null,
  clarificationText: null,
  error: null,
  isLoading: false
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${cookieConsentTypes.GET_COOKIES_ALL_PENDING}`:
    case `${cookieConsentTypes.GET_CLARIFICATION_TEXT_PENDING}`:
    case `${cookieConsentTypes.SAVE_COOKIES_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${cookieConsentTypes.GET_COOKIES_ALL_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        cookiesAll: payload.data
      };

    case `${cookieConsentTypes.GET_COOKIES_ALL_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        cookiesAll: null,
        error: payload
      };

    case `${cookieConsentTypes.GET_CLARIFICATION_TEXT_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        clarificationText: payload.data
      };

    case `${cookieConsentTypes.GET_CLARIFICATION_TEXT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${cookieConsentTypes.SAVE_COOKIES_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };

    case `${cookieConsentTypes.SAVE_COOKIES_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    default:
      return state;
  }
};

export default reducer;
