import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { profileAction } from "redux/modules";
import showErrMessage from "hooks/showErrMessage";
import showMessage from "hooks/showMessage";
import Resizer from "react-image-file-resizer";
import { imageShowUrl } from "../../../config/api.config";

const ProfileContactUs = props => {
  const [newMessageSubject, setNewMessageSubject] = useState("");
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const [remainingCharCount, setRemainingCharCount] = useState(1000);
  const [initialMessageText, setInitialMessageText] = useState("");
  const footer = useSelector(store => store.profile.footer);
  const uploadInput = useRef();
  const [imgSrc, setImgSrc] = useState(null);

  function wordCount(e) {
    var currentText = e.target.value;
    setInitialMessageText(currentText);
    var characterCount = currentText.length;
    setRemainingCharCount(1000 - characterCount);
  }

  function checkRequiredFields(e) {
    if (initialMessageText.length <= 0) {
      showErrMessage("Mesaj Giriniz");
      e.preventDefault();
    } else if (newMessageSubject.length <= 0) {
      showErrMessage("Konu Başlığı Seçiniz");
      e.preventDefault();
    } else {
      saveInitialMessage(
        initialMessageText,
        newMessageSubject,
        employeeFirm.id
      );
      setInitialMessageText("");
      setRemainingCharCount(1000);
      setImgSrc(null);
    }
  }

  function saveInitialMessage(message, contactSubject, senderEmployeeFirmId) {
    var splittedImgSrc = null;
    if (imgSrc != null) {
      var initialSplittedImgSrc = imgSrc.split(",");
      splittedImgSrc = initialSplittedImgSrc[1];
    }
    dispatch(
      profileAction.saveInitialMessage(
        message,
        contactSubject,
        senderEmployeeFirmId,
        splittedImgSrc
      )
    )
      .then(e => {
        showMessage("Mesajınız gönderildi");
        setInitialMessageText("");
        setRemainingCharCount(1000);
        setNewMessageSubject("");
        dispatch(
          profileAction.getMessageSubjectsByEmployeeFirmId(senderEmployeeFirmId)
        );
      })
      .catch(err => {
        showErrMessage("Mesajınız gönderilemedi");
      });
  }

  function fileChangedHandler(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      Resizer.imageFileResizer(
        event.target.files[0],
        620,
        465,
        "JPEG",
        100,
        0,
        uri => {
          setImgSrc(uri);
        },
        "base64"
      );
    }
  }

  return (
    <div
      className={`tab-pane fade ${footer ? " show active" : ""}`}
      id="contact-message"
      role="tabpanel"
      aria-labelledby="contact-message-tab"
    >
      <div className="contact-message-container">
        <div className="form-container">
          <form
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <div className="form-el-row">
              <p>Konu</p>
              <div className="form-el">
                <select
                  className="select2"
                  value={newMessageSubject}
                  onChange={e => setNewMessageSubject(e.target.value)}
                >
                  <option value="">Konu Başlığı Seçiniz</option>
                  <option value="Şikayet/Puan Kazanım">
                    Şikayet/Puan Kazanım
                  </option>
                  <option value="Şikayet/Sipariş Durumu">
                    Şikayet/Sipariş Durumu
                  </option>
                  <option value="Şikayet/Yarışmalar">
                    Şikayet/Yarışmalar{" "}
                  </option>
                  <option value="Öneri">Öneri</option>
                  <option value="Bilgi">Bilgi</option>
                  <option value="Memnuniyet">Memnuniyet</option>
                  <option value="Teknik Sorun">Teknik Sorun</option>
                </select>
              </div>
            </div>
            <div className="form-el-row message-contact-form-row">
              <div className="form-text">
                <p>Mesajınız</p>
                <div className="add-files">
                  <input
                    type="file"
                    ref={uploadInput}
                    style={{ display: "none" }}
                    onChange={fileChangedHandler}
                  />
                  <span>Dosya/Fotoğraf Ekle</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                  >
                    <g fill="#111111">
                      <image
                        style={{ cursor: "pointer" }}
                        onClick={() => uploadInput.current.click()}
                        data-name="Vector Smart Object"
                        width="28"
                        height="28"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAADFklEQVRIia2WSWxNURzGf31tVaSkBJeQsKBioa+JISSGhCJhRQyJYSMhwdawQHISLAxbNtjRxBQ2JCpszAviEsSUEEQeC6UJbdWQv/fd9vSe957SfsnNu+ec//m+d8/5T2Wc28hfUAEsABYBM4BxwCBt+QI8B24DF4FLLo5+lKIrJVgJbAK2AyOD1cJ4BxwADrs4+l7IophgPdAITPTmXgGXgUfAB80NByYB84Cxnu1jYLWLo/s9EVwGHAeqNDaR3cB14Ffa2MURLpsrA2YCO4CFWmqT6NlSgiZ2EsgAzcB64Ezw/SnBbuNsbglwDBgM/ARW+KIZz7ZeX5bRXUwvITZFTwAXR+eAaboC4zrhsrn6tGCl7qxKXzYXeBqw5dFPx3sLqA5W86IvgPnAJ3E2umyu0hfc5DnIBuBZwNKFASKxcBkarHYXXafhRGn8ESwHtmrBHOR0sPs/4eLovMWmdm9x2Vx5Rl41SpN7+krMw169jrYEklEGQZd8LTDvPa6L27A4I280XCkUZ72FiyPjvCqa6Xbx4zV4kOK2fDmkgF5/732Ey+YswL+lbNpdHH31xgn3hAovEX/wDKYCNxQupXCryFqHy+bmuDi6qfFH/Vb7gV/mvVvG7whoeg7b7yfvzquyL2wBBioRJ7gH1Cjm0rATea25OuBNYAGtLo5avfEw/X6pUJBPVtb30a6nFFpcHDWXWE+QcL/MqHgaGlLH2idQJWkQ100TvKDBGGB2XwuqbCW18qIJNgFvNbEzMO89donhvWll5FEHNdmgmthXx7lUVcOwz8VRRxIWh9UWGI5YgAa7u2BB3qo/+ilY7RKzhHJUwyfS6CxPFjOr1RbUKM3VBix5tOleZgGfg9W8WK3S2WDZr0qaKj/wreFZo7bAqscdYHnAlsfdYlnGZXPLtHe0uNb6zVRFyt5aipVqNexLT6lG7lElKZjc5fqz5HTJnbVJrFt9LdS1UaJNtKN+mGoT69QmjvFs7c7sGHvUJiawxL0Z2PYPjbC5/n7g0L82wj6sBbGuwAq11U7zvmKtfpO5flEm4DcDNdc7aF0K4wAAAABJRU5ErkJggg=="
                      ></image>
                    </g>
                  </svg>
                </div>
              </div>
              <div className="form-el">
                <textarea
                  className="form-control"
                  cols="4"
                  rows="4"
                  maxLength="1000"
                  value={initialMessageText}
                  onChange={wordCount}
                ></textarea>
                <span>{remainingCharCount} karakter kaldı</span>
              </div>
            </div>

            {imgSrc ? (
              <div className="form-el" style={{ marginBottom: "-65px" }}>
                <img
                  src={imgSrc}
                  alt=""
                  style={{
                    height: "240px",
                    width: "320px",
                    paddingBottom: "10px",
                    paddingTop: "5px"
                  }}
                ></img>
              </div>
            ) : null}

            <div className="submit">
              <button type="submit" onClick={checkRequiredFields}>
                GÖNDER
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfileContactUs;
