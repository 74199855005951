export const SET_ARENA_CONTENT_PENDING = "SET_ARENA_CONTENT_PENDING";
export const SET_ARENA_CONTENT_FULLFILLED = "SET_ARENA_CONTENT_FULLFILLED";
export const SET_ARENA_CONTENT_REJECTED = "SET_ARENA_CONTENT_REJECTED";

export const GET_SUBJECTS_PENDING = "GET_SUBJECTS_PENDING";
export const GET_SUBJECTS_FULLFILLED = "GET_SUBJECTS_FULLFILLED";
export const GET_SUBJECTS_REJECTED = "GET_SUBJECTS_REJECTED";

export const GET_PAST_SUBJECTS_PENDING = "GET_PAST_SUBJECTS_PENDING";
export const GET_PAST_SUBJECTS_FULLFILLED = "GET_PAST_SUBJECTS_FULLFILLED";
export const GET_PAST_SUBJECTS_REJECTED = "GET_PAST_SUBJECTS_REJECTED";

export const GET_SELECTED_PAST_SUBJECTS_PENDING =
  "GET_SELECTED_PAST_SUBJECTS_PENDING";
export const GET_SELECTED_PAST_SUBJECTS_FULLFILLED =
  "GET_SELECTED_PAST_SUBJECTS_FULLFILLED";
export const GET_SELECTED_PAST_SUBJECTS_REJECTED =
  "GET_SELECTED_PAST_SUBJECTS_REJECTED";

export const SAVE_INITIAL_MESSAGE_PENDING = "SAVE_INITIAL_MESSAGE_PENDING";
export const SAVE_INITIAL_MESSAGE_FULFILLED = "SAVE_INITIAL_MESSAGE_FULFILLED";
export const SAVE_INITIAL_MESSAGE_REJECTED = "SAVE_INITIAL_MESSAGE_REJECTED";

export const SAVE_LIKE_PENDING = "SAVE_LIKE_PENDING";
export const SAVE_LIKE_FULFILLED = "SAVE_LIKE_FULFILLED";
export const SAVE_LIKE_REJECTED = "SAVE_LIKE_REJECTED";
