import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { scoreHuntAction, brandStyleAction, brandAction } from "redux/modules";
import showMessage from "hooks/showMessage";
import showErrMessage from "hooks/showErrMessage";
import { Link } from "react-router-dom";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";
import {
  IoArrowDown,
  IoMenuOutline,
  IoCloseOutline,
  IoChevronDown,
  IoArrowBack,
  IoInformationCircle,
  IoArrowForward
} from "react-icons/io5";

import { FaInfoCircle, FaPlayCircle } from "react-icons/fa";

import BgBlue from "assets/images/new-design/game/card-icon-blue.png";

// Splash
import Splash from "assets/images/new-design/game/splash/splash-puan-avi.jpg";

import VideoPopup from "../../../components/VideoPopup";

const ScoreHunt = props => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const controlScoreHunt = useSelector(
    store => store.scoreHunt.controlScoreHunt
  );
  const brandList = useSelector(store => store.brand.brandList);
  const actives = [];
  const isLoading = useSelector(store => store.scoreHunt.isLoading);

  const [isOpen, setIsOpen] = useState(false);

  const openPopup = e => {
    e.preventDefault();

    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    dispatch(scoreHuntAction.getControlScoreHuntByFirmId(employeeFirm.id));
    dispatch(brandAction.getBrandsByFirmId(employeeFirm.firm.id));
    return () => {};
  }, []);

  function onClickGoBrand(brandId, index2) {
    if (brandList.length > 0)
      dispatch(brandStyleAction.getBrandStyleByBrandId(brandId));
    dispatch(brandStyleAction.setSlider(false));
    dispatch(brandStyleAction.setIndexStyle(index2));
    dispatch(brandStyleAction.setIndexBullet(0));

    brandList.map(item => {
      index2 === item.imageOrder ? actives.push(true) : actives.push(false);
    });
  }

  return (
    <section className="bg-[#f8fafc]">
      <GlobalLoader isLoading={isLoading} />
      <div className="container mx-auto py-20 px-6 md:px-0">
        <div className="flex gap-4 h-100 flex-col md:flex-row">
          <div className="relative h-[333px] w-[308px] gap-20 bg-[url('assets/images/new-design/game/card-bg-blue.png')] bg-left-bottom bg-no-repeat mx-auto">
            <h2 className="md:text-[36px] text-[28px] text-center px-4 py-4 font-bold text-white">
              Puan Avı
            </h2>
            <img
              src={BgBlue}
              className="w-[220px] absolute bottom-0 right-0 "
              alt=""
            />
          </div>

          {/* TODO: key hatası alıyor düzeltilebilir. */}
          {controlScoreHunt &&
            controlScoreHunt.length > 0 &&
            controlScoreHunt.map(item => {
              return (
                <>
                  {item.availableTodayQuestion && item.answerable && (
                    <div
                      className="bg-slate-200 flex-1 flex justify-center items-start rounded-[12px] flex-col py-8 md:px-12 px-8"
                      key={item.questionId}
                    >
                      <h3 className="mb-8 inline-flex items-center gap-4 text-2xl font-semibold text-slate-700">
                        <FaInfoCircle className="h-6 w-6" /> Puan Avı'na
                        Hoşgeldiniz
                      </h3>
                      <p className="mb-6">
                        JTI ürünleri ile ilgili bilgi yarışmasıdır. Her hafta
                        <span className="text-slate-700">
                          Salı, Çarşamba
                        </span>{" "}
                        ve <span className="text-slate-700">Cuma</span> günleri
                        karşınıza çıkar. Puan Avı sorusu her seferinde farklı
                        bir markanın stillerinden birinde gizlidir. Başla
                        butonuna tıkladığınızda otomatik olarak sorunun o gün
                        gizlendiği markanın sayfasına yönlendirilirsiniz.{" "}
                        <span className="text-slate-700">Puan Avı ikonunu</span>{" "}
                        bulana kadar o markanın tüm stillerini inceleyin.{" "}
                        <span className="text-slate-700">Puan Avı ikonunu</span>{" "}
                        bulduğunuzda ikonu tıklayarak soruya ulaşın.{" "}
                        <span className="text-slate-700">90 saniye</span> içinde
                        doğru cevabı verin,{" "}
                        <span className="text-slate-700">100 puan</span>{" "}
                        kazanın.
                      </p>

                      <div className="flex flex-col justify-center gap-6 !mt-4 items-center relative mb-12 mx-auto">
                        <a
                          href="#"
                          onClick={openPopup}
                          className="relative w-[240px] h-[160px] block rounded-xl"
                        >
                          <img src={Splash} className="rounded-xl" alt="" />
                          <FaPlayCircle className="h-8 w-8 text-white absolute top-[50%] left-[50%] mt-[-16px] ml-[-16px]" />
                        </a>

                        <h2 className="text-slate-600 font-semibold text-xl">
                          İzlemek için videoya tıklayınız
                        </h2>
                      </div>
                    </div>
                  )}

                  {item.availableTodayQuestion && !item.answerable && (
                    <div
                      className="bg-slate-200 flex-1 flex justify-center items-start rounded-[12px] flex-col py-8 md:px-12 px-8"
                      key={item.questionId}
                    >
                      <h3 className="mb-8 inline-flex items-center gap-4 text-2xl font-semibold text-slate-700">
                        <FaInfoCircle className="h-6 w-6" /> Puan Avı'na
                        Hoşgeldiniz
                      </h3>
                      <p className="md:text-[16px] text-[14px] font-normal text-slate-500 mb-8">
                        {item.nextDayMessage}
                      </p>
                      <Link
                        to="/userportal/competitions"
                        className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px]"
                      >
                        <IoArrowBack className="h-4 w-4" />
                        Yarışmalar{" "}
                      </Link>
                    </div>
                  )}

                  {!item.availableTodayQuestion && (
                    <div
                      className="bg-slate-200 flex-1 flex justify-center items-start rounded-[12px] flex-col py-8 md:px-12 px-8"
                      key={item.questionId}
                    >
                      <h3 className="mb-8 inline-flex items-center gap-4 text-2xl font-semibold text-slate-700">
                        <FaInfoCircle className="h-6 w-6" /> Puan Avı'na
                        Hoşgeldiniz
                      </h3>

                      <div className="flex flex-col justify-center gap-6 !mt-4 items-center relative mb-12 mx-auto">
                        <a
                          href="#"
                          onClick={openPopup}
                          className="relative w-[240px] h-[160px] block rounded-xl"
                        >
                          <img src={Splash} className="rounded-xl" alt="" />
                          <FaPlayCircle className="h-8 w-8 text-white absolute top-[50%] left-[50%] mt-[-16px] ml-[-16px]" />
                        </a>

                        <h2 className="text-slate-600 font-semibold text-xl">
                          İzlemek için videoya tıklayınız
                        </h2>
                      </div>

                      <p className="md:text-[16px] text-[14px] font-normal text-slate-500 mb-8">
                        {item.nextDayMessage}
                      </p>

                      <Link
                        to="/userportal/competitions"
                        className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px]"
                      >
                        <IoArrowBack className="h-4 w-4" />
                        Yarışmalar{" "}
                      </Link>
                    </div>
                  )}
                </>
              );
            })}
        </div>

        {controlScoreHunt &&
          controlScoreHunt.length > 0 &&
          controlScoreHunt.map(item => {
            return (
              <div
                className={
                  item.availableTodayQuestion && item.answerable
                    ? "w-full md:w-[calc(100%-324px)] bg-white flex-1 flex justify-between ml-auto items-start rounded-[12px] flex-col mt-4 py-6 md:px-8 px-4"
                    : "hi"
                }
                key={item.questionId}
              >
                {item.availableTodayQuestion && item.answerable && (
                  <div className="action flex justify-between w-full">
                    <Link
                      to="/userportal/competitions"
                      className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px]"
                    >
                      <IoArrowBack className="h-5 w-5" />
                      Geri Git{" "}
                    </Link>
                    <Link
                      className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[135px] min-w-[120px] max-h-[52px] rounded-full border-2 bg-[#00b185] border-[#00b185] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px] text-white"
                      key={item.brandId}
                      to={{
                        pathname: "/userportal/brand",
                        actives: actives,
                        brandId: item.brandId,
                        brandIndex: item.imageOrder,
                        brandList: brandList
                      }}
                      onClick={() =>
                        onClickGoBrand(item.brandId, item.imageOrder)
                      }
                    >
                      Başla <IoArrowForward className="h-5 w-5 text-white" />
                    </Link>
                  </div>
                )}
              </div>
            );
          })}
      </div>

      <VideoPopup
        videoUrl={
          process.env.REACT_APP_VIDEO_SHOW_URL + "JTI_BB_PUANAVI_03.mp4"
        }
        isOpen={isOpen}
        onClose={closePopup}
      />
    </section>
  );
};
export default ScoreHunt;
