import React from "react";
import "./IlluminationText.scss";

const IllumintionText = () => {
  return (
    <div className="w-full illumination-text">
      <h3>
        <strong>
          6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA
          <br />
          BENİM BAYİM KULLANICISI AYDINLATMA METNİ
        </strong>
      </h3>
      <br />
      <p>
        <strong>
          <span>Aydınlatma Metni Amacı ve Kapsamı</span>
        </strong>
      </p>
      <p>
        <span>JTI TÜTÜN ÜRÜNLERİ PAZARLAMA A.Ş. (“</span>
        <strong>
          <span>JTI”)</span>
        </strong>
        <span>
          {" "}
          olarak 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK” veya
          “Kanun” olarak anılacaktır.)
        </span>
        <strong>
          <span> </span>
        </strong>
        <span>
          kapsamında kişisel verilerinizin korunması için tedbir almaktayız.
          Kişisel Verilerinizi, KVKK ve ilgili yasal mevzuat kapsamında ve “veri
          sorumlusu” sıfatımızla aşağıda açıklanan sebeplerle ve yöntemlerle
          işlemekteyiz.
        </span>
      </p>
      <br />
      <p>
        <span>
          JTI TÜTÜN ÜRÜNLERİ PAZARLAMA A.Ş. Kişisel Verilerin İşlenmesi Hakkında
          Aydınlatma Metni, KVKK’nın 10. maddesinde yer alan “ Veri
          Sorumlusu’nun Aydınlatma Yükümlülüğü” başlıklı maddesi uyarınca: veri
          sorumlusunun kimliği, kişisel verilerinizin toplanma yöntemi ve hukuki
          sebebi, bu verilerin hangi amaçla işleneceği, kimlere ve hangi amaçla
          aktarılabileceği, veri işleme süresi ve Kanun’un 11. maddesinde
          sayılan haklarınızın neler olduğu ile ilgili sizi en şeffaf şekilde
          bilgilendirme amacıyla&nbsp; hazırlanmıştır.&nbsp;
        </span>
      </p>
      <br />
      <p>
        <strong>
          <span>Kişisel Verilerinizin Toplanma Yöntemi ve Hukuki Sebebi</span>
        </strong>
      </p>
      <p>
        <span>
          Kişisel verileriniz, otomatik ya da otomatik olmayan yöntemlerle,{" "}
        </span>
        <a
          style={{ textDecoration: "none" }}
          href="https://www.jtibenimbayim.com/"
        >
          <span>jtibenimbayim.com</span>
        </a>
        <span>
          {" "}
          internet sitesi, mobil uygulama, çağrı merkezi, JTI’ın bağlı
          birimleri, JTI içerisinde faaliyetlerin yürütülebilmesi için
          kullanılan yazılımlar yolu ile sözlü, yazılı ya da elektronik olarak
          toplanmaktadır. Kişisel verileriniz, Benim Bayim platformu
          aracılığıyla JTI ile ilişkiniz devam ettiği müddetçe oluşturularak ve
          güncellenerek işlenebilecek ve hem dijital hem de fiziki ortamda
          muhafaza altında tutulacaktır.
        </span>
      </p>
      <p>
        <span>
          Kişisel verileriniz, “İşlenen Kişisel Verileriniz ve İşlenme Amaçları”
          başlığında yer alan amaçlar doğrultusunda ve 6698 sayılı Kanun’un
          5.maddesi 1. fıkrası kapsamında açık rıza şartının sağlanması veya 5.
          maddenin 2.fıkrası uyarınca belirtilen;
        </span>
      </p>
      <p>
        <strong>
          <span>b)</span>
        </strong>
        <span>
          {" "}
          Veri sorumlusunun hukuki yükümlülüğünü yerine getirmesi&nbsp;
        </span>
      </p>
      <p>
        <strong>
          <span>c)</span>
        </strong>
        <span>
          {" "}
          Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması
          kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin
          gerekli olması,
        </span>
      </p>
      <p>
        <strong>
          <span>ç)</span>
        </strong>
        <span>
          {" "}
          Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için
          zorunlu olması,
        </span>
      </p>
      <p>
        <strong>
          <span>e)</span>
        </strong>
        <span>
          {" "}
          Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin
          zorunlu olması,
        </span>
      </p>
      <p>
        <strong>
          <span>f)</span>
        </strong>
        <span>
          {" "}
          İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
          veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu
          olması,&nbsp;
        </span>
      </p>
      <p>
        <span>kişisel veri işleme şartları kapsamında işlenmektedir.</span>
      </p>
      <br />
      <p>
        <strong>
          <span>İşlenen Kişisel Verileriniz ve İşlenme Amaçları</span>
        </strong>
      </p>
      <p>
        <span>
          Kişisel Verileriniz yasal yükümlülükler gereği veya JTI iş ve
          işlemlerinde daha uygun bir hizmet verebilmek amacıyla güvenli bir
          şekilde Kişisel Verilerin Korunması Kanunu’na uygun bir şekilde
          işlenmektedir.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          Bu kapsamda kişisel veri olarak aşağıda yer alan faaliyetler
          kapsamında tabloda yer alan kişisel veriler işlenmektedir;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          <span>
            1) Benim Bayim Platformuna Yönelik Üyelik İşlemlerinin
            Tamamlanması&nbsp;
          </span>
        </strong>
      </p>
      <p>&nbsp;</p>
      <div align="left">
        <table style={{ border: "none", width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>
                    <span>İşlenen Kişisel Veriler</span>
                  </strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>
                    <span>İşleme Amaçları</span>
                  </strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>
                    <span>Hukuki Sebep</span>
                  </strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>
                    <span>İşlenen Kişisel Veriler</span>
                  </strong>
                </p>
                <br />
                <p>
                  <strong>
                    <span>Kimlik:&nbsp;</span>
                  </strong>
                  <span>
                    Kişi kimliğine dair bilgilerin bulunduğu veri
                    grubudur.&nbsp;&nbsp;(Örn. Ad,&nbsp;Soyad, Doğum Tarihi,
                    TC&nbsp;Kimlik Numarası, Vergi Kimlik Numarası)
                  </span>
                </p>
                <br />
                <p>
                  <strong>
                    <span>İletişim:&nbsp;</span>
                  </strong>
                  <span>
                    Kişiye ulaşmak için kullanılabilecek veri
                    grubudur.&nbsp;&nbsp;(Örn. Telefon numarası, Adresi, E-posta
                    Adresi)&nbsp;&nbsp;
                  </span>
                </p>
                <br />
                <p>
                  <strong>
                    <span>İşlem Güvenliği:&nbsp;</span>
                  </strong>
                  <span>
                    Bu veri kategorisi IP adresi bilgileri, İnternet sitesi
                    giriş çıkış bilgileri, Şifre ve parola bilgileri&nbsp;gibi
                    veri türlerini ifade etmektedir.&nbsp;(Örn. Güvenlik
                    anahtarları, Kullanıcı adı, Kullanıcı ID, Şifre,
                    Log&nbsp;kayıtları,&nbsp;IP&nbsp;Adresleri)&nbsp;&nbsp;
                  </span>
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      <span>
                        Şirketimizin bir alt girişimi olan jtibenimbayim.com
                        sitesine üye olunması durumunda, sözleşme ilişkisi
                        kapsamında, kullanıcı profilinin oluşturulabilmesi ve
                        etkin bir şekilde Benim Bayim platformu hizmetlerimizden
                        yararlanabilmesi amacıyla şirketimiz tarafından
                        yönetilen web sitemize üye olan kullanıcıların bilgileri
                        işlenmektedir.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Mobil uygulama vasıtası ile üye olunması durumunda,
                        sözleşme ilişkisi kapsamında, kullanıcı profilinin
                        oluşturulabilmesi amacıyla şirketimiz tarafından
                        yönetilen mobil uygulamaya üye olan kullanıcıların
                        bilgileri işlenmektedir.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        JTI işlerinin yürütüldüğü veya JTI’ya bağlı merkez ve
                        birimlerinde bulunan&nbsp;kişilerce iş faaliyetlerin
                        yürütülmesi amacı ile kişisel verileriniz
                        işlenmektedir.&nbsp;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Kanunlardan ve ikincil düzenlemelerden doğan/doğabilecek
                        yasal ve düzenleyici gereksinimlerin yerine getirilmesi
                        ve bu kapsamda gerekli tedbirlerin alınabilmesi amacı
                        ile kişisel verileriniz işlenmektedir.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Benim Bayim platformu üyelik işlemlerinizi
                        gerçekleştirmek, kullanıcı bilgilerinizi tarafınıza
                        gönderebilmek, sadakat programları uyarınca
                        yükümlülüklerimizi yerine getirmek, etkinliklerden
                        faydalanmanızı sağlamak, üyelik bilgilendirmeleri
                        yapabilmek, tarafınızca kazanılan puanları kullanmanızı
                        ve sorgulayabilmenizi sağlamak
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  <span>amaçları ile kişisel verileriniz işlenmektedir.</span>
                </p>
                <p>&nbsp;</p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      <span>
                        Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
                        ilgili olması kaydıyla, sözleşmenin taraflarına ait
                        kişisel verilerin işlenmesinin gerekli olması,
                      </span>
                    </p>
                  </li>

                  <li>
                    <p>
                      <span>
                        Bir hakkın tesisi, kullanılması veya korunması için veri
                        işlemenin zorunlu olması,
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        İlgili kişinin temel hak ve özgürlüklerine zarar
                        vermemek kaydıyla, veri sorumlusunun meşru menfaatleri
                        için veri işlenmesinin zorunlu olması,&nbsp;
                      </span>
                    </p>
                  </li>
                </ul>
                <br />
                <p>
                  <span>
                    hukuki sebeplerine dayanılarak kişisel verileriniz
                    işlenmektedir.&nbsp;
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <br />
        <br />
        <br />
      </p>
      <p>
        <strong>
          <span>
            2) Üyelik İşlemlerinin Tamamlanmasından Sonra Kullanıcı Hesap
            Bilgilerinin Tamamlanması
          </span>
        </strong>
      </p>
      <p>&nbsp;</p>
      <div align="left">
        <table style={{ border: "none", width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>
                    <span>İşlenen Kişisel Veriler&nbsp;</span>
                  </strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>
                    <span>İşleme Amaçları</span>
                  </strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>
                    <span>Hukuki Sebep</span>
                  </strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <br />
                <p>
                  <strong>
                    <span>Kimlik:&nbsp;</span>
                  </strong>
                  <span>
                    Kişi kimliğine dair bilgilerin bulunduğu veri
                    grubudur.&nbsp;&nbsp;(Örn. Ad,&nbsp;Soyad, Doğum Tarihi
                    /&nbsp;Doğum Yeri)
                  </span>
                </p>
                <br />
                <p>
                  <strong>
                    <span>İletişim:&nbsp;</span>
                  </strong>
                  <span>
                    Kişiye ulaşmak için kullanılabilecek veri
                    grubudur.&nbsp;&nbsp;(Örn. Telefon numarası, Adresi, E-posta
                    Adresi)&nbsp;&nbsp;
                  </span>
                </p>
                <br />
                <p>
                  <strong>
                    <span>İşlem Güvenliği:&nbsp;</span>
                  </strong>
                  <span>
                    Bu veri kategorisi IP adresi bilgileri, İnternet sitesi
                    giriş çıkış bilgileri, Şifre ve parola bilgileri&nbsp;gibi
                    veri türlerini ifade etmektedir.&nbsp;(Örn. Güvenlik
                    anahtarları, Kullanıcı adı, Kullanıcı ID, Şifre,
                    Log&nbsp;kayıtları,&nbsp;IP&nbsp;Adresleri)&nbsp;&nbsp;
                  </span>
                </p>
                <br />
                <br />
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      <span>
                        Şirketimizin bir alt girişimi olan jtibenimbayim.com
                        sitesine üye olunması durumunda, kullanıcı profilinin
                        oluşturulabilmesi ve etkin bir şekilde platform
                        hizmetlerimizden yararlanabilmesi amacıyla kullanıcı
                        bilgileri işlenmektedir.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Mobil uygulama vasıtası ile üye olunması durumunda,
                        kullanıcı profilinin oluşturulabilmesi amacıyla
                        kullanıcı bilgileri işlenmektedir.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Kanunlardan ve ikincil düzenlemelerden doğan/doğabilecek
                        yasal ve düzenleyici gereksinimlerin yerine getirilmesi
                        ve bu kapsamda gerekli tedbirlerin alınabilmesi amacı
                        ile bilgileriniz işlenmektedir.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Tarafınızca kazanılan puanları kullanmanızı
                        sağlamak&nbsp;
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  <span>amaçları ile kişisel verileriniz işlenmektedir.</span>
                </p>
                <p>&nbsp;</p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      <span>
                        Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
                        ilgili olması kaydıyla, sözleşmenin taraflarına ait
                        kişisel verilerin işlenmesinin gerekli olması,
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Bir hakkın tesisi, kullanılması veya korunması için veri
                        işlemenin zorunlu olması,
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        İlgili kişinin temel hak ve özgürlüklerine zarar
                        vermemek kaydıyla, veri sorumlusunun meşru menfaatleri
                        için veri işlenmesinin zorunlu olması,&nbsp;
                      </span>
                    </p>
                  </li>
                </ul>
                <br />
                <p>
                  <span>
                    hukuki sebeplerine dayanılarak kişisel verileriniz
                    işlenmektedir.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>
                    <span>Görsel&nbsp;ve&nbsp;İşitsel Kayıtlar:&nbsp;</span>
                  </strong>
                  <span>
                    Kişiye ait görsel ve işitsel verilerin bulunduğu veri
                    grubudur.&nbsp;&nbsp;(Örn. Fotoğraf)&nbsp;&nbsp;
                  </span>
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      <span>
                        Kullanıcı profilinin oluşturulabilmesi amacı ile
                        bilgileriniz işlenmektedir.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Benim Bayim Platformunda yer alan bölümlerin
                        görünürlüğünü ve&nbsp; kullanılmasını sağlamak amacı ile
                        bilgileriniz işlenmektedir
                      </span>
                    </p>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      <span>İlgili kişinin açık rızasını sunması&nbsp;</span>
                    </p>
                  </li>
                </ul>
                <br />
                <p>
                  <span>
                    hukuki sebebine dayanılarak kişisel verileriniz
                    işlenmektedir.
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <br />
        <br />
      </p>
      <p>
        <strong>
          <span>
            3) Benim Bayim Sistemine Yönelik Görüş Öneri ve İstek Hizmetlerinin
            Yürütülmesi
          </span>
        </strong>
      </p>
      <p>
        <br />
      </p>
      <div align="left">
        <table style={{ border: "none", width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>
                    <span>Kimlik:&nbsp;</span>
                  </strong>
                  <span>
                    Kişi kimliğine dair bilgilerin bulunduğu veri
                    grubudur.&nbsp;&nbsp;(Örn. Ad,&nbsp;Soyad, Doğum Tarihi
                    /&nbsp;Doğum Yeri, TC&nbsp;Kimlik Numarası)
                  </span>
                </p>
                <br />
                <p>
                  <strong>
                    <span>İletişim:&nbsp;</span>
                  </strong>
                  <span>
                    Kişiye ulaşmak için kullanılabilecek veri
                    grubudur.&nbsp;&nbsp;(Örn. Telefon numarası, Yerleşim Yeri
                    Adresi, E-posta Adresi)&nbsp;&nbsp;
                  </span>
                </p>
                <br />
                <p>
                  <strong>
                    <span>Görsel&nbsp;ve&nbsp;İşitsel Kayıtlar:&nbsp;</span>
                  </strong>
                  <span>
                    Kişiye ait görsel ve işitsel verilerin bulunduğu veri
                    grubudur.&nbsp;&nbsp;(Örn. Ses kaydı)&nbsp;&nbsp;
                  </span>
                </p>
                <br />
                <br />
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      <span>Güvenliğin ve denetimlerin sağlanması,</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>Talep ve şikayetlerin takibi,</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Görevli ve yetkili kamu kurum ve kuruluşları ile kamu
                        kurumu niteliğindeki meslek kuruluşlarınca yapılacak
                        denetleme ve/veya düzenleme görevlerinin yürütülmesi,
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Yargı organlarının ve/veya idari makamların istediği
                        bilgi ve belge taleplerinin yerine getirilmesi,
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Kanunlardan ve ikincil düzenlemelerden doğan/doğabilecek
                        yasal ve düzenleyici gereksinimlerin yerine getirilmesi
                        ve bu kapsamda gerekli tedbirlerin alınabilmesi,
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        JTI işlerinin yürütüldüğü veya JTI’ya bağlı merkez ve
                        birimlerinde bulunan&nbsp;gerçek ve/veya tüzel üçüncü
                        kişi kurum ve kuruluşların can ve mal güvenlikleri
                        ile&nbsp;hukuki, ticari ve iş sağlığı güvenliklerinin
                        temini
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  <span>amaçları ile kişisel verileriniz işlenmektedir.</span>
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      <span>
                        Bir hakkın tesisi, kullanılması veya korunması için veri
                        işlemenin zorunlu olması,
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        İlgili kişinin temel hak ve özgürlüklerine zarar
                        vermemek kaydıyla, veri sorumlusunun meşru menfaatleri
                        için veri işlenmesinin zorunlu olması,
                      </span>
                    </p>
                  </li>
                </ul>
                <br />
                <p>
                  <span>
                    hukuki sebeplerine dayanılarak kişisel verileriniz
                    işlenmektedir.
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>&nbsp;</p>
      <p>
        <strong>
          <span>
            4) Çerez Kullanımı ve Kullanıcı Takibi Faaliyetlerinin
            Yürütülmesi&nbsp;
          </span>
        </strong>
      </p>
      <p>&nbsp;</p>
      <div align="left">
        <table style={{ border: "none", width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>
                    <span>Pazarlama Verisi:</span>
                  </strong>
                  <span>
                    {" "}
                    Kişiye ait bilgilerin işlenmesi sonucu oluşan dijital
                    izlerin bulunduğu pazarlama, amaçlı kullanılan veri
                    grubudur. (Örn. İnternet sitesi gezinme bilgileri,
                    pazarlamaya yönelik çerez bilgileri, hedef ve analiz
                    bilgileri)
                  </span>
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      <span>
                        Platform kullanıcılarımızın hedefleme, analiz ve site
                        performans takibi yapılarak kullanıcıların tercih ve
                        ihtiyaçları doğrultusunda uygulama, mal, ürün ve
                        hizmetlerin pazarlamasının yapılması amacıyla şirketimiz
                        tarafından yönetilen platformda pazarlama amaçlı çerez
                        bilgileri işlenmektedir.&nbsp;
                      </span>
                    </p>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      <span>İlgili kişinin açık rızasını sunması</span>
                    </p>
                  </li>
                </ul>
                <br />
                <p>
                  <span>
                    hukuki sebebine dayanılarak kişisel verileriniz
                    işlenmektedir.
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <br />
        <br />
      </p>
      <p>
        <strong>
          <span>
            5) Elektronik İleti ve Bilgilendirme Faaliyetlerinin Yürütülmesi
          </span>
        </strong>
      </p>
      <p>&nbsp;</p>
      <div align="left">
        <table style={{ border: "none", width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>
                    <span>Kimlik:&nbsp;</span>
                  </strong>
                  <span>
                    Kişi kimliğine dair bilgilerin bulunduğu veri
                    grubudur.&nbsp;&nbsp;(Örn. Ad,&nbsp;Soyad,)
                  </span>
                </p>
                <br />
                <p>
                  <strong>
                    <span>İletişim:&nbsp;</span>
                  </strong>
                  <span>
                    Kişiye ulaşmak için kullanılabilecek veri
                    grubudur.&nbsp;&nbsp;(Örn. Telefon numarası, E-posta
                    Adresi)&nbsp;&nbsp;
                  </span>
                </p>
                <br />
                <br />
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      <span>
                        Platform kapsamında kullanıcılarımızın tercih ve
                        ihtiyaçları doğrultusunda uygulama, mal, ürün ve
                        hizmetlerin bilgilendirilmesinin yapılması&nbsp;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Kanunlardan ve ikincil düzenlemelerden doğan/doğabilecek
                        yasal ve düzenleyici gereksinimlerin yerine getirilmesi
                        ve bu kapsamda gerekli tedbirlerin alınabilmesi
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  <span>amaçları ile kişisel verileriniz işlenmektedir.</span>
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      <span>İlgili kişinin açık rızasını sunması</span>
                    </p>
                  </li>
                </ul>
                <br />
                <p>
                  <span>
                    hukuki sebeplerine dayanılarak kişisel verileriniz
                    işlenmektedir.
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <br />
        <br />
      </p>
      <p>
        <strong>
          <span>
            İşlenen Kişisel Verilerinizin Kimlere ve Hangi Amaçla
            Aktarılabileceği
          </span>
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          KVKK ve ilgili mevzuat uyarınca uygun güvenlik düzeyini temin etmeye
          yönelik gerekli her türlü teknik ve idari tedbirlerin alınmasını
          sağlayarak, kişisel verileriniz belirtilen amaçlar doğrultusunda;
        </span>
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            <span>
              İlgili kamu kurum ve kuruluşlarının talepleri doğrultusunda ve
              talep amaçlarıyla sınırlı olarak hukuken yetkili kamu kurum ve
              kuruluşlarına,
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Hizmetlerimizin yerine getirilebilmesi ve sürekliliğinin
              sağlanabilmesi amacıyla ile grup şirketlerimize,
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Faaliyetlerimizi yürütmek üzere sözleşmesel olarak hizmet
              aldığımız ve/veya hizmet verdiğimiz, iş birliği yaptığımız iş
              ortaklarımıza
            </span>
          </p>
        </li>
      </ul>
      <p>
        <span>
          KVK Kanunu’nun 8. ve 9. maddesinde yer alan kişisel veri işleme
          şartları çerçevesinde aktarılmaktadır.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          <span>Veri İşleme Süresi ve Muhafaza Süresi</span>
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>Kişisel verileriniz, </span>
        <span>
          yukarıda belirtilen işleme amaçlarının gerektirdiği süre ve ilgili
          mevzuatta belirtilen süreler boyunca saklanmaktadır.{" "}
        </span>
        <span>
          İşbu Aydınlatma Metninde belirtilen amaçlarla sınırlı olmak üzere; JTI
          ve JTI’ya bağlı merkez ve birimlerinin tabi olduğu, ilgili tüm kanun
          ve sair yasal mevzuatta yer alan veri işleme ve zamanaşımı sürelerine
          riayet edilerek işlenmektedir. Kanunlarda veri işleme sürelerine
          ilişkin değişiklik yapılması halinde, belirlenen yeni süreler esas
          alınacaktır.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          Kişisel verileriniz, amaçla sınırlılık ilkesinin bir gereği olarak
          işbu Aydınlatma Metninde açıklanan amaçların yerine getirilmesi ve her
          halükârda JTI uygulamaları ve ticari yaşamının teamülleri uyarınca
          işlenmesini gerektiren süre ile sınırlı olarak işlenmekte, sürelerin
          dolması ardından ise silinmekte, yok edilmekte veya anonim hale
          getirilmektedir.&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <strong>
          <span>İlgili Kişinin Haklarını Kullanması:</span>
        </strong>
      </p>
      <p>
        <span>
          İlgili kişi olarak, Kanunun ilgili kişinin haklarını düzenleyen
          11.maddesi kapsamındaki taleplerinizi “Veri Sorumlusuna Başvuru Usul
          ve Esasları Hakkında Tebliğ’e göre{" "}
        </span>
        <em>
          <span>“Torbalı Mahallesi 2561 Sokak No: 12 Torbalı, İzmir”</span>
        </em>
        <span>&nbsp;adresine yazılı olarak veya </span>
        <span>&nbsp;</span>
        <a
          style={{ textDecoration: "none", color: "#007bff" }}
          href="mailto:kvk@jti.com"
        >
          <span>kvk@jti.com</span>
        </a>
        <span>
          {" "}
          adresine&nbsp; e-postanızın şirket sistemlerinde kayıtlı olması şartı
          ile e-posta adresine elektronik ortamdan iletmek suretiyle veya
          şirketimizin{" "}
        </span>
        <a
          style={{ textDecoration: "none", color: "#007bff" }}
          href="mailto:jtitutunurunleripazarlama@hs03.kep.tr"
        >
          <span>jtitutunurunleripazarlama@hs03.kep.tr</span>
        </a>
        <span>
          &nbsp;kep adresine kayıtlı elektronik posta ile başvurarak sayılan
          haklarını kullanabilecektir. Bu konuda daha kapsamlı düzenleme veri
          gruplarına ilişkin daha detaylı bilgiler&nbsp;
        </span>
        <strong>
          <span>
            JTI Kişisel Verilerin Korunması ve İşlenmesi Politikasında{" "}
          </span>
        </strong>
        <span>
          yapılmıştır. İlgili prosedüre kurum internet sitemizden veya ilgili
          personele yapacağınız talep doğrultusunda ulaşabilirsiniz.
        </span>
      </p>
      <p>&nbsp;</p>
    </div>
  );
};

export default IllumintionText;
