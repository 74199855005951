import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { imageShowUrl } from "../../../config/api.config";
import { catalogAction } from "redux/modules";
import { IoArrowForward } from "react-icons/io5";
import { Link } from "react-router-dom";

const ProfileFavourites = () => {
  const dispatch = useDispatch();

  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const favouriteProducts = useSelector(
    store => store.catalog.favouriteProducts
  );

  useEffect(() => {
    dispatch(catalogAction.getFavouriteProducts(employeeFirm.id));

    return () => {};
  }, [dispatch]);

  return (
    <div
      className="tab-pane fade show active"
      id="favourites"
      role="tabpanel"
      aria-labelledby="favourites-tab"
    >
      <div className="row">
        <div className="container">
          <div className="col">
            <h3 className="px:lg-[36px] px-[0] !text-lg">Favorilerim</h3>

            <div className="!mt-4 grid grid-cols-2 gap-2 sm:mt-12 lg:grid-cols-3 lg:gap-4">
              {favouriteProducts &&
              favouriteProducts?.productDTOList?.length > 0 ? (
                favouriteProducts?.productDTOList?.map((item, index) => {
                  return (
                    <Link
                      to={{
                        pathname: "/userportal/catalog",
                        product: item
                      }}
                      className={
                        item.noneStock
                          ? "pointer-events-none select-none flex flex-col rounded-xl bg-white p-3 shadow-lg shadow-slate-200 sm:p-6 sm:pb-12"
                          : "select-none flex flex-col rounded-xl bg-white p-3 shadow-lg shadow-slate-200 sm:p-6 sm:pb-12"
                      }
                      key={index}
                    >
                      <div className="min-h-[200px] mb-7 flex flex-col justify-center align-center">
                        <img
                          src={
                            imageShowUrl +
                            (favouriteProducts?.productDTOList &&
                            favouriteProducts?.productDTOList?.length > 0
                              ? favouriteProducts?.productDTOList[index].icon
                              : "")
                          }
                          className="max-h-[200px] mx-auto"
                          alt=""
                        />
                      </div>

                      <div className="mt-auto">
                        {item.criticalStock && item.criticalStock > 0 ? (
                          <span className="text-xs font-semibold text-slate-500">
                            Son {item.criticalStock} Adet
                          </span>
                        ) : (
                          ""
                        )}

                        {item?.name && (
                          <h5 className="mt-2 text-sm font-medium text-slate-700 sm:text-xl">
                            {item?.name}
                          </h5>
                        )}

                        {item.noneStock ? (
                          <div className="relative mt-4 flex items-center justify-center rounded-full bg-danger p-3 text-center text-white max-sm:text-sm sm:mt-6 sm:px-6 sm:py-4">
                            Tükendi
                          </div>
                        ) : (
                          <div className="btn btn-progress relative mt-4 flex items-center justify-center rounded-full p-3 text-center text-white max-sm:text-sm sm:mt-6 sm:px-6 sm:py-4">
                            <span className="position-relative d-flex justify-center items-center">
                              {item.score} Puan
                              <IoArrowForward className="!ml-1" />
                            </span>
                            <span
                              className="bg-primary-700"
                              style={{
                                width:
                                  Math.round(
                                    (employeeFirm.score.score / item.score) *
                                      100
                                  ) + "%"
                              }}
                            ></span>
                          </div>
                        )}
                      </div>
                    </Link>
                  );
                })
              ) : (
                <div className="absolute">
                  <p>Henüz favoriye bir ürün eklemediniz.</p>
                  <Link
                    to={"/userportal/catalog"}
                    className="btn btn-success bg-primary-500 border-primary-500 text-white !mt-5"
                  >
                    Benim Bayim Dükkanı
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileFavourites;
