export const GET_IYS_DISCLAIMER_BY_ERC_PENDING =
  "GET_IYS_DISCLAIMER_BY_ERC_PENDING";
export const GET_IYS_DISCLAIMER_BY_ERC_FULFILLED =
  "GET_IYS_DISCLAIMER_BY_ERC_FULFILLED";
export const GET_IYS_DISCLAIMER_BY_ERC_REJECTED =
  "GET_IYS_DISCLAIMER_BY_ERC_REJECTED";

export const SAVE_IYS_DISCLAIMER_PENDING = "SAVE_IYS_DISCLAIMER_PENDING";
export const SAVE_IYS_DISCLAIMER_FULFILLED = "SAVE_IYS_DISCLAIMER_FULFILLED";
export const SAVE_IYS_DISCLAIMER_REJECTED = "SAVE_IYS_DISCLAIMER_REJECTED";

export const GET_DISCLAIMER_BY_FIRMID_PENDING =
  "GET_DISCLAIMER_BY_FIRMID_PENDING";
export const GET_DISCLAIMER_BY_FIRMID_FULFILLED =
  "GET_DISCLAIMER_BY_FIRMID_FULFILLED";
export const GET_DISCLAIMER_BY_FIRMID_REJECTED =
  "GET_DISCLAIMER_BY_FIRMID_REJECTED";

export const SAVE_DISCLAIMER_CHECK_PENDING = "SAVE_DISCLAIMER_CHECK_PENDING";
export const SAVE_DISCLAIMER_CHECK_FULFILLED =
  "SAVE_DISCLAIMER_CHECK_FULFILLED";
export const SAVE_DISCLAIMER_CHECK_REJECTED = "SAVE_DISCLAIMER_CHECK_REJECTED";

export const GET_DISCLAIMER_PHOTO_BY_FIRMID_PENDING =
  "GET_DISCLAIMER_PHOTO_BY_FIRMID_PENDING";
export const GET_DISCLAIMER_PHOTO_BY_FIRMID_FULFILLED =
  "GET_DISCLAIMER_PHOTO_BY_FIRMID_FULFILLED";
export const GET_DISCLAIMER_PHOTO_BY_FIRMID_REJECTED =
  "GET_DISCLAIMER_PHOTO_BY_FIRMID_REJECTED";

export const SAVE_DISCLAIMER_PHOTO_PENDING = "SAVE_DISCLAIMER_PHOTO_PENDING";
export const SAVE_DISCLAIMER_PHOTO_FULFILLED =
  "SAVE_DISCLAIMER_PHOTO_FULFILLED";
export const SAVE_DISCLAIMER_PHOTO_REJECTED = "SAVE_DISCLAIMER_PHOTO_REJECTED";
