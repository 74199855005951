import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { magazineAction } from "redux/modules";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";

const MagazineLeftMenu = props => {
  const dispatch = useDispatch();
  const categoryList = useSelector(store => store.magazine.categoryList);
  const actives = useSelector(store => store.magazine.actives);
  const [disableActives, setDisableActives] = useState(false);
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const isLoading = useSelector(store => store.magazine.isLoading);
  const subCatId = props.subCatId;

  const topicCategory = useSelector(store => store.magazine.topicCategory);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location && location.state && location.state.actives) {
      dispatch(
        magazineAction.getSubCatList(
          employeeFirm.firm.id,
          "Bizim Dergi",
          location.state.actives
        )
      );
    } else {
      dispatch(
        magazineAction.getSubCatList(
          employeeFirm.firm.id,
          "Bizim Dergi",
          actives
        )
      );
    }

    if (location && location.pathname.split("/").length > 3) {
      setDisableActives(true);
    }

    if (subCatId) {
      dispatch(
        magazineAction.getArticlesBySubCatId(employeeFirm.firm.id, subCatId)
      );
      dispatch(
        magazineAction.getTopicCategory(employeeFirm.firm.id, "Bizim Dergi")
      );
    } else {
      dispatch(magazineAction.getArticlesBySubCatId(employeeFirm.firm.id, 1));
      dispatch(
        magazineAction.getTopicCategory(employeeFirm.firm.id, "Bizim Dergi")
      );
    }

    return () => {};
  }, []);

  function setClicked(index2, subCatId) {
    categoryList.map((item, index) => {
      index === index2 ? (actives[index] = true) : (actives[index] = false);
    });

    if (location.pathname.split("/").length > 3) {
      history.push("/userportal/magazine", {
        subCatId: subCatId,
        actives: actives
      });
    } else {
      dispatch(magazineAction.setMagazineContent());
      dispatch(
        magazineAction.getArticlesBySubCatId(employeeFirm.firm.id, subCatId)
      );
    }
  }

  return (
    <>
      <GlobalLoader isLoading={isLoading} />
      <div className="md:col-span-4 lg:col-span-3 left-side-blank">
        {topicCategory && topicCategory != null
          ? topicCategory.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col gap-6 rounded-xl bg-slate-200 p-6 pb-9 font-medium"
                >
                  {/* <h3 className="text-2xl text-slate-700">
                    Benim Bayim Dergi’ye hoşgeldiniz.
                  </h3>

                  <p className="text-sm font-normal leading-6 text-slate-500">
                    Benim Bayim Dergi, her hafta Otomotiv,
                    Teknoloji, Gurme ve Ekonomi konularinda yeni
                    bilgileri sizlerle bulusturyor. Hayatinizi
                    kolaylastiracak ve ufkunuzu genisletecek
                    yararli bilgiler için makalelerimize goz
                    atmayi unutmayin!
                  </p> */}

                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              );
            })
          : null}

        <div className="mt-4 flex flex-col gap-6 rounded-xl rounded-bl-[48px] bg-slate-200 p-6 pb-9 font-medium sm:mt-6 select-none">
          {categoryList && categoryList.length > 0
            ? categoryList.map((item, index) => {
                return (
                  <a
                    key={index}
                    data-test={item.id}
                    className={`${
                      actives && actives[index] && disableActives === false
                        ? "active [&.active]:text-primary-500"
                        : "flex items-center justify-between gap-4 text-slate-500"
                    }`}
                    onClick={() => {
                      setClicked(index, item.id);
                    }}
                  >
                    {item.name}
                  </a>
                );
              })
            : null}
        </div>
      </div>
    </>
  );
};
export default MagazineLeftMenu;
