import * as weekStoreTypes from "./weekStore.type";
import WeekCompetition from "pages/UserPortal/WeekStore/WeekCompetition";

const initialState = {
  currentWeekStoreContent: WeekCompetition,
  weekStoreToBeVotedList: null,
  selectedWeekStoreCandidate: null,
  myVoteListByCompetition: null,
  weekWinnerList: null
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${weekStoreTypes.SET_WEEK_STORE_CONTENT_PENDING}`:
    case `${weekStoreTypes.GET_WEEK_STORE_TO_BE_VOTED_LIST_PENDING}`:
    case `${weekStoreTypes.SET_SELECTED_SLIDE_PENDING}`:
    case `${weekStoreTypes.GET_MY_VOTES_BY_COMPETITION_PENDING}`:
    case `${weekStoreTypes.VOTE_VENDOR_PENDING}`:
    case `${weekStoreTypes.GET_WEEK_WINNER_LIST_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${weekStoreTypes.SET_WEEK_STORE_CONTENT_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        currentWeekStoreContent: payload
      };
    case `${weekStoreTypes.SET_WEEK_STORE_CONTENT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        currentWeekStoreContent: null
      };

    case `${weekStoreTypes.GET_WEEK_STORE_TO_BE_VOTED_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        weekStoreToBeVotedList: payload.data
      };
    case `${weekStoreTypes.GET_WEEK_STORE_TO_BE_VOTED_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        weekStoreToBeVotedList: null
      };

    case `${weekStoreTypes.SET_SELECTED_SLIDE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        selectedWeekStoreCandidate: payload
      };
    case `${weekStoreTypes.SET_SELECTED_SLIDE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        selectedWeekStoreCandidate: null
      };

    case `${weekStoreTypes.GET_MY_VOTES_BY_COMPETITION_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        myVoteListByCompetition: payload.data
      };
    case `${weekStoreTypes.GET_MY_VOTES_BY_COMPETITION_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        myVoteListByCompetition: null
      };

    case `${weekStoreTypes.VOTE_VENDOR_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };
    case `${weekStoreTypes.VOTE_VENDOR_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${weekStoreTypes.GET_WEEK_WINNER_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        weekWinnerList: payload.data
      };
    case `${weekStoreTypes.GET_WEEK_WINNER_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        weekWinnerList: null
      };

    default:
      return state;
  }
};

export default reducer;
