export const GET_ORDERS_BY_ID_PENDING = "GET_ORDERS_BY_ID_PENDING";
export const GET_ORDERS_BY_ID_FULFILLED = "GET_ORDERS_BY_ID_FULFILLED";
export const GET_ORDERS_BY_ID_REJECTED = "GET_ORDERS_BY_ID_REJECTED";

export const GET_ORDER_NUMBER_PENDING = "GET_ORDER_NUMBER_PENDING";
export const GET_ORDER_NUMBER_FULFILLED = "GET_ORDER_NUMBER_FULFILLED";
export const GET_ORDER_NUMBER_REJECTED = "GET_ORDER_NUMBER_REJECTED";

export const SET_ORDER_LIST_PENDING = "SET_ORDER_LIST_PENDING";
export const SET_ORDER_LIST_FULFILLED = "SET_ORDER_LIST_FULFILLED";
export const SET_ORDER_LIST_REJECTED = "SET_ORDER_LIST_REJECTED";

export const SET_SAVE_ORDER_LIST_PENDING = "SET_SAVE_ORDER_LIST_PENDING";
export const SET_SAVE_ORDER_LIST_FULFILLED = "SET_SAVE_ORDER_LIST_FULFILLED";
export const SET_SAVE_ORDER_LIST_REJECTED = "SET_SAVE_ORDER_LIST_REJECTED";

export const SET_ORDER_LOGO_PENDING = "SET_ORDER_LOGO_PENDING";
export const SET_ORDER_LOGO_FULFILLED = "SET_ORDER_LOGO_FULFILLED";
export const SET_ORDER_LOGO_REJECTED = "SET_ORDER_LOGO_REJECTED";

export const SET_ADDRESS_PENDING = "SET_ADDRESS_PENDING";
export const SET_ADDRESS_FULFILLED = "SET_ADDRESS_FULFILLED";
export const SET_ADDRESS_REJECTED = "SET_ADDRESS_REJECTED";
