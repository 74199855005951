export const GET_TOPIC_SUB_CATEGORY_LIST_PENDING =
  "GET_TOPIC_SUB_CATEGORY_LIST_PENDING";
export const GET_TOPIC_SUB_CATEGORY_LIST_FULLFILLED =
  "GET_TOPIC_SUB_CATEGORY_LIST_FULLFILLED";
export const GET_TOPIC_SUB_CATEGORY_LIST_REJECTED =
  "GET_TOPIC_SUB_CATEGORY_LIST_REJECTED";

export const GET_TOPIC_CATEGORY_PENDING = "GET_TOPIC_CATEGORY_PENDING";
export const GET_TOPIC_CATEGORY_FULLFILLED = "GET_TOPIC_CATEGORY_FULLFILLED";
export const GET_TOPIC_CATEGORY_REJECTED = "GET_TOPIC_CATEGORY_REJECTED";

export const SET_MAGAZINE_CONTENT_PENDING = "SET_MAGAZINE_CONTENT_PENDING";
export const SET_MAGAZINE_CONTENT_FULLFILLED =
  "SET_MAGAZINE_CONTENT_FULLFILLED";
export const SET_MAGAZINE_CONTENT_REJECTED = "SET_MAGAZINE_CONTENT_REJECTED";

export const GET_ARTICLES_PENDING = "GET_ARTICLES_PENDING";
export const GET_ARTICLES_FULLFILLED = "GET_ARTICLES_FULLFILLED";
export const GET_ARTICLES_REJECTED = "GET_ARTICLES_REJECTED";

export const GET_ARTICLE_DETAIL_PENDING = "GET_ARTICLE_DETAIL_PENDING";
export const GET_ARTICLE_DETAIL_FULLFILLED = "GET_ARTICLE_DETAIL_FULLFILLED";
export const GET_ARTICLE_DETAIL_REJECTED = "GET_ARTICLE_DETAIL_REJECTED";

export const GET_MIXED_LIST_PENDING = "GET_MIXED_LIST_PENDING";
export const GET_MIXED_LIST_FULLFILLED = "GET_MIXED_LIST_FULLFILLED";
export const GET_MIXED_LIST_REJECTED = "GET_MIXED_LIST_REJECTED";

export const GET_ARTICLE_HASHTAG_PENDING = "GET_ARTICLE_HASHTAG_PENDING";
export const GET_ARTICLE_HASHTAG_FULLFILLED = "GET_ARTICLE_HASHTAG_FULLFILLED";
export const GET_ARTICLE_HASHTAG_REJECTED = "GET_ARTICLE_HASHTAG_REJECTED";

export const SET_ACTIVES_PENDING = "SET_ACTIVES_PENDING";
export const SET_ACTIVES_FULFILLED = "SET_ACTIVES_FULFILLED";
export const SET_ACTIVES_REJECTED = "SET_ACTIVES_REJECTED";

export const SET_CATEGORYLIST_PENDING = "SET_CATEGORYLIST_PENDING";
export const SET_CATEGORYLIST_FULFILLED = "SET_CATEGORYLIST_FULFILLED";
export const SET_CATEGORYLIST_REJECTED = "SET_CATEGORYLIST_REJECTED";
