import showErrMessage from "hooks/showErrMessage";

export default function validatePasswords(password, confirmPassword) {
  var returnMessage = false;

  if (password.trim() !== "" && confirmPassword.trim() !== "") {
    if (password.trim() === confirmPassword.trim()) {
      var regex = new RegExp("^[a-zA-Z0-9 ]+$");
      if (regex.test(password.trim())) {
        returnMessage = true;
      } else {
        showErrMessage(" Şifre özel karakter içeremez ");
      }
    } else {
      showErrMessage(" Şifre alanları eşleşmiyor ");
    }
  } else {
    showErrMessage(" Şifre alanları boş bırakılamaz ");
  }

  return returnMessage;
}
