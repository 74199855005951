export const GET_EMPLOYEE_FIRM_BY_ID_PENDING =
  "GET_EMPLOYEE_FIRM_BY_ID_PENDING";
export const GET_EMPLOYEE_FIRM_BY_ID_FULFILLED =
  "GET_EMPLOYEE_FIRM_BY_ID_FULFILLED";
export const GET_EMPLOYEE_FIRM_BY_ID_REJECTED =
  "GET_EMPLOYEE_FIRM_BY_ID_REJECTED";

export const GET_EMPLOYEE_FIRM_LOGIN_DETAILS_PENDING =
  "GET_EMPLOYEE_FIRM_LOGIN_DETAILS_PENDING";
export const GET_EMPLOYEE_FIRM_LOGIN_DETAILS_FULFILLED =
  "GET_EMPLOYEE_FIRM_LOGIN_DETAILS_FULFILLED";
export const GET_EMPLOYEE_FIRM_LOGIN_DETAILS_REJECTED =
  "GET_EMPLOYEE_FIRM_LOGIN_DETAILS_REJECTED";

export const SET_SECURITY_STATUS_PENDING = "SET_SECURITY_STATUS_PENDING";
export const SET_SECURITY_STATUS_FULFILLED = "SET_SECURITY_STATUS_FULFILLED";
export const SET_SECURITY_STATUS_REJECTED = "SET_SECURITY_STATUS_REJECTED";

export const SET_SAFE_DEVICE_PENDING = "SET_SAFE_DEVICE_PENDING";
export const SET_SAFE_DEVICE_FULFILLED = "SET_SAFE_DEVICE_FULFILLED";
export const SET_SAFE_DEVICE_REJECTED = "SET_SAFE_DEVICE_REJECTED";
