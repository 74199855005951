import * as brandStyleTypes from "./brandStyle.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getBrandStyleByBrandId = brandId => async dispatch => {
  dispatch({ type: brandStyleTypes.GET_BRAND_STYLE_BY_ID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/brandStyleList/${brandId}`,
      config
    );
    dispatch({
      type: brandStyleTypes.GET_BRAND_STYLE_BY_ID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: brandStyleTypes.GET_BRAND_STYLE_BY_ID_REJECTED,
      payload: error
    });
  }
};

function returnSlider(openSlider) {
  switch (openSlider) {
    case true:
      return true;

    case false:
      return false;
  }
}

export const setSlider = openSlider => async dispatch => {
  dispatch({ type: brandStyleTypes.SET_SLIDER_PENDING });

  try {
    const returnComponent = returnSlider(openSlider);

    dispatch({
      type: brandStyleTypes.SET_SLIDER_FULFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: brandStyleTypes.SET_SLIDER_REJECTED,
      payload: error
    });
  }
};

function returnIndex(index) {
  return index;
}

export const setIndexStyle = index => async dispatch => {
  dispatch({ type: brandStyleTypes.SET_STYLE_INDEX_PENDING });

  try {
    const returnComponent = returnIndex(index);

    dispatch({
      type: brandStyleTypes.SET_STYLE_INDEX_FULFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: brandStyleTypes.SET_STYLE_INDEX_REJECTED,
      payload: error
    });
  }
};

function returnIndexBullet(index) {
  return index;
}

export const setIndexBullet = index => async dispatch => {
  dispatch({ type: brandStyleTypes.SET_INDEX_BULLET_PENDING });

  try {
    const returnComponent = returnIndexBullet(index);
    dispatch({
      type: brandStyleTypes.SET_INDEX_BULLET_FULFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: brandStyleTypes.SET_INDEX_BULLET_REJECTED,
      payload: error
    });
  }
};

export const changeSelectedDataList = listId => async dispatch => {
  dispatch({ type: brandStyleTypes.SELECTED_DATA_LIST, payload: listId });
};
