import * as wwpTypes from "./wwp.type";

const initialState = {
  wwpList: null,
  bonus: null,
  controlWwp: null,
  resultWwp: null,
  answer: false,
  error: null,
  isLoading: false
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${wwpTypes.GET_AVAILABLEWWP_BY_FIRMID_PENDING}`:
    case `${wwpTypes.GET_WWPLIST_PENDING}`:
    case `${wwpTypes.SAVE_WWP_ANSWER_PENDING}`:
    case `${wwpTypes.GET_RESULT_WWP_BY_FIRMID_PENDING}`:
    case `${wwpTypes.GET_CONTROL_WWP_PENDING}`:
    case `${wwpTypes.GET_BONUS_WWP_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${wwpTypes.GET_WWPLIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        wwpList: payload.data
      };

    case `${wwpTypes.GET_WWPLIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${wwpTypes.GET_BONUS_WWP_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        bonus: payload.data
      };

    case `${wwpTypes.GET_BONUS_WWP_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${wwpTypes.GET_CONTROL_WWP_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        controlWwp: payload.data
      };

    case `${wwpTypes.GET_CONTROL_WWP_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${wwpTypes.SAVE_WWP_ANSWER_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };

    case `${wwpTypes.SAVE_WWP_ANSWER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${wwpTypes.GET_RESULT_WWP_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        resultWwp: payload.data
      };

    case `${wwpTypes.GET_RESULT_WWP_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${wwpTypes.SELECTED_OPTION}`:
      return {
        ...state,
        selectedOption: payload
      };

    case `${wwpTypes.WWP_ANSWER}`:
      return {
        ...state,
        answer: payload
      };

    default:
      return state;
  }
};

export default reducer;
