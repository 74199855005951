import * as scoreHuntTypes from "./scoreHunt.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getControlScoreHuntByFirmId = employeeId => async dispatch => {
  dispatch({ type: scoreHuntTypes.GET_AVAILABLESCOREHUNT_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/scoreHunt/${employeeId}`,
      config
    );
    dispatch({
      type: scoreHuntTypes.GET_AVAILABLESCOREHUNT_BY_FIRMID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: scoreHuntTypes.GET_AVAILABLESCOREHUNT_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};

export const getScoreHuntByFirmId = employeeId => async dispatch => {
  dispatch({ type: scoreHuntTypes.GET_SCOREHUNTLIST_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/scoreHuntQuestion/${employeeId}`,
      config
    );
    dispatch({
      type: scoreHuntTypes.GET_SCOREHUNTLIST_BY_FIRMID_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: scoreHuntTypes.GET_SCOREHUNTLIST_BY_FIRMID_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const saveFindQuestionAnswer = (
  question,
  questionChoice,
  employeeFirmId
) => async dispatch => {
  dispatch({ type: scoreHuntTypes.SAVE_SCOREHUNT_ANSWER_PENDING });

  const data = { question, questionChoice, employeeFirmId };
  try {
    const response = await API.post(`/scoreHunt/save`, data);
    const interaction = {
      message: " ",
      type: "notification"
    };

    dispatch({
      type: scoreHuntTypes.SAVE_SCOREHUNT_ANSWER_FULFILLED,
      payload: response,
      interaction
    });

    //    dispatch(getResultSearchFind(firmId, employeeFirmId)); ???
    dispatch({
      type: scoreHuntTypes.SELECTED_OPTION,
      payload: null
    });
  } catch (error) {
    dispatch({
      type: scoreHuntTypes.SAVE_SCOREHUNT_ANSWER_REJECTED,
      payload: error
    });
  }
};

export const getResultScoreHunt = employeeId => async dispatch => {
  dispatch({ type: scoreHuntTypes.GET_RESULT_SCOREHUNT_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/scoreHuntAnswer/${employeeId}`,
      config
    );
    dispatch({
      type: scoreHuntTypes.GET_RESULT_SCOREHUNT_BY_FIRMID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: scoreHuntTypes.GET_RESULT_SCOREHUNT_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};

export const changeOption = selectedOption => async dispatch => {
  dispatch({ type: scoreHuntTypes.SELECTED_OPTION, payload: selectedOption });
};
