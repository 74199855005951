import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { disclaimerAction, orderAction } from "redux/modules";

import IllumintionText from "../../../components/IlluminationText";
import PdpPolicy from "../../../components/PdpPolicy";

import showMessage from "hooks/showMessage";
import showErrMessage from "hooks/showErrMessage";

const ProfileMyDocuments = () => {
  const dispatch = useDispatch();

  const [checkedImageConfirm, setCheckedImageConfirm] = useState(null);
  const [selectedChoice, setSelectedChoice] = useState(null);

  const [checkedIysConfirm, setCheckedIysConfirm] = useState(null);
  const [selectedChoiceIys, setSelectedChoiceIys] = useState(null);

  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const disclaimerPhoto = useSelector(
    store => store.disclaimer.disclaimerPhoto
  );

  useEffect(() => {
    if (employeeFirm?.id) {
      dispatch(orderAction.getOrdersByEmployeeFirmId(employeeFirm.id));
    }
  }, [dispatch, employeeFirm?.id]);

  useEffect(() => {
    if (checkedImageConfirm !== null) {
      setSelectedChoice(checkedImageConfirm);
    }
  }, [checkedImageConfirm]);

  useEffect(() => {
    if (checkedIysConfirm !== null) {
      setSelectedChoiceIys(checkedIysConfirm);
    }
  }, [checkedIysConfirm]);

  useEffect(() => {
    if (employeeFirm?.id) {
      dispatch(
        disclaimerAction.getDisclaimerPhotoByFirmId(employeeFirm?.id)
      ).then(resPhoto => {
        if (resPhoto.isHttpSuccess && resPhoto?.data?.length > 0) {
          setCheckedImageConfirm(resPhoto?.data[0]?.confirmed);
        }
      });
    }

    if (employeeFirm?.ercCode) {
      dispatch(
        disclaimerAction.getIysDisclaimerByErc(employeeFirm.ercCode)
      ).then(res => {
        if (res.isHttpSuccess) {
          setCheckedIysConfirm(res.data?.confirmed);
        }
      });
    }
  }, [dispatch, employeeFirm]);

  const saveIys = e => {
    e.preventDefault();

    if (selectedChoiceIys !== null) {
      dispatch(
        disclaimerAction.saveIysDisclaimer(
          employeeFirm.ercCode,
          selectedChoiceIys
        )
      ).then(res => {
        if (res.isHttpSuccess) {
          showMessage("Iys tercihleriniz kaydedildi!");
        }
      });
    } else {
      showErrMessage("Lütfen bir seçenek seçiniz!");
    }
  };

  const savePhotoIys = e => {
    e.preventDefault();

    if (selectedChoice !== null) {
      dispatch(
        disclaimerAction.saveDisclaimerPhoto(
          employeeFirm.id,
          disclaimerPhoto[0]?.id,
          selectedChoice
        )
      ).then(res => {
        if (res.isHttpSuccess) {
          showMessage("Fotoğraf izinleriniz kaydedildi!");
        }
      });
    } else {
      showErrMessage("Lütfen bir seçenek seçiniz!");
    }
  };

  return (
    <div
      className="tab-pane fade show active"
      id="orders"
      role="tabpanel"
      aria-labelledby="orders-tab"
    >
      <div className="user-content-item orders">
        <ul className="nav" role="tablist">
          <li>
            <a
              className="active"
              id="illumination-text-tab"
              data-toggle="tab"
              href="#illumination-text"
              role="tab"
              aria-controls="illumination-text"
              aria-selected="true"
            >
              Aydınlatma Metni
            </a>
          </li>

          <li className="nav-item">
            <a
              id="pdp-tab"
              data-toggle="tab"
              href="#pdp"
              role="tab"
              aria-controls="pdp"
              aria-selected="true"
            >
              Kişisel Verilerin Korunması ve İşlenmesi Politikası
            </a>
          </li>

          <li className="nav-item">
            <a
              id="photo-tab"
              data-toggle="tab"
              href="#photo"
              role="tab"
              aria-controls="photo"
              aria-selected="true"
            >
              Açık Rıza Beyanı - Fotoğraf Paylaşımı
            </a>
          </li>

          <li className="nav-item">
            <a
              id="iys-tab"
              data-toggle="tab"
              href="#iys"
              role="tab"
              aria-controls="iys"
              aria-selected="true"
            >
              Açık Rıza Beyanı - Ticari İleti
            </a>
          </li>
        </ul>

        <div className="tab-content">
          <div
            className="tab-pane fade show active"
            id="illumination-text"
            role="tabpanel"
            aria-labelledby="illumination-text-tab"
          >
            <IllumintionText />
          </div>

          <div
            className="tab-pane fade"
            id="pdp"
            role="tabpanel"
            aria-labelledby="pdp-tab"
          >
            <PdpPolicy />
          </div>

          <div
            className="tab-pane fade"
            id="photo"
            role="tabpanel"
            aria-labelledby="photo-tab"
          >
            <div className="max-h-[800px] p-[25px] overflow-y-scroll">
              <div>
                <h1 className="font-bold">
                  KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA <br />
                  <br />
                  BENİM BAYİM KULLANICILARINA AİT GÖRSELLERİN İŞLENMESİNE
                  İLİŞKİN AÇIK RIZA BEYANI
                </h1>

                <br />

                <p>
                  İşbu açık rıza beyanı kapsamında JTI Tütün Ürünleri Pazarlama
                  A.Ş ile paylaşmış olduğum fotoğraf görsel kayıt bilgilerinin
                  Benim Bayim platformu bünyesinde profil fotoğrafı olarak
                  yayımlanmasına açık rıza gösterdiğimi, işbu muvafakatnameyi
                  hiçbir baskı veya zorlama altında olmaksızın, hür irademle
                  okuduğumu, kabul ve beyan ediyorum.
                </p>

                <form
                  className="d-flex flex-col justify-center align-center w-full mt-4"
                  onSubmit={savePhotoIys}
                >
                  <div className="text-center mb-3">
                    <label className="mr-3 cursor-pointer">
                      <input
                        type="radio"
                        name="iysPhoto"
                        checked={selectedChoice === true}
                        onChange={() => setSelectedChoice(true)}
                      />
                      <span>İzin veriyorum</span>
                    </label>

                    <label className="cursor-pointer">
                      <input
                        type="radio"
                        name="iysPhoto"
                        checked={selectedChoice === false}
                        onChange={() => setSelectedChoice(false)}
                      />
                      <span>İzin vermiyorum</span>
                    </label>
                  </div>

                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-success text-white hover:opacity-70 rounded-[10px]"
                    >
                      Gönder
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="iys"
            role="tabpanel"
            aria-labelledby="iys-tab"
          >
            <div className="max-h-[800px] p-[25px] overflow-y-scroll">
              <div>
                <h1 className="font-bold">
                  KİŞİSEL VERİLERİN İŞLENMESİ VE TİCARİ ELEKTRONİK İLETİ
                  GÖNDERİLMESİNE İLİŞKİN AÇIK RIZA METNİ
                </h1>

                <br />

                <p>
                  İşbu açık rıza metni kapsamında aydınlatma metninde de detaylı
                  belirtildiği üzere mevcut veya ileride güncelleyeceğim, veri
                  sorumlusu <strong>JTI Tütün Ürünleri Pazarlama A.Ş</strong>{" "}
                  ile paylaşmış olduğum <strong>telefon</strong> numarası ve{" "}
                  <strong>e-posta</strong> iletişim kişisel verilerimin
                </p>
                <br />
                <p>
                  • JTI Benim Bayim platformu üyeliği kapsamında oluşturulan
                  bilgilendirmeler, pazarlama faaliyetlerinin duyurulması ve
                  iletilmesi,
                  <br />• Ürün ve hizmetlerimizle ilgili kullanıcı memnuniyetine
                  yönelik anketlerin, yarışma diğer etkinliklere ilişkin
                  bilgilerin iletilmesi,
                </p>
                <br />
                <p>
                  amaçları ile ürün, hizmet ve ilgili pazarlama ve bilgi amaçlı{" "}
                  <strong>SMS</strong> ve <strong>E-Posta</strong> şeklinde
                  elektronik ileti gönderilmesine ve tarafımla iletişime
                  geçilmesine açık rıza gösterdiğimi, işbu muvafakati hiçbir
                  baskı veya zorlama altında olmaksızın, hür irademle okuduğumu,
                  kabul ve beyan ediyorum. Tarafıma ticari ve pazarlama amaçlı
                  elektronik ileti gönderilmesine onay veriyorum.
                </p>
                <br />
                <p>
                  Benim Bayim platformuna yönelik bilgilendirmelerin
                  yapılabilmesi ve pazarlama çalışmasının yürütülebilmesi için
                  yukarıdaki açık rıza metni doğrultusunda{" "}
                  <strong>JTI Tütün Ürünleri Pazarlama A.Ş</strong> tarafından
                  tarafıma ticari elektronik ileti gönderilmesine:
                </p>

                <form
                  className="d-flex flex-col justify-center align-center w-full mt-4"
                  onSubmit={saveIys}
                >
                  <div className="text-center mb-3">
                    <label className="mr-3 cursor-pointer">
                      <input
                        type="radio"
                        name="iys"
                        checked={selectedChoiceIys === true}
                        onChange={() => setSelectedChoiceIys(true)}
                      />
                      <span>İzin veriyorum</span>
                    </label>

                    <label className="cursor-pointer">
                      <input
                        type="radio"
                        name="iys"
                        checked={selectedChoiceIys === false}
                        onChange={() => setSelectedChoiceIys(false)}
                      />
                      <span>İzin vermiyorum</span>
                    </label>
                  </div>

                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-success text-white hover:opacity-70 rounded-[10px]"
                    >
                      Gönder
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileMyDocuments;
