import React from "react";
import ReactLoading from "react-loading";

const GlobalLoader = isLoading => {
  return (
    <>
      {isLoading.isLoading === true && (
        <div
          className="w-100 h-100 fixed flex flex-col justify-content-center align-center"
          style={{
            top: "0",
            left: "0",
            margin: "0",
            zIndex: "99999"
          }}
        >
          <ReactLoading type="spin" color="#00B185" className="m-auto" />
        </div>
      )}
    </>
  );
};

export default GlobalLoader;
