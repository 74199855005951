import * as magazineTypes from "./magazine.type";

import { API } from "../../../services/http.service";
import HealthyLife from "pages/UserPortal/Magazine/HealthyLife";
const REQUEST_URL = "";

const config = {
  headers: {
    "Content-Type": "application/json-application"
  }
};

export const getSubCatList = (
  id,
  topicCatName,
  activesLast
) => async dispatch => {
  dispatch({
    type: magazineTypes.GET_TOPIC_SUB_CATEGORY_LIST_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/topicSubCategory/${id}/${topicCatName}`,
      config
    );
    const actives = [];
    const a = 0;
    if (activesLast && activesLast.length > 0)
      activesLast.map((item, index) => {
        if (a === 0)
          response.data.map((item2, index2) => {
            activesLast[index] === true
              ? actives.push(true)
              : actives.push(false);
          });
        a = a + 1;
      });
    else
      response.data.map((item, index) => {
        index === 0 ? actives.push(true) : actives.push(false);
      });
    /*
        if (activesLast[0] === true)
          response.data.map((item, index) => {
            index === 0 ? actives.push(true) : actives.push(false);
          });
        else if (activesLast[1] === true)
          response.data.map((item, index) => {
            index === 1 ? actives.push(true) : actives.push(false);
          }); */

    dispatch({
      type: magazineTypes.GET_TOPIC_SUB_CATEGORY_LIST_FULLFILLED,
      payload: response.data,
      actives: actives
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: magazineTypes.GET_TOPIC_SUB_CATEGORY_LIST_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};

export const getTopicCategory = (id, text) => async dispatch => {
  dispatch({
    type: magazineTypes.GET_TOPIC_CATEGORY_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/topicCategory/${id}/${text}`,
      config
    );
    dispatch({
      type: magazineTypes.GET_TOPIC_CATEGORY_FULLFILLED,
      payload: response.data
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: magazineTypes.GET_TOPIC_CATEGORY_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};

export const setMagazineContent = () => async dispatch => {
  dispatch({
    type: magazineTypes.SET_MAGAZINE_CONTENT_PENDING
  });

  try {
    const returnComponent = HealthyLife;
    dispatch({
      type: magazineTypes.SET_MAGAZINE_CONTENT_FULLFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: magazineTypes.SET_MAGAZINE_CONTENT_REJECTED,
      payload: error
    });
  }
};

export const getArticlesBySubCatId = (id, subCatId) => async dispatch => {
  dispatch({
    type: magazineTypes.GET_ARTICLES_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/article/${id}/${subCatId}`,
      config
    );
    dispatch({
      type: magazineTypes.GET_ARTICLES_FULLFILLED,
      payload: response.data
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: magazineTypes.GET_ARTICLES_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};

export const getArticleById = id => async dispatch => {
  dispatch({
    type: magazineTypes.GET_ARTICLE_DETAIL_PENDING
  });

  try {
    const response = await API.get(`${REQUEST_URL}/article/${id}`, config);

    dispatch({
      type: magazineTypes.GET_ARTICLE_DETAIL_FULLFILLED,
      payload: response.data
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: magazineTypes.GET_ARTICLE_DETAIL_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};

function returnActives(actives) {
  return actives;
}

export const setActives = actives => async dispatch => {
  dispatch({
    type: magazineTypes.SET_ACTIVES_PENDING
  });

  try {
    const returnComponent = returnActives(actives);
    dispatch({
      type: magazineTypes.SET_ACTIVES_FULFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: magazineTypes.SET_ACTIVES_REJECTED,
      payload: error
    });
  }
};

function returnCatList(categoryList) {
  return categoryList;
}

export const setCategoryList = categoryList => async dispatch => {
  dispatch({
    type: magazineTypes.SET_CATEGORYLIST_PENDING
  });

  try {
    const returnComponent = returnCatList(categoryList);
    dispatch({
      type: magazineTypes.SET_CATEGORYLIST_FULFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: magazineTypes.SET_CATEGORYLIST_REJECTED,
      payload: error
    });
  }
};

export const getMixedList = (firmId, subCatId, articleId) => async dispatch => {
  dispatch({
    type: magazineTypes.GET_MIXED_LIST_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/mixedList/${firmId}/${subCatId}/${articleId}`,
      config
    );

    dispatch({
      type: magazineTypes.GET_MIXED_LIST_FULLFILLED,
      payload: response.data
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: magazineTypes.GET_MIXED_LIST_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};

export const getArticleHashtag = hashtag => async dispatch => {
  dispatch({
    type: magazineTypes.GET_ARTICLE_HASHTAG_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/article/hashtag/${hashtag}`,
      config
    );

    dispatch({
      type: magazineTypes.GET_ARTICLE_HASHTAG_FULLFILLED,
      payload: response.data
    });

    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: magazineTypes.GET_ARTICLE_HASHTAG_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};
