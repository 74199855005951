import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lookFindAction, authAction } from "redux/modules";
import showMessage from "hooks/showMessage";
import showErrMessage from "hooks/showErrMessage";
import { Link, Redirect } from "react-router-dom";
import { imageShowUrl } from "config/api.config";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { CountdownCircleTimer } from "react-countdown-circle-timer";

const LookFind = props => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const lookFindList = useSelector(store => store.lookFind.lookFindList);
  const selectedOption = useSelector(store => store.lookFind.selectedOption);
  const isLoading = useSelector(store => store.lookFind.isLoading);

  var lookFindGame = null;

  const [seconds, setSeconds] = useState();
  const [isActive, setIsActive] = useState(true);

  const [redirect, setRedirect] = useState(false);
  const [redirectNoQuestion, setRedirectNoQuestion] = useState(false);

  const choiceList = [];
  const [selectedChoice, setSelectedChoice] = useState([]);

  const [countDownTimer, setCountDownTimer] = useState(null);

  useEffect(() => {
    dispatch(
      lookFindAction.getLookFindByFirmId(employeeFirm.firm.id, employeeFirm.id)
    ).then(e => {
      if (e.data) {
        e.data.map(item => {
          setCountDownTimer(
            <CountdownCircleTimer
              isPlaying
              duration={item.totalTime}
              initialRemainingTime={item.remainTime}
              colors={["#fff"]}
              size={120}
              trailColor={"#00b9ff"}
              onComplete={() => {
                stopTimerToZero(
                  item.questionId,
                  selectedChoice,
                  employeeFirm.id,
                  employeeFirm.firm.id
                );
              }}
            >
              {({ remainingTime }) => remainingTime}
            </CountdownCircleTimer>
          );
        });
      }
    });

    return () => {};
  }, []);

  useEffect(() => {
    return () => {};
  }, [lookFindList]);

  function stopTimer() {
    setIsActive(false);
  }

  function stopTimerToZero(question, questionChoice, employeeFirmId, firmId) {
    setSeconds(0);
    setIsActive(false);
    questionChoice = null;
    dispatch(
      lookFindAction.saveQuestionAnswer(
        question,
        questionChoice,
        employeeFirmId,
        firmId
      )
    )
      .then(e => {
        console.log("e", e);
      })
      .catch(err => {
        // Show Error Message
        if (err.error.response.data) {
          if (err.error.response.data.errors) {
            err.error.response.data.errors.map(item => {
              showErrMessage(item.message);
            });
          }
        }
      });
    setRedirect(true);
  }

  function onClickOption(value, choiceId) {
    choiceList.push(choiceId);
    setSelectedChoice(choiceList);
    dispatch(lookFindAction.changeOption(value));
  }

  function showChoices(choicesList) {
    var questionChoices = null;

    questionChoices = choicesList.map((itemChoice, index) => (
      <div key={itemChoice.id} className="col-12 col-lg-6">
        <div id="cevaplar">
          <div
            className="cevap d-flex"
            onClick={() => onClickOption(index + 1, itemChoice.id)}
          >
            <div
              className={
                selectedOption === index + 1 ? "option active" : "option"
              }
              data-cevap={index + 1}
            >
              <div></div>
            </div>
            <div className="text align-self-center">{itemChoice.name}</div>
          </div>
        </div>
      </div>
    ));
    return questionChoices;
  }

  function saveQuestion(question, questionChoice, employeeFirmId, firmId, e) {
    if (questionChoice.length <= 0) {
      showErrMessage("Seçenek seçiniz.");
      e.preventDefault();
    } else {
      stopTimer();
      dispatch(
        lookFindAction.saveQuestionAnswer(
          question,
          questionChoice,
          employeeFirmId,
          firmId
        )
      )
        .then(e => {
          if (e) {
            dispatch(
              authAction.returnNewScoreOfEmployee(
                employeeFirm.firm.id,
                employeeFirm.id
              )
            );
            setRedirect(true);
          } else {
            setRedirect(false);
          }
        })
        .catch(err => {
          // Show Error Message
          if (err.error.response.data) {
            if (err.error.response.data.errors) {
              err.error.response.data.errors.map(item => {
                showErrMessage(item.message);
              });
            }
          }
        });
    }
  }

  if (redirect) {
    return (
      <Redirect
        from="/userportal/lookFindQuestion"
        to={{ pathname: "/userportal/lookFindAnswer", lookFind: "lookFind" }}
      />
    );
  }

  function noQuestion() {
    setRedirectNoQuestion(true);
  }

  if (redirectNoQuestion) {
    return (
      <Redirect
        from="/userportal/lookFindQuestion"
        to={{ pathname: "/userportal/lookFindAnswer", lookFind: "lookFind" }}
      />
    );
  }

  if (lookFindList) {
    lookFindGame = lookFindList.map(item => {
      return (
        <div id="soru" key={item.id}>
          <div className="games games-detail-page w-100">
            <div className="container-fluid">
              <div className="wrapper-games-content row justify-content-center">
                <div className="col-12 col-md-4 col-lg-3 look-find d-flex flex-column-reverse flex-sm-column">
                  <div className="ml-auto mr-auto">
                    <img
                      className="d-block mb-5 rounded-16"
                      src={imageShowUrl + item.icon}
                    />
                  </div>

                  <div className="games-content mb-3 mb-sm-0">
                    <div className="games-content-item">
                      <div className="games-content-body games-content-badge-body">
                        <p>KALAN SÜRE</p>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <div className="games-content-body-countdown">
                            {countDownTimer}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-8 col-lg-9 d-flex flex-column">
                  <div id="oyun-bak-bul-soru" className="games-question">
                    <h1 className="games-title mt-0">Bul Kazan</h1>

                    <h2>{item.description}</h2>

                    <form
                      className=""
                      onSubmit={e => {
                        e.preventDefault();
                      }}
                    >
                      <input
                        type="hidden"
                        name="cevap"
                        value={selectedOption}
                      />

                      <div className="row pb-5">
                        {showChoices(item.questionChoices)}
                      </div>

                      <div className="mt-5 d-flex flex-row-reverse">
                        <div className="d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn-games btn-games-success"
                            onClick={e =>
                              saveQuestion(
                                item.questionId,
                                selectedChoice,
                                employeeFirm.id,
                                employeeFirm.firm.id,
                                e
                              )
                            }
                          >
                            <span>CEVAPLA</span>
                            <FontAwesomeIcon
                              icon={faArrowRight}
                              style={{
                                fontSize: "16px",
                                color: "#fff"
                              }}
                            />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <div className="root-page-content">
      <GlobalLoader isLoading={isLoading} />
      <div>{lookFindGame}</div>
    </div>
  );
};
export default LookFind;
