export const GET_SEARCHFINDLIST_BY_FIRMID_PENDING =
  "GET_SEARCHFINDLIST_BY_FIRMID_PENDING";
export const GET_SEARCHFINDLIST_BY_FIRMID_FULFILLED =
  "GET_SEARCHFINDLIST_BY_FIRMID_FULFILLED";
export const GET_SEARCHFINDLIST_BY_FIRMID_REJECTED =
  "GET_SEARCHFINDLIST_BY_FIRMID_REJECTED";

export const SAVE_SEARCHFIND_ANSWER_PENDING = "SAVE_SEARCHFIND_ANSWER_PENDING";
export const SAVE_SEARCHFIND_ANSWER_FULFILLED =
  "SAVE_SEARCHFIND_ANSWER_FULFILLED";
export const SAVE_SEARCHFIND_ANSWER_REJECTED =
  "SAVE_SEARCHFIND_ANSWER_REJECTED";

export const GET_AVAILABLESEARCHFIND_BY_FIRMID_PENDING =
  "GET_AVAILABLESEARCHFIND_BY_FIRMID_PENDING";
export const GET_AVAILABLESEARCHFIND_BY_FIRMID_FULFILLED =
  "GET_AVAILABLESEARCHFIND_BY_FIRMID_FULFILLED";
export const GET_AVAILABLESEARCHFIND_BY_FIRMID_REJECTED =
  "GET_AVAILABLESEARCHFIND_BY_FIRMID_REJECTED";

export const GET_RESULT_SEARCHFIND_BY_FIRMID_PENDING =
  "GET_RESULT_SEARCHFIND_BY_FIRMID_PENDING";
export const GET_RESULT_SEARCHFIND_BY_FIRMID_FULFILLED =
  "GET_RESULT_SEARCHFIND_BY_FIRMID_FULFILLED";
export const GET_RESULT_SEARCHFIND_BY_FIRMID_REJECTED =
  "GET_RESULT_SEARCHFIND_BY_FIRMID_REJECTED";

export const GET_BRAND_BADGES_PENDING = "GET_BRAND_BADGES_PENDING";
export const GET_BRAND_BADGES_FULFILLED = "GET_BRAND_BADGES_FULFILLED";
export const GET_BRAND_BADGES_REJECTED = "GET_BRAND_BADGES_REJECTED";

export const GET_ALL_GAME_BADGES_PENDING = "GET_ALL_GAME_BADGES_PENDING";
export const GET_ALL_GAME_BADGES_FULFILLED = "GET_ALL_GAME_BADGES_FULFILLED";
export const GET_ALL_GAME_BADGES_REJECTED = "GET_ALL_GAME_BADGES_REJECTED";

export const GET_ALL_BY_EMP_PENDING = "GET_ALL_BY_EMP_PENDING";
export const GET_ALL_BY_EMP_FULFILLED = "GET_ALL_BY_EMP_FULFILLED";
export const GET_ALL_BY_EMP_REJECTED = "GET_ALL_BY_EMP_REJECTED";

export const SELECTED_OPTION = "SELECTED_OPTION";
