import React from "react";
import "./IlluminationText.scss";

const CookieText = () => {
  return (
    <div className="w-full illumination-text">
      <h3 className="font-bold">ÇEREZ POLİTİKASI</h3>
      <div>
        <p>
          JTI Tütün Ürünleri Pazarlama A.Ş. (“<strong>JTI</strong>”) olarak,
          internet sitesi ziyaretçilerimize ait kişisel verilerin, 6698 sayılı
          Kişisel Verilerin Korunması Kanunu ve ilgili sair mevzuata (“KVK
          Mevzuatı”) uygun olarak işlenmesi için azami hassasiyeti göstermekte
          ve internet sitemiz aracılığıyla çerez kullanmakta olduğumuzu
          belirtmek isteriz.
        </p>
        <p>
          İşbu Çerez Politikasının amacı, tarafımızca işletilmekte olan
          https://www.jtibenimbayim.com adresinin (“<strong>Site</strong>”),
          Site kullanıcıları / üyeleri / ziyaretçileri (“ilgili kişi”)
          tarafından ziyaret edilmesi ile elde edilen çerezlerin, türleri ve
          kullanım amaçları hakkında ilgili kişileri bilgilendirmektir.
        </p>
        <p>
          <strong>Çerez Nedir?</strong>
        </p>
        <p>
          Çerezler, bir internet sitesini ziyaret ettiğiniz zaman
          bilgisayarınızda veya mobil cihazınızda (“cihaz”) saklanan ve söz
          konusu cihazın tespit edilmesine olanak sağlayan çoğunlukla harf veya
          sayılardan oluşan küçük metin dosyalarıdır.{" "}
        </p>
        <p>
          <strong>
            Çerezler Vasıtasıyla İşlenen Kişisel Verileriniz Nelerdir?
          </strong>
        </p>
        <p>
          Çerezler vasıtasıyla Site üzerindeki kullanım ve ziyaret
          alışkanlıklarınız, oturum bilgileriniz, geçmiş işlemleriniz ve gezinme
          bilgileriniz işlenmektedir.{" "}
        </p>
        <p>
          <strong>
            Çerezler Hangi Yöntemle Toplanmakta ve Hangi Amaçlarla
            Kullanılmaktadır?{" "}
          </strong>
        </p>
        <p>
          Şirket olarak günlük dosyalar, boş gif dosyaları ve/veya üçüncü taraf
          kaynakları yoluyla topladığımız çerezlerden oluşan kişisel
          verilerinizi, KVK Mevzuatına uygun olarak ve aşağıda belirtilen
          amaçlar ile sınırlı olmak kaydıyla işlemekteyiz:
        </p>
        <ul className="cookie-policy">
          <li>
            Site ziyaretçilerimize daha iyi hizmet sağlayabilmek amacıyla Site
            üzerinde gezinme bilgileriniz işlenmekte, bu amaç ile sınırlı olarak
            üçüncü kişilerle paylaşılmakta ve güvenli olarak saklanmaktadır.
          </li>
          <li>
            Tercihlerinizle ilgili bir özet oluşturmak amacıyla geçmiş
            işlemleriniz depolanmaktadır.
          </li>
          <li>
            Pazarlama izniniz dikkate alınmak suretiyle size özel tanıtım
            yapmak, promosyonlar ve pazarlama teklifleri sunmak, Site’nin
            içeriğini size göre iyileştirmek ve/veya tercihlerinizi belirlemek
            amaçlarıyla, Site üzerinde gezinme bilgileriniz ve/veya Site
            üzerindeki kullanım geçmişiniz izlenebilmektedir.
          </li>
          <li>
            Site’nin nasıl kullanıldığını analiz etmek ve Siteyi kullanım
            deneyiminizi iyileştirmek amaçlarıyla işlevsel ve analitik çerezler
            kullanılmaktadır.
          </li>
          <li>
            İlgi alanlarınıza ve size daha uygun içerik ve reklamları sunmak
            için reklam/tanıtım amacıyla reklam çerezleri kullanılmaktadır. Bu
            şekilde, Site’yi kullandığınızda size daha uygun içerikler, kişiye
            özel kampanya ve ürünler sunulmakta ve daha önceden istemediğinizi
            belirttiğiniz içerik veya fırsatlar bir daha sunulmamaktadır. Reklam
            teknolojisi, size özel reklamlar sunabilmek için web sitesine ve web
            sitesinin reklam verdiği web sitelerine yaptığınız önceki
            ziyaretlerle ilgili bilgileri kullanır. Bu reklamları sunarken, web
            sitesinin sizi tanıyabilmesi amacıyla tarayıcınıza benzersiz bir
            üçüncü taraf çerezi yerleştirilebilir.{" "}
          </li>
        </ul>
        <p>
          Şirket, çevrimiçi ve çevrimdışı olarak farklı yöntemlerle toplanan
          bilgileri veya farklı zamanlarda Site üzerinde sizden toplanan
          bilgileri eşleştirebilir ve bu bilgileri üçüncü taraflar gibi başka
          kaynaklardan alınan bilgilerle birlikte kullanabilir.
        </p>
        <p>
          <strong>Hangi Çerez Türleri Kullanılmaktadır? </strong>
        </p>
        <p>
          Sitemizde üçüncü parti çerezleri (ziyaret ettiğiniz site haricindeki
          sunucular tarafından yerleştirilen) kullanılmaktadır. Aşağıda
          Sitemizde kullandığımız farklı türdeki çerezleri bulabilirsiniz.
        </p>
        <p>
          <strong>Kullanım Amaçlarına Göre Çerez Türleri:</strong>
        </p>
        <ul>
          <li>
            <strong>İşlevsel ve Analitik Çerezler:</strong>Siteye nereden
            bağlandığınız, Site üzerinde hangi içeriği görüntülediğiniz ve
            ziyaretinizin süresi gibi Siteyi nasıl kullandığınızın izlenmesi
            dâhil olmak üzere, Siteyi nasıl kullandığınızı belirlemek için
            kullanılan çerezlerdir.
          </li>
        </ul>
        <p>
          <strong>Saklanma Sürelerine Göre Çerezler:</strong>
        </p>

        <ul>
          <li>
            <strong>Kalıcı Çerezler:</strong>Site’nin işlevselliğini artırmak,
            ziyaretçilerimize daha hızlı ve iyi bir hizmet sunmak amacıyla
            kullanılan ve tarayıcılar vasıtasıyla tarafınızca silininceye dek
            veya son kullanım tarihine kadar geçerliliğini koruyan çerezlerdir.
          </li>
          <li>
            <strong>Oturum Çerezleri:</strong>Siteyi ziyaretiniz süresince
            kullanılan, tarayıcı kapatıldıktan sonra silinen geçici çerezlerdir.
            Bu tür çerezler, kişisel veri içermezler ve kişisel verilerin
            internette aktarılma ihtiyacını azaltmaya yardım eder. Bu çerezleri
            silebilir veya kullanılmalarını engelleyebilirsiniz; ancak, bu
            çerezlerin devre dışı bırakılması, Site performansının düşmesine ve
            kullanıcı deneyiminizin kısıtlanmasına neden olabilir.
          </li>
        </ul>

        <p>
          <strong>Google Analytics</strong>
        </p>

        <ul>
          <li>
            <strong>İşlevi:</strong>İzleme / Analiz
          </li>
          <li>
            <strong>Kullanılma Amacı:</strong>Performans ve kullanıcı deneyimini
            artırmak için, siteye gelen ziyaretçi hareketlerini farklı açılardan
            ölçümlemek ve analiz etmek.
          </li>
          <li>
            <strong>Çalışma Yöntemi:</strong>jtibenimbayim.com sitesi içerisinde
            hangi ekranlarda gezindiğiniz ve neler yaptığınız hakkında isimsiz
            (anonim) toplu veriler sağlar.
          </li>
          <li>
            <strong>Çerez Türü:</strong>Üçüncü Parti, Kalıcı ve Oturum çerezleri
          </li>
        </ul>

        <p>
          <strong>Google Tag Manager</strong>
        </p>
        <ul>
          <li>
            <strong>İşlevi:</strong>Google Analytics parametre yönetimi
          </li>
          <li>
            <strong>Kullanılma Amacı:</strong>Google Analytics çerezlerinin
            işlevinin doğru ayarlanması
          </li>
          <li>
            <strong>Çalışma Yöntemi:</strong>Google Analytics çerezlerinin
            ihtiyaç duyduğu verileri doğru şekilde toplamasını sağlar.
          </li>
          <li>
            <strong>Çerez Türü:</strong>Üçüncü Parti, Kalıcı ve Oturum çerezleri
          </li>
        </ul>

        <p>
          <strong>Yandex Metrica</strong>
        </p>
        <ul>
          <li>
            <strong>İşlevi:</strong>İzleme / Analiz
          </li>
          <li>
            <strong>Kullanılma Amacı:</strong>Performans ve kullanıcı deneyimini
            artırmak için, siteye gelen ziyaretçi hareketlerini farklı açılardan
            ölçümlemek ve analiz etmek.
          </li>
          <li>
            <strong>Çalışma Yöntemi:</strong>jtibenimbayim.com sitesi içerisinde
            hangi ekranlarda gezindiğiniz ve neler yaptığınız hakkında isimsiz
            (anonim) toplu veriler sağlar.
          </li>
          <li>
            <strong>Çerez Türü:</strong>Üçüncü Parti, Kalıcı ve Oturum çerezleri
          </li>
        </ul>

        <p>
          <strong>Kişisel Verinizin Toplanmasındaki Hukuki Sebep Nedir?</strong>
        </p>
        <p>
          Teknik açıdan gerekli olma halinde kişisel veriler “İlgili kişinin
          temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun
          ve diğer tarafların meşru menfaatleri için veri işlenmesinin zorunlu
          olması” sebebine; gezinti alışkanlıklarının analizinde ve
          reklam/pazarlama süreçlerinde kullanılan kişisel veriler ise ilgili
          kişinin açık rıza sunması hukuki sebebine dayalı olarak işlenir.
          Kullanıcı analizlerde, reklam ve pazarlama faaliyetlerinde kullanılan
          kişisel verilerinin işlenmesini engelleyebilir.
        </p>
        <p>
          <strong>Çerez Kullanımını Nasıl Kontrol Edebilirsiniz?</strong>
        </p>
        <p>
          Çerezler, web sitemize iletildikleri kullanıcının bilgisayarında
          saklanır. Kullanıcı olarak, bu nedenle çerezlerin kullanımı üzerinde
          tam kontrole sahipsiniz. Tanımlama bilgilerinin kullanımına onay
          vermiş olsanız bile, onayınızı istediğiniz zaman ileride geçerli
          olacak şekilde iptal edebilirsiniz.
        </p>
        <p>
          Sitemizi kullanabilmek için zorunlu (temel) çerezler dışındaki
          çerezlerin kullanımı zorunlu değildir; tarayıcınızın ayarlarını
          değiştirerek çerezlere ilişkin tercihlerinizi kişiselleştirme imkânına
          sahipsiniz.
        </p>
        <p>
          Tarayıcınızı işlenmesini istemediğiniz çerezleri engelleyecek şekilde
          ayarlamanız halinde, Sitemizi kullanmaya devam edebilirsiniz; ancak bu
          ihtimalde Site’nin tüm işlevlerine erişemeyebilirsiniz veya erişiminiz
          sınırlı olabilir, kullanıcı deneyiminizin kalitesi düşebilir. Bazı
          tarayıcıların çerez ayar sayfaları şunlardır:
        </p>
        <table border="1">
          <tbody>
            <tr>
              <td>Adobe Analytics</td>
              <td>
                <a href="http://www.adobe.com/uk/privacy/opt-out.html">
                  http://www.adobe.com /uk/privacy/opt-out.html
                </a>
              </td>
            </tr>
            <tr>
              <td>AOL</td>
              <td>
                <a href="https://help.aol.com/articles/restore-security-settings-and-enable-cookie-settings-on-browser">
                  https://help.aol.com/ articles/restore-security-settings-and-
                  enable-cookie-settings-on-browser
                </a>
              </td>
            </tr>
            <tr>
              <td>Google Adwords</td>
              <td>
                <a href="https://support.google.com/ads/answer/2662922?hl=en">
                  https://support.google.com/ ads/answer/2662922?hl=en
                </a>
              </td>
            </tr>
            <tr>
              <td>Google Analytics</td>
              <td>
                <a href="https://tools.google.com/dlpage/gaoptout">
                  https://tools.google.com/ dlpage/gaoptout
                </a>
              </td>
            </tr>
            <tr>
              <td>Google Chrome</td>
              <td>
                <a href="http://www.google.com/support/chrome/bin/answer.py?hl=en&answer=95647">
                  http://www.google.com/
                  support/chrome/bin/answer.py?hl=en&answer=95647
                </a>
              </td>
            </tr>
            <tr>
              <td>Internet Explorer</td>
              <td>
                <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">
                  https://support.microsoft.com/
                  en-us/help/17442/windows-internet-
                  explorer-delete-manage-cookies
                </a>
              </td>
            </tr>
            <tr>
              <td>Mozilla Firefox</td>
              <td>
                <a href="http://support.mozilla.com/en-US/kb/Cookies">
                  http://support.mozilla.com/ en-US/kb/Cookies
                </a>
              </td>
            </tr>
            <tr>
              <td>Opera</td>
              <td>
                <a href="http://www.opera.com/browser/tutorials/security/privacy/">
                  http://www.opera.com/ browser/tutorials/security/privacy/
                </a>
              </td>
            </tr>
            <tr>
              <td>Safari</td>
              <td>
                <a href="https://support.apple.com/kb/ph19214?locale=tr TR">
                  https://support.apple.com/ kb/ph19214?locale=tr TR
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CookieText;
