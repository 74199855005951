export const GET_AVAILABLELOOKFIND_BY_FIRMID_PENDING =
  "GET_AVAILABLELOOKFIND_BY_FIRMID_PENDING";
export const GET_AVAILABLELOOKFIND_BY_FIRMID_FULFILLED =
  "GET_AVAILABLELOOKFIND_BY_FIRMID_FULFILLED";
export const GET_AVAILABLELOOKFIND_BY_FIRMID_REJECTED =
  "GET_AVAILABLELOOKFIND_BY_FIRMID_REJECTED";

export const GET_LOOKFINDLIST_BY_FIRMID_PENDING =
  "GET_LOOKFINDLIST_BY_FIRMID_PENDING";
export const GET_LOOKFINDLIST_BY_FIRMID_FULFILLED =
  "GET_LOOKFINDLIST_BY_FIRMID_FULFILLED";
export const GET_LOOKFINDLIST_BY_FIRMID_REJECTED =
  "GET_LOOKFINDLIST_BY_FIRMID_REJECTED";

export const SAVE_LOOKFIND_ANSWER_PENDING = "SAVE_LOOKFIND_ANSWER_PENDING";
export const SAVE_LOOKFIND_ANSWER_FULFILLED = "SAVE_LOOKFIND_ANSWER_FULFILLED";
export const SAVE_LOOKFIND_ANSWER_REJECTED = "SAVE_LOOKFIND_ANSWER_REJECTED";

export const GET_RESULT_LOOKFIND_BY_FIRMID_PENDING =
  "GET_RESULT_LOOKFIND_BY_FIRMID_PENDING";
export const GET_RESULT_LOOKFIND_BY_FIRMID_FULFILLED =
  "GET_RESULT_LOOKFIND_BY_FIRMID_FULFILLED";
export const GET_RESULT_LOOKFIND_BY_FIRMID_REJECTED =
  "GET_RESULT_LOOKFIND_BY_FIRMID_REJECTED";

export const SELECTED_OPTION = "SELECTED_OPTION";
