import React from "react";
import Router from "./pages/Router";
import { useHandleRouteWhenAuth } from "hooks";

import CacheBuster from "react-cache-buster";
import CacheLoader from "./components/CacheLoader";
import packageFile from "../package.json";

import _ from "lodash";
global._ = _;

function App() {
  useHandleRouteWhenAuth();

  const isProduction = process.env.NODE_ENV === "production";
  const { version } = packageFile;

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<CacheLoader />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <Router />
    </CacheBuster>
  );
}

export default App;
