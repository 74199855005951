import * as aboutTypes from "./about.type";

export const getActiveTab = actives => async dispatch => {
  dispatch({ type: aboutTypes.GET_ABOUT_ACTIVE_PENDING });

  try {
    dispatch({
      type: aboutTypes.GET_ABOUT_ACTIVE_FULFILLED,
      payload: actives
    });
  } catch (error) {
    dispatch({
      type: aboutTypes.GET_ABOUT_ACTIVE_REJECTED,
      payload: error
    });
  }
};
