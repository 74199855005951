import * as employeeFirmTypes from "./employeeFirm.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getEmployeeFirmById = id => async dispatch => {
  dispatch({ type: employeeFirmTypes.GET_EMPLOYEE_FIRM_BY_ID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/employeeFirm?id=${id}`,
      config
    );
    dispatch({
      type: employeeFirmTypes.GET_EMPLOYEE_FIRM_BY_ID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: employeeFirmTypes.GET_EMPLOYEE_FIRM_BY_ID_REJECTED,
      payload: error
    });
  }
};

export const getLoginDetails = id => async dispatch => {
  dispatch({ type: employeeFirmTypes.GET_EMPLOYEE_FIRM_LOGIN_DETAILS_PENDING });

  const data = {
    employeeFirmId: id
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/get/employee/firm/login/details`,
      data,
      config
    );
    dispatch({
      type: employeeFirmTypes.GET_EMPLOYEE_FIRM_LOGIN_DETAILS_FULFILLED,
      payload: response
    });

    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: employeeFirmTypes.GET_EMPLOYEE_FIRM_LOGIN_DETAILS_REJECTED,
      payload: error
    });

    return Promise.reject(error);
  }
};

export const setSecurityStatus = (employeeFirmId, isOpen) => async dispatch => {
  dispatch({ type: employeeFirmTypes.SET_SECURITY_STATUS_PENDING });

  const data = {
    employeeFirmId: employeeFirmId,
    isOpen: isOpen
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/employeeFirm/twoFactorAuth`,
      data,
      config
    );
    dispatch({
      type: employeeFirmTypes.SET_SECURITY_STATUS_FULFILLED,
      payload: response
    });

    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: employeeFirmTypes.SET_SECURITY_STATUS_REJECTED,
      payload: error
    });

    return Promise.reject(error);
  }
};

export const setSafeDevice = (employeeFirmId, macAddress) => async dispatch => {
  dispatch({ type: employeeFirmTypes.SET_SAFE_DEVICE_PENDING });

  const data = {
    employeeFirmId: employeeFirmId,
    macAddress: macAddress
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/employeeFirm/add/safeDevice`,
      data,
      config
    );
    dispatch({
      type: employeeFirmTypes.SET_SAFE_DEVICE_FULFILLED,
      payload: response
    });

    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: employeeFirmTypes.SET_SAFE_DEVICE_REJECTED,
      payload: error
    });

    return Promise.reject(error);
  }
};
