import * as arenaTypes from "./ourArena.type";

import { API } from "../../../services/http.service";
import SubjectOfWeek from "pages/UserPortal/OurArena/SubjectOfWeek";
import PastSubjects from "pages/UserPortal/OurArena/PastSubjects";
const REQUEST_URL = "";

const config = {
  headers: {
    "Content-Type": "application/json-application"
  }
};

function returnFromKey(key) {
  switch (key) {
    case `BU HAFTANIN KONUSU`:
      return SubjectOfWeek;

    case `GECMIS KONULAR`:
      return PastSubjects;

    default:
      return SubjectOfWeek;
  }
}

export const setArenaContent = key => async dispatch => {
  dispatch({
    type: arenaTypes.SET_ARENA_CONTENT_PENDING
  });

  try {
    const returnComponent = returnFromKey(key);
    dispatch({
      type: arenaTypes.SET_ARENA_CONTENT_FULLFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: arenaTypes.SET_ARENA_CONTENT_REJECTED,
      payload: error
    });
  }
};

export const getSubject = (employeeId, page) => async dispatch => {
  dispatch({
    type: arenaTypes.GET_SUBJECTS_PENDING
  });

  if (!page) {
    page = 0;
  }

  try {
    const response = await API.get(
      `${REQUEST_URL}/forum/findAll/${employeeId}?page=${page}&size=10`,
      config
    );
    dispatch({
      type: arenaTypes.GET_SUBJECTS_FULLFILLED,
      payload: response.data
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: arenaTypes.GET_SUBJECTS_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};

export const getPastSubject = employeeFirmId => async dispatch => {
  dispatch({
    type: arenaTypes.GET_PAST_SUBJECTS_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/forum/past/findAll/${employeeFirmId}`,
      config
    );
    dispatch({
      type: arenaTypes.GET_PAST_SUBJECTS_FULLFILLED,
      payload: response.data
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: arenaTypes.GET_PAST_SUBJECTS_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};

export const getSelectedPastSubject = (
  subjectId,
  employeeFirmId,
  page
) => async dispatch => {
  dispatch({
    type: arenaTypes.GET_SELECTED_PAST_SUBJECTS_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/forum/past/findAll/selected/${employeeFirmId}/${subjectId}?page=${page}&size=10`,
      config
    );
    dispatch({
      type: arenaTypes.GET_SELECTED_PAST_SUBJECTS_FULLFILLED,
      payload: response.data
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: arenaTypes.GET_SELECTED_PAST_SUBJECTS_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};

export const saveInitialMessage = (
  message,
  subjectId,
  senderId
) => async dispatch => {
  dispatch({
    type: arenaTypes.SAVE_INITIAL_MESSAGE_PENDING
  });

  const data = {
    message,
    subjectId,
    senderId
  };
  try {
    const response = await API.post(`/forum/save`, data);
    const interaction = {
      message: "Yorumunuz onaylandıktan sonra görüntülenecektir.",
      type: "notification"
    };

    dispatch({
      type: arenaTypes.SAVE_INITIAL_MESSAGE_FULFILLED,
      payload: response,
      interaction
    });
  } catch (error) {
    dispatch({
      type: arenaTypes.SAVE_INITIAL_MESSAGE_REJECTED,
      payload: error
    });
  }
};

export const saveLike = (
  forumLikeId,
  forumMessageId,
  senderId,
  subjectId
) => async dispatch => {
  dispatch({
    type: arenaTypes.SAVE_LIKE_PENDING
  });

  const data = {
    forumLikeId,
    forumMessageId,
    senderId,
    subjectId
  };
  try {
    const response = await API.post(`/forumLike/save`, data);
    const interaction = {
      message: "Mesajınız iletildi",
      type: "notification"
    };

    dispatch({
      type: arenaTypes.SAVE_LIKE_FULFILLED,
      payload: response,
      interaction
    });

    //dispatch(getSubject());
  } catch (error) {
    dispatch({
      type: arenaTypes.SAVE_LIKE_REJECTED,
      payload: error
    });
  }
};
