import * as brandTypes from "./brand.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";
const videoList = [];

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getBrandsByFirmId = firmId => async dispatch => {
  dispatch({ type: brandTypes.GET_BRAND_BY_ID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/brandList/${firmId}`,
      config
    );
    const actives = [];
    response.data.map((item, index) => {
      index === 0 ? actives.push(true) : actives.push(false);
    });
    dispatch({
      type: brandTypes.GET_BRAND_BY_ID_FULFILLED,
      payload: response,
      actives: actives
    });
  } catch (error) {
    dispatch({
      type: brandTypes.GET_BRAND_BY_ID_REJECTED,
      payload: error
    });
  }
};

function addListVideos(listVideos) {
  videoList.push(listVideos);
  return videoList;
}

export const setListVideos = listVideos => async dispatch => {
  dispatch({ type: brandTypes.SET_LIST_VIDEOS_PENDING });

  try {
    const returnComponent = addListVideos(listVideos);
    dispatch({
      type: brandTypes.SET_LIST_VIDEOS_FULFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: brandTypes.SET_LIST_VIDEOS_REJECTED,
      payload: error
    });
  }
};

export const clearListVideos = listVideos => async dispatch => {
  dispatch({ type: brandTypes.CLEAR_LIST_VIDEOS_PENDING });

  try {
    listVideos = [];
    dispatch({
      type: brandTypes.CLEAR_LIST_VIDEOS_FULFILLED,
      payload: listVideos
    });
  } catch (error) {
    dispatch({
      type: brandTypes.CLEAR_LIST_VIDEOS_REJECTED,
      payload: error
    });
  }
};

export const changeSelected = actives => async dispatch => {
  dispatch({ type: brandTypes.SELECTED_ACTIVES, payload: actives });
};

function returnIndex(index) {
  return index;
}

export const setFooterFlag = index => async dispatch => {
  dispatch({ type: brandTypes.SET_FOOTER_PENDING });

  try {
    const returnComponent = returnIndex(index);

    dispatch({
      type: brandTypes.SET_FOOTER_FULFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: brandTypes.SET_FOOTER_REJECTED,
      payload: error
    });
  }
};
