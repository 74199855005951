import * as wwpTypes from "./wwp.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getWwpList = () => async dispatch => {
  dispatch({ type: wwpTypes.GET_WWPLIST_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/wws`, config);
    dispatch({
      type: wwpTypes.GET_WWPLIST_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: wwpTypes.GET_WWPLIST_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getBonusWwp = (employeeId, whoWantsScoreId) => async dispatch => {
  dispatch({ type: wwpTypes.GET_BONUS_WWP_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/wws/getBonus/${employeeId}/${whoWantsScoreId}`,
      config
    );
    dispatch({
      type: wwpTypes.GET_BONUS_WWP_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: wwpTypes.GET_BONUS_WWP_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const startWwp = employeeId => async dispatch => {
  dispatch({ type: wwpTypes.GET_START_WWP_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/start/wws/${employeeId}`,
      config
    );
    dispatch({
      type: wwpTypes.GET_START_WWP_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: wwpTypes.GET_START_WWP_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const controlWwp = employeeId => async dispatch => {
  dispatch({ type: wwpTypes.GET_CONTROL_WWP_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/control/wws/${employeeId}`,
      config
    );
    dispatch({
      type: wwpTypes.GET_CONTROL_WWP_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: wwpTypes.GET_CONTROL_WWP_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const saveFindQuestionAnswer = (
  whoWantsScoreQuestionId,
  employeeFirmId,
  choiceId
) => async dispatch => {
  dispatch({ type: wwpTypes.SAVE_WWP_ANSWER_PENDING });

  const data = { whoWantsScoreQuestionId, choiceId, employeeFirmId };
  try {
    const response = await API.post(`/wws/saveReward`, data);
    const interaction = {
      message: " ",
      type: "notification"
    };

    dispatch({
      type: wwpTypes.SAVE_WWP_ANSWER_FULFILLED,
      payload: response,
      interaction
    });

    //    dispatch(getResultSearchFind(firmId, employeeFirmId)); ???
    dispatch({
      type: wwpTypes.SELECTED_OPTION,
      payload: null
    });
  } catch (error) {
    dispatch({
      type: wwpTypes.SAVE_WWP_ANSWER_REJECTED,
      payload: error
    });
  }
};

export const getResultWWP = employeeId => async dispatch => {
  dispatch({ type: wwpTypes.GET_RESULT_WWP_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/wwpAnswer/${employeeId}`,
      config
    );
    dispatch({
      type: wwpTypes.GET_RESULT_WWP_BY_FIRMID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: wwpTypes.GET_RESULT_WWP_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};

export const changeOption = selectedOption => async dispatch => {
  dispatch({ type: wwpTypes.SELECTED_OPTION, payload: selectedOption });
};

export const changeAnswer = answer => async dispatch => {
  dispatch({ type: wwpTypes.WWP_ANSWER, payload: answer });
};
