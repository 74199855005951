import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "services/http.service";
import { imageShowUrl } from "../../config/api.config";

// Modal
import Modal from "react-responsive-modal";

// Confetti
import Confetti from "react-confetti";

// Styles
import "react-responsive-modal/styles.css";
import "./HomeFocus.scss";

const HomeFocus = () => {
  // Store
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const [modalStates, setModalStates] = useState({});
  const [popupList, setPopupList] = useState([]);
  const [dg, setDg] = useState(false);

  // Function to change the modal state of a particular element
  const toggleModal = item => {
    if (item.name === "Doğum Günü Pop Up") {
      localStorage.setItem("dg", "true");
    }

    setModalStates(prevModalStates => ({
      ...prevModalStates,
      [item.name]: !prevModalStates[item.name]
    }));
  };

  const getPopupList = async () => {
    const { data } = await API.get("focus/HomepagePopUp");

    if (data) {
      setPopupList(data.reverse());
    }
  };

  useEffect(() => {
    if (localStorage.getItem("dg") === "true") {
      setDg(true);
    } else {
      setDg(false);
    }
  }, []);

  useEffect(() => {
    // Mark all modals as open when page loads
    if (popupList && popupList.length > 0) {
      const modalStateUpdates = popupList.reduce((acc, item) => {
        acc[item.name] = true;
        return acc;
      }, {});
      setModalStates(modalStateUpdates);
    }
  }, [popupList]);

  // If PopupList Null This Effect will be work!
  useEffect(() => {
    // Get Popup List
    getPopupList();

    return () => {};
  }, [popupList.length === 0]);

  return (
    <>
      {popupList &&
        popupList.length > 0 &&
        popupList.map(item => (
          <div key={item.name}>
            {employeeFirm?.isBirthDate === true &&
              item.isBirthday === true &&
              dg === false && (
                <Modal
                  open={modalStates[item.name]} // The modal is open, we determine it by the name of the element
                  role="dialog"
                  closeOnEsc={true}
                  showCloseIcon={true}
                  closeOnOverlayClick={true}
                  closeIconId="focus-close-icon"
                  onClose={() => {
                    toggleModal(item); // Update state by calling toggleModal function when modal is closed
                  }}
                >
                  {/* Modal Content */}
                  <>
                    <Confetti
                      width={
                        document.querySelectorAll(
                          ".react-responsive-modal-modal"
                        )[0]?.clientWidth
                          ? document.querySelectorAll(
                              ".react-responsive-modal-modal"
                            )[0]?.clientWidth - 10
                          : 700
                      }
                      height={
                        document.querySelectorAll(
                          ".react-responsive-modal-modal"
                        )[0]?.clientHeight
                          ? document.querySelectorAll(
                              ".react-responsive-modal-modal"
                            )[0]?.clientHeight - 10
                          : 868
                      }
                      numberOfPieces={300}
                    />

                    <div className="d-none d-sm-block web focus 1 relative">
                      <div className="popup-firstname-lastname">
                        Sayın, {employeeFirm?.employee?.fullName}
                      </div>
                      <img src={imageShowUrl + item.webPic} alt={item.name} />
                    </div>

                    <div className="d-block d-sm-none mobile relative">
                      <div className="popup-firstname-lastname">
                        Sayın, {employeeFirm?.employee?.fullName}
                      </div>
                      <img src={imageShowUrl + item.mobilPic} alt={item.name} />
                    </div>
                  </>
                </Modal>
              )}

            {item.name !== "Doğum Günü Pop Up" && (
              <Modal
                open={modalStates[item.name]} // The modal is open, we determine it by the name of the element
                role="dialog"
                closeOnEsc={true}
                showCloseIcon={true}
                closeOnOverlayClick={true}
                closeIconId="focus-close-icon"
                onClose={() => {
                  toggleModal(item); // Update state by calling toggleModal function when modal is closed
                }}
              >
                {/* Modal Content */}
                <>
                  <div className="d-none d-sm-block web focus 2">
                    <img src={imageShowUrl + item.webPic} alt={item.name} />
                  </div>

                  <div className="d-block d-sm-none mobile">
                    <img src={imageShowUrl + item.mobilPic} alt={item.name} />
                  </div>
                </>
              </Modal>
            )}
          </div>
        ))}
    </>
  );
};
export default HomeFocus;
