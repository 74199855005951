import { useState } from "react";

const Accordion = ({ accordionItems }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = index => {
    setActiveIndex(prevState => (prevState === index ? prevState : index));
  };

  return (
    <div className="w-full global-accordion overflow-hidden rounded-lg">
      {accordionItems.map((item, index) => (
        <div key={index}>
          <button
            className={
              activeIndex === index
                ? "active bg-primary-500 text-white flex items-center justify-between py-4 px-8 w-full focus:outline-none"
                : "flex items-center justify-between bg-white py-4 px-8 w-full focus:outline-none text-left"
            }
            onClick={() => toggleAccordion(index)}
          >
            <span>{item.title}</span>
            <svg
              className={`w-5 h-5 transition-transform ${
                activeIndex === index ? "transform rotate-180" : ""
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>
          <div
            className={`overflow-hidden transition-max-height duration-300 border-b border-tab-400 ${
              activeIndex === index ? "max-h-none" : "max-h-0"
            }`}
          >
            <div className="p-4 bg-white">
              <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
