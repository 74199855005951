import * as leaderboardTypes from "./leaderboard.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getLeaderboard = ercCode => async dispatch => {
  dispatch({
    type: leaderboardTypes.GET_LEADERBOARD_PENDING
  });

  const data = {
    leaderboardType: "ALL",
    ercCode: ercCode
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/get/leaderboard`,
      data,
      config
    );
    dispatch({
      type: leaderboardTypes.GET_LEADERBOARD_FULLFILLED,
      payload: response.data
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: leaderboardTypes.GET_LEADERBOARD_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};

export const getLeaderboardRegion = ercCode => async dispatch => {
  dispatch({
    type: leaderboardTypes.GET_LEADERBOARD_REGION_PENDING
  });

  const data = {
    leaderboardType: "CITY",
    ercCode: ercCode
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/get/leaderboard`,
      data,
      config
    );
    dispatch({
      type: leaderboardTypes.GET_LEADERBOARD_REGION_FULLFILLED,
      payload: response.data
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: leaderboardTypes.GET_LEADERBOARD_REGION_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};
