import * as homeTypes from "./home.type";

const initialState = {
  priceList: null,
  homePriceList: null,
  announcementList: null,
  surveyList: null,
  selectedOption: null,
  disclaimerList: null,
  activityList: null,
  ramadanWidgetList: null,
  iysPopup: null,
  saveIysPopup: null,
  homeOrderInfo: null,
  error: null,
  isLoading: false
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${homeTypes.GET_ANNOUNCEMENTLIST_BY_FIRMID_PENDING}`:
    case `${homeTypes.GET_SURVEYLIST_BY_FIRMID_PENDING}`:
    case `${homeTypes.SAVE_SURVEY_ANSWER_PENDING}`:
    case `${homeTypes.SAVE_DISCLAIMER_CHECK_PENDING}`:
    case `${homeTypes.GET_DISCLAIMER_BY_FIRMID_PENDING}`:
    case `${homeTypes.GET_SPECIAL_ACTIVITY_BY_FIRMID_PENDING}`:
    case `${homeTypes.GET_RAMADAN_WIDGET_PENDING}`:
    case `${homeTypes.SET_RAMADAN_WIDGET_PENDING}`:
    case `${homeTypes.GET_PRICE_LIST_PENDING}`:
    case `${homeTypes.GET_HOME_PRICE_LIST_PENDING}`:
    case `${homeTypes.GET_IYS_POPUP_PENDING}`:
    case `${homeTypes.GET_HOME_ORDER_INFO_PENDING}`:
    case `${homeTypes.SET_IYS_POPUP_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${homeTypes.GET_ANNOUNCEMENTLIST_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        announcementList: payload.data
      };

    case `${homeTypes.GET_SURVEYLIST_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        surveyList: payload.data
      };

    case `${homeTypes.GET_ANNOUNCEMENTLIST_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${homeTypes.GET_SURVEYLIST_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${homeTypes.SAVE_SURVEY_ANSWER_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };

    case `${homeTypes.SAVE_SURVEY_ANSWER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${homeTypes.GET_DISCLAIMER_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        disclaimerList: payload.data
      };

    case `${homeTypes.GET_DISCLAIMER_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${homeTypes.SAVE_DISCLAIMER_CHECK_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };

    case `${homeTypes.SAVE_DISCLAIMER_CHECK_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${homeTypes.GET_SPECIAL_ACTIVITY_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        activityList: payload.data
      };

    case `${homeTypes.GET_SPECIAL_ACTIVITY_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${homeTypes.GET_RAMADAN_WIDGET_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        ramadanWidgetList: payload.data
      };

    case `${homeTypes.GET_RAMADAN_WIDGET_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${homeTypes.SET_RAMADAN_WIDGET_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };

    case `${homeTypes.SET_RAMADAN_WIDGET_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${homeTypes.GET_PRICE_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        priceList: payload.data
      };

    case `${homeTypes.GET_PRICE_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${homeTypes.GET_HOME_PRICE_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        homePriceList: payload.data
      };

    case `${homeTypes.GET_HOME_PRICE_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        homePriceList: null
      };

    case `${homeTypes.GET_IYS_POPUP_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        iysPopup: payload.data
      };

    case `${homeTypes.GET_IYS_POPUP_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        iysPopup: null
      };

    case `${homeTypes.SET_IYS_POPUP_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        iysPopup: payload.data
      };

    case `${homeTypes.SET_IYS_POPUP_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        iysPopup: null
      };

    case `${homeTypes.GET_HOME_ORDER_INFO_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        homeOrderInfo: payload.data
      };

    case `${homeTypes.GET_HOME_ORDER_INFO_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        homeOrderInfo: null
      };

    case `${homeTypes.SELECTED_OPTION}`:
      return {
        ...state,
        selectedOption: payload
      };

    default:
      return state;
  }
};

export default reducer;
