import * as catalogTypes from "./catalog.type";
import DynamicCatalogPage from "pages/UserPortal/Catalog/DynamicCatalogPage";

const initialState = {
  currentCatalogContent: DynamicCatalogPage,
  error: null,
  isLoading: false,
  productList: [],
  categoryList: [],
  subCategoryList: [],
  actives: [],
  activesSubCategory: [],
  selectedCategory: "",
  selectedSubCategory: "",
  sortAsc: true,
  sortByTypeAsc: "ALL",
  selectedProduct: {},
  employeeAddresses: [],
  totalPages: null,
  sortingType: "Puana göre artan",
  catalogModalState: false,
  catalogCartItemState: [],
  similarProducts: [],
  favouriteProducts: null
};

const reducer = (
  state = initialState,
  { type, payload, actives, activesSubCategory, ...params }
) => {
  switch (type) {
    case `${catalogTypes.SET_CATALOG_CONTENT_PENDING}`:
    case `${catalogTypes.GET_CATEGORY_LIST_PENDING}`:
    case `${catalogTypes.GET_SUB_CATEGORY_LIST_PENDING}`:
    case `${catalogTypes.SET_CATEGORY_NAME_PENDING}`:
    case `${catalogTypes.SET_SUB_CATEGORY_NAME_PENDING}`:
    case `${catalogTypes.GET_PRODUCTS_PENDING}`:
    case `${catalogTypes.SET_SORTING_ASC_PENDING}`:
    case `${catalogTypes.SET_SORTING_BY_TYPE_ASC_PENDING}`:
    case `${catalogTypes.SET_SELECTED_PRODUCT_PENDING}`:
    case `${catalogTypes.GET_ADDRESSES_PENDING}`:
    case `${catalogTypes.SET_SORTING_TYPE_PENDING}`:
    case `${catalogTypes.GET_PRODUCTS_WITH_ID_PENDING}`:
    case `${catalogTypes.GET_FAVOURITE_PRODUCT_LIST_PENDING}`:
    case `${catalogTypes.SAVE_FAVOURITE_PRODUCT_PENDING}`:
    case `${catalogTypes.REMOVE_FAVOURITE_PRODUCT_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case `${catalogTypes.GET_CATEGORY_LIST_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        categoryList: payload,
        actives: actives,
        activesSubCategory: activesSubCategory
      };
    case `${catalogTypes.GET_CATEGORY_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case `${catalogTypes.SET_CATEGORY_NAME_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        selectedCategory: payload
      };
    case `${catalogTypes.SET_CATEGORY_NAME_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case `${catalogTypes.SET_SUB_CATEGORY_NAME_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        selectedSubCategory: payload
      };
    case `${catalogTypes.SET_SUB_CATEGORY_NAME_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case `${catalogTypes.GET_PRODUCTS_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        productList: payload.productDTOS,
        totalPages: payload.totalPages
      };
    case `${catalogTypes.GET_PRODUCTS_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case `${catalogTypes.SET_CATALOG_CONTENT_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        currentCatalogContent: payload
      };
    case `${catalogTypes.SET_CATALOG_CONTENT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        currentCatalogContent: null
      };
    case `${catalogTypes.SET_SORTING_ASC_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        sortAsc: payload
      };
    case `${catalogTypes.SET_SORTING_ASC_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case `${catalogTypes.SET_SORTING_BY_TYPE_ASC_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        sortByTypeAsc: payload
      };
    case `${catalogTypes.SET_SORTING_BY_TYPE_ASC_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${catalogTypes.SET_SELECTED_PRODUCT_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        selectedProduct: payload
      };
    case `${catalogTypes.SET_SELECTED_PRODUCT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${catalogTypes.GET_ADDRESSES_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        employeeAddresses: payload
      };
    case `${catalogTypes.GET_ADDRESSES_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${catalogTypes.SET_SORTING_TYPE_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        sortingType: payload
      };
    case `${catalogTypes.SET_SORTING_TYPE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case `${catalogTypes.CATALOG_MODAL_STATE}`:
      return {
        ...state,
        catalogModalState: payload
      };

    case `${catalogTypes.CATALOG_CART_ITEM_STATE}`:
      return {
        ...state,
        catalogCartItemState: payload
      };

    case `${catalogTypes.GET_PRODUCTS_WITH_ID_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        similarProducts: payload
      };
    case `${catalogTypes.GET_PRODUCTS_WITH_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${catalogTypes.GET_FAVOURITE_PRODUCT_LIST_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        favouriteProducts: payload
      };
    case `${catalogTypes.GET_FAVOURITE_PRODUCT_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        favouriteProducts: null
      };

    case `${catalogTypes.SAVE_FAVOURITE_PRODUCT_FULLFILLED}`:
      return {
        ...state,
        isLoading: false
      };
    case `${catalogTypes.SAVE_FAVOURITE_PRODUCT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${catalogTypes.REMOVE_FAVOURITE_PRODUCT_FULLFILLED}`:
      return {
        ...state,
        isLoading: false
      };
    case `${catalogTypes.REMOVE_FAVOURITE_PRODUCT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    default:
      return state;
  }
};

export default reducer;
