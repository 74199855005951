import React, { Component } from "react";

const ProfileContent = ContentComponent =>
  class extends Component {
    render() {
      return (
        <>
          <ContentComponent {...this.props} />
        </>
      );
    }
  };

export default ProfileContent;
