export const SET_WEEK_STORE_CONTENT_PENDING = "SET_WEEK_STORE_CONTENT_PENDING";
export const SET_WEEK_STORE_CONTENT_FULFILLED =
  "SET_WEEK_STORE_CONTENT_FULFILLED";
export const SET_WEEK_STORE_CONTENT_REJECTED =
  "SET_WEEK_STORE_CONTENT_REJECTED";

export const GET_WEEK_STORE_TO_BE_VOTED_LIST_PENDING =
  "GET_WEEK_STORE_TO_BE_VOTED_LIST_PENDING";
export const GET_WEEK_STORE_TO_BE_VOTED_LIST_FULFILLED =
  "GET_WEEK_STORE_TO_BE_VOTED_LIST_FULFILLED";
export const GET_WEEK_STORE_TO_BE_VOTED_LIST_REJECTED =
  "GET_WEEK_STORE_TO_BE_VOTED_LIST_REJECTED";

export const SET_SELECTED_SLIDE_PENDING = "SET_SELECTED_SLIDE_PENDING";
export const SET_SELECTED_SLIDE_FULFILLED = "SET_SELECTED_SLIDE_FULFILLED";
export const SET_SELECTED_SLIDE_REJECTED = "SET_SELECTED_SLIDE_REJECTED";

export const GET_MY_VOTES_BY_COMPETITION_PENDING =
  "GET_MY_VOTES_BY_COMPETITION_PENDING";
export const GET_MY_VOTES_BY_COMPETITION_FULFILLED =
  "GET_MY_VOTES_BY_COMPETITION_FULFILLED";
export const GET_MY_VOTES_BY_COMPETITION_REJECTED =
  "GET_MY_VOTES_BY_COMPETITION_REJECTED";

export const VOTE_VENDOR_PENDING = "VOTE_VENDOR_PENDING";
export const VOTE_VENDOR_FULFILLED = "VOTE_VENDOR_FULFILLED";
export const VOTE_VENDOR_REJECTED = "VOTE_VENDOR_REJECTED";

export const GET_WEEK_WINNER_LIST_PENDING = "GET_WEEK_WINNER_LIST_PENDING";
export const GET_WEEK_WINNER_LIST_FULFILLED = "GET_WEEK_WINNER_LIST_FULFILLED";
export const GET_WEEK_WINNER_LIST_REJECTED = "GET_WEEK_WINNER_LIST_REJECTED";
