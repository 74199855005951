import React, { useState, useEffect } from "react";
import "./CookieConsent.scss";
// import Modal from "react-responsive-modal";
import { Button, Modal } from "react-bootstrap";

import CookieText from "components/CookieText";
import IllumintionText from "components/IlluminationText";

import { IoArrowForward, IoOptions } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { cookieConsentAction, authAction } from "redux/modules";

const CookieConsent = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [consentIsActive, setConsentIsActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [cookieStatus, setCookieStatus] = useState([]);
  const cookiesAll = useSelector(store => store.cookieConsent.cookiesAll);
  // const employeeFirm = useSelector(store => store.auth.employeeFirm);
  // const clarificationText = useSelector(
  //   store => store.cookieConsent.clarificationText
  // );

  const loadAnalyticsScripts = () => {
    // Google Analytics script
    const gaScript = document.createElement("script");
    gaScript.src = "https://www.googletagmanager.com/gtag/js?id=UA-45481240-1";
    gaScript.async = true;
    document.head.appendChild(gaScript);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "UA-45481240-1");

    // Yandex Metrica script
    (function(m, e, t, r, i, k, a) {
      m[i] =
        m[i] ||
        function() {
          (m[i].a = m[i].a || []).push(arguments);
        };
      m[i].l = 1 * new Date();
      (k = e.createElement(t)),
        (a = e.getElementsByTagName(t)[0]),
        (k.async = 1),
        (k.src = r),
        a.parentNode.insertBefore(k, a);
    })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

    ym(56883085, "init", {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true
    });

    // Google Tag Manager script
    const gtmScript = document.createElement("script");
    gtmScript.src =
      "https://www.googletagmanager.com/gtag/js?id=UA-45481240-1https://www.googletagmanager.com/gtag/js?id=UA-45481240-1";
    gtmScript.async = true;
    document.head.appendChild(gtmScript);
  };

  useEffect(() => {
    if (localStorage.getItem("cookieConsent")) {
      setConsentIsActive(true);
    }

    return () => {};
  }, []);

  useEffect(() => {
    dispatch(cookieConsentAction.getCookiesAll());

    return () => {};
  }, [dispatch]);

  useEffect(() => {
    if (cookiesAll?.length > 0) {
      setCookieStatus(
        cookiesAll.map(item => ({
          cookiesId: item.id,
          acceptedVersion: item.acceptedVersion,
          success: item.title === "Zorunlu Çerezler" ? true : false
        }))
      );
    }
  }, [cookiesAll]);

  // Accept All Cookies
  const handleClickAccept = (e, data) => {
    e.preventDefault();

    if (data) {
      if (cookiesAll?.length > 0) {
        setCookieStatus(
          cookiesAll.map(item => ({
            cookiesId: item.id,
            acceptedVersion: item.acceptedVersion,
            success: true
          }))
        );

        // New Cookie Status
        const dataUpdate = cookiesAll.map(item => ({
          cookiesId: item.id,
          acceptedVersion: item.acceptedVersion,
          success: true
        }));

        dispatch(cookieConsentAction.saveCookieConsent(dataUpdate))
          .then(res => {
            if (res.isHttpSuccess) {
              // Analytics Script Call Here
              loadAnalyticsScripts();

              localStorage.setItem("cookieConsent", JSON.stringify(data));
              setConsentIsActive(true);
              setIsOpen(false);
            }
          })
          .catch(error => {
            console.log("error: ", error);
          });
      }
    }
  };

  // Reject All Cookies
  const handleClickReject = (e, data) => {
    e.preventDefault();

    if (data) {
      if (cookiesAll?.length > 0) {
        setCookieStatus(
          cookiesAll.map(item => ({
            cookiesId: item.id,
            acceptedVersion: item.acceptedVersion,
            success: item.title === "Zorunlu Çerezler" ? true : false
          }))
        );

        // New Cookie Status
        const dataUpdate = cookiesAll.map(item => ({
          cookiesId: item.id,
          acceptedVersion: item.acceptedVersion,
          success: true
        }));

        dispatch(cookieConsentAction.saveCookieConsent(dataUpdate)).then(
          res => {
            if (res.isHttpSuccess) {
              localStorage.setItem("cookieConsent", JSON.stringify(data));
              setConsentIsActive(true);
              setIsOpen(false);
            }
          }
        );
      }
    }
  };

  // Save && Close Modal
  const handleClickSave = (e, data) => {
    e.preventDefault();

    if (data) {
      dispatch(cookieConsentAction.saveCookieConsent(data))
        .then(res => {
          if (res.isHttpSuccess) {
            // Analytics Script Call Here
            loadAnalyticsScripts();

            localStorage.setItem("cookieConsent", JSON.stringify(data));
            setConsentIsActive(true);
            setIsOpen(false);
          }
        })
        .catch(error => {
          console.log("error: ", error);
        });
    }
  };

  // Open Modal
  const handleOpenModal = e => {
    e.preventDefault();
    setIsOpen(true);
  };

  // Tercihler Checkboxes - When Change The Checkboxes
  const handleCheckboxChange = (data, checked) => {
    if (data.title === "Zorunlu Çerezler") {
      setCookieStatus(prevStatus =>
        prevStatus.map(item =>
          item.cookiesId === data.id
            ? { ...item, acceptedVersion: data.acceptedVersion, success: true }
            : item
        )
      );
    } else {
      setCookieStatus(prevStatus =>
        prevStatus.map(item =>
          item.cookiesId === data.id
            ? {
                ...item,
                acceptedVersion: data.acceptedVersion,
                success: checked
              }
            : item
        )
      );
    }
  };

  return (
    <>
      {!consentIsActive && (
        <div className="w-full max-w-[450px] text-sm fixed z-50 bottom-2 left-2 bg-[#000] text-white !p-6 rounded-lg">
          <div>
            <p className="!m-0 !p-0">
              Benim Bayim platformunda deneyimlerinizi analiz etmek ve
              iyileştirmek amacıyla çerezler kullanılmakta olup, izin vermeniz
              halinde zorunlu çerezler haricindeki çerezlerle toplanan veriler
              işlenmekte ve çerez politikası kapsamında işlenmektedir. Çerezler
              bakımından kişisel tercihlerinizi, seçeneklerde yer alan tercihler
              kısmındaki ayarlar bölümünden yönetebilirsiniz. Daha fazla bilgi
              için Çerez Politikamızı okuyabilirsiniz. Kişisel verilerin
              korunması kapsamında aydınlatma metnine tercihler bölümünden
              erişebilirsiniz.
            </p>

            <div className="flex items-center !pt-4">
              <a
                href="#"
                className="bg-white text-black hover:text-[#aaa] rounded-lg !p-4 !mr-4"
                onClick={e => handleClickAccept(e, cookieStatus)}
              >
                Tümünü Kabul Et
              </a>

              <a
                href="#"
                className="bg-white text-black hover:text-[#aaa] rounded-lg !p-4 !mr-4"
                onClick={e => handleClickReject(e, cookieStatus)}
              >
                Tümünü Reddet
              </a>

              <a
                href="#"
                className="bg-white text-black hover:text-[#aaa] rounded-lg !p-4"
                onClick={e => handleOpenModal(e)}
              >
                Tercihler
              </a>
            </div>
          </div>

          <Modal
            show={isOpen}
            onHide={() => setIsOpen(!isOpen)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal-cookie-consent"
          >
            <Modal.Header closeButton>
              <Modal.Title className="w-full">
                <div className="flex items-center">
                  <IoOptions className="!mr-3" />
                  <span className="text-xl">Tercihlerinizi Özelleştirin</span>
                </div>

                <div
                  className="tabs tabs-boxed !mt-4 justify-between"
                  role="tablist"
                >
                  <a
                    className={activeIndex === 0 ? "tab tab-active" : "tab"}
                    id="preferences-tab"
                    data-toggle="tab"
                    href="#preferences"
                    role="tab"
                    aria-controls="preferences"
                    aria-selected="true"
                    onClick={() => {
                      setActiveIndex(0);
                    }}
                  >
                    Tercihler
                  </a>

                  <a
                    className={activeIndex === 1 ? "tab tab-active" : "tab"}
                    id="popup-cookie-text-tab"
                    data-toggle="tab"
                    href="#popup-cookie-text"
                    role="tab"
                    aria-controls="popup-cookie-text"
                    aria-selected="true"
                    onClick={() => {
                      setActiveIndex(1);
                    }}
                  >
                    Çerez Politikası
                  </a>

                  <a
                    className={activeIndex === 2 ? "tab tab-active" : "tab"}
                    id="popup-illumination-text-tab"
                    data-toggle="tab"
                    href="#popup-illumination-text"
                    role="tab"
                    aria-controls="popup-illumination-text"
                    aria-selected="true"
                    onClick={() => {
                      setActiveIndex(2);
                    }}
                  >
                    Aydınlatma Metni
                  </a>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="tab-content min-h-[466px] max-h-[466px] overflow-y-auto">
                <div
                  className="tab-pane overflow-hidden fade show active"
                  id="preferences"
                  role="tabpanel"
                  aria-labelledby="preferences-tab"
                >
                  {cookiesAll?.length > 0 ? (
                    cookiesAll.map(item => (
                      <div
                        key={item.id}
                        className="border-2 p-2 mb-2 select-none"
                      >
                        <label
                          className={
                            item.title === "Zorunlu Çerezler"
                              ? "cursor-pointer label pointer-events-none"
                              : "cursor-pointer label"
                          }
                        >
                          <div className="flex flex-col">
                            {item.title && (
                              <h3 className="text-lg">{item.title}</h3>
                            )}
                            {item.description && (
                              <span
                                className="label-text"
                                dangerouslySetInnerHTML={{
                                  __html: item.description
                                }}
                              />
                            )}
                          </div>

                          {item.title === "Zorunlu Çerezler" ? (
                            <input
                              type="checkbox"
                              className="toggle toggle-accent pointer-events-none !ml-3"
                              defaultChecked
                            />
                          ) : (
                            <input
                              type="checkbox"
                              className="toggle toggle-accent !ml-3"
                              onChange={e =>
                                handleCheckboxChange(item, e.target.checked)
                              }
                            />
                          )}
                        </label>
                      </div>
                    ))
                  ) : (
                    <p>Henüz bir veri bulunamadı.</p>
                  )}

                  <div className="flex justify-end">
                    <Button
                      className="relative my-2 flex items-center justify-center rounded-full border-primary-500 bg-primary-500 p-3 hover:!border-primary-700 hover:!bg-primary-700 text-center text-white max-sm:text-sm sm:px-6 sm:py-4 max-w-[200px] w-100"
                      disabled={!isOpen}
                      onClick={e => handleClickSave(e, cookieStatus)}
                    >
                      Kaydet
                      <IoArrowForward className="absolute right-6 top-1/2 ml-auto h-5 w-5 shrink-0 -translate-y-1/2 transform max-sm:hidden" />
                    </Button>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="popup-cookie-text"
                  role="tabpanel"
                  aria-labelledby="popup-cookie-text-tab"
                >
                  <CookieText />
                </div>

                <div
                  className="tab-pane fade"
                  id="popup-illumination-text"
                  role="tabpanel"
                  aria-labelledby="popup-illumination-text-tab"
                >
                  <IllumintionText />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
