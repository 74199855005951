import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TvAreaContent from "./TvAreaContent";
import TvAreaLeftMenu from "./TvAreaLeftMenu";
import { magazineAction } from "redux/modules";
import { imageShowUrl, videoShowUrl } from "../../../config/api.config";

import { IoCloseOutline, IoArrowForward, IoPlayCircle } from "react-icons/io5";

const Magazine = () => {
  const dispatch = useDispatch();
  const topicCategory = useSelector(store => store.magazine.topicCategory);
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const articleList = useSelector(store => store.magazine.articleList);
  const [clickedVideo, setClickedVideo] = useState(null);

  // Detail Modal
  const [showItem, setShowItem] = useState(false);

  const currentTVContent = useSelector(store => store.tvArea.currentTVContent);
  const SelectedContentDisplay = TvAreaContent(currentTVContent);
  window.$.fancybox.defaults.hash = false;

  useEffect(() => {
    dispatch(
      magazineAction.getTopicCategory(employeeFirm.firm.id, "Benim Bayim TV")
    );

    return () => {};
  }, []);

  useEffect(() => {
    return () => {};
  }, [topicCategory]);

  useEffect(() => {
    if (clickedVideo) {
      document.getElementById("tv-video").pause();
      document.getElementById("tv-video").load();
    }

    return () => {};
  }, [clickedVideo]);

  return (
    <div className="root-page-content">
      <main className="bg-slate-50 pb-20">
        {/* title */}
        <section className="glaire bg-[#16435E] py-6 md:py-12 flex flex-col justify-center items-center min-h-[146px]">
          <div className="container mx-auto px-4">
            <h2 className="text-center text-2xl font-semibold leading-none text-white md:text-[32px]">
              Benim Bayim TV
            </h2>
          </div>
        </section>

        <div className="mx-auto px-4 xl:container md:mt-20 lg:mt-12">
          <div className="grid grid-cols-1 gap-3 sm:gap-4 md:grid-cols-12">
            <TvAreaLeftMenu />

            <div className="max-sm:mt-4 md:col-span-8 lg:col-span-9">
              <div className="grid grid-cols-2 gap-2 lg:grid-cols-3 lg:gap-4">
                {articleList &&
                  articleList.length > 0 &&
                  articleList.map((item, index) => (
                    <a
                      href="#"
                      className="flex flex-col overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-200"
                      key={index}
                      onClick={e => {
                        e.preventDefault();
                        setShowItem(true);
                        setClickedVideo(item);
                        document.body.style.overflow = "hidden";
                      }}
                    >
                      <div className="relative">
                        {item.icon ? (
                          <img
                            src={imageShowUrl + item.icon}
                            alt=""
                            className="w-full"
                          />
                        ) : (
                          <div className="w-full min-h-[200px] bg-black"></div>
                        )}
                        <IoPlayCircle className="absolute left-1/2 top-1/2 h-12 w-12 -translate-x-1/2 -translate-y-1/2 transform text-white drop-shadow-xl" />
                      </div>

                      <div className="p-3 sm:p-6">
                        <h5 className="mt-2 text-sm font-medium text-slate-700 sm:text-xl">
                          {item.name && item.name}
                        </h5>

                        {/* <div className="mt-4 inline-flex gap-3">
                        <span className="text-xs font-semibold text-blue-900">
                          #TV
                        </span>
                      </div> */}
                      </div>
                    </a>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </main>

      {showItem && (
        <div className="fixed inset-0 z-[9999] bg-slate-900/75 backdrop-blur-sm">
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-2 sm:p-4">
              <div className="relative w-[1300px] max-w-full overflow-hidden rounded-xl bg-white shadow-md">
                <button
                  className="absolute right-4 top-4 rounded-full bg-black/75 md:right-6 md:top-6 z-[9999]"
                  onClick={() => {
                    setShowItem(false);
                    document.body.style.overflow = "visible";
                  }}
                >
                  <IoCloseOutline className=" h-8 w-8 text-white" />
                </button>

                {clickedVideo && (
                  <video
                    width="1600"
                    height="900"
                    controls
                    autoPlay
                    className="w-full md:h-[900] h-[300] relative"
                    id="tv-video"
                  >
                    <source
                      src={videoShowUrl + clickedVideo.videoUrl}
                      type="video/mp4"
                    />
                    Tarayıcınız video etiketini desteklemiyor.
                  </video>
                )}

                <div className="rounded-b-xl bg-slate-100 px-2 py-6 sm:px-4 sm:py-12">
                  <h3 className="text-center text-lg font-semibold text-slate-700 sm:text-2xl">
                    Benzer Videolar
                  </h3>
                  <div className="mt-6 grid grid-cols-2 gap-2 sm:mt-12 lg:grid-cols-4 lg:gap-4">
                    {articleList &&
                      articleList.length > 0 &&
                      articleList
                        .sort(() => Math.random() - 0.5) // Diziyi rastgele sırala
                        .slice(0, 4) // Diziden ilk 4 öğeyi seç
                        .map((item, index) => (
                          <a
                            href="#"
                            className="flex flex-col overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-200"
                            key={index}
                            onClick={e => {
                              e.preventDefault();
                              setClickedVideo(item);
                            }}
                          >
                            <div className="relative">
                              {item.icon ? (
                                <img
                                  src={item.icon}
                                  alt=""
                                  className="w-full"
                                />
                              ) : (
                                <div className="w-full min-h-[200px] bg-black"></div>
                              )}
                              <IoPlayCircle className="absolute left-1/2 top-1/2 h-12 w-12 -translate-x-1/2 -translate-y-1/2 transform text-white drop-shadow-xl" />
                            </div>

                            <div className="p-3 sm:p-6">
                              <h5 className="mt-2 text-sm font-medium text-slate-700 sm:text-xl">
                                {item.name}
                              </h5>

                              {/* <div className="mt-4 inline-flex gap-3">
														<span className="text-xs font-semibold text-blue-900">
															#TV
														</span>
														<span className="text-xs font-semibold text-blue-900">
															#Korona
														</span>
													</div> */}
                            </div>
                          </a>
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Magazine;
