import * as newsTypes from "./news.type";

const initialState = {
  financeList: [],
  newsByCategory: null,
  newsByCategoryContent: null,
  newsDetail: null,
  error: null,
  isLoading: false,
  newsLoading: false
};

const reducer = (
  state = initialState,
  { type, payload, actives, ...params }
) => {
  switch (type) {
    case `${newsTypes.GET_NEWS_PENDING}`:
    case `${newsTypes.GET_NEWS_BY_ID_PENDING}`:
      return { ...state, isLoading: true, error: null };
    case `${newsTypes.GET_NEWS_BY_CATEGORY_PENDING}`:
      return { ...state, newsLoading: true, error: null };

    case `${newsTypes.GET_NEWS_BY_CATEGORY_CONTENT_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${newsTypes.GET_NEWS_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        financeList: payload.data
      };
    case `${newsTypes.GET_NEWS_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        financeList: []
      };

    case `${newsTypes.GET_NEWS_BY_CATEGORY_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        newsByCategory: payload.data
      };
    case `${newsTypes.GET_NEWS_BY_CATEGORY_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        newsByCategory: null
      };

    case `${newsTypes.GET_NEWS_BY_CATEGORY_CONTENT_FULFILLED}`:
      return {
        ...state,
        newsLoading: false,
        newsByCategoryContent: payload.data
      };
    case `${newsTypes.GET_NEWS_BY_CATEGORY_CONTENT_REJECTED}`:
      return {
        ...state,
        newsLoading: false,
        error: payload,
        newsByCategoryContent: null
      };

    case `${newsTypes.GET_NEWS_BY_ID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        newsDetail: payload.data
      };
    case `${newsTypes.GET_NEWS_BY_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        newsDetail: null
      };

    default:
      return state;
  }
};

export default reducer;
