import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { orderAction } from "redux/modules";
import { imageShowUrl } from "../../../config/api.config";

const ProfileOrders = () => {
  const dispatch = useDispatch();

  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const orderList = useSelector(store => store.order.orderList);

  useEffect(() => {
    dispatch(orderAction.getOrdersByEmployeeFirmId(employeeFirm.id));
    return () => {};
  }, []);

  return (
    <div
      className="tab-pane fade show active"
      id="orders"
      role="tabpanel"
      aria-labelledby="orders-tab"
    >
      <div className="user-content-item orders">
        <div className="user-content-text">
          <p style={{ fontSize: "14px" }}>
            Siparişlerinizin durumunu bu bölümde detaylı olarak görebilir, takip
            edebilirsiniz. &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;
            &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;
            &emsp; &emsp; &emsp; &emsp; &emsp;
            <br />
            <br />
          </p>
        </div>

        <ul className="nav" role="tablist">
          <li>
            <a
              className="active"
              id="myorders-tab"
              data-toggle="tab"
              href="#myorders"
              role="tab"
              aria-controls="myorders"
              aria-selected="true"
            >
              Siparişlerim
            </a>
          </li>
          <li className="nav-item">
            <a
              id="pastorders-tab"
              data-toggle="tab"
              href="#pastorders"
              role="tab"
              aria-controls="pastorders"
              aria-selected="false"
            >
              Sipariş Geçmişim
            </a>
          </li>
        </ul>

        <div className="tab-content">
          <div
            className="tab-pane fade show active"
            id="myorders"
            role="tabpanel"
            aria-labelledby="myorders-tab"
          >
            {orderList && orderList.length > 0 ? (
              orderList.map((item, index) => {
                return item.orderStatus === "TESLİM EDİLDİ" ? null : (
                  <div key={index} className="orders-list-container">
                    <div className="orders-list-item">
                      <div className="orders-list-item-img mr-0 mr-md-3 mr-lg-0">
                        <img src={imageShowUrl + item.icon} alt="" />
                      </div>
                      <div className="orders-list-item-info">
                        <div className="orders-list-item-info-left">
                          <ul className="p-0 m-0">
                            {item.orderNo && (
                              <li>
                                <span>Sipariş Kodu:</span>
                                <p>{item.orderNo}</p>
                              </li>
                            )}

                            {item.productName && (
                              <li className="product-name">
                                <span>Ürün:</span>
                                <p>{item.productName}</p>
                              </li>
                            )}

                            {item.score && (
                              <li>
                                <span>Puan:</span>
                                <p>{item.score}</p>
                              </li>
                            )}

                            {item.orderStatus && (
                              <li>
                                <span>Sipariş Durumu:</span>
                                <p>{item.orderStatus}</p>
                              </li>
                            )}

                            {item.cargoFirm && (
                              <li>
                                <span>Kargo Firması:</span>
                                <p>{item.cargoFirm}</p>
                              </li>
                            )}

                            {item.deliveryAddress && (
                              <li className="product-name">
                                <span>Teslimat Adresi:</span>
                                {item.orderStatus === "SİPARİŞ ALINDI" ||
                                item.orderStatus === "DAĞITIMA ÇIKTI" ||
                                item.orderStatus ===
                                  "SİPARİŞİNİZ HAZIRLANIYOR" ? (
                                  <span
                                    style={{
                                      color: "#686868",
                                      fontSize: "17px",
                                      textAlign: "right",
                                      marginBottom: 0,
                                      marginLeft: "30px"
                                    }}
                                  >
                                    {item.deliveryAddressName}
                                  </span>
                                ) : null}
                              </li>
                            )}

                            {item.cargoTrackingUrl && (
                              <li>
                                <a
                                  href={item.cargoTrackingUrl}
                                  target="_blank"
                                  className="bg-green-500 text-white px-4 py-3 rounded-lg"
                                >
                                  Kargom nerede?
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p> Gösterilecek siparişiniz bulunmamaktadır. </p>
            )}
          </div>
          <div
            className="tab-pane fade"
            id="pastorders"
            role="tabpanel"
            aria-labelledby="pastorders-tab"
          >
            <div className="orders-list-container orders-past-list">
              {orderList && orderList.length > 0
                ? orderList.map((item, index) => {
                    return item.orderStatus === "TESLİM EDİLDİ" ? (
                      <div key={index} className="orders-past-item">
                        <ul>
                          {item.icon && (
                            <li>
                              <img src={imageShowUrl + item.icon} alt="" />
                            </li>
                          )}

                          {item.productName && (
                            <li>
                              <span>Ürün:</span>
                              <p>{item.productName}</p>
                            </li>
                          )}

                          {item.orderNo && (
                            <li>
                              <span>Sipariş Kodu:</span>
                              <p>{item.orderNo}</p>
                            </li>
                          )}

                          {item.orderStatus && (
                            <li>
                              <span>Sipariş Durumu:</span>
                              <p>{item.orderStatus}</p>
                            </li>
                          )}

                          {item.cargoFirm && (
                            <li>
                              <span>Kargo Firması:</span>
                              <p>{item.cargoFirm}</p>
                            </li>
                          )}

                          {item.deliveryAddress && (
                            <li>
                              <span>Teslimat Adresi:</span>
                              <p>{item.deliveryAddress}</p>
                            </li>
                          )}
                        </ul>
                      </div>
                    ) : null;
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileOrders;
