import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import AboutImg from "assets/portal-resource/img/about-3-img.png";

const JtiAbout = props => {
  return (
    <section className="about-page about-2-page bbd-menu-active">
      <div className="page-container">
        <div className="about-text">
          <h2>JTI Hakkında</h2>
          <p>
            JT’nin uluslararası tütün iş kolunu oluşturan JTI, 1999 yılında
            Japan Tobacco’nun R.J.Reynolds’ın uluslararası tütün operasyonlarını
            satın almasıyla kurulmuştur. Tütün alanında JT’nin 18’nci yüzyıla
            uzanan geçmişine ve yüzyıllara dayanan deneyimine karşılık genç bir
            sanayi kuruluşu olan JTI, gücünü 119’dan fazla farklı milletten
            çalışanının kültürel çeşitliliğinden alır. JTI, 70 ülkede 46 bin
            çalışanı, 38 fabrikası, 8 araştırma ve geliştirme merkeziyle
            faaliyet gösteren çok uluslu bir şirkettir.{" "}
          </p>
          {/* <br />
          <h4
            style={{
              color: "#00af69",
              marginBottom: "30px",
              fontWeight: "600"
            }}
          >
            JTI’ın Hedefi
          </h4>
          <p>2030 yılında satış hacminde dünyanın en büyük şirketi olmaktır.</p> */}
          <h4
            style={{ color: "#00af69", marginTop: "30px", fontWeight: "600" }}
          >
            Kilometre Taşları
          </h4>
        </div>
        <div className="about-img">
          <img src={AboutImg} alt="" />
        </div>
      </div>
    </section>
  );
};
export default JtiAbout;
