import * as magazineTypes from "./magazine.type";
import HealthyLife from "pages/UserPortal/Magazine/HealthyLife";

const initialState = {
  currentMagazineContent: HealthyLife,
  error: null,
  isLoading: false,
  articleList: [],
  categoryList: [],
  actives: [],
  mixedList: [],
  articleHashtag: [],
  topicCategory: null,
  article: null
};

const reducer = (
  state = initialState,
  { type, payload, actives, ...params }
) => {
  switch (type) {
    case `${magazineTypes.GET_TOPIC_SUB_CATEGORY_LIST_PENDING}`:
    case `${magazineTypes.SET_MAGAZINE_CONTENT_PENDING}`:
    case `${magazineTypes.GET_ARTICLES_PENDING}`:
    case `${magazineTypes.GET_TOPIC_CATEGORY_PENDING}`:
    case `${magazineTypes.SET_ACTIVES_PENDING}`:
    case `${magazineTypes.GET_ARTICLE_DETAIL_PENDING}`:
    case `${magazineTypes.GET_MIXED_LIST_PENDING}`:
    case `${magazineTypes.GET_ARTICLE_HASHTAG_PENDING}`:
    case `${magazineTypes.SET_CATEGORYLIST_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case `${magazineTypes.GET_TOPIC_SUB_CATEGORY_LIST_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        categoryList: payload,
        actives: actives
      };
    case `${magazineTypes.GET_TOPIC_SUB_CATEGORY_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${magazineTypes.GET_TOPIC_CATEGORY_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        topicCategory: payload
      };
    case `${magazineTypes.GET_TOPIC_CATEGORY_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case `${magazineTypes.SET_MAGAZINE_CONTENT_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        currentMagazineContent: payload
      };
    case `${magazineTypes.SET_MAGAZINE_CONTENT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        currentMagazineContent: null
      };

    case `${magazineTypes.GET_ARTICLES_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        articleList: payload
      };
    case `${magazineTypes.GET_ARTICLES_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        articleList: null
      };
    case `${magazineTypes.GET_ARTICLE_DETAIL_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        article: payload
      };
    case `${magazineTypes.GET_ARTICLE_DETAIL_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        article: null
      };
    case `${magazineTypes.SET_ACTIVES_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        actives: payload
      };
    case `${magazineTypes.SET_ACTIVES_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${magazineTypes.SET_CATEGORYLIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        categoryList: payload
      };
    case `${magazineTypes.SET_CATEGORYLIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${magazineTypes.GET_MIXED_LIST_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        mixedList: payload
      };
    case `${magazineTypes.GET_MIXED_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        mixedList: []
      };

    case `${magazineTypes.GET_ARTICLE_HASHTAG_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        articleHashtag: payload
      };
    case `${magazineTypes.GET_ARTICLE_HASHTAG_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        articleHashtag: []
      };

    default:
      return state;
  }
};

export default reducer;
