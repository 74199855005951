import * as searchFindTypes from "./searchFind.type";

const initialState = {
  searchFindList: null,
  controlSearchFind: null,
  resultSearchFind: null,
  brandBadges: null,
  allBadges: null,
  allEmpBadges: null,
  error: null,
  isLoading: false
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${searchFindTypes.GET_AVAILABLESEARCHFIND_BY_FIRMID_PENDING}`:
    case `${searchFindTypes.GET_SEARCHFINDLIST_BY_FIRMID_PENDING}`:
    case `${searchFindTypes.SAVE_SEARCHFIND_ANSWER_PENDING}`:
    case `${searchFindTypes.GET_RESULT_SEARCHFIND_BY_FIRMID_PENDING}`:
    case `${searchFindTypes.GET_BRAND_BADGES_PENDING}`:
    case `${searchFindTypes.GET_ALL_GAME_BADGES_PENDING}`:
    case `${searchFindTypes.GET_ALL_BY_EMP_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${searchFindTypes.GET_SEARCHFINDLIST_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        searchFindList: payload.data
      };

    case `${searchFindTypes.GET_SEARCHFINDLIST_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${searchFindTypes.GET_AVAILABLESEARCHFIND_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        controlSearchFind: payload.data
      };

    case `${searchFindTypes.GET_AVAILABLESEARCHFIND_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${searchFindTypes.SAVE_SEARCHFIND_ANSWER_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };

    case `${searchFindTypes.SAVE_SEARCHFIND_ANSWER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${searchFindTypes.GET_RESULT_SEARCHFIND_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        resultSearchFind: payload.data
      };

    case `${searchFindTypes.GET_RESULT_SEARCHFIND_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${searchFindTypes.GET_BRAND_BADGES_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        brandBadges: payload.data
      };

    case `${searchFindTypes.GET_BRAND_BADGES_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        brandBadges: null
      };

    case `${searchFindTypes.GET_ALL_GAME_BADGES_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        allBadges: payload.data
      };

    case `${searchFindTypes.GET_ALL_GAME_BADGES_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        allBadges: null
      };

    case `${searchFindTypes.GET_ALL_BY_EMP_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        allEmpBadges: payload.data
      };

    case `${searchFindTypes.GET_ALL_BY_EMP_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        allEmpBadges: null
      };

    case `${searchFindTypes.SELECTED_OPTION}`:
      return {
        ...state,
        selectedOption: payload
      };

    default:
      return state;
  }
};

export default reducer;
