import * as profileAddressTypes from "./profileAddress.type";

import { API } from "../../../../services/http.service";
const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getAddressByEmployee = id => async dispatch => {
  dispatch({ type: profileAddressTypes.GET_ADDRESS_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/address/${id}`, config);
    dispatch({
      type: profileAddressTypes.GET_ADDRESS_FULLFILLED,
      payload: response.data
    });

    return Promise.resolve(response.data);
  } catch (error) {
    dispatch({
      type: profileAddressTypes.GET_ADDRESS_REJECTED,
      payload: error
    });

    return Promise.reject(error);
  }
};

export const getProvinceListByName = countryName => async dispatch => {
  dispatch({
    type: profileAddressTypes.GET_PROVINCELIST_BY_COUNTRY_ID_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/address/province/${countryName}`,
      config
    );
    dispatch({
      type: profileAddressTypes.GET_PROVINCELIST_BY_COUNTRY_ID_FULLFILLED,
      payload: response
    });

    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileAddressTypes.GET_PROVINCELIST_BY_COUNTRY_ID_REJECTED,
      payload: error
    });

    return Promise.reject(error);
  }
};

export const getCountyListById = provinceId => async dispatch => {
  dispatch({ type: profileAddressTypes.GET_COUNTYLIST_BY_PROVINCE_ID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/address/county/${provinceId}`,
      config
    );
    dispatch({
      type: profileAddressTypes.GET_COUNTYLIST_BY_PROVINCE_ID_FULLFILLED,
      payload: response
    });

    return Promise.resolve(response.data);
  } catch (error) {
    dispatch({
      type: profileAddressTypes.GET_COUNTYLIST_BY_PROVINCE_ID_REJECTED,
      payload: error
    });

    return Promise.reject(error);
  }
};

export const getDistrictListById = countyId => async dispatch => {
  dispatch({ type: profileAddressTypes.GET_DISTRICTLIST_BY_COUNTY_ID_PENDING });

  try {
    const data = {
      countyId: countyId
    };
    const response = await API.post(
      `${REQUEST_URL}/address/get/all/districts/county`,
      data,
      config
    );
    dispatch({
      type: profileAddressTypes.GET_DISTRICTLIST_BY_COUNTY_ID_FULLFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileAddressTypes.GET_DISTRICTLIST_BY_COUNTY_ID_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const saveAddress = (
  addressId,
  name,
  county,
  province,
  districtId,
  street,
  apartmentNo,
  flatNo,
  employeeFirmId
) => async dispatch => {
  dispatch({ type: profileAddressTypes.SAVE_ADDRESSES_BY_EMPLOYEE_ID_PENDING });

  const data = {
    addressId,
    name,
    province,
    county,
    districtId,
    street,
    apartmentNo,
    flatNo,
    employeeFirmId
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/address/save`,
      data,
      config
    );
    dispatch({
      type: profileAddressTypes.SAVE_ADDRESSES_BY_EMPLOYEE_ID_FULLFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileAddressTypes.SAVE_ADDRESSES_BY_EMPLOYEE_ID_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const setOrderAddress = (
  addressId,
  employeeFirmId
) => async dispatch => {
  dispatch({ type: profileAddressTypes.SAVE_ADDRESSES_BY_EMPLOYEE_ID_PENDING });

  const data = {
    addressId,
    employeeFirmId
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/address/orderAddress/${addressId}/${employeeFirmId}`,
      data,
      config
    );
    dispatch({
      type: profileAddressTypes.SAVE_ADDRESSES_BY_EMPLOYEE_ID_FULLFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: profileAddressTypes.SAVE_ADDRESSES_BY_EMPLOYEE_ID_REJECTED,
      payload: error
    });
  }
};

export const deleteAddress = addressId => async dispatch => {
  dispatch({ type: profileAddressTypes.DELETE_ADDRESS_PENDING });
  try {
    const response = await API.post(
      `${REQUEST_URL}/address/delete/${addressId}`,
      config
    );
    dispatch({
      type: profileAddressTypes.DELETE_ADDRESS_FULLFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: profileAddressTypes.DELETE_ADDRESS_REJECTED,
      payload: error
    });
  }
};

function setAddress(text) {
  return text;
}

export const setAddressName = text => async dispatch => {
  dispatch({ type: profileAddressTypes.SET_SELECTED_ADDRESS_PENDING });

  try {
    const returnComponent = setAddress(text);
    dispatch({
      type: profileAddressTypes.SET_SELECTED_ADDRESS_FULLFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: profileAddressTypes.SET_SELECTED_ADDRESS_REJECTED,
      payload: error
    });
  }
};

function setSelectedCountryName(text) {
  return text;
}

export const setSelectedCountry = text => async dispatch => {
  dispatch({ type: profileAddressTypes.SET_SELECTED_COUNTRY_PENDING });

  try {
    const returnComponent = setSelectedCountryName(text);

    dispatch({
      type: profileAddressTypes.SET_SELECTED_COUNTRY_FULLFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: profileAddressTypes.SET_SELECTED_COUNTRY_REJECTED,
      payload: error
    });
  }
};

function setSelectedProvinceName(text) {
  return text;
}

export const setSelectedProvince = text => async dispatch => {
  dispatch({ type: profileAddressTypes.SET_SELECTED_PROVINCE_PENDING });

  try {
    const returnComponent = setSelectedProvinceName(text);

    dispatch({
      type: profileAddressTypes.SET_SELECTED_PROVINCE_FULLFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: profileAddressTypes.SET_SELECTED_PROVINCE_REJECTED,
      payload: error
    });
  }
};

function setSelectedCountyName(text) {
  return text;
}

export const setSelectedCounty = text => async dispatch => {
  dispatch({ type: profileAddressTypes.SET_SELECTED_COUNTY_PENDING });

  try {
    const returnComponent = setSelectedCountyName(text);

    dispatch({
      type: profileAddressTypes.SET_SELECTED_COUNTY_FULLFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: profileAddressTypes.SET_SELECTED_COUNTY_REJECTED,
      payload: error
    });
  }
};

function setSelectedAddressDetailName(text) {
  return text;
}

export const setSelectedDistrict = text => async dispatch => {
  dispatch({ type: profileAddressTypes.SET_SELECTED_DISTRICT_PENDING });

  try {
    const returnComponent = setSelectedAddressDetailName(text);

    dispatch({
      type: profileAddressTypes.SET_SELECTED_DISTRICT_FULLFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: profileAddressTypes.SET_SELECTED_DISTRICT_REJECTED,
      payload: error
    });
  }
};

function setSelectedStreetName(text) {
  return text;
}

export const setSelectedStreet = text => async dispatch => {
  dispatch({ type: profileAddressTypes.SET_SELECTED_STREET_PENDING });

  try {
    const returnComponent = setSelectedStreetName(text);

    dispatch({
      type: profileAddressTypes.SET_SELECTED_STREET_FULLFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: profileAddressTypes.SET_SELECTED_STREET_REJECTED,
      payload: error
    });
  }
};

function setSelectedApartmentNoName(text) {
  return text;
}

export const setSelectedApartmentNo = text => async dispatch => {
  dispatch({ type: profileAddressTypes.SET_SELECTED_APARTMENTNO_PENDING });

  try {
    const returnComponent = setSelectedApartmentNoName(text);

    dispatch({
      type: profileAddressTypes.SET_SELECTED_APARTMENTNO_FULLFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: profileAddressTypes.SET_SELECTED_APARTMENTNO_REJECTED,
      payload: error
    });
  }
};

function setSelectedFlatNoName(text) {
  return text;
}

export const setSelectedFlatNo = text => async dispatch => {
  dispatch({ type: profileAddressTypes.SET_SELECTED_FLATNO_PENDING });

  try {
    const returnComponent = setSelectedFlatNoName(text);

    dispatch({
      type: profileAddressTypes.SET_SELECTED_FLATNO_FULLFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: profileAddressTypes.SET_SELECTED_FLATNO_REJECTED,
      payload: error
    });
  }
};

function getSelectedAddressID(id) {
  return id;
}

export const setSelectedAddressID = id => async dispatch => {
  dispatch({ type: profileAddressTypes.SET_SELECTED_ADDRESS_ID_PENDING });

  try {
    const returnComponent = getSelectedAddressID(id);

    dispatch({
      type: profileAddressTypes.SET_SELECTED_ADDRESS_ID_FULLFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: profileAddressTypes.SET_SELECTED_ADDRESS_ID_REJECTED,
      payload: error
    });
  }
};
