import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MagazineContent from "./MagazineContent";
import MagazineLeftMenu from "./MagazineLeftMenu";
import { magazineAction } from "redux/modules";
import { imageShowUrl } from "../../../config/api.config";

import {
  IoMenuOutline,
  IoCloseOutline,
  IoChevronDown,
  IoArrowForward
} from "react-icons/io5";
("react-icons/fa");

const Magazine = props => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const location = useLocation();

  // const articleHashtag = useSelector(store => store.magazine.articleHashtag);
  // const actives = useSelector(store => store.magazine.actives);

  const categoryList = useSelector(store => store.magazine.categoryList);
  const topicCategory = useSelector(store => store.magazine.topicCategory);
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const currentMagazineContent = useSelector(
    store => store.magazine.currentMagazineContent
  );
  const articleList = useSelector(store => store.magazine.articleList);
  const article = useSelector(store => store.magazine.article);
  const mixedList = useSelector(store => store.magazine.mixedList);

  const SelectedContentDisplay = MagazineContent(currentMagazineContent);

  const [subCatId, setSubCatId] = useState(null);
  const [showItem, setShowItem] = useState(false);

  useEffect(() => {
    const size = location.pathname.split("/");
    if (size.length > 3) {
      const hashtag = size[size.length - 1];
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (article) {
      dispatch(
        magazineAction.getMixedList(
          employeeFirm.firm.id,
          article[0].topicSubCategoryId,
          article[0].id
        )
      );
    }

    return () => {};
  }, [article]);

  useEffect(() => {
    if (location && location.state && location.state.subCatId) {
      setSubCatId(location.state.subCatId);

      dispatch(
        magazineAction.getArticlesBySubCatId(
          employeeFirm.firm.id,
          location.state.subCatId
        )
      );

      // Clear History State - location.state remove when we reload page ...
      window.history.replaceState({}, document.title);
    } else {
      setSubCatId(props.location.subCatId);
    }

    dispatch(
      magazineAction.getTopicCategory(employeeFirm.firm.id, "Bizim Dergi")
    );

    return () => {};
  }, []);

  useEffect(() => {
    return () => {};
  }, [topicCategory]);

  // New Theme
  useEffect(() => {
    if (props.match.params.hashtag) {
      dispatch(magazineAction.getArticleHashtag(props.match.params.hashtag));
    }

    dispatch(
      magazineAction.getTopicCategory(employeeFirm.firm.id, "Bizim Dergi")
    );

    return () => {};
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });

    return () => {};
  }, [topicCategory]);

  const getArticleById = articleId => {
    dispatch(magazineAction.getArticleById(articleId));
    dispatch(magazineAction.setCategoryList(categoryList));
    // window.scroll({
    //   top: 0,
    //   left: 0,
    //   behavior: "smooth"
    // });
  };

  const handleSimilarMagazine = (e, id) => {
    e.preventDefault();
    getArticleById(id);
    document.getElementById("scrollable-item").scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
      <main className="bg-slate-50 pb-20">
        {/* title */}
        <section className="glaire bg-[#16435E] py-6 md:py-12 flex flex-col justify-center items-center min-h-[146px]">
          <div className="container mx-auto px-4">
            <h2 className="text-center text-2xl font-semibold leading-none text-white md:text-[32px]">
              Benim Bayim Dergi
            </h2>
          </div>
        </section>

        <div className="mx-auto px-4 xl:container md:mt-20 lg:mt-12">
          <div className="grid grid-cols-1 gap-3 sm:gap-4 md:grid-cols-12">
            <MagazineLeftMenu subCatId={subCatId} />

            <div className="md:col-span-8 lg:col-span-9">
              <div className="flex flex-col gap-2 lg:gap-4">
                {articleList &&
                  articleList.length > 0 &&
                  articleList.map((item, index) => (
                    <a
                      href="#"
                      className="grid grid-cols-9 items-center overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-200"
                      key={index}
                      onClick={e => {
                        e.preventDefault();
                        setShowItem(true);
                        document.body.style.overflow = "hidden";
                        getArticleById(item.id);
                      }}
                    >
                      <div className="col-span-3">
                        <img
                          src={imageShowUrl + item.icon}
                          alt=""
                          className="w-full object-cover"
                        />
                      </div>
                      <div className="col-span-6 p-4 sm:p-8 lg:px-16 lg:py-8">
                        <span className="text-xs font-semibold text-blue-900">
                          {item.categoryName && item.categoryName}
                        </span>
                        <h5 className="mt-2 text-sm font-medium text-slate-700 sm:text-xl">
                          {item.name && item.name}
                        </h5>
                        <span className="mt-4 flex items-center gap-2 text-sm text-primary-500">
                          Devamı
                          <IoArrowForward className="h-4 w-4" />
                        </span>
                      </div>
                    </a>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </main>

      {showItem && article && (
        <div className="fixed inset-0 z-30 bg-slate-900/75 backdrop-blur-sm">
          <div className="fixed inset-0 overflow-y-auto" id="scrollable-item">
            <div className="flex min-h-full items-center justify-center p-2 sm:p-4">
              <div className="relative w-[1300px] max-w-full overflow-hidden rounded-xl bg-white shadow-md">
                <button
                  className="absolute right-4 top-4 rounded-full bg-black/75 md:right-6 md:top-6"
                  onClick={() => {
                    setShowItem(false);
                    document.body.style.overflow = "visible";
                  }}
                >
                  <IoCloseOutline className=" h-8 w-8 text-white" />
                </button>

                {article.length > 0 &&
                  article.map(articleItem => (
                    <>
                      <div className="max-w-[1680px] w-100 max-h-[480px] h-100 overflow-hidden">
                        <img
                          src={imageShowUrl + articleItem.icon}
                          alt=""
                          className="mb-4 w-full sm:mb-7"
                        />
                      </div>

                      <div className="mx-auto w-full text-slate-500 p-4 p-md-5 md:w-4/5 [&>*]:mb-12 [&>h2]:text-lg [&>h2]:font-semibold [&>h2]:text-slate-700">
                        <h1 className="mx-auto !mb-6 w-full text-center text-2xl font-semibold leading-snug text-slate-700 sm:!mb-12 sm:text-4xl md:w-2/3">
                          {articleItem.name}
                        </h1>
                        <div
                          className="dangerously-description"
                          dangerouslySetInnerHTML={{
                            __html: articleItem.description
                          }}
                        ></div>
                      </div>

                      <div className="rounded-b-xl bg-slate-100 px-2 py-6 sm:px-4 sm:py-12">
                        <h3 className="text-center text-lg font-semibold text-slate-700 sm:text-2xl">
                          İlginizi Çekebilir
                        </h3>
                        <div className="mt-6 grid grid-cols-2 gap-2 sm:mt-12 lg:grid-cols-4 lg:gap-4">
                          {mixedList &&
                            mixedList.length > 0 &&
                            mixedList.map((mixedItem, index) => (
                              <a
                                href="#"
                                className="flex flex-col rounded-xl bg-white p-3 shadow-lg shadow-slate-200 sm:p-6 sm:pb-12"
                                key={index}
                                onClick={e => {
                                  handleSimilarMagazine(e, mixedItem.id);
                                }}
                              >
                                <img
                                  src={imageShowUrl + mixedItem.icon}
                                  alt=""
                                  className="mb-4 w-full sm:mb-7"
                                />
                                <div>
                                  <span className="text-xs font-semibold text-blue-900">
                                    {mixedItem.categoryName}
                                  </span>
                                  <h5 className="mt-2 text-sm font-medium text-slate-700 sm:text-xl">
                                    {mixedItem.name}
                                  </h5>
                                </div>
                              </a>
                            ))}
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Magazine;
