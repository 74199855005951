import { authAction } from "../modules";

/**
 * When the http status is 401, it will automatically dispatch for logout
 * @param {import('redux').Dispatch} Object
 */
export const handleUnauthorized = ({ store }) => next => action => {
  if (action.payload?.status === 401) {
    if (store) {
      store.dispatch(authAction.logout());
    } else {
      // When token expried then remote token and redirect to login page
      window.location.href = "/login";
      localStorage.removeItem("token");
      localStorage.removeItem("reControl");
      localStorage.removeItem("disclamerForce");
      localStorage.removeItem("saveDisclaimer");
    }
  } else {
    next(action);
  }
};
