import * as lookFindTypes from "./lookFind.type";

const initialState = {
  lookFindList: null,
  controlLookFind: null,
  resultLookFind: null,
  error: null,
  isLoading: false
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${lookFindTypes.GET_AVAILABLELOOKFIND_BY_FIRMID_PENDING}`:
    case `${lookFindTypes.GET_LOOKFINDLIST_BY_FIRMID_PENDING}`:
    case `${lookFindTypes.SAVE_LOOKFIND_ANSWER_PENDING}`:
    case `${lookFindTypes.GET_RESULT_LOOKFIND_BY_FIRMID_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${lookFindTypes.GET_LOOKFINDLIST_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        lookFindList: payload.data
      };

    case `${lookFindTypes.GET_LOOKFINDLIST_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${lookFindTypes.GET_AVAILABLELOOKFIND_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        controlLookFind: payload.data
      };

    case `${lookFindTypes.GET_AVAILABLELOOKFIND_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${lookFindTypes.SAVE_LOOKFIND_ANSWER_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };

    case `${lookFindTypes.SAVE_LOOKFIND_ANSWER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${lookFindTypes.GET_RESULT_LOOKFIND_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        resultLookFind: payload.data
      };

    case `${lookFindTypes.GET_RESULT_LOOKFIND_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${lookFindTypes.SELECTED_OPTION}`:
      return {
        ...state,
        selectedOption: payload
      };

    default:
      return state;
  }
};

export default reducer;
