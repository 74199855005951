import * as orderTypes from "./order.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getOrdersByEmployeeFirmId = employeeFirmId => async dispatch => {
  dispatch({ type: orderTypes.GET_ORDERS_BY_ID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/orderList/${employeeFirmId}`,
      config
    );
    dispatch({
      type: orderTypes.GET_ORDERS_BY_ID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: orderTypes.GET_ORDERS_BY_ID_REJECTED,
      payload: error
    });
  }
};

export const getOrderNumber = employeeFirmId => async dispatch => {
  dispatch({ type: orderTypes.GET_ORDER_NUMBER_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/orderNumber/${employeeFirmId}`,
      config
    );
    dispatch({
      type: orderTypes.GET_ORDER_NUMBER_FULFILLED,
      payload: response
    });
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch({
      type: orderTypes.GET_ORDER_NUMBER_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

/* function deleteItem(orderList) {
  const orderListHistory = orderList.slice(1);
  return orderListHistory;
}

export const deleteItemFromList = orderList => async dispatch => {
  dispatch({ type: orderTypes.SET_ORDER_LIST_PENDING });

  try {
    const returnComponent = deleteItem(orderList);

    dispatch({
      type: orderTypes.SET_ORDER_LIST_FULFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: orderTypes.SET_ORDER_LIST_REJECTED,
      payload: error
    });
  }
}; */

export const saveOrder = (
  selectedAddress,
  employeeFirmId,
  productId,
  employeeIdentityNo
) => async dispatch => {
  dispatch({ type: orderTypes.SET_SAVE_ORDER_LIST_PENDING });

  const data = {
    selectedAddress,
    employeeFirmId,
    productId,
    employeeIdentityNo
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/orderList/saveOrder`,
      data,
      config
    );
    dispatch({
      type: orderTypes.SET_SAVE_ORDER_LIST_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({ type: orderTypes.SET_SAVE_ORDER_LIST_REJECTED, payload: error });
    return Promise.reject(error);
  }
};

export const saveOrderLogo = orderId => async dispatch => {
  dispatch({ type: orderTypes.SET_ORDER_LOGO_PENDING });

  const data = {
    orderId
  };

  try {
    const response = await API.post(`${REQUEST_URL}/order/logo`, data, config);
    dispatch({
      type: orderTypes.SET_ORDER_LOGO_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({ type: orderTypes.SET_ORDER_LOGO_REJECTED, payload: error });
    return Promise.reject(error);
  }
};

function returnAddress(value) {
  return value;
}

export const setAddress = value => async dispatch => {
  dispatch({ type: orderTypes.SET_ADDRESS_PENDING });

  try {
    const returnComponent = returnAddress(value);

    dispatch({
      type: orderTypes.SET_ADDRESS_FULFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: orderTypes.SET_ADDRESS_REJECTED,
      payload: error
    });
  }
};
