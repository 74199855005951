import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { weekStoreAction, authAction } from "redux/modules";
import { imageShowUrl } from "../../../config/api.config";
import showErrMessage from "hooks/showErrMessage";
import showMessage from "hooks/showMessage";
import $ from "jquery";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faArrowLeft,
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";

const WeekVoting = () => {
  const weekStoreToBeVotedList = useSelector(
    store => store.weekStore.weekStoreToBeVotedList
  );
  const selectedWeekStoreCandidate = useSelector(
    store => store.weekStore.selectedWeekStoreCandidate
  );
  const myVoteListByCompetition = useSelector(
    store => store.weekStore.myVoteListByCompetition
  );

  const dispatch = useDispatch();
  var weekStoreToBeVotedContent = null;
  const [styleDynamic, setStyleDynamic] = useState("");
  const employeeFirm = useSelector(store => store.auth.employeeFirm);

  useEffect(() => {
    let sliderWidth = $(".swiper-wrapper").width();

    setStyleDynamic(
      "translate3d(-" +
        (selectedWeekStoreCandidate - 1) * sliderWidth +
        "px, 0px, 0px)"
    );
    return () => {};
  }, [selectedWeekStoreCandidate]);

  function candidateAscend(candidate) {
    if (weekStoreToBeVotedList.length === candidate) {
      dispatch(weekStoreAction.setSliderContent(1));
    } else {
      dispatch(weekStoreAction.setSliderContent(candidate + 1));
    }
  }

  function candidateDescend(candidate) {
    if (0 === candidate - 1) {
      dispatch(weekStoreAction.setSliderContent(weekStoreToBeVotedList.length));
    } else {
      dispatch(weekStoreAction.setSliderContent(candidate - 1));
    }
  }

  if (weekStoreToBeVotedList) {
    weekStoreToBeVotedContent = weekStoreToBeVotedList.map(function(
      item,
      index
    ) {
      return (
        <div
          key={index}
          className={
            selectedWeekStoreCandidate - 1 === index
              ? "swiper-slide swiper-slide-active"
              : "swiper-slide"
          }
          data-swiper-slide-index={index}
        >
          <div className="row" style={{ maxWidth: "none" }}>
            <div className="col-md-7">
              <img
                src={imageShowUrl + item.employeeImageDTO.imageUrl}
                style={{ width: "620px", height: "465px" }}
              />
            </div>
            <div className="col-md-5">
              <div className="ml-3 mt-md-5">
                <div>
                  <div className="person mb-3 font-weight-bold">
                    {item.employeeName} {item.employeeSurname}
                  </div>
                  <div className="location">{item.vendorName}</div>
                  <div className="location">
                    {item.addressDTO && item.addressDTO.provinceName}
                  </div>
                </div>
                <div className="d-flex mt-4 pt-3 stats">
                  <div className="thumbs-up"></div>
                  <div className="voted">{item.votesReceived} Oy</div>
                </div>
                {myVoteListByCompetition &&
                myVoteListByCompetition.length > 0 ? (
                  myVoteListByCompetition[0] === item.id ? (
                    <div>
                      <p>
                        Bu kişiye daha önceden oy verdiniz, lütfen diğer
                        kullanıcılara oy veriniz.
                      </p>
                    </div>
                  ) : myVoteListByCompetition.every(
                      childItem => childItem !== item.id
                    ) === true ? null : (
                    <div>
                      <p>
                        Oyunuz kaydedildi! İlk oyunuzda {item.score} puan
                        kazandığınız için yeni puan kazanamadınız. Fakat
                        dilediğiniz kadar oy kullanabilirsiniz ve beğendiğiniz
                        dükkanları desteklemeye devam edebilirsiniz.
                      </p>
                    </div>
                  )
                ) : null}
                <div>
                  {myVoteListByCompetition ? (
                    myVoteListByCompetition.every(
                      childItem => childItem !== item.id
                    ) === true ? (
                      <button
                        type="submit"
                        className="btn-games btn-games-success d-inline-block"
                        onClick={() =>
                          dispatch(
                            weekStoreAction.voteVendor(
                              employeeFirm.firm.id,
                              employeeFirm.id,
                              item.hdCompetitionId,
                              item.id
                            )
                          )
                            .then(e => {
                              showMessage("Oyunuz kaydedildi");
                            })
                            .catch(err => {
                              showErrMessage(
                                err.error.response.data.errors[0].message
                              );
                            })
                        }
                      >
                        <span>OY VER</span>
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          style={{ fontSize: "16px", color: "#fff" }}
                        />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn-games btn-games-success d-inline-block"
                        style={{ opacity: "0.3", cursor: "default" }}
                      >
                        <span>OY VER</span>
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          style={{ fontSize: "16px", color: "#fff" }}
                        />
                      </button>
                    )
                  ) : (
                    <button
                      type="submit"
                      className="btn-games btn-games-success d-inline-block"
                      onClick={() =>
                        dispatch(
                          weekStoreAction.voteVendor(
                            employeeFirm.firm.id,
                            employeeFirm.id,
                            item.hdCompetitionId,
                            item.id
                          )
                        )
                          .then(e => {
                            showMessage("Oyunuz kaydedildi");
                            dispatch(
                              authAction.returnNewScoreOfEmployee(
                                employeeFirm.firm.id,
                                employeeFirm.id
                              )
                            );
                          })
                          .catch(err => {
                            showErrMessage("Oyunuz kaydedilemedi");
                          })
                      }
                    >
                      <span>OY VER</span>
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        style={{ fontSize: "16px", color: "#fff" }}
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <div
      id="hafta-dukkan-slider"
      className="main-content"
      style={{ display: "block" }}
    >
      {selectedWeekStoreCandidate ? (
        <div className="container">
          <div
            className="close"
            onClick={() => dispatch(weekStoreAction.setSliderContent(null))}
          >
            <FontAwesomeIcon
              icon={faTimes}
              style={{ fontSize: "40px", color: "#383838" }}
            />
          </div>
          <div
            className="slideLeft"
            style={{ background: "none" }}
            onClick={() => candidateDescend(selectedWeekStoreCandidate)}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{ fontSize: "40px", color: "#383838" }}
            />
          </div>
          <div
            className="slideRight"
            style={{ background: "none" }}
            onClick={() => candidateAscend(selectedWeekStoreCandidate)}
          >
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{ fontSize: "40px", color: "#383838" }}
            />
          </div>
          <div className="swiper-container swiper-container-initialized swiper-container-horizontal">
            <div
              className="swiper-wrapper"
              style={{ transform: styleDynamic, transitionDuration: "0ms" }}
            >
              {weekStoreToBeVotedContent}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default WeekVoting;
