export const GET_SCOREHUNTLIST_BY_FIRMID_PENDING =
  "GET_SCOREHUNTLIST_BY_FIRMID_PENDING";
export const GET_SCOREHUNTLIST_BY_FIRMID_FULFILLED =
  "GET_SCOREHUNTLIST_BY_FIRMID_FULFILLED";
export const GET_SCOREHUNTLIST_BY_FIRMID_REJECTED =
  "GET_SCOREHUNTLIST_BY_FIRMID_REJECTED";

export const SAVE_SCOREHUNT_ANSWER_PENDING = "SAVE_SCOREHUNT_ANSWER_PENDING";
export const SAVE_SCOREHUNT_ANSWER_FULFILLED =
  "SAVE_SCOREHUNT_ANSWER_FULFILLED";
export const SAVE_SCOREHUNT_ANSWER_REJECTED = "SAVE_SCOREHUNT_ANSWER_REJECTED";

export const GET_AVAILABLESCOREHUNT_BY_FIRMID_PENDING =
  "GET_AVAILABLESCOREHUNT_BY_FIRMID_PENDING";
export const GET_AVAILABLESCOREHUNT_BY_FIRMID_FULFILLED =
  "GET_AVAILABLESCOREHUNT_BY_FIRMID_FULFILLED";
export const GET_AVAILABLESCOREHUNT_BY_FIRMID_REJECTED =
  "GET_AVAILABLESCOREHUNT_BY_FIRMID_REJECTED";

export const GET_RESULT_SCOREHUNT_BY_FIRMID_PENDING =
  "GET_RESULT_SCOREHUNT_BY_FIRMID_PENDING";
export const GET_RESULT_SCOREHUNT_BY_FIRMID_FULFILLED =
  "GET_RESULT_SCOREHUNT_BY_FIRMID_FULFILLED";
export const GET_RESULT_SCOREHUNT_BY_FIRMID_REJECTED =
  "GET_RESULT_SCOREHUNT_BY_FIRMID_REJECTED";

export const SELECTED_OPTION = "SELECTED_OPTION";
