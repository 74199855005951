import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import IllegalTradeImg from "assets/portal-resource/img/illegal-trade-img.png";

const IllegalTrade = props => {
  return (
    <section className="about-page about-3-page bbd-menu-active">
      <div className="page-container">
        <div className="about-text">
          <h2>Yasa Dışı Ticaret</h2>
          <div className="about-bottom-img">
            <img src={IllegalTradeImg} alt="" />
          </div>
          <p>
            Yasa dışı ticaret; malların üretimi, ithalatı, ihracatı, satın
            alması, satışı veya temin edilmesinin mevzuata uymayan bir şekilde
            yapılması anlamına gelmektedir. JTI Türkiye’nin de üye olduğu Tütün
            Sanayicileri Derneği 2007 yılından bu yana sigarada yasa dışı
            ticaret oranının gelişimini izlemek üzere “Boş Paket Araştırması”
            çalışmasını gerçekleştirmektedir. 10.000 sigara paketinin
            sokaklardan toplanması ve incelenmesine dayanan bu çalışma 2022 yılı
            itibarıyla Türkiye’de yasa dışı sigara oranının pazarın %5.5’i
            seviyesinde olduğunu göstermektedir. Yasa dışı sigara oranındaki
            azalmada, etkin kaçakçılıkla mücadele uygulamalarının yanı sıra,
            sarmalık kıyılmış tütünün yurt içinde yasa dışı ticaretindeki
            artışın da etkili olduğu gözlenmektedir.  Yasa dışı sarmalık
            kıyılmış tütünle birlikte değerlendirildiinde araştırmalar yasa dışı
            ticaretin pazarın %20 seviyelerinde olduğunu göstermektedir.
          </p>{" "}
          <br />
          <p>
            JTI, kaçak ve sahte ürünlere karşı “sıfır tolerans” yaklaşımını
            desteklemekte ve iç prosedürlerini buna göre belirlemektedir. Bu
            prosedürler, JTI müşterilerinin ve tedarik zinciri kontrolünün yanı
            sıra ürün sevkiyatı, JTI çalışanları ve çalışma alanlarının
            güvenliğini de sağlamaktadır.
          </p>{" "}
          <br />
          <p>
            JTI Tütün Sanayicileri Derneği’nin hem bayileri hem de toplumu
            bilinçlendirme çalışmalarında aktif olarak rol almaktadır. Derneğin
            Haziran 2023’te başlattığı Yasa Dışı Kapı Dışı Kampanyası ile yasal
            pazarın korunması için ülke çapında farkındalık yaratmak
            hedeflenmektedir.
          </p>
        </div>
      </div>
    </section>
  );
};
export default IllegalTrade;
