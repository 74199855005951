import * as profileAddressTypes from "./profileAddress.type";

const initialState = {
  error: null,
  isLoading: false,
  addressList: [],
  provinceList: null,
  countyList: null,
  districtList: null,
  selectedAddress: "",
  selectedProvince: null,
  selectedCounty: null,
  selectedDistrict: "",
  selectedStreet: "",
  selectedApartmentNo: "",
  selectedFlatNo: "",
  selectedAddressID: null
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${profileAddressTypes.GET_ADDRESS_PENDING}`:
    case `${profileAddressTypes.DELETE_ADDRESS_PENDING}`:
    case `${profileAddressTypes.GET_DISTRICTLIST_BY_COUNTY_ID_PENDING}`:
    case `${profileAddressTypes.GET_COUNTYLIST_BY_PROVINCE_ID_PENDING}`:
    case `${profileAddressTypes.GET_PROVINCELIST_BY_COUNTRY_ID_PENDING}`:
    case `${profileAddressTypes.SET_SELECTED_ADDRESS_PENDING}`:
    case `${profileAddressTypes.SET_SELECTED_COUNTRY_PENDING}`:
    case `${profileAddressTypes.SET_SELECTED_PROVINCE_PENDING}`:
    case `${profileAddressTypes.SET_SELECTED_COUNTY_PENDING}`:
    case `${profileAddressTypes.SET_SELECTED_DISTRICT_PENDING}`:
    case `${profileAddressTypes.SET_SELECTED_STREET_PENDING}`:
    case `${profileAddressTypes.SET_SELECTED_APARTMENTNO_PENDING}`:
    case `${profileAddressTypes.SET_SELECTED_FLATNO_PENDING}`:
    case `${profileAddressTypes.SET_SELECTED_ADDRESS_ID_PENDING}`:
    case `${profileAddressTypes.SAVE_ADDRESSES_BY_EMPLOYEE_ID_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case `${profileAddressTypes.GET_ADDRESS_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        addressList: payload
      };
    case `${profileAddressTypes.GET_ADDRESS_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        addressList: null
      };

    case `${profileAddressTypes.DELETE_ADDRESS_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        addressList: payload.data
      };
    case `${profileAddressTypes.DELETE_ADDRESS_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        addressList: null
      };

    case `${profileAddressTypes.SAVE_ADDRESSES_BY_EMPLOYEE_ID_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        addressList: payload.data
      };
    case `${profileAddressTypes.SAVE_ADDRESSES_BY_EMPLOYEE_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        addressList: null
      };

    case `${profileAddressTypes.GET_PROVINCELIST_BY_COUNTRY_ID_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        provinceList: payload.data
      };
    case `${profileAddressTypes.GET_PROVINCELIST_BY_COUNTRY_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        provinceList: null
      };

    case `${profileAddressTypes.GET_COUNTYLIST_BY_PROVINCE_ID_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        countyList: payload.data
      };
    case `${profileAddressTypes.GET_COUNTYLIST_BY_PROVINCE_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        countyList: null
      };

    case `${profileAddressTypes.GET_DISTRICTLIST_BY_COUNTY_ID_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        districtList: payload.data
      };
    case `${profileAddressTypes.GET_DISTRICTLIST_BY_COUNTY_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        districtList: null
      };

    case `${profileAddressTypes.SET_SELECTED_ADDRESS_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        selectedAddress: payload
      };
    case `${profileAddressTypes.SET_SELECTED_ADDRESS_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        selectedAddress: null
      };

    case `${profileAddressTypes.SET_SELECTED_COUNTRY_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        selectedCountry: payload
      };
    case `${profileAddressTypes.SET_SELECTED_COUNTRY_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        selectedCountry: null
      };

    case `${profileAddressTypes.SET_SELECTED_PROVINCE_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        selectedProvince: payload
      };
    case `${profileAddressTypes.SET_SELECTED_PROVINCE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        selectedProvince: null
      };

    case `${profileAddressTypes.SET_SELECTED_COUNTY_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        selectedCounty: payload
      };
    case `${profileAddressTypes.SET_SELECTED_COUNTY_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        selectedCounty: null
      };

    case `${profileAddressTypes.SET_SELECTED_DISTRICT_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        selectedDistrict: payload
      };
    case `${profileAddressTypes.SET_SELECTED_DISTRICT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        selectedDistrict: null
      };

    case `${profileAddressTypes.SET_SELECTED_STREET_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        selectedStreet: payload
      };
    case `${profileAddressTypes.SET_SELECTED_STREET_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        selectedStreet: null
      };

    case `${profileAddressTypes.SET_SELECTED_APARTMENTNO_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        selectedApartmentNo: payload
      };
    case `${profileAddressTypes.SET_SELECTED_APARTMENTNO_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        selectedApartmentNo: null
      };

    case `${profileAddressTypes.SET_SELECTED_FLATNO_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        selectedFlatNo: payload
      };
    case `${profileAddressTypes.SET_SELECTED_FLATNO_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        selectedFlatNo: null
      };

    case `${profileAddressTypes.SET_SELECTED_ADDRESS_ID_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        selectedAddressID: payload
      };
    case `${profileAddressTypes.SET_SELECTED_ADDRESS_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        selectedAddressID: null
      };

    default:
      return state;
  }
};

export default reducer;
