import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { tvAreaAction } from "redux/modules";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";
import { magazineAction } from "redux/modules";

const TvAreaLeftMenu = () => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const categoryList = useSelector(store => store.magazine.categoryList);
  const actives = useSelector(store => store.magazine.actives);
  const topicCategory = useSelector(store => store.magazine.topicCategory);
  const isLoading = useSelector(store => store.magazine.isLoading);

  useEffect(() => {
    dispatch(
      tvAreaAction.getSubCatList(
        employeeFirm.firm.id,
        "Benim Bayim TV",
        actives
      )
    );
    dispatch(tvAreaAction.getArticlesBySubCatId(employeeFirm.firm.id, 2223738));

    magazineAction.getTopicCategory(employeeFirm.firm.id, "Benim Bayim TV");
    return () => {};
  }, []);

  function setClicked(index2, subCatId) {
    categoryList.map((item, index) => {
      index === index2 ? (actives[index] = true) : (actives[index] = false);
    });
    dispatch(tvAreaAction.settvAreaContent());
    dispatch(
      tvAreaAction.getArticlesBySubCatId(employeeFirm.firm.id, subCatId)
    );
  }

  return (
    <>
      <GlobalLoader isLoading={isLoading} />

      <div className="pr-4 md:col-span-4 lg:col-span-3 left-side-blank">
        {topicCategory && topicCategory != null
          ? topicCategory.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col gap-6 rounded-xl bg-slate-200 p-6 pb-9 font-medium"
                >
                  {/* <h3 className="text-2xl text-slate-700">
                    Benim Bayim TV’ye hoşgeldiniz.
                  </h3>

                  <p className="text-sm font-normal leading-6 text-slate-500">
                    Benim Bayim TV Otomotiv, Teknoloji, Hukuk ve
                    Koronavirüs konularyla ilgili videolari
                    sizlerle bulusturuyor.
                  </p> 

                  <p className="text-sm font-normal leading-6 text-slate-500">
                    Gelismelerden uzak kalmamak icin Benim Bayim
                    TV'yi takip etmeyi unutmayin.
                  </p>
                  */}

                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              );
            })
          : null}

        <div className="mt-4 flex flex-col gap-6 rounded-xl rounded-bl-[48px] bg-slate-200 p-6 pb-9 font-medium sm:mt-6 select-none">
          {categoryList && categoryList.length > 0
            ? categoryList.map((item, index) => {
                return (
                  <a
                    key={index}
                    data-test={item.id}
                    className={`${
                      actives && actives[index]
                        ? "active [&.active]:text-primary-500"
                        : "flex items-center justify-between gap-4 text-slate-500"
                    }`}
                    onClick={() => {
                      setClicked(index, item.id);
                    }}
                  >
                    {item.name}
                  </a>
                );
              })
            : null}
        </div>
      </div>
    </>
  );
};
export default TvAreaLeftMenu;
