import * as pickFindTypes from "./pickFind.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getControlPickFindByFirmId = (
  id,
  employeeId
) => async dispatch => {
  dispatch({ type: pickFindTypes.GET_AVAILABLEPICKFIND_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/pickFind/${id}/${employeeId}`,
      config
    );
    dispatch({
      type: pickFindTypes.GET_AVAILABLEPICKFIND_BY_FIRMID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: pickFindTypes.GET_AVAILABLEPICKFIND_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};

export const getPickFindByFirmId = (id, employeeId) => async dispatch => {
  dispatch({ type: pickFindTypes.GET_PICKFINDLIST_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/pickFindQuestion/${id}/${employeeId}`,
      config
    );
    const activesTop = [];
    const activesBottom = [];

    /*    response.data[0].brandStyleNames.map((item, index) => {
      activesTop.push(null);
      activesBottom.push(null);
    }); */
    dispatch({
      type: pickFindTypes.GET_PICKFINDLIST_BY_FIRMID_FULFILLED,
      payload: response /* ,
      activesTop: activesTop,
      activesBottom: activesBottom */
    });
  } catch (error) {
    dispatch({
      type: pickFindTypes.GET_PICKFINDLIST_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};

export const savePickFindAnswer = (
  pickFindId,
  employeeFirmId,
  firmId
) => async dispatch => {
  dispatch({ type: pickFindTypes.SAVE_PICKFIND_ANSWER_PENDING });

  const data = { pickFindId, employeeFirmId, firmId };
  try {
    const response = await API.post(`/pickFind/save`, data);
    const interaction = {
      message: " ",
      type: "notification"
    };

    dispatch({
      type: pickFindTypes.SAVE_PICKFIND_ANSWER_FULFILLED,
      payload: response,
      interaction
    });
  } catch (error) {
    dispatch({
      type: pickFindTypes.SAVE_PICKFIND_ANSWER_PENDING,
      payload: error
    });
  }
};
