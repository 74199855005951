import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { imageShowUrl } from "../../config/api.config";

import JtiLogo from "assets/images/new-design/logo-jti.svg";

const HomeBrands = () => {
  const brandList = useSelector(store => store.brand.brandList);

  return (
    <section className="mt-6">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 overflow-hidden rounded-xl rounded-tl-[100px] bg-gradient-to-t from-gray-200 to-white to-20% shadow-md md:min-h-[200px]">
          <div className="flex flex-col items-center justify-center text-center lg:bg-[url('assets/images/new-design/rectangle.svg')] bg-no-repeat bg-right !my-6 lg:!my-0">
            <Link
              className="flex flex-col items-center justify-center text-center duration-200 ease-in hover:scale-105"
              to={`/userportal/brand`}
            >
              <img src={JtiLogo} alt="" />
              <h6 className="mt-6 font-bold text-slate-700">Markalarımız</h6>
            </Link>
          </div>

          {brandList &&
            brandList.length > 0 &&
            brandList.map((item, index) => (
              <Link
                key={item.id}
                className="flex items-center justify-center text-center lg:bg-[url('assets/images/new-design/rectangle.svg')] bg-no-repeat bg-right !my-6 lg:!my-0"
                to={{
                  pathname: "/userportal/brand",
                  brandId: item.id,
                  brandIndex: index + 1,
                  handleImgClick: false
                }}
              >
                <img
                  src={imageShowUrl + item.icon}
                  alt={item.name}
                  className="duration-200 ease-in hover:scale-105 max-h-[60px] w-auto md:w-100 md:-max-h-[auto] md:max-w-[150px]"
                />
              </Link>
            ))}
        </div>
      </div>
    </section>
  );
};
export default HomeBrands;
