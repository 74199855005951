import * as brandStyleTypes from "./brandStyle.type";

const initialState = {
  brandStyleList: [],
  error: null,
  isLoading: false,
  openSlider: false,
  indexStyle: 0,
  indexBullet: 0,
  brandId: 0,
  selectedDataList: null
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${brandStyleTypes.GET_BRAND_STYLE_BY_ID_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${brandStyleTypes.GET_BRAND_STYLE_BY_ID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        brandStyleList: payload.data
      };
    case `${brandStyleTypes.GET_BRAND_STYLE_BY_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        brandStyleList: null,
        brandStyleImagesList: null
      };
    case `${brandStyleTypes.SET_SLIDER_FULFILLED}`:
      return {
        ...state,
        openSlider: payload
      };
    case `${brandStyleTypes.SET_SLIDER_PENDING}`:
      return {
        ...state,
        openSlider: false
      };
    case `${brandStyleTypes.SET_SLIDER_REJECTED}`:
      return {
        ...state,
        openSlider: false
      };
    case `${brandStyleTypes.SET_STYLE_INDEX_FULFILLED}`:
      return {
        ...state,
        indexStyle: payload
      };
    case `${brandStyleTypes.SET_STYLE_INDEX_PENDING}`:
      return {
        ...state,
        indexStyle: false
      };
    case `${brandStyleTypes.SET_STYLE_INDEX_REJECTED}`:
      return {
        ...state,
        indexStyle: false
      };
    case `${brandStyleTypes.SET_INDEX_BULLET_FULFILLED}`:
      return {
        ...state,
        indexBullet: payload
      };
    case `${brandStyleTypes.SET_INDEX_BULLET_PENDING}`:
      return {
        ...state,
        indexBullet: false
      };
    case `${brandStyleTypes.SET_INDEX_BULLET_REJECTED}`:
      return {
        ...state,
        indexBullet: false
      };
    case `${brandStyleTypes.SELECTED_DATA_LIST}`:
      return {
        ...state,
        selectedDataList: payload
      };
    default:
      return state;
  }
};

export default reducer;
