import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { imageShowUrl } from "../../../config/api.config";
import { Link, useHistory, useLocation } from "react-router-dom";
import { magazineAction } from "redux/modules";

import "./Magazine.scss";

const HealthyLife = props => {
  const articleList = useSelector(store => store.magazine.articleList);
  const actives = useSelector(store => store.magazine.actives);
  const categoryList = useSelector(store => store.magazine.categoryList);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  function getArticleById(articleId) {
    dispatch(magazineAction.getArticleById(articleId));
    dispatch(magazineAction.setCategoryList(categoryList));
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }

  useEffect(() => {
    const size = location.pathname.split("/");
    if (size.length > 3) {
      const hashtag = size[size.length - 1];
    }

    return () => {};
  }, []);

  return (
    <div>
      {articleList && articleList.length > 0
        ? articleList.map((item, index) => {
            return (
              <div key={index} className="post">
                <div className="row" style={{ maxWidth: "none" }}>
                  <div className="col-md-4">
                    <Link
                      onClick={() => getArticleById(item.id)}
                      to={{
                        pathname: "/userportal/magazine/detail/" + item.id,
                        subCategoryId: item.topicSubCategoryId,
                        articleList: articleList,
                        actives: actives,
                        categoryList: categoryList
                      }}
                    >
                      <img className="img" src={imageShowUrl + item.icon} />
                    </Link>
                  </div>
                  <div className="col-md-8 d-flex align-items-stretch">
                    <div className="d-flex flex-column">
                      <Link
                        onClick={() => getArticleById(item.id)}
                        to={{
                          pathname: "/userportal/magazine/detail/" + item.id,
                          subCategoryId: item.topicSubCategoryId,
                          articleList: articleList,
                          actives: actives,
                          categoryList: categoryList
                        }}
                      >
                        <h2>{item.name}</h2>
                        {item.description && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item.description.slice(0, 600)
                            }}
                          />
                        )}
                      </Link>

                      <div className="d-flex flex-row flex-wrap">
                        {item.listHashtag &&
                          item.listHashtag.length > 0 &&
                          item.listHashtag.map(elem => {
                            return (
                              <Link
                                to={`/userportal/magazine/${elem.trim()}`}
                                className="hashtag-name mr-1"
                              >
                                <span>{"#" + elem.trim()}</span>
                              </Link>
                            );
                          })}
                      </div>

                      <div className="footer d-flex mt-auto justify-content-end">
                        <div className="readmore ml-4">
                          <Link
                            onClick={() => getArticleById(item.id)}
                            to={{
                              pathname:
                                "/userportal/magazine/detail/" + item.id,
                              /* icon: item.icon, 
                            topIcon: item.topIcon, 
                            topIconMobil: item.topIconMobil, 
                            description: item.description, 
                            name: item.name, 
                            date: item.dateCreated.slice(0, 16), */
                              subCategoryId: item.topicSubCategoryId,
                              articleList: articleList,
                              actives: actives,
                              categoryList: categoryList
                            }}
                          >
                            + DEVAMINI OKU
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default HealthyLife;
