export const SET_CATALOG_CONTENT_PENDING = "SET_CATALOG_CONTENT_PENDING";
export const SET_CATALOG_CONTENT_FULLFILLED = "SET_CATALOG_CONTENT_FULLFILLED";
export const SET_CATALOG_CONTENT_REJECTED = "SET_CATALOG_CONTENT_REJECTED";

export const GET_SUB_CATEGORY_LIST_PENDING = "GET_SUB_CATEGORY_LIST_PENDING";
export const GET_SUB_CATEGORY_LIST_FULLFILLED =
  "GET_SUB_CATEGORY_LIST_FULLFILLED";
export const GET_SUB_CATEGORY_LIST_REJECTED = "GET_SUB_CATEGORY_LIST_REJECTED";

export const GET_PRODUCTS_PENDING = "GET_PRODUCTS_PENDING";
export const GET_PRODUCTS_FULLFILLED = "GET_PRODUCTS_FULLFILLED";
export const GET_PRODUCTS_REJECTED = "GET_PRODUCTS_REJECTED";

export const GET_ADDRESSES_PENDING = "GET_ADDRESSES_PENDING";
export const GET_ADDRESSES_FULLFILLED = "GET_ADDRESSES_FULLFILLED";
export const GET_ADDRESSES_REJECTED = "GET_ADDRESSES_REJECTED";

export const GET_CATEGORY_LIST_PENDING = "GET_CATEGORY_LIST_PENDING";
export const GET_CATEGORY_LIST_FULLFILLED = "GET_CATEGORY_LIST_FULLFILLED";
export const GET_CATEGORY_LIST_REJECTED = "GET_CATEGORY_LIST_REJECTED";

export const SET_CATEGORY_NAME_PENDING = "SET_CATEGORY_NAME_PENDING";
export const SET_CATEGORY_NAME_FULLFILLED = "SET_CATEGORY_NAME_FULLFILLED";
export const SET_CATEGORY_NAME_REJECTED = "SET_CATEGORY_NAME_REJECTED";

export const SET_SUB_CATEGORY_NAME_PENDING = "SET_SUB_CATEGORY_NAME_PENDING";
export const SET_SUB_CATEGORY_NAME_FULLFILLED =
  "SET_SUB_CATEGORY_NAME_FULLFILLED";
export const SET_SUB_CATEGORY_NAME_REJECTED = "SET_SUB_CATEGORY_NAME_REJECTED";

export const SET_SORTING_ASC_PENDING = "SET_SORTING_ASC_PENDING";
export const SET_SORTING_ASC_FULLFILLED = "SET_SORTING_ASC_FULLFILLED";
export const SET_SORTING_ASC_REJECTED = "SET_SORTING_ASC_REJECTED";

export const SET_SORTING_TYPE_PENDING = "SET_SORTING_TYPE_PENDING";
export const SET_SORTING_TYPE_FULLFILLED = "SET_SORTING_TYPE_FULLFILLED";
export const SET_SORTING_TYPE_REJECTED = "SET_SORTING_TYPE_REJECTED";

export const SET_SELECTED_PRODUCT_PENDING = "SET_SELECTED_PRODUCT_PENDING";
export const SET_SELECTED_PRODUCT_FULLFILLED =
  "SET_SELECTED_PRODUCT_FULLFILLED";
export const SET_SELECTED_PRODUCT_REJECTED = "SET_SELECTED_PRODUCT_REJECTED";

export const SET_SORTING_BY_TYPE_ASC_PENDING =
  "SET_SORTING_BY_TYPE_ASC_PENDING";
export const SET_SORTING_BY_TYPE_ASC_FULLFILLED =
  "SET_SORTING_BY_TYPE_ASC_FULLFILLED";
export const SET_SORTING_BY_TYPE_ASC_REJECTED =
  "SET_SORTING_BY_TYPE_ASC_REJECTED";

export const CATALOG_MODAL_STATE = "CATALOG_MODAL_STATE";
export const CATALOG_CART_ITEM_STATE = "CATALOG_CART_ITEM_STATE";

export const GET_PRODUCTS_WITH_ID_PENDING = "GET_PRODUCTS_WITH_ID_PENDING";
export const GET_PRODUCTS_WITH_ID_FULLFILLED =
  "GET_PRODUCTS_WITH_ID_FULLFILLED";
export const GET_PRODUCTS_WITH_ID_REJECTED = "GET_PRODUCTS_WITH_ID_REJECTED";

export const GET_FAVOURITE_PRODUCT_LIST_PENDING =
  "GET_FAVOURITE_PRODUCT_LIST_PENDING";
export const GET_FAVOURITE_PRODUCT_LIST_FULLFILLED =
  "GET_FAVOURITE_PRODUCT_LIST_FULLFILLED";
export const GET_FAVOURITE_PRODUCT_LIST_REJECTED =
  "GET_FAVOURITE_PRODUCT_LIST_REJECTED";

export const SAVE_FAVOURITE_PRODUCT_PENDING = "SAVE_FAVOURITE_PRODUCT_PENDING";
export const SAVE_FAVOURITE_PRODUCT_FULLFILLED =
  "SAVE_FAVOURITE_PRODUCT_FULLFILLED";
export const SAVE_FAVOURITE_PRODUCT_REJECTED =
  "SAVE_FAVOURITE_PRODUCT_REJECTED";

export const REMOVE_FAVOURITE_PRODUCT_PENDING =
  "REMOVE_FAVOURITE_PRODUCT_PENDING";
export const REMOVE_FAVOURITE_PRODUCT_FULLFILLED =
  "REMOVE_FAVOURITE_PRODUCT_FULLFILLED";
export const REMOVE_FAVOURITE_PRODUCT_REJECTED =
  "REMOVE_FAVOURITE_PRODUCT_REJECTED";
