import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import TobaccoIcon from "assets/portal-resource/img/tutun1.png";
import TobaccoIcon2 from "assets/portal-resource/img/tutun2.png";
import TobaccoIcon3 from "assets/portal-resource/img/tutun3.png";
import TobaccoIcon4 from "assets/portal-resource/img/tutun4.png";

const HistoryTobacco = props => {
  return (
    <section className="about-page about-3-page bbd-menu-active">
      <div className="page-container">
        <div>
          <h2>T&uuml;rk T&uuml;t&uuml;n&uuml;n&uuml;n Tarihi</h2>
          <p>
            <img
              src={TobaccoIcon}
              style={{ padding: "20px 0 10px 0" }}
              alt=""
            />
          </p>
          <p>
            Tütün 1600’lü yıllarda iri yapraklı, kaba dokulu, ari, ilkel tipte
            bir bitki olarak Türkiye’ye girmiş, Türk topraklarının kendine has
            özelliği ve ekolojik şartları nedeniyle yeni bir kültür ortaya
            çıkmıştır. Bu yeni tür, zaman içinde "Türk harmanı", adını alarak
            kaliteli, düşük nikotinli, aromatik renkli bir tütün haline
            gelmiştir; Türk harmanı katılarak yapılan sigaralara ise “Turkish
            Blend”, “Turkish Cigarettes” gibi isimler verilmiştir.
          </p>
          <br />
          <img src={TobaccoIcon2} style={{ padding: "20px 0 10px 0" }} alt="" />
          <p>
            Osmanlı tütünle 17. Yüzyılın başlarında tanışır ama aynı zamanda
            tütün yasağı ile de karşı karşıya kalır. Ne var ki; IV. Murat
            tarafından konan yasağın ömrü uzun olmaz. Tütün kullandığı için
            sürgüne gönderilen Bahai Efendi, 1646’da şeyhülislam olunca, tütünü
            yeniden serbest bırakan fetvayı verir. Tütün ilk olarak bu yüzyılda
            vergilendirilir.
          </p>{" "}
          <br />
          <p>
            18. yüzyılda ise, “İstanbul ve Tevâbiî Tütün Gümrüğü”nün ülkedeki
            diğer tütün gümrükleri ile birleştirilerek tek elden idaresine
            başlanır. 18. yüzyılın sonlarına doğru, tütün gelirleri özel bir
            hazineye yönlendirilir ve ülkenin savaş finansmanında kullanacağı
            özel bir hazinenin gelirine dönüştürülür. (Kaynak: Osmanlı’dan
            Günümüze Tekel; Prof. Dr. Fatma Doğruel, Prof. Dr. Suut Doğruel)
          </p>{" "}
          <br />
          <p>
            Osmanlı’da tütün ticaretini ilk kez Mısır Valisi İbrahim Paşa
            başlatır. Tütün sigara formuna da bu dönemde kavuşur. İbrahim
            Paşa’nın Akka Kalesini’ni kuşatan Fransızlar ile savaşan askerlere
            moral olarak tütün gönderdiği rivayet edilir. Hatta nargile
            bombardımanda parçalanınca, askerlerin tütünü tüfek fişeklerinde
            kullanılan kağıda sararak içtiği söylenir. Kırım Savaşı'nda İngiliz
            ve Fransız askerler de tütünü gazete kağıdına sararak içmeyi
            öğrenir.
          </p>{" "}
          <br />
          <img src={TobaccoIcon3} style={{ padding: "20px 0 10px 0" }} alt="" />
          <p>
            İlk sigara fabrikaları 1881'de kurulmaya başlanır. 1884 yılında
            Osmanlı’nın ekonomik güçlük çekmeye başladığı dönemde, Cibali Sigara
            Fabrikası Fransız sermayesi ile Reji Şirketi tarafından kurulur.
            1886'da Dresden'de kurulan Yenice fabrikası da Osmanlı’nın ilk
            sigara fabrikalarından biridir. İzmir, Adana, Samsun ve İstanbul
            Osmanlı'nın en önemli tütün üretim ve işleme alanları olmuştur.
          </p>{" "}
          <br />
          <p>
            Reji idaresi 1925 yılına dek hüküm sürer, 1946 yılında ise Tekel
            Genel Müdürlüğüne dönüşür.Türk tütünü dünya genelinde o kadar çok ün
            yapmıştır ki, aynı ayardaki Yunanistan, İskeçe ve civarında yetişen
            tütünlerin Türkiye dışında yetiştirilmesi üzerine bu tütünleri Türk
            tütünlerinden ayırmak için Türk tütününe “Şark Tütünü” adı verilir.
          </p>{" "}
          <br />
          <p style={{ color: "#00af69", fontWeight: "600" }}>
            Türk tütününün karakteristik özellikleri ve kullanım alanları
          </p>
          <p>
            Türk tütünü, Ege, Karadeniz, Marmara, Doğu ve Güneydoğu Anadolu
            bölgelerinde yetiştirilmektedir. Manisa, Denizli, İzmir, Balıkesir,
            Muğla, Aydın il ve ilçelerinde yetiştirilen Ege bölgesi tütünü;
            nikotini düşük, yanma hızı yavaş, tatlı içimli, aromatik tütünler
            olup sert karakterli harmanların tadını yumuşatma, yavan içimli
            harmanlara tat ve nötr harmanlara koku vermede kullanılır.
          </p>{" "}
          <br />
          <p>
            Samsun, Tokat, Trabzon, Amasya, Sinop, Artvin ve Giresun il ve
            ilçelerinde yetiştirilmekte olan Karadeniz bölgesi tütünü;
            harmanlara tat ve koku vermek, aromatik özellikleriyle kalitelerini
            yükseltmek ve sertlik derecelerini ayarlamak amacıyla kullanılır.
          </p>
          <br />
          <p>
            Bursa, Çanakkale, Bilecik, Bolu, Sakarya, Kocaeli, Edirne, Tekirdağ
            ve Kırklareli il ve ilçelerinde yetiştirilen Marmara bölgesi
            tütünleri menşe özellikleri bakımından hem Ege hem de Karadeniz
            bölgesi tütünleriyle benzerlik gösterir. Sigara harmanlarını ıslah
            edici özelliğe sahip Marmara bölgesinde yetiştirilen Türk tütünleri;
            harmanlara sertlik, saçaklık ve aroma kazandırmak; sigara verimini
            yükseltmek amacıyla kullanılır.
          </p>
          <br />
          <p>
            Adıyaman, Batman, Hatay, Diyarbakır, Bitlis, Muş, Malatya, Adana,
            Siirt, Bingöl, Hakkari, Mardin il ve ilçelerinde üretilen tütünler
            arasında tek başına içilebilen niteliğe sahip tütünler bulunduğu
            gibi, harmanlara sertlik vermek, tat katmak amacıyla kullanılan
            ürünler de yer almaktadır.
          </p>
          <img src={TobaccoIcon4} style={{ padding: "20px 0 10px 0" }} alt="" />
          <p style={{ color: "#00af69", fontWeight: "600" }}>
            <strong>Kaynak:</strong>
          </p>
          <p>
            Tütün Kitabı, Emine Gürsoy Naskali, Kitabevi 2003 / Bilgi
            Üniversitesi Kütüphanesi, sayfa 139-151 Türkiye’de Tütün, Yapı Kredi
            Yayınları / Bilgi Üniversitesi Kütüphanesi, sayfa 14-15
          </p>
        </div>
      </div>
    </section>
  );
};
export default HistoryTobacco;
