import * as leaderboardTypes from "./leaderboard.type";

const initialState = {
  leaderboardList: [],
  leaderboardRegionList: [],
  error: null,
  isLoading: false
};

const reducer = (
  state = initialState,
  { type, payload, actives, ...params }
) => {
  switch (type) {
    case `${leaderboardTypes.GET_LEADERBOARD_PENDING}`:
    case `${leaderboardTypes.GET_LEADERBOARD_REGION_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${leaderboardTypes.GET_LEADERBOARD_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        leaderboardList: payload
      };
    case `${leaderboardTypes.GET_LEADERBOARD_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        leaderboardList: []
      };

    case `${leaderboardTypes.GET_LEADERBOARD_REGION_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        leaderboardRegionList: payload
      };
    case `${leaderboardTypes.GET_LEADERBOARD_REGION_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        leaderboardRegionList: []
      };

    default:
      return state;
  }
};

export default reducer;
