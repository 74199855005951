import * as tvAreaTypes from "./tvArea.type";
import DynamicTvPage from "pages/UserPortal/TvArea/DynamicTvPage";

const initialState = {
  currentTVContent: DynamicTvPage,
  error: null,
  isLoading: false,
  articleList: [],
  categoryList: [],
  actives: [],
  topicCategory: null
};

const reducer = (
  state = initialState,
  { type, payload, actives, ...params }
) => {
  switch (type) {
    case `${tvAreaTypes.GET_TOPIC_SUB_CATEGORY_LIST_PENDING}`:
    case `${tvAreaTypes.SET_TVAREA_CONTENT_PENDING}`:
    case `${tvAreaTypes.GET_ARTICLES_PENDING}`:
    case `${tvAreaTypes.GET_TOPIC_CATEGORY_PENDING}`:
    case `${tvAreaTypes.SET_ACTIVES_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case `${tvAreaTypes.GET_TOPIC_SUB_CATEGORY_LIST_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        categoryList: payload,
        actives: actives
      };
    case `${tvAreaTypes.GET_TOPIC_SUB_CATEGORY_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${tvAreaTypes.GET_TOPIC_CATEGORY_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        topicCategory: payload
      };
    case `${tvAreaTypes.GET_TOPIC_CATEGORY_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case `${tvAreaTypes.SET_TVAREA_CONTENT_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        currentTVContent: payload
      };
    case `${tvAreaTypes.SET_TVAREA_CONTENT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        currentTVContent: null
      };

    case `${tvAreaTypes.GET_ARTICLES_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        articleList: payload
      };
    case `${tvAreaTypes.GET_ARTICLES_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        articleList: null
      };
    case `${tvAreaTypes.SET_ACTIVES_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        actives: payload
      };
    case `${tvAreaTypes.SET_ACTIVES_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    default:
      return state;
  }
};

export default reducer;
