import * as cookieConsentTypes from "./cookieConsent.type";
import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getCookiesAll = () => async dispatch => {
  dispatch({ type: cookieConsentTypes.GET_COOKIES_ALL_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/public/getCookiesAll`,
      config
    );

    dispatch({
      type: cookieConsentTypes.GET_COOKIES_ALL_FULFILLED,
      payload: response
    });

    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: cookieConsentTypes.GET_COOKIES_ALL_REJECTED,
      payload: error
    });

    return Promise.reject(error);
  }
};

export const getClarificationText = () => async dispatch => {
  dispatch({ type: cookieConsentTypes.GET_CLARIFICATION_TEXT_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/public/getClarificationText`,
      config
    );

    dispatch({
      type: cookieConsentTypes.GET_CLARIFICATION_TEXT_FULFILLED,
      payload: response
    });

    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: cookieConsentTypes.GET_CLARIFICATION_TEXT_REJECTED,
      payload: error
    });

    return Promise.reject(error);
  }
};

export const saveCookieConsent = data => async dispatch => {
  dispatch({ type: cookieConsentTypes.SAVE_COOKIES_PENDING });

  try {
    const response = await API.post(`/public/saveCookies`, data);

    dispatch({
      type: cookieConsentTypes.SAVE_COOKIES_FULFILLED,
      payload: response
    });

    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: cookieConsentTypes.SAVE_COOKIES_REJECTED,
      payload: error
    });

    return Promise.reject(error);
  }
};
