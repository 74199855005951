import React from "react";

const NotFound = ({ history }) => {
  return (
    <>
      <div className="not-found-page">
        <div className="not-found-img">
          <img
            src="../portal-resource/img/login-logo.png"
            className="w-[200px] mx-auto"
            alt=""
          />
        </div>
        <div className="not-found-oops">Hata!</div>
        <div className="not-found-404-message">
          Üzgünüz Beklenmeyen Bir Hata Oluştu. <br /> Aradığınız Sayfaya Şu Anda
          Ulaşılamıyor.
        </div>
        <button
          className="not-found-button"
          onClick={() => history.replace("/")}
        >
          Ana Sayfa
        </button>
      </div>
    </>
  );
};

export default NotFound;
