export const GET_ANNOUNCEMENTLIST_BY_FIRMID_PENDING =
  "GET_ANNOUNCEMENTLIST_BY_FIRMID_PENDING";
export const GET_ANNOUNCEMENTLIST_BY_FIRMID_FULFILLED =
  "GET_ANNOUNCEMENTLIST_BY_FIRMID_FULFILLED";
export const GET_ANNOUNCEMENTLIST_BY_FIRMID_REJECTED =
  "GET_ANNOUNCEMENTLIST_BY_FIRMID_REJECTED";

export const GET_SURVEYLIST_BY_FIRMID_PENDING =
  "GET_SURVEYLIST_BY_FIRMID_PENDING";
export const GET_SURVEYLIST_BY_FIRMID_FULFILLED =
  "GET_SURVEYLIST_BY_FIRMID_FULFILLED";
export const GET_SURVEYLIST_BY_FIRMID_REJECTED =
  "GET_SURVEYLIST_BY_FIRMID_REJECTED";

export const SAVE_SURVEY_ANSWER_PENDING = "SAVE_SURVEY_ANSWER_PENDING";
export const SAVE_SURVEY_ANSWER_FULFILLED = "SAVE_SURVEY_ANSWER_FULFILLED";
export const SAVE_SURVEY_ANSWER_REJECTED = "SAVE_SURVEY_ANSWER_REJECTED";

export const GET_DISCLAIMER_BY_FIRMID_PENDING =
  "GET_DISCLAIMER_BY_FIRMID_PENDING";
export const GET_DISCLAIMER_BY_FIRMID_FULFILLED =
  "GET_DISCLAIMER_BY_FIRMID_FULFILLED";
export const GET_DISCLAIMER_BY_FIRMID_REJECTED =
  "GET_DISCLAIMER_BY_FIRMID_REJECTED";

export const SAVE_DISCLAIMER_CHECK_PENDING = "SAVE_DISCLAIMER_CHECK_PENDING";
export const SAVE_DISCLAIMER_CHECK_FULFILLED =
  "SAVE_DISCLAIMER_CHECK_FULFILLED";
export const SAVE_DISCLAIMER_CHECK_REJECTED = "SAVE_DISCLAIMER_CHECK_REJECTED";

export const SELECTED_OPTION = "SELECTED_OPTION";

export const GET_SPECIAL_ACTIVITY_BY_FIRMID_PENDING =
  "GET_SPECIAL_ACTIVITY_BY_FIRMID_PENDING";
export const GET_SPECIAL_ACTIVITY_BY_FIRMID_FULFILLED =
  "GET_SPECIAL_ACTIVITY_BY_FIRMID_FULFILLED";
export const GET_SPECIAL_ACTIVITY_BY_FIRMID_REJECTED =
  "GET_SPECIAL_ACTIVITY_BY_FIRMID_REJECTED";

export const GET_RAMADAN_WIDGET_PENDING = "GET_RAMADAN_WIDGET_PENDING";
export const GET_RAMADAN_WIDGET_FULFILLED = "GET_RAMADAN_WIDGET_FULFILLED";
export const GET_RAMADAN_WIDGET_REJECTED = "GET_RAMADAN_WIDGET_REJECTED";

export const SET_RAMADAN_WIDGET_PENDING = "SET_RAMADAN_WIDGET_PENDING";
export const SET_RAMADAN_WIDGET_FULFILLED = "SET_RAMADAN_WIDGET_FULFILLED";
export const SET_RAMADAN_WIDGET_REJECTED = "SET_RAMADAN_WIDGET_REJECTED";

export const GET_PRICE_LIST_PENDING = "GET_PRICE_LIST_PENDING";
export const GET_PRICE_LIST_FULFILLED = "GET_PRICE_LIST_FULFILLED";
export const GET_PRICE_LIST_REJECTED = "GET_PRICE_LIST_REJECTED";

export const GET_HOME_PRICE_LIST_PENDING = "GET_HOME_PRICE_LIST_PENDING";
export const GET_HOME_PRICE_LIST_FULFILLED = "GET_HOME_PRICE_LIST_FULFILLED";
export const GET_HOME_PRICE_LIST_REJECTED = "GET_HOME_PRICE_LIST_REJECTED";

export const GET_IYS_POPUP_PENDING = "GET_IYS_POPUP_PENDING";
export const GET_IYS_POPUP_FULFILLED = "GET_IYS_POPUP_FULFILLED";
export const GET_IYS_POPUP_REJECTED = "GET_IYS_POPUP_REJECTED";

export const SET_IYS_POPUP_PENDING = "SET_IYS_POPUP_PENDING";
export const SET_IYS_POPUP_FULFILLED = "SET_IYS_POPUP_FULFILLED";
export const SET_IYS_POPUP_REJECTED = "SET_IYS_POPUP_REJECTED";

export const GET_HOME_ORDER_INFO_PENDING = "GET_HOME_ORDER_INFO_PENDING";
export const GET_HOME_ORDER_INFO_FULFILLED = "GET_HOME_ORDER_INFO_FULFILLED";
export const GET_HOME_ORDER_INFO_REJECTED = "GET_HOME_ORDER_INFO_REJECTED";
