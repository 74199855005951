export const GET_BRAND_BY_ID_PENDING = "GET_BRAND_BY_ID_PENDING";
export const GET_BRAND_BY_ID_FULFILLED = "GET_BRAND_BY_ID_FULFILLED";
export const GET_BRAND_BY_ID_REJECTED = "GET_BRAND_BY_ID_REJECTED";

export const SET_LIST_VIDEOS_PENDING = "SET_LIST_VIDEOS_PENDING";
export const SET_LIST_VIDEOS_FULFILLED = "SET_LIST_VIDEOS_FULFILLED";
export const SET_LIST_VIDEOS_REJECTED = "SET_LIST_VIDEOS_REJECTED ";

export const CLEAR_LIST_VIDEOS_PENDING = "CLEAR_LIST_VIDEOS_PENDING";
export const CLEAR_LIST_VIDEOS_FULFILLED = "CLEAR_LIST_VIDEOS_FULFILLED";
export const CLEAR_LIST_VIDEOS_REJECTED = "CLEAR_LIST_VIDEOS_REJECTED ";

export const SELECTED_ACTIVES = "SELECTED_ACTIVES";

export const SET_FOOTER_PENDING = "SET_FOOTER_PENDING";
export const SET_FOOTER_FULFILLED = "SET_FOOTER_FULFILLED";
export const SET_FOOTER_REJECTED = "SET_FOOTER_REJECTED ";
