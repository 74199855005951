import * as modalTypes from "./modal.type";

const initialState = {
  SAVE_QUESTION: { isVisible: true, isAdding: true }
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  const { key, ...other } = payload || {};

  switch (type) {
    case `${modalTypes.OPEN_MODAL}`:
      return { ...state, [key]: { ...other } };

    case `${modalTypes.CLOSE_MODAL}`:
      return { ...state, [key]: { ...other, isAdding: state[key].isAdding } };

    default:
      return state;
  }
};

export default reducer;
