import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import InvestmentIcon from "assets/portal-resource/img/yatirim1.png";
import InvestmentIcon2 from "assets/portal-resource/img/yatirim2.png";
import InvestmentIcon3 from "assets/portal-resource/img/yatirim3.jpg";
import InvestmentIcon4 from "assets/portal-resource/img/yatirim4.jpg";
import InvestmentIcon5 from "assets/portal-resource/img/yatirim5.png";
import InvestmentIcon6 from "assets/portal-resource/img/yatirim6.jpg";
import InvestmentIcon7 from "assets/portal-resource/img/yatirim7.jpg";
import InvestmentIcon8 from "assets/portal-resource/img/yatirim8.png";
import InvestmentIcon9 from "assets/portal-resource/img/yatirim9.png";
import InvestmentIcon10 from "assets/portal-resource/img/yatirim10.jpg";
import InvestmentIcon11 from "assets/portal-resource/img/yatirim11.png";

import BannerCooperative from "assets/images/new-design/statics/banner-my-cooperative.jpg";
import BannerWater from "assets/images/new-design/statics/banner-my-water.jpg";

const SocietyInvestment = props => {
  return (
    <section className="about-page about-3-page bbd-menu-active">
      <div className="page-container">
        <div>
          <h2> Topluma Yatırım </h2>
          <p>
            <img
              width="300"
              height="200"
              src={InvestmentIcon}
              alt="https://jtibenimbayim.com/img/topluma-yatirim/japon-kulturu.jpg"
              style={{ padding: "10px 0" }}
            />
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            Bir dünya şirketi olan ve çalışmalarını onlarca ülkede yürüten JTI,
            faaliyet gösterdiği topluluklarda eşitsizlikleri azaltma ve
            toplumların hayatlarını iyileştirme sorumluluklarının bilinciyle
            hareket etmekte, içinde faaliyet gösterdiği toplumlara planlı ve
            gönüllü bir şekilde katkıda bulunmanın önemine inanmaktadır.
          </p>{" "}
          <br />
          <p>
            JTI Türkiye, yarattığı doğrudan ve dolaylı istihdamın ve pozitif
            ekonomik etkinin yanı sıra, pek çok alanda toplumsal sorumluluk
            çalışmalarına katkı sağlamaktadır. Sürdürülebilir tarım
            uygulamalarıyla değer zinciri için artı değer yaratırken, uzun
            soluklu 'topluma yatırım' projeleriyle sorumlu bir kurumsal vatandaş
            olarak hareket etmektedir.
          </p>
          <p>
            <img
              width="355"
              height="220"
              src={InvestmentIcon2}
              style={{ padding: "20px 0 10px 0" }}
              alt="https://jtibenimbayim.com/img/topluma-yatirim/fikrim-gelecegim-logo.jpg"
            />
          </p>
          <p>
            {" "}
            <strong>Fikrim Geleceğim</strong>
          </p>
          <p>
            Fikrim Geleceğim Programı, ekonomik büyümede aktif rol almak
            isteyen, girişimcilik yetenekleri doğrultusunda işlerini yaratıp
            geliştirme potansiyeli bulunan ve ortaya sürdürülebilir bir iş
            modeli koyabilen kadın girişimci adaylarını desteklemeyi ve bu yolla
            fırsat eşitliği yolunda kadınların yanında yer almayı
            hedeflemektedir. 2015 yılından bu yana on beş şehirde 450 kadın
            girişimci adayı eğitimlere katılarak sertifika almaya hak
            kazanmıştır. Sertifika sahibi kadınların %15'i kendi işlerini
            kurarak ekonomik hayata dahil olmuşlardır.
          </p>
          <br />
          <p>
            Kapsayıcı Büyüme Derneği, Boğaziçi Üniversitesi Yaşam Boyu Eğitim
            Merkezi ve Küçük ve Orta Ölçekli İşletmeleri Geliştirme ve
            Destekleme İdaresi Başkanlığı’nın (KOSGEB) birlikte yürüttüğü
            program, bugüne değin, Adana, Çanakkale, Denizli, Edirne, Eskişehir,
            Gaziantep, İzmir, Kars, Kastamonu, Konya, Şanlıurfa, Mardin,
            Antalya, Kayseri ve Artvinli kadın girişimci adaylarına ulaşmıştır.{" "}
          </p>
          <p>
            <img
              src={InvestmentIcon3}
              style={{
                padding: "20px 0 10px 0",
                maxWidth: "460px",
                width: "100%"
              }}
            />
            <p>Çanakkale Sertifika Töreni</p>
            <img
              src={InvestmentIcon4}
              style={{ padding: "10px 0", maxWidth: "460px", width: "100%" }}
            />
          </p>
          <p>Kars Sertifika Töreni</p>
          <br />
          <p>
            <img
              src={BannerCooperative}
              style={{
                padding: "20px 0 10px 0",
                maxWidth: "460px",
                width: "100%"
              }}
            />
          </p>
          <p>
            <strong>Benim Kooperatifim</strong>
          </p>
          <p>
            Faaliyet gösterdiği topluluklarda eşitsizlikleri azaltma taahhüdünü
            yerine getirmeye devam eden JTI, 2023 yılında kadınların yoksullukla
            mücadele etmelerinin önemli koşullarından olan kollektif üretimi
            desteklemek amacıyla Benim Kooperatifim programını başlatmıştır.
          </p>
          <br />
          <p>
            Ülkemizin farklı bölgelerinde kadın kooperatifleri aracılığıyla
            kadın istihdamını artırmayı ve onlar için sürdürülebilir iş
            modelleri oluşturmayı hedefleyen program ile daha fazla kadın ortak
            üretime katılacaktır.
          </p>
          <p>
            <img
              width="234"
              height="200"
              src={InvestmentIcon5}
              style={{ padding: "20px 0 10px 0" }}
              alt="https://jtibenimbayim.com/img/topluma-yatirim/bilgim-bereketim-logo.jpg"
            />
          </p>
          <p>
            <strong>Bilgim Bereketim</strong>
          </p>
          <p>
            JTI Türkiye’nin desteği ve Kapsayıcı Büyüme Derneği’nin
            başkanlığında hayata geçirilen Bilgim Bereketim Programı çiftçileri
            sürdürülebilir tarım uygulamaları ve finansal okuryazarlık
            alanlarında bilgilendirmeyi hedeflemektedir. 2017 yılında İzmir’de
            başlayan program kapsamında İzmir Gıda Tarım ve Hayvancılık İl
            Müdürlüğü, İzmir Ticaret Borsası ve Boğaziçi Üniversitesi Yaşam Boyu
            Eğitim Merkezinin katkılarıyla İzmir ve çevresinde eğitimler
            düzenlenmiş, 2000 sektör çalışanına ulaşmayı başarmıştır.
          </p>
          <p>
            <img
              src={InvestmentIcon6}
              style={{
                padding: "20px 0 10px 0",
                maxWidth: "460px",
                width: "100%"
              }}
            />
          </p>
          <p>
            <br />
            <strong>
              Benim Suyum Benim Geleceğim Veri Tabanlı Sulama Yönetimi Projesi
            </strong>
          </p>
          <p>
            JTI Türkiye’nin Tokyo Tarım Teknolojileri Üniversitesi ve Ege
            Üniversitesi ile işbirliği ile hayata geçirilen proje ile bilim
            dünyası profesyonelleri daha az su tüketerek yüksek verimde tarım
            ürünleri yetiştirebilmeyi amaçlamaktadır. İzmir Ticaret Borsası’nın
            öncülüğünde kurulan İzmir Tarım Teknoloji Merkezi, projenin kuluçka
            merkezi olurken, tüm paydaşlar daha verimli su kullanım
            teknolojileri geliştirerek gelecek nesillerin yaşayacağına kesin
            gözü ile bakılan "Su Krizi" ne çözüm arayışına katkı sağlamayı
            hedeflemektedir.
          </p>
          <p>
            <img
              src={BannerWater}
              style={{
                padding: "20px 0 10px 0",
                maxWidth: "460px",
                width: "100%"
              }}
            />
          </p>
          <p>
            <br />
            <strong>JTI Türkiye’nin Pozitif Ekonomik Etkisi</strong>
          </p>
          <p>
            JTI Türkiye, yıllık yaklaşık 56 milyar adet sigara, 2.163 ton
            sarmalık kıyılmış tütün üretim kapasitesi, geniş satış ağı ve 2900
            kişiye sağladığı istihdam ile JTI’ın dünya çapında ölçekteki38
            fabrikası içinde lider üretim üsslerinden biridir ve bağlı olduğu
            MENEAT bölgesinin lideri konumundadır. JTI Türkiye, kuruluşundan bu
            yana 1,7 milyar Amerikan Doları tutarında yatırım
            gerçekleştirmiştir.
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            <br />
            <strong>Ağaçlandırma ve Çevreye Yönelik Çalışmalar</strong>
          </p>
          <p>
            JTI Türkiye'nin 2004 yılında başladığı ağaçlandırma çalışmaları ile
            100 bine yakın ağaç dikilmiştir.
          </p>
          <br />
          <p>
            JTI ağaçlandırma çalışmalarının yanı sıra Torbalı fabrikasında da
            enerjisini kendi üreterek çevreye katkı sağlamaya devam etmektedir.
            Torbalı fabrikasındaki solar panel ve trigen teknolojileri sayesinde
            fabrika kendi enerjisini büyük oranda kendi üretmektedir.{" "}
          </p>
          <br></br>
          <p>
            <img
              src={InvestmentIcon7}
              style={{
                padding: "20px 0 10px 0",
                maxWidth: "400px",
                width: "100%"
              }}
            />
            <strong></strong>
          </p>
          <p>
            <br />
            <strong>Yaşlılara Destek</strong>
          </p>
          <p>
            JTI Türkiye İzmir Karabağlar’daki Türkiye Yardımseverler Derneği
            (TYSD) Dr. Kemal Tarım Dinlenme Evi'ni 2007 yılından beri
            desteklemekte ve her sene yaşlıların bakımı ve hayat kalitelerini
            yükseltmek amacıyla Dinlenme Evi'nin eksiklerini gidermektedir.
          </p>
          <br></br>
          <p>
            <strong>Engellilerin Yaşam Koşullarının İyileştirilmesi</strong>
          </p>
          <p>
            <img
              width="338"
              height="220"
              src={InvestmentIcon8}
              style={{ padding: "20px 0 10px 0" }}
              alt="https://jtibenimbayim.com/img/topluma-yatirim/bedensel-engelliler-logo.jpg"
            />
          </p>
          <p>
            JTI, 2009 yılından bu yana Bakırköy Bedensel Engelliler Eğitim
            Enstitüsü Derneği’nin düzenli destekçilerindendir.
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            <br />
            <strong>GEA İşbirliği</strong>
          </p>
          <p>
            JTI Türkiye ve JTI Vakfı Türkiye’de ve dünyanın çeşitli afet
            bölgelerinde faaliyet gösteren GEA Arama Kurtarma Ekoloji Grubu’na
            geniş çaplı destek sağlamaktadır. 2002 yılından bu yana JTI'ın
            verdiği destekle afetlerde aktif görev üstlenen GEA, afetlerden
            etkilenen insanlara global JTI ağı aracılığıyla ulaşmaktadır. GEA,
            2012 yılından itibaren JTI Vakfı tarafından desteklenmektedir.
          </p>
          <br></br>
          <p>
            <img
              width="348"
              height="232"
              src={InvestmentIcon9}
              style={{ padding: "20px 0 10px 0" }}
              alt="https://jtibenimbayim.com/img/topluma-yatirim/deprem-logo.jpg"
            />
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            <strong>JTI Vakfı</strong>
          </p>
          <p>
            JTI Vakfı İsviçre yasalarına bağlı olarak faaliyet gösteren ve JT
            International SA tarafından finanse edilen bir hayır kuruluşudur.
            Vakıf, afet yardımı sunan kuruluşlarla işbirliğinde bulunarak dünya
            çapındaki acil durum operasyonlarının finanse edilmesine yardımcı
            olmaktadır. Vakıf ayrıca, hazırlık, önleme ve tahmin faaliyetleri
            dâhil afet riskini azaltma konusunda çalışmalar yürüten kuruluşları
            desteklemektedir.
          </p>
          <p>
            <strong></strong>
          </p>
          <br></br>
          <p>
            <img
              src={InvestmentIcon11}
              style={{
                padding: "20px 0 10px 0",
                maxWidth: "460px",
                width: "100%"
              }}
              alt="https://jtibenimbayim.com/img/topluma-yatirim/hattusa.jpg"
            />
          </p>
          <p>
            <strong>Hattuşa Surlarının Ayağa Kaldırılması</strong>
          </p>
          <p>
            Alman Arkeoloji Enstitüsü tarafından yürütülen Hattuşa Kazılarında
            dünyada bir ilk gerçekleştirilerek kerpiç Hitit surlarının 65
            metrelik kısmı, 2002-2007 yılları arasında JTI Türkiye'nin
            katkılarıyla surlar yapılırken kullanılan orijinal tekniklerle
            yeniden ortaya çıkarılmıştır.
          </p>
          <p>
            <strong></strong>
          </p>
          <br></br>
          <p>
            <img
              src={InvestmentIcon10}
              style={{
                padding: "20px 0 10px 0",
                maxWidth: "460px",
                width: "100%"
              }}
            />
          </p>
          <p>
            <strong>Pers Mezar Anıtı’nın Çevre Düzenlemesi</strong>
          </p>
          <p>
            JTI Türkiye, tarihi M.Ö. 4. yüzyıla kadar uzanan ve içinde Athena,
            Kybele Tapınakları ve dünyanın en eski tiyatrosunun bulunduğu Foça
            Kazılarına destek vermiş ve Pers Mezar Anıtı’nın yenileme ve çevre
            düzenlemesini 1998-2001 yılları arasında gerçekleştirmiştir.
          </p>
        </div>
      </div>
    </section>
  );
};
export default SocietyInvestment;
