export const GET_NEWS_PENDING = "GET_NEWS_PENDING";
export const GET_NEWS_FULFILLED = "GET_NEWS_FULFILLED";
export const GET_NEWS_REJECTED = "GET_NEWS_REJECTED";

export const GET_NEWS_BY_CATEGORY_PENDING = "GET_NEWS_BY_CATEGORY_PENDING";
export const GET_NEWS_BY_CATEGORY_FULFILLED = "GET_NEWS_BY_CATEGORY_FULFILLED";
export const GET_NEWS_BY_CATEGORY_REJECTED = "GET_NEWS_BY_CATEGORY_REJECTED";

export const GET_NEWS_BY_CATEGORY_CONTENT_PENDING =
  "GET_NEWS_BY_CATEGORY_CONTENT_PENDING";
export const GET_NEWS_BY_CATEGORY_CONTENT_FULFILLED =
  "GET_NEWS_BY_CATEGORY_CONTENT_FULFILLED";
export const GET_NEWS_BY_CATEGORY_CONTENT_REJECTED =
  "GET_NEWS_BY_CATEGORY_CONTENT_REJECTED";

export const GET_NEWS_BY_ID_PENDING = "GET_NEWS_BY_ID_PENDING";
export const GET_NEWS_BY_ID_FULFILLED = "GET_NEWS_BY_ID_FULFILLED";
export const GET_NEWS_BY_ID_REJECTED = "GET_NEWS_BY_ID_REJECTED";
