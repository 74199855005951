import * as tvAreaTypes from "./tvArea.type";

import { API } from "../../../services/http.service";
import DynamicTvPage from "pages/UserPortal/TvArea/DynamicTvPage";
const REQUEST_URL = "";

const config = {
  headers: {
    "Content-Type": "application/json-application"
  }
};

export const getSubCatList = (
  id,
  topicCatName,
  activesLast
) => async dispatch => {
  dispatch({
    type: tvAreaTypes.GET_TOPIC_SUB_CATEGORY_LIST_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/topicSubCategory/${id}/${topicCatName}`,
      config
    );
    const actives = [];
    const a = 0;
    if (activesLast && activesLast.length > 0)
      activesLast.map((item, index) => {
        if (a === 0)
          response.data.map((item2, index2) => {
            activesLast[index] === true
              ? actives.push(true)
              : actives.push(false);
          });
        a = a + 1;
      });
    else
      response.data.map((item, index) => {
        index === 0 ? actives.push(true) : actives.push(false);
      });
    dispatch({
      type: tvAreaTypes.GET_TOPIC_SUB_CATEGORY_LIST_FULLFILLED,
      payload: response.data,
      actives: actives
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: tvAreaTypes.GET_TOPIC_SUB_CATEGORY_LIST_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};

export const getTopicCategory = (id, text) => async dispatch => {
  dispatch({
    type: tvAreaTypes.GET_TOPIC_CATEGORY_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/topicCategory/${id}/${text}`,
      config
    );
    dispatch({
      type: tvAreaTypes.GET_TOPIC_CATEGORY_FULLFILLED,
      payload: response.data
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: tvAreaTypes.GET_TOPIC_CATEGORY_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};

export const settvAreaContent = () => async dispatch => {
  dispatch({
    type: tvAreaTypes.SET_TVAREA_CONTENT_PENDING
  });

  try {
    const returnComponent = DynamicTvPage;
    dispatch({
      type: tvAreaTypes.SET_TVAREA_CONTENT_FULLFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: tvAreaTypes.SET_TVAREA_CONTENT_REJECTED,
      payload: error
    });
  }
};

export const getArticlesBySubCatId = (id, subCatId) => async dispatch => {
  dispatch({
    type: tvAreaTypes.GET_ARTICLES_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/article/${id}/${subCatId}`,
      config
    );
    dispatch({
      type: tvAreaTypes.GET_ARTICLES_FULLFILLED,
      payload: response.data
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: tvAreaTypes.GET_ARTICLES_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};

function returnActives(actives) {
  return actives;
}

export const setActives = actives => async dispatch => {
  dispatch({
    type: tvAreaTypes.SET_ACTIVES_PENDING
  });

  try {
    const returnComponent = returnActives(actives);
    dispatch({
      type: tvAreaTypes.SET_ACTIVES_FULFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: tvAreaTypes.SET_ACTIVES_REJECTED,
      payload: error
    });
  }
};
