import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { topAnnouncementAction } from "../../../redux/modules";
import { Link } from "react-router-dom";
import { imageShowUrl } from "../../../config/api.config";
import "./TopAnnouncement.scss";

const TopAnnouncement = props => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const topAnnouncementList = useSelector(
    store => store.topAnnouncement.topAnnouncementList
  );

  const [announcementStatus, setAnnouncementStatus] = useState(false);

  useEffect(() => {
    dispatch(
      topAnnouncementAction.getTopAnnouncementByEmployeeId(employeeFirm.id)
    );
    return () => {};
  }, []);

  useEffect(() => {
    return () => {};
  }, [topAnnouncementList]);

  useEffect(() => {
    const pathName = window.location.pathname;
    if (pathName == "/") {
      setAnnouncementStatus(true);
    }

    return () => {};
  }, []);

  return (
    <section>
      {topAnnouncementList &&
        topAnnouncementList.map((item, index) => {
          return (
            <div
              key={index}
              className={
                announcementStatus
                  ? "top-announcement d-flex justify-content-center text-center mx-auto"
                  : "top-announcement d-flex justify-content-center text-center mx-auto hide"
              }
            >
              <Link to={item.url} target={item.newTab ? "_blank" : "_self"}>
                <picture>
                  <source
                    media="(max-width:767px)"
                    srcSet={imageShowUrl + item.iconPhone}
                  />
                  <img src={imageShowUrl + item.icon} alt="" />
                </picture>
              </Link>
            </div>
          );
        })}
    </section>
  );
};

export default TopAnnouncement;
