import React, { useState, useEffect } from "react";
import ProfilePhotoUpload from "./ProfilePhotoUpload";
import { useDispatch, useSelector } from "react-redux";
import { imageShowUrl } from "../../../config/api.config";
import { profileAction } from "redux/modules";

const ProfilePhotos = props => {
  const [showPhotoUpload, setShowPhotoUpload] = useState(false);
  const [showPhotoList, setShowPhotoList] = useState(true);
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const dispatch = useDispatch();
  const employeeImageList = useSelector(
    store => store.profile.employeeImageList
  );
  const [approvedPhoto, setApprovedPhoto] = useState(null);
  const [pendingPhoto, setPendingPhoto] = useState(null);
  const [rejectedPhoto, setRejectedPhoto] = useState(null);
  const displayPhotoList = useSelector(store => store.profile.displayPhotoList);
  const [approvedProfilePhoto, setApprovedProfilePhoto] = useState(null);
  const [pendingProfilePhoto, setPendingProfilePhoto] = useState(null);
  const [rejectedProfilePhoto, setRejectedProfilePhoto] = useState(null);
  const [showApprovedPhoto, setShowApprovedPhoto] = useState(true);
  const [showPendingPhoto, setShowPendingPhoto] = useState(false);
  const [showRejectedPhoto, setShowRejectedPhoto] = useState(false);
  const displayPendingPhotoTab = useSelector(
    store => store.profile.displayPendingPhotoTab
  );

  useEffect(() => {
    dispatch(profileAction.getImagesByEmployeeFirmId(employeeFirm.id));
    return () => {};
  }, []);

  useEffect(() => {
    if (employeeImageList && employeeImageList.length > 0) {
      employeeImageList.map(function(item) {
        if (item.imageStatus === "WAITING") {
          if (item.imageType === "PROFILE") {
            setPendingProfilePhoto(item);
          }
          if (item.imageType === "SHOP") {
            setPendingPhoto(item);
          }
        } else if (item.imageStatus === "APPROVED") {
          if (item.imageType === "PROFILE") {
            setApprovedProfilePhoto(item);
          }
          if (item.imageType === "SHOP") {
            setApprovedPhoto(item);
          }
        } else if (item.imageStatus === "DENIED") {
          if (item.imageType === "PROFILE") {
            setRejectedProfilePhoto(item);
          }
          if (item.imageType === "SHOP") {
            setRejectedPhoto(item);
          }
        }
      });
    }

    return () => {};
  }, [employeeImageList]);

  useEffect(() => {
    if (displayPhotoList) {
      setShowPhotoUpload(false);
      setShowPhotoList(true);
      dispatch(profileAction.getImagesByEmployeeFirmId(employeeFirm.id));
      dispatch(profileAction.returnToPhotoList(null));
    }

    return () => {};
  }, [displayPhotoList]);

  useEffect(() => {
    if (displayPendingPhotoTab) {
      dispatch(profileAction.goToPendingPhotoTab(null));
      setShowApprovedPhoto(false);
      setShowPendingPhoto(true);
      setShowRejectedPhoto(false);
    }
    return () => {};
  }, [displayPendingPhotoTab]);

  return (
    <div
      className="tab-pane fade show active"
      id="photos"
      role="tabpanel"
      aria-labelledby="photos-tab"
    >
      {showPhotoList ? (
        <div
          className="user-content-item photos photos-list"
          style={{ display: "block" }}
        >
          <div className="user-content-text">
            <p style={{ fontSize: "14px" }}>
              Bu bölümde profil ve dükkan fotoğraflarınızı ekleyebilir veya
              güncelleyebilirsiniz.
              <br />
              <br />
              Profil fotoğrafınızın onaylanabilmesi için, lütfen yüzünüzün net
              olarak göründüğü bir fotoğraf seçin. Dükkan fotoğrafınızın
              onaylanabilmesi içinse, dükkanınızın içeriden veya dışarıdan geniş
              açı ile çekilmiş bir fotoğrafını yükleyin.
              <br />
              <br />
              Yüklediğiniz fotoğraflar için onay mesajını bir hafta içinde
              Mesajlarım bölümünde görebilirsiniz. Fotoğrafınız ile ilgili mesaj
              almadığınız takdirde “Bize Ulaşın” formunu doldurarak veya 0850
              532 5 584 (JTI) Benim Bayim Çağrı Merkezi’ni arayarak detaylı
              bilgi alabilirsiniz.
              <br />
              <br />
              Yüklenen fotoğraflar onaylanana kadar Beklemede bölümünde
              görüntülenir. Onaylanmayan fotograflar ise Reddedildi bölümünde
              görüntülenir.
              <br />
            </p>
          </div>

          <div className="photos-header">
            <h2>Fotoğraflarım</h2>

            <ul className="nav" role="tablist">
              <li className="nav-item">
                <a
                  className={showApprovedPhoto ? "active" : ""}
                  id="approved-photos-tab"
                  data-toggle="tab"
                  href="#approved-photos"
                  role="tab"
                  aria-controls="approved-photos"
                  aria-selected="true"
                  onClick={() => {
                    setShowApprovedPhoto(true);
                    setShowPendingPhoto(false);
                    setShowRejectedPhoto(false);
                  }}
                >
                  Onaylandı
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={showPendingPhoto ? "active" : ""}
                  id="pending-photos-tab"
                  data-toggle="tab"
                  href="#pending-photos"
                  role="tab"
                  aria-controls="pending-photos"
                  aria-selected="false"
                  onClick={() => {
                    setShowApprovedPhoto(false);
                    setShowPendingPhoto(true);
                    setShowRejectedPhoto(false);
                  }}
                >
                  Beklemede
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={showRejectedPhoto ? "active" : ""}
                  id="denied-photos-tab"
                  data-toggle="tab"
                  href="#denied-photos"
                  role="tab"
                  aria-controls="denied-photos"
                  aria-selected="false"
                  onClick={() => {
                    setShowApprovedPhoto(false);
                    setShowPendingPhoto(false);
                    setShowRejectedPhoto(true);
                  }}
                >
                  Reddedildi
                </a>
              </li>
            </ul>

            <div
              className="add-files"
              onClick={() => {
                setShowPhotoList(false);
                setShowPhotoUpload(true);
              }}
            >
              <span className="d-inline-block">Yeni Fotoğraf Ekle</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                className="inline-block !ml-3"
              >
                <g fill="#111111">
                  <image
                    data-name="Vector Smart Object"
                    width="28"
                    height="28"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAADFklEQVRIia2WSWxNURzGf31tVaSkBJeQsKBioa+JISSGhCJhRQyJYSMhwdawQHISLAxbNtjRxBQ2JCpszAviEsSUEEQeC6UJbdWQv/fd9vSe957SfsnNu+ec//m+d8/5T2Wc28hfUAEsABYBM4BxwCBt+QI8B24DF4FLLo5+lKIrJVgJbAK2AyOD1cJ4BxwADrs4+l7IophgPdAITPTmXgGXgUfAB80NByYB84Cxnu1jYLWLo/s9EVwGHAeqNDaR3cB14Ffa2MURLpsrA2YCO4CFWmqT6NlSgiZ2EsgAzcB64Ezw/SnBbuNsbglwDBgM/ARW+KIZz7ZeX5bRXUwvITZFTwAXR+eAaboC4zrhsrn6tGCl7qxKXzYXeBqw5dFPx3sLqA5W86IvgPnAJ3E2umyu0hfc5DnIBuBZwNKFASKxcBkarHYXXafhRGn8ESwHtmrBHOR0sPs/4eLovMWmdm9x2Vx5Rl41SpN7+krMw169jrYEklEGQZd8LTDvPa6L27A4I280XCkUZ72FiyPjvCqa6Xbx4zV4kOK2fDmkgF5/732Ey+YswL+lbNpdHH31xgn3hAovEX/wDKYCNxQupXCryFqHy+bmuDi6qfFH/Vb7gV/mvVvG7whoeg7b7yfvzquyL2wBBioRJ7gH1Cjm0rATea25OuBNYAGtLo5avfEw/X6pUJBPVtb30a6nFFpcHDWXWE+QcL/MqHgaGlLH2idQJWkQ100TvKDBGGB2XwuqbCW18qIJNgFvNbEzMO89donhvWll5FEHNdmgmthXx7lUVcOwz8VRRxIWh9UWGI5YgAa7u2BB3qo/+ilY7RKzhHJUwyfS6CxPFjOr1RbUKM3VBix5tOleZgGfg9W8WK3S2WDZr0qaKj/wreFZo7bAqscdYHnAlsfdYlnGZXPLtHe0uNb6zVRFyt5aipVqNexLT6lG7lElKZjc5fqz5HTJnbVJrFt9LdS1UaJNtKN+mGoT69QmjvFs7c7sGHvUJiawxL0Z2PYPjbC5/n7g0L82wj6sBbGuwAq11U7zvmKtfpO5flEm4DcDNdc7aF0K4wAAAABJRU5ErkJggg=="
                  ></image>
                </g>
              </svg>
            </div>
          </div>

          <div className="tab-content">
            <div
              className={
                showApprovedPhoto
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="approved-photos"
              role="tabpanel"
              aria-labelledby="approved-photos-tab"
            >
              <div className="photos-img-item-container">
                <div className="photos-img-item">
                  {approvedPhoto ? (
                    <img src={imageShowUrl + approvedPhoto.imageUrl}></img>
                  ) : null}
                </div>
                <div className="photos-img-item">
                  {approvedProfilePhoto ? (
                    <img
                      src={imageShowUrl + approvedProfilePhoto.imageUrl}
                    ></img>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              className={
                showPendingPhoto ? "tab-pane fade show active" : "tab-pane fade"
              }
              id="pending-photos"
              role="tabpanel"
              aria-labelledby="pending-photos-tab"
            >
              <div className="photos-img-item-container">
                <div className="photos-img-item">
                  {pendingPhoto ? (
                    <img src={imageShowUrl + pendingPhoto.imageUrl}></img>
                  ) : null}
                </div>
                <div className="photos-img-item">
                  {pendingProfilePhoto ? (
                    <img
                      src={imageShowUrl + pendingProfilePhoto.imageUrl}
                    ></img>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              className={
                showRejectedPhoto
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="denied-photos"
              role="tabpanel"
              aria-labelledby="denied-photos-tab"
            >
              <div className="photos-img-item-container">
                <div className="photos-img-item">
                  {rejectedPhoto ? (
                    <img src={imageShowUrl + rejectedPhoto.imageUrl}></img>
                  ) : null}
                </div>
                <div className="photos-img-item">
                  {rejectedProfilePhoto ? (
                    <img
                      src={imageShowUrl + rejectedProfilePhoto.imageUrl}
                    ></img>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {showPhotoUpload ? <ProfilePhotoUpload /> : null}
    </div>
  );
};

export default ProfilePhotos;
