import * as eOrderType from "./eorder.type";

const initialState = {
  totalParcel: 0,
  totalPackage: 0,
  totalPrice: 0,
  summaryAccess: {
    isAccess: false,
    data: null
  },
  summaryData: null
};

const reducer = (
  state = initialState,
  { type, payload, totalParcel, totalPackage, totalPrice, ...params }
) => {
  switch (type) {
    case `${eOrderType.GET_TOTAL_PARCEL}`:
      return { ...state, error: null, totalParcel: payload };
    case `${eOrderType.GET_TOTAL_PACKAGE}`:
      return { ...state, error: null, totalPackage: payload };
    case `${eOrderType.GET_TOTAL_PRICE}`:
      return { ...state, error: null, totalPrice: payload };
    case `${eOrderType.UPDATE_TOTAL_PARCEL}`:
      return { ...state, error: null, totalParcel: payload };
    case `${eOrderType.UPDATE_TOTAL_PACKAGE}`:
      return { ...state, error: null, totalPackage: payload };
    case `${eOrderType.UPDATE_TOTAL_PRICE}`:
      return { ...state, error: null, totalPrice: payload };
    case `${eOrderType.UPDATE_SUMMARY_ACCESS}`:
      return { ...state, error: null, summaryAccess: payload };
    case `${eOrderType.UPDATE_SUMMARY_DATA}`:
      return { ...state, error: null, summaryData: payload };
    default:
      return state;
  }
};

export default reducer;
