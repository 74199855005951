import React from "react";
import "./IlluminationText.scss";

const PdpPolicy = () => {
  return (
    <div className="w-full illumination-text">
      <p>
        <strong>
          <span>JTI TÜTÜN ÜRÜNLERİ PAZARLAMA A.Ş</span>
        </strong>
        <span>.&nbsp;</span>
      </p>
      <p>
        <strong>
          <span>BENİM BAYİM PLATFORMU</span>
        </strong>
      </p>
      <p>
        <strong>
          <span>KİŞİSEL </span>
        </strong>
        <strong>
          <span>VERILERIN KORUNMASI VE İŞLENMESI POLITIKASI</span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>1.GİRİŞ</span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>Veri sorumlusu olarak JTI Tütün Ürünleri Pazarlama A.Ş. (</span>
        <strong>
          <span>“JTI”, "Şirket”</span>
        </strong>
        <span> veya </span>
        <strong>
          <span>“Veri Sorumlusu”</span>
        </strong>
        <span>
          {" "}
          olarak anılacaktır.) için kullanıcıları, müşterileri, çalışanları ve
          ilişki içinde olduğu diğer gerçek kişilere ait kişisel verilerin
          korunması, büyük önem arz etmektedir. Kişisel verilerin işlenmesi ve
          korunması süreçleri için işbu{" "}
        </span>
        <strong>
          <span>
            JTI KIŞISEL VERILERIN KORUNMASI VE İŞLENMESI POLITIKASI (“
          </span>
        </strong>
        <span>
          Politika”) ile hedeflenen; Benim Bayim Platformu üzerinden JTI ile
          ilişki kuran ürün veya hizmet alıcılarımızın,&nbsp; ziyaretçilerimizin
          ve üçüncü kişilerin kişisel verilerinin hukuka uygun biçimde işlenmesi
          ve korunmasıdır. Bu kapsamda, 6698 sayılı Kişisel Verilerin Korunması
          Kanun’u (Kanun) ve ilgili mevzuat gereğince kişisel verilerin
          işlenmesi ve korunması için şirket tarafından gereken idari ve teknik
          tedbirler alınmaktadır.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>2. POLİTİKANIN AMACI VE KAPSAMI</span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          Bu Politikanın temel amacı, JTI tarafından Benim Bayim Platformu
          üzerinden hukuka uygun bir biçimde yürütülen kişisel veri işleme
          faaliyeti ve kişisel verilerin korunmasına yönelik benimsenen
          sistemler konusunda açıklamalarda bulunmak, bu kapsamda JTI’ın
          ilişkili olduğu kişilere karşı şeffaflık sağlamak ve ilgili kişileri
          kişisel verilerin işlenmesi konusunda aydınlatmaktır.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          Bu Politika kapsamı; Benim Bayim kullanıcılarının otomatik olan ya da
          herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik
          veya otomatik olmayan yollarla işlenen kişisel verilerine ilişkindir.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>3. KİŞİSEL VERİLERİN KORUNMASINA İLİŞKİN HUSUSLAR</span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>3.1 Kişisel Verilerin Korunması&nbsp;</span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          Şirketimiz, Kanunu’nun 12. maddesine uygun olarak, işlemekte olduğu
          kişisel verilerin hukuka aykırı olarak işlenmesini, verilere hukuka
          aykırı olarak erişilmesini önlemek ve verilerin muhafazasını sağlamak
          için uygun güvenlik düzeyini sağlamaya yönelik gerekli teknik ve idari
          tedbirleri almakta, bu kapsamda gerekli denetimleri yapmakta veya
          yaptırmaktadır. Bu kapsamda şirketimiz Kişisel Verileri Koruma Kurulu
          (“Kurul”) tarafından yayımlanmış olan rehberlere uygun olarak gerekli
          güvenlik düzeyini sağlamaya yönelik idari ve teknik tedbirleri
          almakta, denetimleri yapmakta veya yaptırmaktadır.&nbsp;
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>3.2 Özel Nitelikli Kişisel Verilerin Korunması&nbsp;</span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          Hassasiyet arz eden kişisel verilere, hukuka aykırı olarak
          işlendiğinde kişilerin mağduriyetine veya ayrımcılığa sebep olma riski
          nedeniyle Kanun kapsamında özel önem atfedilmiştir. Kanun’un 6.
          maddesi uyarınca “özel nitelikli” kişisel veriler; ırk, etnik köken,
          siyasi düşünce, felsefi inanç, din, mezhep veya diğer inançlar, kılık
          ve kıyafet, dernek, vakıf ya da sendika üyeliği, ceza mahkûmiyeti ve
          güvenlik tedbirleriyle ilgili veriler (“Sağlık ve cinsel hayat
          dışındaki özel nitelikli kişisel veriler”) sağlık, cinsel hayat, ile
          biyometrik ve genetik veriler (“Sağlık ve cinsel hayata ilişkin özel
          nitelikli kişisel veriler”) olarak belirlenmiştir. Şirketimiz
          tarafından, kişisel verilerin korunması için alınan teknik ve idari
          tedbirler özel nitelikli kişisel veriler bakımından Kurul’un
          31/01/2018 Tarihli ve 2018/10 Sayılı Kararı’nda öngörülen yeterli
          önlemler alınmakta ve bu doğrultuda yürütülen çalışmalar şirketimiz
          bünyesinde gerçekleştirilen denetimler çerçevesinde takip edilmekte ve
          denetlenmektedir.&nbsp;
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>
            3.3 Kişisel Verilerin Korunması Konusunda Alınan Tedbirlerin
            Denetimi ve JTI Personellerinin Eğitimi
          </span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          JTI Kanun’un 12. maddesinden kaynaklanan görevi gereği, kendi kurum
          veya kuruluşunda Kanun hükümlerinin uygulanmasını sağlamak amacıyla
          gerekli denetimleri bizzat yapmakta ve ihtiyaç halinde yetkin
          kuruluşlardan destek almak suretiyle takip etmektedir. Bu denetim
          sonuçlarına göre, tespit edilen ihlaller, olumsuzluklar ve
          uygunsuzluklar giderilmekte ve bu hususlar nezdinde gereken tedbirleri
          aldırmaktadır. Şirket tarafından dışarıdan bir hizmet alınan gerçek
          veya tüzel kişilere kişisel verilerin aktarılması durumunda, kişisel
          verilerin hukuka uygun olarak aktarıldığı ilgili firmalar ile kişisel
          verilerin aktarıldığı kişilerin, kişisel verilerin korunması amacıyla
          gerekli güvenlik tedbirlerini alacağına ve kendi kuruluşlarında bu
          tedbirlere uyulmasını sağlanacağına ilişkin hükümler içeren ek
          sözleşmeler yapılmaktadır. Bunlarla birlikte şirket içi disiplin
          politikalarında JTI personelleri ile kişisel verilerin korunması
          tedbirlerine uyulmasına yönelik sözleşmeler yapmaktadır.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          JTI, kişisel verilerin hukuka aykırı olarak işlenmesini, verilere
          hukuka aykırı olarak erişilmesini önlemeye ve verilerin muhafazasını
          sağlamaya yönelik farkındalığın artırılması için çalışanlarına,
          gerekli eğitimlerin düzenlenmesini sağlamaktadır.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>4. KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN HUSUSLAR</span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          JTI, Anayasa’nın 20. maddesine ve Kanunu’nun 4. maddesine uygun olarak
          kişisel veri işleme faaliyetinde bulunmaktadır. JTI, kanunlarda
          öngörülen veya kişisel veri işleme amacının gerektirdiği süre kadar
          kişisel verileri muhafaza etmektedir. Şirketimiz, Kanunu’nun 10.
          maddesine uygun olarak kişisel verisi işlenen ilgili kişileri
          aydınlatmakta ve rıza alınması gereken durumlarda ilgili kişilerinden
          rızalarını talep etmekte ve rızaları doğrultusunda bu kişisel verileri
          aşağıda belirtilen kriterleri esas alarak işlemektedir.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>4.1. Hukuka ve Dürüstlük Kuralına Uygun İşleme</span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          JTI, kişisel verilerin işlenmesinde yasal düzenlemelerle getirilen
          ilkeler ile güven ve dürüstlük kuralına uygun hareket etmektedir.
          Dürüstlük kuralına uygun olma ilkesi uyarınca şirket veri işlemedeki
          hedeflerine ulaşmaya çalışırken, ilgili kişilerin haklarını dikkate
          almaktadır.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>
            4.2. Kişisel Verilerin Doğru ve Gerektiğinde Güncel Olmasını Sağlama
          </span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          Kişisel verilerin doğru ve güncel bir şekilde tutulması, JTI açısından
          ilgili kişinin temel hak ve özgürlüklerinin korunması açısından
          gereklidir. Kişisel verilerin doğru ve gerektiğinde güncel olmasının
          sağlanması noktasında şirket azami özen yükümlülüğünü yerine
          getirmektedir. Bu sebeple JTI tarafından kişisel verisi işlenen ilgili
          kişilerin bilgilerinin doğru ve güncel olarak tutulması için bütün
          iletişim kanalları açıktır ve bu hususlarda gerekli tedbirleri
          almaktadır.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>4.3. Belirli, Açık ve Meşru Amaçlarla İşleme</span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          JTI, meşru ve hukuka uygun olan kişisel veri işleme amacını açık ve
          kesin olarak belirlemektedir. Şirketimiz, yürütmekte olduğu faaliyet
          ile bağlantılı ve sunduğu hizmetler için gerekli olan kadar kişisel
          veri işlemektedir.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>
            4.4. İşlendikleri Amaçla Bağlantılı, Sınırlı ve Ölçülü Olma
          </span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          JTI, kişisel verileri, ilgili ve işinin yürütülmesi için gerekli olan
          amaçlar dahilinde işlemektedir. Bu sebeple şirket, kişisel verileri
          belirlenen amaçların gerçekleştirilebilmesine elverişli bir biçimde
          işlemekte ve amacın gerçekleştirilmesiyle ilgili olmayan veya ihtiyaç
          duyulmayan kişisel verilerin işlenmesinden kaçınmaktadır.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>
            4.5. İlgili Mevzuatta Öngörülen veya İşlendikleri Amaç için Gerekli
            Olan Süre Kadar Muhafaza Etme
          </span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          JTI, kişisel verileri ancak ilgili mevzuatta belirtildiği veya şirket
          politikaları gereği işlendikleri amaç için gerekli olan süre kadar
          muhafaza etmektedir. Bu kapsamda, şirket öncelikle ilgili mevzuatta
          kişisel verilerin saklanması için bir süre öngörülüp öngörülmediğini
          tespit etmekte, bir süre belirlenmişse bu süreye uygun davranmakta,
          bir süre belirlenmemişse kişisel verileri işlendikleri amaç için
          gerekli olan ve JTI’ın saklama politikasında belirtilen süre kadar
          saklamaktadır. JTI kişisel veri envanterindeki saklama sürelerini esas
          almakta olup, burada belirtilen süreler sonunda Kanun kapsamındaki
          yükümlülükler çerçevesinde kişisel veriler, verinin niteliğine ve
          kullanım amacına göre silinmekte, yok edilmekte veya
          anonimleştirilmektedir.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>5. KİŞİSEL VERİLERİN İŞLENMESİ</span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          Kişisel verisi işlenen ilgili kişinin açık rıza vermesi, kişisel
          verilerin hukuka uygun olarak işlenmesini mümkün kılan hukuki
          dayanaklardan yalnızca biridir. Açık rıza dışında, kanunda belirtilen
          şartlardan birinin varlığı durumunda da kişisel veriler işlenebilir.
          Kişisel veri işleme faaliyetinin dayanağı aşağıda belirtilen
          şartlardan yalnızca biri olabildiği gibi bu şartlardan birden fazlası
          da aynı kişisel veri işleme faaliyetinin dayanağı olabilir. İşlenen
          verilerin özel nitelikli kişisel veri olması halinde, işbu
          Politika’nın 5.2 başlığı (“Özel Nitelikli Kişisel Verilerin
          İşlenmesi”) içerisinde yer alan şartlar uygulanacaktır.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>5.1 Kişisel Verilerin İşlenme Şartları&nbsp;</span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>a) İlgili kişinin Açık Rızasının Bulunması&nbsp;</span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          Kişisel verilerin işlenme şartlarından biri ilgili kişinin açık
          rızasıdır. Kişisel ilgili kişinin açık rızası belirli bir konuya
          ilişkin, bilgilendirilmeye dayalı olarak ve özgür iradeyle
          açıklanmalıdır. Aşağıda sayılan kişisel veri işleme şartlarının
          varlığı durumunda ilgili kişinin açık rızasına gerek kalmaksızın
          kişisel veriler işlenebilmektedir.&nbsp;
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>b) Kanunlarda Açıkça Öngörülmesi</span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          &nbsp;İlgili kişinin kişisel verileri, kanunda açıkça öngörülmekte ise
          diğer bir ifade ile ilgili kanunda kişisel verilerin işlenmesine
          ilişkin açıkça bir hüküm olması halinde işbu veri işleme şartının
          varlığından söz edilebilecektir.&nbsp;
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>
            c) Fiili İmkânsızlık Sebebiyle İlgilinin Açık Rızasının
            Alınamaması&nbsp;
          </span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda olan veya
          rızasına geçerlilik tanınamayacak olan kişinin kendisinin ya da başka
          bir kişinin hayatı veya beden bütünlüğünü korumak için kişisel
          verisinin işlenmesinin zorunlu olması halinde ilgili kişinin kişisel
          verileri işlenebilecektir.&nbsp;
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>
            d) Sözleşmenin Kurulması veya İfasıyla Doğrudan İlgi Olması
          </span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          &nbsp;İlgili kişinin taraf olduğu bir sözleşmenin kurulması veya
          ifasıyla doğrudan doğruya ilgili olması kaydıyla, kişisel verilerin
          işlenmesinin gerekli olması halinde işbu şart yerine getirilmiş
          sayılabilecektir.&nbsp;
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>e) JTI’ın Hukuki Yükümlülüğünü Yerine Getirmesi</span>
        </strong>
        <span>&nbsp;</span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          JTI’ın hukuki yükümlülüklerini yerine getirmesi için işlemenin zorunlu
          olması halinde ilgili kişinin kişisel verileri işlenebilecektir.&nbsp;
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>
            f) Kişisel İlgili kişinin Kişisel Verisini Alenileştirmesi
          </span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          &nbsp;İlgili kişinin, kişisel verisini alenileştirmiş olması halinde
          ilgili kişisel veriler alenileştirme amacıyla sınırlı olarak
          işlenebilecektir.&nbsp;
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>
            g) Bir Hakkın Tesisi veya Korunması için Veri İşlemenin Zorunlu
            Olması&nbsp;
          </span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin
          zorunlu olması halinde ilgili kişinin kişisel verileri
          işlenebilecektir.&nbsp;
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>
            h) JTI’ın Meşru Menfaati için Veri İşlemenin Zorunlu Olması&nbsp;
          </span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          Kişisel ilgili kişinin temel hak ve özgürlüklerine zarar vermemek
          kaydıyla Şirketimizin meşru menfaatleri için veri işlemesinin zorunlu
          olması halinde ilgili kişinin kişisel verileri işlenebilecektir.&nbsp;
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>6. KİŞİSEL VERİLERİN İŞLENME AMAÇLARI</span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          JTI, KVK Kanunu’nun 5. maddesinin 2. fıkrasında ve 6. maddenin 3.
          fıkrasında belirtilen kişisel veri işleme şartları içerisindeki
          amaçlarla sınırlı olarak kişisel verileri işlemektedir. Bu konu ile
          ilgili ayrıntılı bilgiye işbu Politika’nın EK-2 (“
        </span>
        <em>
          <span>EK 2- JTI Tarafından İşlenen Kişisel Veriler ve Amaçları</span>
        </em>
        <span>
          ”) dokümanından ulaşılması mümkündür. Bununla beraber işlenen kişisel
          verilere ilişkin genel amaçlardan kayıtlı olunan Veri Sorumluları
          Sicili’nde de bahsedilmiştir.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>7. İLGİLİ KİŞİNİN AYDINLATILMASI VE BİLGİLENDİRİLMESİ</span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          JTI, KVK Kanunu’nun 10. maddesine uygun olarak, kişisel verilerin elde
          edilmesi sırasında kişisel verisi işlenen ilgili kişileri
          aydınlatmaktadır. Bu kapsamda JTI, veri sorumlusunun kimliği, varsa
          temsilcisinin kimliği, kişisel verilerin hangi amaçla işleneceği,
          işlenen kişisel verilerin kimlere ve hangi amaçla aktarılabileceği,
          kişisel veri toplamanın yöntemi ve hukuki sebebi ile kişisel verisi
          işlenen ilgili kişilerin sahip olduğu haklar konusunda ilgili kişinin
          niteliğine ve veri işleme sürecine göre aydınlatma yapmaktadır. Bu
          kapsamda şirket içerisinde ve ortak kullanım alanlarında kolayca
          görebilecekleri ön aydınlatma bilgilendirmeleri ve aydınlatma
          metinleri yerleştirilmiştir. JTI Benim Bayim web sitesinde bu politika
          ile birlikte süreç ve kişi bazlı aydınlatma metni, çerez politikası,
          başvuru formu da yayınlanmıştır.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>8. KİŞİSEL VERİLERİN AKTARILMASI</span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          JTI, hukuka uygun olan kişisel veri işleme amaçları doğrultusunda,
          gerekli güvenlik önlemlerini alarak, ilgili kişinin kişisel verilerini
          üçüncü kişilere aktarabilmektedir. Bu kapsamda;
        </span>
      </p>
      <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
        <li>
          <p
            style={{
              lineHeight: "1.2",
              marginRight: "14.15pt",
              textAlign: "justify",
              marginTop: "14pt",
              marginBottom: "0pt"
            }}
          >
            <span>
              Kanunlarda kişisel verinin aktarılacağına ilişkin açık bir
              düzenleme var ise,
            </span>
          </p>
        </li>
      </ul>
      <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
        <li>
          <p>
            <span>
              Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
              olmak kaydıyla sözleşmenin taraflarına ait kişisel verinin
              aktarılması gerekli ise,
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Şirket’in hukuki yükümlülüğünü yerine getirmesi için kişisel veri
              aktarımı zorunlu ise,
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Kişisel veri aktarımı bir hakkın tesisi, kullanılması veya
              korunması için zorunlu ise,
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
              kaydıyla, JTI’ın meşru menfaatleri için kişisel veri aktarımı
              zorunlu ise
            </span>
          </p>
        </li>
      </ul>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "7.5pt",
          marginBottom: "7.5pt"
        }}
      >
        <span>aktarılmaktadır.</span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>
            9. JTI TARAFINDAN KİŞİSEL VERİLERİN AKTARILDIĞI ÜÇÜNCÜ KİŞİLER VE
            AKTARILMA AMAÇLARI
          </span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          JTI hukuka uygun olan kişisel veri işleme amaçları doğrultusunda
          gerekli güvenlik önlemlerini alarak ilgili kişinin kişisel verilerini
          ve özel nitelikli kişisel verilerini üçüncü kişilere
          aktarabilmektedir. Şirketimiz bu doğrultuda Kanun’un 8. ve 9.
          maddesinde öngörülen düzenlemelere uygun hareket etmektedir. Bu konu
          ile ilgili ayrıntılı bilgiye işbu Politika’nın EK-3{" "}
        </span>
        <em>
          <span>
            (“EK 3- JTI Tarafından Kişisel Verilerin Aktarıldığı Üçüncü Kişiler
            ve Aktarılma Amaçları”)
          </span>
        </em>
        <span> dokümanından ulaşılması mümkündür.</span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>
            10. JTI BENİM BAYİM İÇERİSİNDE İŞLENEN KİŞİSEL VERİLERİN
            SINIFLANDIRILMASI
          </span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          JTI nezdinde, JTI’ın meşru ve hukuka uygun kişisel veri işleme
          amaçları doğrultusunda, Kanunu’nun 5. maddesinde ve 6. maddesinde
          belirtilen kişisel veri işleme şartlarından bir veya birkaçına dayalı
          ve bunlarla sınırlı olarak, Kanun’da belirtilen genel ilkelere ve
          Kanun’da düzenlenen tüm yükümlülüklere uyularak aşağıda belirtilen
          kategorilerdeki kişisel veriler, ilgili kişiler aydınlatılmak
          suretiyle işlenmektedir.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          JTI, Kişisel Verileri Koruma Kurumu tarafından yürürlüğe sokulan Veri
          Sorumluları Sicili Yönetmeliği uyarınca kişisel veri envanteri
          oluşturmuştur. Bu veri envanterinde veri kategorileri, verinin
          kaynağı, veri işleme amaçları, veri işleme süreci, verilerin
          aktarıldığı alıcı grupları ve saklama süreleri yer almaktadır. Bu
          kapsamda JTI içerisinde aşağıdaki veri kategorilerinde yer alan
          kişisel veriler bulunmaktadır.
        </span>
      </p>
      <div align="left">
        <table style={{ border: "none", tableLayout: "fixed" }}>
          <tbody>
            <tr style={{ height: "22.45pt" }}>
              <td style={{ border: "0.75pt solid #000000" }}>
                <p>
                  <strong>
                    <span>KİŞİSEL VERİ KATEGORİZASYONU</span>
                  </strong>
                </p>
              </td>
              <td style={{ border: "0.75pt solid #000000" }}>
                <p
                  style={{
                    lineHeight: "1.2",
                    marginRight: "14.15pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt"
                  }}
                >
                  <strong>
                    <span>KİŞİSEL VERİ KATEGORİZASYONU AÇIKLAMA</span>
                  </strong>
                </p>
              </td>
            </tr>
            <tr style={{ height: "22.45pt" }}>
              <td style={{ border: "0.75pt solid #000000" }}>
                <p
                  style={{
                    lineHeight: "1.2",
                    marginRight: "14.15pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt"
                  }}
                >
                  <strong>
                    <span>Kimlik Verisi</span>
                  </strong>
                </p>
              </td>
              <td style={{ border: "0.75pt solid #000000" }}>
                <p
                  style={{
                    lineHeight: "1.2",
                    marginRight: "14.15pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt"
                  }}
                >
                  <span>
                    Kişi kimliğine dair bilgilerin bulunduğu veri grubudur.
                  </span>
                </p>
                <p
                  style={{
                    lineHeight: "1.2",
                    marginRight: "14.15pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt"
                  }}
                >
                  <em>
                    <span>
                      Örn. ad-soyad, T.C. kimlik numarası, doğum yeri, doğum
                      tarihi, cinsiyet, vergi numarası gibi bilgiler
                    </span>
                  </em>
                </p>
              </td>
            </tr>
            <tr style={{ height: "22.9pt" }}>
              <td style={{ border: "0.75pt solid #000000" }}>
                <p
                  style={{
                    lineHeight: "1.2",
                    marginRight: "14.15pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt"
                  }}
                >
                  <strong>
                    <span>İletişim Verisi</span>
                  </strong>
                </p>
              </td>
              <td style={{ border: "0.75pt solid #000000" }}>
                <p
                  style={{
                    lineHeight: "1.2",
                    marginRight: "14.15pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt"
                  }}
                >
                  <span>
                    Kişiye ulaşmak için kullanılabilecek veri grubudur.
                  </span>
                  <em>
                    <span>
                      {" "}
                      Örn. telefon numarası, adres, e-mail adresi, faks numarası
                      gibi bilgiler
                    </span>
                  </em>
                </p>
              </td>
            </tr>
            <tr style={{ height: "33.9pt" }}>
              <td style={{ border: "0.75pt solid #000000" }}>
                <p
                  style={{
                    lineHeight: "1.2",
                    marginRight: "14.15pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt"
                  }}
                >
                  <strong>
                    <span>Görsel ve İşitsel Veri</span>
                  </strong>
                </p>
              </td>
              <td style={{ border: "0.75pt solid #000000" }}>
                <p
                  style={{
                    lineHeight: "1.2",
                    marginRight: "14.15pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt"
                  }}
                >
                  <span>
                    Kişiye ait görsel ve işitsel verilerin bulunduğu veri
                    grubudur.{" "}
                  </span>
                  <em>
                    <span>
                      Örn. bir gerçek kişiye ait olduğu açık olan; fotoğraf ve
                      kamera kayıtları, kişisel veri içeren belgelerin kopyası
                      gibi belgelerde yer alan veriler
                    </span>
                  </em>
                </p>
              </td>
            </tr>
            <tr style={{ height: "22.9pt" }}>
              <td style={{ border: "0.75pt solid #000000" }}>
                <p
                  style={{
                    lineHeight: "1.2",
                    marginRight: "14.15pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt"
                  }}
                >
                  <strong>
                    <span>İşlem Güvenliği Verisi</span>
                  </strong>
                </p>
              </td>
              <td style={{ border: "0.75pt solid #000000" }}>
                <p
                  style={{
                    lineHeight: "1.2",
                    marginRight: "14.15pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt"
                  }}
                >
                  <span>
                    Kişiye ait IP bilgisi, log kaydı ve çerezler, kullanıcı adı
                    ve parola gibi işlem güvenliği verilerinin bulunduğu veri
                    grubudur.&nbsp;
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: "22.9pt" }}>
              <td style={{ border: "0.75pt solid #000000" }}>
                <p
                  style={{
                    lineHeight: "1.2",
                    marginRight: "14.15pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt"
                  }}
                >
                  <strong>
                    <span>Pazarlama Verisi</span>
                  </strong>
                </p>
              </td>
              <td style={{ border: "0.75pt solid #000000" }}>
                <p
                  style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt"
                  }}
                >
                  <span>
                    Kişiye ait bilgilerin işlenmesi sonucu oluşan dijital
                    izlerin bulunduğu pazarlama ve satış amaçlı kullanılan veri
                    grubudur. (Örn. İnternet sitesi gezinme bilgileri,
                    pazarlamaya yönelik çerez bilgileri, hedef ve analiz
                    bilgileri)
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>11. KİŞİSEL VERİLERİN SAKLANMA SÜRELERİ</span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          JTI, ilgili kanunlarda ve mevzuatlarda öngörülen durumlarda kişisel
          verileri bu mevzuatlarda belirtilen süre boyunca saklamaktadır.
          Kişisel verilerin ne kadar süre boyunca saklanması gerektiğine ilişkin
          mevzuatta bir süre düzenlenmemişse, kişisel veriler JTI’ın o veriyi
          işlerken yürüttüğü faaliyet ile bağlı olarak, JTI’ın uygulamaları ve
          sektörün teamülleri uyarınca saklanmasını gerektiren süre kadar
          saklamaktadır
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          Türk Ceza Kanunu’nun 138. maddesinde, KVK Kanunu’nun 7. maddesinde ve
          Kişisel Verileri Koruma Kurumu tarafından yürürlüğe konulan “Kişisel
          Verilerin Silinmesi, Yok Edilmesi ve Anonimleştirilmesi Hakkında
          Yönetmelik” uyarınca, ilgili kanun hükümlerine uygun olarak işlenen
          kişisel veriler, işlenmesini gerektiren sebeplerin ortadan kalkması
          hâlinde JTI’ın politikalarına istinaden veya ilgili kişinin talebi
          üzerine kişisel veriler silinir, yok edilir veya anonim hâle
          getirilir. JTI bu konuda yönetmelik hükümlerine göre bir politika
          oluşturmuş olup, bu politika uyarınca hareket etmektedir. Ayrıca
          saklama sürelerine ilişkin genel bilgiler Veri Sorumluları Sicil
          kaydında da belirtilmiştir.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>12. İLGİLİ KİŞİLERİN HAKLARI; BU HAKLARIN KULLANILMASI</span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          JTI, KVK Kanunu’nun 10. maddesine uygun olarak kişisel ilgili kişinin
          haklarını kendisine bildirmekte ve 11. maddede düzenlenen bu hakların
          nasıl kullanılacağı konusunda kişisel verisi işlenen ilgili kişiye yol
          göstermektedir ve JTI ilgili kişilerin haklarının değerlendirilmesi ve
          ilgili kişilere gereken bilgilendirmenin yapılması için KVK Kanunu’nun
          13. maddesine uygun olarak gerekli kanalları, iç işleyişi, idari ve
          teknik düzenlemeleri yürütmektedir.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>12.1 Kişisel verisi işlenen ilgili kişilerin hakları</span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          Kişisel verisi işlenen ilgili kişiler aşağıda yer alan haklara
          sahiptirler:
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>a.&nbsp;Kişisel veri işlenip işlenmediğini öğrenme,</span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          b.&nbsp;Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          c.&nbsp;Kişisel verilerin işlenme amacını ve bunların amacına uygun
          kullanılıp kullanılmadığını öğrenme,
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          d.&nbsp;Yurtiçinde veya yurtdışında kişisel verilerin aktarıldığı
          üçüncü kişileri bilme,
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          e.&nbsp;Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
          bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel
          verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          f.&nbsp;KVK Kanunu ve ilgili diğer kanun hükümlerine uygun olarak
          işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan
          kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini
          isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı
          üçüncü kişilere bildirilmesini isteme,
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          g.&nbsp;İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
          analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya
          çıkması halinde bu sonuca itiraz etme,
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          h.&nbsp;Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
          zarara uğraması hâlinde zararın giderilmesini talep etme.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>
            12.2. Kişisel Verisi İşlenen İlgili Kişinin Haklarını İleri
            Süremeyeceği Haller
          </span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          Kişisel verisi işlenen ilgili kişiler, KVK Kanunu’nun 28. maddesi
          gereğince aşağıdaki haller KVK Kanunu kapsamı dışında tutulduğundan,
          bu konularda 12.1.’de sayılan haklarını ileri süremezler:
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          a.&nbsp;Kişisel verilerin resmi istatistik ile anonim hâle getirilmek
          suretiyle araştırma, planlama ve istatistik gibi amaçlarla işlenmesi,
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          b.&nbsp;Kişisel verilerin millî savunmayı, millî güvenliği, kamu
          güvenliğini, kamu düzenini, ekonomik güvenliği, özel hayatın
          gizliliğini veya kişilik haklarını ihlal etmemek ya da suç teşkil
          etmemek kaydıyla, sanat, tarih, edebiyat veya bilimsel amaçlarla ya da
          ifade özgürlüğü kapsamında işlenmesi,
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          c.&nbsp;Kişisel verilerin millî savunmayı, millî güvenliği, kamu
          güvenliğini, kamu düzenini veya ekonomik güvenliği sağlamaya yönelik
          olarak kanunla görev ve yetki verilmiş kamu kurum ve kuruluşları
          tarafından yürütülen önleyici, koruyucu ve istihbaratı faaliyetler
          kapsamında işlenmesi,
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          d.&nbsp;Kişisel verilerin soruşturma, kovuşturma, yargılama veya infaz
          işlemlerine ilişkin olarak yargı makamları veya infaz mercileri
          tarafından işlenmesi.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          KVK Kanunu’nun 28/2 maddesi gereğince; aşağıda sıralanan hallerde
          kişisel verisi işlenen ilgili kişiler zararın giderilmesini talep etme
          hakkı hariç, 12.1.’de sayılan diğer haklarını ileri süremezler:
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          a.&nbsp;Kişisel veri işlemenin suç işlenmesinin önlenmesi veya suç
          soruşturması için gerekli olması,
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          b.&nbsp;Kişisel verisi işlenen ilgili kişinin kendisi tarafından
          alenileştirilmiş kişisel verilerin işlenmesi,
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          c.&nbsp;Kişisel veri işlemenin kanunun verdiği yetkiye dayanılarak
          görevli ve yetkili kamu kurum ve kuruluşları ile kamu kurumu
          niteliğindeki meslek kuruluşlarınca, denetleme veya düzenleme
          görevlerinin yürütülmesi ile disiplin soruşturma veya kovuşturması
          için gerekli olması,
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          d.&nbsp;Kişisel veri işlemenin bütçe, vergi ve mali konulara ilişkin
          olarak Devletin ekonomik ve mali çıkarlarının korunması için gerekli
          olması.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>12.3. Kişisel İlgili kişinin Haklarını Kullanması</span>
        </strong>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          Kişisel verisi işlenen ilgili kişiler bu Politikada belirtilen
          haklarına ilişkin taleplerini kimliklerini tespit edecek bilgi ve
          belgelerle ve aşağıda belirtilen yöntemlerle veya Kişisel Verileri
          Koruma Kurulu’nun belirlediği diğer yöntemlerle başvuru formunu
          doldurup imzalayarak JTI’a ücretsiz olarak iletebileceklerdir. Aynı
          şekilde bilgilendirme aydınlatma metinleri içerisinde de
          yapılmıştır.&nbsp;
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>İlgili kişi,</span>
      </p>
      <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
        <li>
          <p>
            <span>
              İnternet adresinde bulunan formun doldurulduktan sonra ıslak
              imzalı bir nüshasının bizzat elden veya yazılı olarak iadeli
              taahhütlü posta aracılığı ile{" "}
            </span>
            <em>
              <span>“Torbalı Mahallesi 2561 Sokak No: 12 Torbalı İzmir” </span>
            </em>
            <span>adresine iletilmesi veya,</span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Şahsen başvuru formun doldurulması ve 5070 Sayılı Elektronik İmza
              Kanunu kapsamındaki “güvenli elektronik imza” ile imzalandıktan
              sonra güvenli elektronik imzalı formun
            </span>
            <span
              style={{ fontSize: "11pt", fontFamily: "Calibri, sans-serif" }}
            >
              &nbsp;{" "}
            </span>
            <a
              style={{ textDecoration: "none" }}
              href="mailto:jtitutunurunleripazarlama@hs03.kep.tr"
            >
              <span
                style={{
                  fontSize: "12pt",
                  color: "#0000ff",
                  backgroundColor: "#ffffff",
                  textDecoration: "underline",
                  textDecorationSkipInk: "none"
                }}
              >
                jtitutunurunleripazarlama@hs03.kep.tr
              </span>
            </a>
            <span
              style={{
                fontSize: "12pt",
                color: "#0000ff",
                backgroundColor: "#ffffff",
                textDecoration: "underline",
                textDecorationSkipInk: "none"
              }}
            >
              {" "}
            </span>
            <span>
              adresine kayıtlı elektronik posta ile gönderilmesi, güvenli
              elektronik imza, mobil imza ya da ilgili kişi tarafından JTI’a
              daha önce bildirilen ve JTI’ın sisteminde kayıtlı bulunan
              elektronik posta adresini kullanmak suretiyle{" "}
            </span>
            <a style={{ textDecoration: "none" }} href="mailto:kvk@jti.com">
              <span
                style={{
                  fontSize: "11pt",
                  color: "#0000ff",
                  backgroundColor: "#ffffff",
                  textDecoration: "underline",
                  textDecorationSkipInk: "none"
                }}
              >
                kvk@jti.com
              </span>
            </a>
            <span>
              {" "}
              adresine başvuru yapılması yolları ile haklarını
              kullanabilir.&nbsp;
            </span>
          </p>
        </li>
      </ul>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "7.5pt",
          marginBottom: "7.5pt"
        }}
      >
        <span>
          Yukarıda sayılan başvurunun geçerli bir başvuru olarak kabul
          edilebilmesi için, Veri Sorumlusuna Başvuru Usulleri Hakkında Tebliğ
          uyarınca başvuruda, ilgili kişinin;
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>a) Ad, soyad ve başvuru yazılı ise imza,</span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          b) Türkiye Cumhuriyeti vatandaşları için T.C. kimlik numarası,
          yabancılar için uyruğu, pasaport numarası veya varsa kimlik numarası,
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>c) Tebligata esas yerleşim yeri veya iş yeri adresi,</span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          ç) Varsa bildirime esas elektronik posta adresi, telefon ve faks
          numarası,
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>d) Talep konusu,</span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          bilgilerini belirtmesi zorunludur. Aksi halde başvuru geçerli bir
          başvuru olarak değerlendirilmeyecektir. Başvuru formu doldurmadan
          yapılacak başvurularda burada sayılan hususların eksiksiz olarak JTI’a
          iletilmesi gerekmektedir.
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          marginTop: "14pt",
          marginBottom: "14pt"
        }}
      >
        <span>
          Kişisel verisi işlenen ilgili kişilerin adına üçüncü kişilerin başvuru
          talebinde bulunabilmesi için ilgili kişi tarafından başvuruda
          bulunacak kişi adına noter kanalıyla düzenlenmiş
          özel&nbsp;vekâletname&nbsp;bulunmalıdır.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          <span>Veri Sorumlusu: </span>
        </strong>
        <span>JTI TÜTÜN ÜRÜNLERİ PAZARLAMA A.Ş.</span>
      </p>
      <p>
        <strong>
          <span>Adres: </span>
        </strong>
        <span>Torbalı Mahallesi 2561 Sokak No: 12 Torbalı İzmir</span>
      </p>
      <p>
        <span>
          Aşağıda politikanın incelenmesinde yardımcı olunması için tanımlar ve
          kişisel verilerin işlenme amaçları ve aktarılma amaçları tablosu
          sunulmuştur.
        </span>
      </p>
      <p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </p>
      <p>
        <strong>
          <span>EK 1-JTI TARAFINDAN İŞLENEN KİŞİSEL VERİLER VE AMAÇLARI</span>
        </strong>
      </p>
      <p>
        <strong>
          <span>
            1) Benim Bayim Platformuna Yönelik Üyelik İşlemlerinin
            Tamamlanması&nbsp;
          </span>
        </strong>
      </p>
      <p>&nbsp;</p>
      <div align="left">
        <table style={{ border: "none", tableLayout: "fixed" }}>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>
                    <span>İşlenen Kişisel Veriler&nbsp;</span>
                  </strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>
                    <span>İşleme Amaçları</span>
                  </strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>
                    <span>Hukuki Sebep</span>
                  </strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <br />
                <p>
                  <strong>
                    <span>Kimlik:&nbsp;</span>
                  </strong>
                  <span>
                    Kişi kimliğine dair bilgilerin bulunduğu veri
                    grubudur.&nbsp;&nbsp;(Örn. Ad,&nbsp;Soyad, Doğum Tarihi
                    /&nbsp;Doğum Yeri, TC&nbsp;Kimlik Numarası)
                  </span>
                </p>
                <br />
                <p>
                  <strong>
                    <span>İletişim:&nbsp;</span>
                  </strong>
                  <span>
                    Kişiye ulaşmak için kullanılabilecek veri
                    grubudur.&nbsp;&nbsp;(Örn. Telefon numarası, Adresi, E-posta
                    Adresi)&nbsp;&nbsp;
                  </span>
                </p>
                <br />
                <p>
                  <strong>
                    <span>İşlem Güvenliği:&nbsp;</span>
                  </strong>
                  <span>
                    Bu veri kategorisi IP adresi bilgileri, İnternet sitesi
                    giriş çıkış bilgileri, Şifre ve parola bilgileri&nbsp;gibi
                    veri türlerini ifade etmektedir.&nbsp;(Örn. Güvenlik
                    anahtarları, Kullanıcı adı, Kullanıcı ID, Şifre,
                    Log&nbsp;kayıtları,&nbsp;IP&nbsp;Adresleri)&nbsp;&nbsp;
                  </span>
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      <span>
                        Şirketimizin bir alt girişimi olan jtibenimbayim.com
                        sitesine üye olunması durumunda, sözleşme ilişkisi
                        kapsamında, kullanıcı profilinin oluşturulabilmesi ve
                        etkin bir şekilde Platform hizmetlerimizden
                        yararlanabilmesi amacıyla şirketimiz tarafından
                        yönetilen web sitemize üye olan kullanıcıların bilgileri
                        işlenmektedir.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Mobil uygulamaya üye olunması durumunda, sözleşme
                        ilişkisi kapsamında, kullanıcı profilinin
                        oluşturulabilmesi amacıyla şirketimiz tarafından
                        yönetilen mobil uygulamaya üye olan kullanıcıların
                        bilgileri işlenmektedir.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Firma işlerinin yürütüldüğü veya firmaya bağlı merkez ve
                        birimlerinde bulunan&nbsp;kişilerce iş faaliyetlerin
                        yürütülmesi amacı ile kişisel verileriniz
                        işlenmektedir.&nbsp;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Kanunlardan ve ikincil düzenlemelerden doğan/doğabilecek
                        yasal ve düzenleyici gereksinimlerin yerine getirilmesi
                        ve bu kapsamda gerekli tedbirlerin alınabilmesi amacı
                        ile kişisel verileriniz işlenmektedir.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Platform üyelik işlemlerinizi gerçekleştirmek, Kullanıcı
                        bilgilerinizi tarafınıza gönderebilmek, Benim Bayim
                        platformunun kullanımı uyarınca yükümlülüklerimizi
                        yerine getirmek, kampanyalarından faydalanmanızı
                        sağlamak, Üyelik bilgilendirmeleri yapabilmek,
                        tarafınızca kazanılan puanları kullanmanızı ve
                        sorgulayabilmenizi sağlamak amaçlarıyla kişisel
                        verileriniz işlenmektedir.&nbsp;
                      </span>
                    </p>
                  </li>
                </ul>
                <p
                  style={{
                    lineHeight: "1.295",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "8pt",
                    paddingLeft: 48
                  }}
                >
                  &nbsp;
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p
                      style={{
                        lineHeight: "1.295",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span>
                        Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
                        ilgili olması kaydıyla, sözleşmenin taraflarına ait
                        kişisel verilerin işlenmesinin gerekli olması,
                      </span>
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        lineHeight: "1.295",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span>
                        Bir hakkın tesisi, kullanılması veya korunması için veri
                        işlemenin zorunlu olması,
                      </span>
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        lineHeight: "1.295",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "8pt"
                      }}
                    >
                      <span>
                        İlgili kişinin temel hak ve özgürlüklerine zarar
                        vermemek kaydıyla, veri sorumlusunun meşru menfaatleri
                        için veri işlenmesinin zorunlu olması,&nbsp;
                      </span>
                    </p>
                  </li>
                </ul>
                <br />
                <p>
                  <span>
                    hukuki sebeplerine dayanılarak kişisel verileriniz
                    işlenmektedir.&nbsp;
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>&nbsp;</p>
      <p>
        <strong>
          <span>
            2) Üyelik İşlemlerinin Tamamlanmasından Sonra Kullanıcı Hesap
            Bilgilerinin Tamamlanması
          </span>
        </strong>
      </p>
      <p>&nbsp;</p>
      <div align="left">
        <table style={{ border: "none", tableLayout: "fixed" }}>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>
                    <span>İşlenen Kişisel Veriler&nbsp;</span>
                  </strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>
                    <span>İşleme Amaçları</span>
                  </strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>
                    <span>Hukuki Sebep</span>
                  </strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <br />
                <p>
                  <strong>
                    <span>Kimlik:&nbsp;</span>
                  </strong>
                  <span>
                    Kişi kimliğine dair bilgilerin bulunduğu veri
                    grubudur.&nbsp;&nbsp;(Örn. Ad,&nbsp;Soyad, Doğum Tarihi
                    /&nbsp;Doğum Yeri)
                  </span>
                </p>
                <br />
                <p>
                  <strong>
                    <span>İletişim:&nbsp;</span>
                  </strong>
                  <span>
                    Kişiye ulaşmak için kullanılabilecek veri
                    grubudur.&nbsp;&nbsp;(Örn. Telefon numarası, Adresi, E-posta
                    Adresi)&nbsp;&nbsp;
                  </span>
                </p>
                <br />
                <p>
                  <strong>
                    <span>İşlem Güvenliği:&nbsp;</span>
                  </strong>
                  <span>
                    Bu veri kategorisi IP adresi bilgileri, İnternet sitesi
                    giriş çıkış bilgileri, Şifre ve parola bilgileri&nbsp;gibi
                    veri türlerini ifade etmektedir.&nbsp;(Örn. Güvenlik
                    anahtarları, Kullanıcı adı, Kullanıcı ID, Şifre,
                    Log&nbsp;kayıtları,&nbsp;IP&nbsp;Adresleri)&nbsp;&nbsp;
                  </span>
                </p>
                <br />
                <br />
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      <span>
                        Şirketimizin bir alt girişimi olan jtibenimbayim.com
                        sitesine üye olunması durumunda, kullanıcı profilinin
                        oluşturulabilmesi ve etkin bir şekilde Platform
                        hizmetlerimizden yararlanabilmesi amacıyla kullanıcı
                        bilgileri işlenmektedir.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Mobil uygulamaya üye olunması durumunda, kullanıcı
                        profilinin oluşturulabilmesi amacıyla kullanıcı
                        bilgileri işlenmektedir.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Kanunlardan ve ikincil düzenlemelerden doğan/doğabilecek
                        yasal ve düzenleyici gereksinimlerin yerine getirilmesi
                        ve bu kapsamda gerekli tedbirlerin alınabilmesi amacı
                        ile bilgileriniz işlenmektedir.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        lineHeight: "1.295",
                        textAlign: "justify",
                        backgroundColor: "#ffffff",
                        marginTop: "0pt",
                        marginBottom: "8pt"
                      }}
                    >
                      <span>
                        Tarafınızca kazanılan puanları kullanmanızı sağlamak
                        amacı ile bilgileriniz işlenmektedir
                      </span>
                    </p>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <p
                      style={{
                        lineHeight: "1.295",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span>
                        Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
                        ilgili olması kaydıyla, sözleşmenin taraflarına ait
                        kişisel verilerin işlenmesinin gerekli olması,
                      </span>
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        lineHeight: "1.295",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span>
                        Bir hakkın tesisi, kullanılması veya korunması için veri
                        işlemenin zorunlu olması,
                      </span>
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        lineHeight: "1.295",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span>
                        İlgili kişinin temel hak ve özgürlüklerine zarar
                        vermemek kaydıyla, veri sorumlusunun meşru menfaatleri
                        için veri işlenmesinin zorunlu olması,&nbsp;
                      </span>
                    </p>
                  </li>
                </ul>
                <br />
                <p>
                  <span>
                    hukuki sebeplerine dayanılarak kişisel verileriniz
                    işlenmektedir.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>
                    <span>Görsel&nbsp;ve&nbsp;İşitsel Kayıtlar:&nbsp;</span>
                  </strong>
                  <span>
                    Kişiye ait görsel ve işitsel verilerin bulunduğu veri
                    grubudur.&nbsp;&nbsp;(Örn. Fotoğraf)&nbsp;&nbsp;
                  </span>
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      <span>
                        Kullanıcı profilinin oluşturulabilmesi amacı ile
                        bilgileriniz işlenmektedir
                      </span>
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        lineHeight: "1.28",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "8pt"
                      }}
                    >
                      <span>
                        Benim Bayim Platformunda yer alan bölümlerin
                        görünürlüğünü ve&nbsp; kullanılmasını sağlamak amacı ile
                        bilgileriniz işlenmektedir
                      </span>
                    </p>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <p
                      style={{
                        lineHeight: "1.295",
                        marginTop: "0pt",
                        marginBottom: "8pt"
                      }}
                    >
                      <span>İlgili kişinin açık rızasını sunması&nbsp;</span>
                    </p>
                  </li>
                </ul>
                <br />
                <p
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt"
                  }}
                >
                  <span>
                    hukuki sebebine dayanılarak kişisel verileriniz
                    işlenmektedir.
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>&nbsp;</p>
      <p>
        <strong>
          <span>
            3) Benim Bayim Sistemine Yönelik Görüş Öneri ve İstek Hizmetlerinin
            Yürütülmesi
          </span>
        </strong>
      </p>
      <p>
        <br />
        <br />
      </p>
      <div align="left">
        <table style={{ border: "none", tableLayout: "fixed" }}>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>
                    <span>Kimlik:&nbsp;</span>
                  </strong>
                  <span>
                    Kişi kimliğine dair bilgilerin bulunduğu veri
                    grubudur.&nbsp;&nbsp;(Örn. Ad,&nbsp;Soyad, Doğum Tarihi
                    /&nbsp;Doğum Yeri, TC&nbsp;Kimlik Numarası)
                  </span>
                </p>
                <br />
                <p>
                  <strong>
                    <span>İletişim:&nbsp;</span>
                  </strong>
                  <span>
                    Kişiye ulaşmak için kullanılabilecek veri
                    grubudur.&nbsp;&nbsp;(Örn. Telefon numarası, Yerleşim Yeri
                    Adresi, E-posta Adresi)&nbsp;&nbsp;
                  </span>
                </p>
                <br />
                <p>
                  <strong>
                    <span>Görsel&nbsp;ve&nbsp;İşitsel Kayıtlar:&nbsp;</span>
                  </strong>
                  <span>
                    Kişiye ait görsel ve işitsel verilerin bulunduğu veri
                    grubudur.&nbsp;&nbsp;(Örn. Ses kaydı)&nbsp;&nbsp;
                  </span>
                </p>
                <br />
                <br />
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      <span>Güvenliğin ve denetimlerin sağlanması,</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>Talep ve şikayetlerin takibi,</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Görevli ve yetkili kamu kurum ve kuruluşları ile kamu
                        kurumu niteliğindeki meslek kuruluşlarınca yapılacak
                        denetleme ve/veya düzenleme görevlerinin yürütülmesi,
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Yargı organlarının ve/veya idari makamların istediği
                        bilgi ve belge taleplerinin yerine getirilmesi,
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Kanunlardan ve ikincil düzenlemelerden doğan/doğabilecek
                        yasal ve düzenleyici gereksinimlerin yerine getirilmesi
                        ve bu kapsamda gerekli tedbirlerin alınabilmesi,
                      </span>
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        lineHeight: "1.295",
                        textAlign: "justify",
                        backgroundColor: "#ffffff",
                        marginTop: "0pt",
                        marginBottom: "8pt"
                      }}
                    >
                      <span>
                        Firma işlerinin yürütüldüğü veya firmaya bağlı merkez ve
                        birimlerinde bulunan&nbsp;gerçek ve/veya tüzel üçüncü
                        kişi kurum ve kuruluşların can ve mal güvenlikleri
                        ile&nbsp;hukuki, ticari ve iş sağlığı güvenliklerinin
                        temini,
                      </span>
                    </p>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <p
                      style={{
                        lineHeight: "1.295",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span>
                        Bir hakkın tesisi, kullanılması veya korunması için veri
                        işlemenin zorunlu olması,
                      </span>
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        lineHeight: "1.295",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span>
                        İlgili kişinin temel hak ve özgürlüklerine zarar
                        vermemek kaydıyla, veri sorumlusunun meşru menfaatleri
                        için veri işlenmesinin zorunlu olması,
                      </span>
                    </p>
                  </li>
                </ul>
                <p
                  style={{
                    lineHeight: "1.295",
                    marginTop: "0pt",
                    marginBottom: "8pt",
                    paddingLeft: 24
                  }}
                >
                  <span>&nbsp;</span>
                </p>
                <p>
                  <span>
                    hukuki sebeplerine dayanılarak kişisel verileriniz
                    işlenmektedir.
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>&nbsp;</p>
      <p>
        <strong>
          <span>
            4- Çerez Kullanımı ve Kullanıcı Takibi Faaliyetlerinin Yürütülmesi
          </span>
        </strong>
      </p>
      <p>&nbsp;</p>
      <div align="left">
        <table style={{ border: "none", tableLayout: "fixed" }}>
          <tbody>
            <tr style={{ height: "162.5pt" }}>
              <td>
                <p
                  style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt"
                  }}
                >
                  <strong>
                    <span>Pazarlama Verisi:</span>
                  </strong>
                  <span>
                    {" "}
                    Kişiye ait bilgilerin işlenmesi sonucu oluşan dijital
                    izlerin bulunduğu pazarlama, satış ve tanıtım amaçlı
                    kullanılan veri grubudur. (Örn. İnternet sitesi gezinme
                    bilgileri, pazarlamaya yönelik çerez bilgileri, hedef ve
                    analiz bilgileri)
                  </span>
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p
                      style={{
                        lineHeight: "1.295",
                        textAlign: "justify",
                        backgroundColor: "#ffffff",
                        marginTop: "0pt",
                        marginBottom: "8pt"
                      }}
                    >
                      <span>
                        Platform kullanıcılarımızın hedefleme, analiz ve site
                        performans takibi yapılarak kullanıcıların tercih ve
                        ihtiyaçları doğrultusunda uygulama, mal, ürün ve
                        hizmetlerin satış ve pazarlamasının yapılması amacıyla
                        şirketimiz tarafından yönetilen platformda pazarlama
                        amaçlı çerez bilgileri işlenmektedir.&nbsp;
                      </span>
                    </p>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <p
                      style={{
                        lineHeight: "1.295",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span>İlgili kişinin açık rızasını sunması</span>
                    </p>
                  </li>
                </ul>
                <br />
                <p>
                  <span>
                    hukuki sebebine dayanılarak kişisel verileriniz
                    işlenmektedir.
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>&nbsp;</p>
      <p>
        <strong>
          <span>
            5-Elektronik İleti ve Bilgilendirme Faaliyetlerinin Yürütülmesi
          </span>
        </strong>
      </p>
      <p>&nbsp;</p>
      <div align="left">
        <table style={{ border: "none", tableLayout: "fixed" }}>
          <tbody>
            <tr style={{ height: "34.7pt" }}>
              <td>
                <p>
                  <strong>
                    <span>Kimlik:&nbsp;</span>
                  </strong>
                  <span>
                    Kişi kimliğine dair bilgilerin bulunduğu veri
                    grubudur.&nbsp;&nbsp;(Örn. Ad,&nbsp;Soyad,)
                  </span>
                </p>
                <br />
                <p>
                  <strong>
                    <span>İletişim:&nbsp;</span>
                  </strong>
                  <span>
                    Kişiye ulaşmak için kullanılabilecek veri
                    grubudur.&nbsp;&nbsp;(Örn. Telefon numarası, E-posta
                    Adresi)&nbsp;&nbsp;
                  </span>
                </p>
                <br />
                <br />
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      <span>
                        Platform kapsamında kullanıcılarımızın tercih ve
                        ihtiyaçları doğrultusunda uygulama, mal, ürün ve
                        hizmetlerin bilgilendirilmesinin yapılması&nbsp;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Kanunlardan ve ikincil düzenlemelerden doğan/doğabilecek
                        yasal ve düzenleyici gereksinimlerin yerine getirilmesi
                        ve bu kapsamda gerekli tedbirlerin alınabilmesi,
                      </span>
                    </p>
                  </li>
                </ul>
                <p
                  style={{
                    lineHeight: "1.295",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "8pt",
                    paddingLeft: 24
                  }}
                >
                  &nbsp;
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p
                      style={{
                        lineHeight: "1.295",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span>İlgili kişinin açık rızasını sunması</span>
                    </p>
                  </li>
                </ul>
                <br />
                <p>
                  <span>
                    hukuki sebeplerine dayanılarak kişisel verileriniz
                    işlenmektedir.
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          backgroundColor: "#ffffff",
          marginTop: "14pt",
          marginBottom: "0pt",
          paddingBottom: "14pt"
        }}
      >
        &nbsp;
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          backgroundColor: "#ffffff",
          marginTop: "0pt",
          marginBottom: "0pt",
          paddingBottom: "14pt"
        }}
      >
        &nbsp;
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          backgroundColor: "#ffffff",
          marginTop: "0pt",
          marginBottom: "0pt",
          paddingBottom: "14pt"
        }}
      >
        &nbsp;
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          backgroundColor: "#ffffff",
          marginTop: "0pt",
          marginBottom: "0pt",
          paddingBottom: "14pt"
        }}
      >
        &nbsp;
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          backgroundColor: "#ffffff",
          marginTop: "0pt",
          marginBottom: "0pt",
          paddingBottom: "14pt"
        }}
      >
        &nbsp;
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          backgroundColor: "#ffffff",
          marginTop: "0pt",
          marginBottom: "0pt",
          paddingBottom: "14pt"
        }}
      >
        &nbsp;
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          backgroundColor: "#ffffff",
          marginTop: "0pt",
          marginBottom: "0pt",
          paddingBottom: "14pt"
        }}
      >
        &nbsp;
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          backgroundColor: "#ffffff",
          marginTop: "0pt",
          marginBottom: "0pt",
          paddingBottom: "14pt"
        }}
      >
        &nbsp;
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          backgroundColor: "#ffffff",
          marginTop: "0pt",
          marginBottom: "0pt",
          paddingBottom: "14pt"
        }}
      >
        &nbsp;
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          backgroundColor: "#ffffff",
          marginTop: "0pt",
          marginBottom: "0pt",
          paddingBottom: "14pt"
        }}
      >
        &nbsp;
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginRight: "14.15pt",
          textAlign: "justify",
          backgroundColor: "#ffffff",
          marginTop: "0pt",
          marginBottom: "14pt"
        }}
      >
        <strong>
          <span>
            EK 2- JTI TARAFINDAN KİŞİSEL VERİLERİN AKTARILDIĞI ÜÇÜNCÜ KİŞİLER VE
            AKTARILMA AMAÇLARI
          </span>
        </strong>
      </p>
      <div align="left">
        <table style={{ border: "none", tableLayout: "fixed" }}>
          <tbody>
            <tr>
              <td style={{ border: "0.75pt solid #000000" }}>
                <p
                  style={{
                    lineHeight: "1.2",
                    marginRight: "14.15pt",
                    textAlign: "center",
                    marginTop: "0pt",
                    marginBottom: "0pt"
                  }}
                >
                  <strong>
                    <span>Veri Aktarımı Yapılabilecek Üçünü Kişiler</span>
                  </strong>
                </p>
              </td>
              <td style={{ border: "0.75pt solid #000000" }}>
                <p
                  style={{
                    lineHeight: "1.2",
                    marginRight: "14.15pt",
                    textAlign: "center",
                    marginTop: "0pt",
                    marginBottom: "0pt"
                  }}
                >
                  <strong>
                    <span>Veri Aktarım Amacı</span>
                  </strong>
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ border: "0.75pt solid #000000" }}>
                <p>
                  <span>JTI İş Ortağı</span>
                </p>
              </td>
              <td style={{ border: "0.75pt solid #000000" }}>
                <ul>
                  <li>
                    <p>
                      <span>İş ortaklığının kurulma amaçlarının</span>
                      <span>
                        <br />
                      </span>
                      <span>yerine getirilmesini temin etmek</span>
                      <span>
                        <br />
                      </span>
                      <span>amacıyla sınırlı olarak</span>
                      <span>
                        <br />
                      </span>
                      <span>
                        aktarılmaktadır. Şirketin ticari faaliyetlerine ilişkin
                        faaliyet yürüten veya iş faaliyetlerini kolaylaştıran
                        hizmetler sunan şirketler ile, iş birliğinin ve
                        koordinasyonunun sağlanması, denetim ve geliştirmesi
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Şirketimizin ortak kullanmış olduğu sunucular ve bilişim
                        teknolojileri sebebi ile faaliyet ve hizmetlerin
                        aksamaması
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Bilişim teknolojilerine yönelik uzmanlık gerektiren
                        konularda ürün ve hizmet desteği almak amaçlarıyla{" "}
                      </span>
                      <span>
                        faaliyetlerimizi yürütmek üzere sözleşmesel olarak
                        hizmet aldığımız
                      </span>
                      <span>
                        {" "}
                        JTI’ın yurt dışında bulunan hizmet sağlayıcıları iş
                        ortaklarıyla yaptığı anlaşmalar kapsamında anlaşmaların
                        gereğini yerine getirebilmesi ve güvenliğin
                        sağlanabilmesi,
                      </span>
                    </p>
                  </li>
                </ul>
                <br />
                <p
                  style={{
                    lineHeight: "1.2",
                    marginRight: "14.15pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt"
                  }}
                >
                  <span>
                    amaçlarıyla sınırlı olarak aktarım yapılmaktadır.&nbsp;
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ border: "0.75pt solid #000000" }}>
                <p>
                  <span>Tedarikçi</span>
                </p>
              </td>
              <td style={{ border: "0.75pt solid #000000" }}>
                <p>
                  <span>
                    JTI’ın tedarikçiden dış kaynaklı olarak temin ettiği ve
                    JTI’ın ticari faaliyetlerini yerine getirmek için gerekli
                    hizmetlerin JTI’a sunulmasını sağlamak amacıyla sınırlı
                    olarak aktarılmaktadır.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ border: "0.75pt solid #000000" }}>
                <p>
                  <span>Yetkili Kamu Kurum ve Kuruluşları</span>
                </p>
              </td>
              <td style={{ border: "0.75pt solid #000000" }}>
                <p
                  style={{
                    lineHeight: "1.2",
                    marginRight: "14.15pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt"
                  }}
                >
                  <span>
                    Kamu kurum ve kuruluşlarının talep ettiği ve hukuki dayanak
                    sundukları durumlarda amaçla sınırlı olarak aktarılmaktadır.
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PdpPolicy;
