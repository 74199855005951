import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { profileAddressAction, profileAction } from "redux/modules";
import showErrMessage from "hooks/showErrMessage";
import showMessage from "hooks/showMessage";

import NewAddressAddIcon from "assets/portal-resource/img/icon/new-address-add.png";
import AddressEditIcon from "assets/portal-resource/img/icon/address-edit.png";
import AddressDeleteIcon from "assets/portal-resource/img/icon/address-delete.png";
import OrderAddressIcon from "assets/portal-resource/img/icon/order-address.png";

const ProfileAddressInfo = props => {
  const dispatch = useDispatch();
  const addressList = useSelector(store => store.profileAddress.addressList);
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const countyList = useSelector(store => store.profileAddress.countyList);
  const provinceList = useSelector(store => store.profileAddress.provinceList);
  const districtList = useSelector(store => store.profileAddress.districtList);

  //const isLoading = useSelector(store => store.profileAddress.isLoading);
  const displayNewAddressPage = useSelector(
    store => store.profile.displayNewAddressPage
  );

  const selectedAddress = useSelector(
    store => store.profileAddress.selectedAddress
  );
  const selectedProvince = useSelector(
    store => store.profileAddress.selectedProvince
  );
  const selectedCounty = useSelector(
    store => store.profileAddress.selectedCounty
  );

  // district !
  const selectedDistrict = useSelector(
    store => store.profileAddress.selectedDistrict
  );
  const selectedStreet = useSelector(
    store => store.profileAddress.selectedStreet
  );

  const selectedApartmentNo = useSelector(
    store => store.profileAddress.selectedApartmentNo
  );
  const selectedFlatNo = useSelector(
    store => store.profileAddress.selectedFlatNo
  );
  const selectedAddressID = useSelector(
    store => store.profileAddress.selectedAddressID
  );

  const [showNewAddress, setShowNewAddress] = useState(false);
  const [showAddresses, setShowAddresses] = useState(true);

  const Address = {
    addressId: null,
    name: "",
    province: "",
    county: "",
    district: "",
    street: "",
    apartmentNo: "",
    flatNo: ""
  };
  useEffect(() => {
    dispatch(
      profileAddressAction.getAddressByEmployee(employeeFirm.employee.id)
    );

    return () => {};
  }, [dispatch, employeeFirm.employee.id]);

  useEffect(() => {
    if (displayNewAddressPage) {
      dispatch(profileAction.goToNewAddress(null));
      setShowAddresses(false);
      setShowNewAddress(true);
      getProvinceByCountry("TÜRKİYE");
      setSelectedProvince("");
      setAddressName("");
      setSelectedCounty("");
      setSelectedDistrict("");
      setSelectedStreet("");
      setSelectedApartmentNo("");
      setSelectedFlatNo("");
    }
    return () => {};
  }, [
    dispatch,
    displayNewAddressPage,
    getProvinceByCountry,
    setAddressName,
    setSelectedDistrict,
    setSelectedStreet,
    setSelectedApartmentNo,
    setSelectedFlatNo,
    setSelectedCounty,
    setSelectedProvince
  ]);

  function getAddressByEmployee(id) {
    dispatch(profileAddressAction.getAddressByEmployee(id));
  }

  // PROVINCES
  function getProvinceByCountry(value) {
    dispatch(profileAddressAction.getProvinceListByName(value));
  }

  // COUNTYS
  function getCountyByProvince(value) {
    if (value && value.target) {
      dispatch(
        profileAddressAction.getCountyListById(
          value.target[value.target.selectedIndex].text
        )
      ).then(res => {
        dispatch(profileAddressAction.getDistrictListById(res[0].id)).then(
          district => {
            let elem = document.getElementById("district");
            if (selectedDistrict === "") {
              elem.selectedIndex = 1;
            } else {
              elem.selectedIndex = 0;
            }
          }
        );
      });
    } else {
      dispatch(profileAddressAction.getCountyListById(value));
    }
  }

  // DISTRICTS
  function getDistrictByCounty(value) {
    if (value && value.target) {
      dispatch(
        profileAddressAction.getDistrictListById(
          parseInt(value.target[value.target.selectedIndex].id)
        )
      ).then(district => {
        let elem = document.getElementById("district");
        if (selectedDistrict === "") {
          elem.selectedIndex = 1;
        } else {
          elem.selectedIndex = 0;
        }
      });
    } else {
      dispatch(profileAddressAction.getDistrictListById(value)).then(
        district => {
          let elem = document.getElementById("district");
          if (selectedDistrict === "") {
            elem.selectedIndex = 1;
          } else {
            elem.selectedIndex = 0;
          }
        }
      );
    }
  }

  async function deleteAddress(value) {
    await dispatch(profileAddressAction.deleteAddress(value))
      .then(e => {
        showMessage("Adres Silindi");
        dispatch(
          profileAddressAction.getAddressByEmployee(employeeFirm.employee.id)
        );
        setShowAddresses(true);
        setShowNewAddress(false);
      })
      .catch(err => {
        showErrMessage("Adres Silinemedi");
      });
  }
  function checkRequiredFields(e) {
    Address.name = selectedAddress;
    Address.province = selectedProvince;

    if (selectedCounty) Address.county = selectedCounty;
    else Address.county = countyList[0].name;

    if (selectedDistrict) Address.districtId = selectedDistrict;
    else Address.districtId = districtList[0].name;

    Address.street = selectedStreet;
    Address.apartmentNo = selectedApartmentNo;
    Address.flatNo = selectedFlatNo;
    Address.addressId = selectedAddressID;
    if (!Address.name) {
      showErrMessage("Adres Adı Giriniz");
      e.preventDefault();
    } else if (Address.province.length <= 0) {
      showErrMessage("Şehir Seçiniz");
      e.preventDefault();
    } else if (Address.county.length <= 0) {
      showErrMessage("İlçe Seçiniz");
      e.preventDefault();
    } else if (!Address.districtId) {
      showErrMessage("Mahalle Giriniz");
      e.preventDefault();
    } else if (!Address.street) {
      showErrMessage("Cadde/Sokak Giriniz");
      e.preventDefault();
    } else if (!Address.apartmentNo) {
      showErrMessage("Apartman No Giriniz");
      e.preventDefault();
    } else if (!Address.flatNo) {
      showErrMessage("Daire No Giriniz");
      e.preventDefault();
    } else if (Address.flatNo.length > 3) {
      showErrMessage("Daire No, 3 karakterli olmalıdır!");
      e.preventDefault();
    } else if (/^\d+$/.test(Address.flatNo) === false) {
      showErrMessage("Daire No sadece rakam içerebilir!");
      e.preventDefault();
    } else {
      dispatch(
        profileAddressAction.saveAddress(
          Address.addressId,
          Address.name,
          Address.county,
          Address.province,
          Address.districtId,
          Address.street,
          Address.apartmentNo,
          Address.flatNo,
          employeeFirm.id
        )
      )
        .then(e => {
          showMessage("Adres Kaydedildi");
          dispatch(
            profileAddressAction.getAddressByEmployee(employeeFirm.employee.id)
          );
          setShowAddresses(true);
          setShowNewAddress(false);
        })
        .catch(err => {
          if (err.error.response.data.errors) {
            err.error.response.data.errors.map(item => {
              showErrMessage(item.message);
            });
          } else {
            showErrMessage("Adres Kaydedilemedi");
          }
        });
    }
  }

  function setAddressName(event) {
    if (event.target) {
      dispatch(profileAddressAction.setAddressName(event.target.value));
    } else {
      dispatch(profileAddressAction.setAddressName(event));
    }
  }

  function setSelectedProvince(event) {
    if (event && event.target) {
      dispatch(
        profileAddressAction.setSelectedProvince(
          event.target[event.target.selectedIndex].text
        )
      );
    } else {
      dispatch(profileAddressAction.setSelectedProvince(event));
    }
  }

  function setSelectedCounty(event) {
    if (event && event.target) {
      dispatch(
        profileAddressAction.setSelectedCounty(
          event.target[event.target.selectedIndex].text
        )
      );
    } else {
      dispatch(profileAddressAction.setSelectedCounty(event));
    }
  }

  function setSelectedDistrict(event) {
    if (event && event.target)
      dispatch(
        profileAddressAction.setSelectedDistrict(
          event.target[event.target.selectedIndex].value
        )
      );
    else dispatch(profileAddressAction.setSelectedDistrict(event));
  }

  function setSelectedStreet(event) {
    if (event) dispatch(profileAddressAction.setSelectedStreet(event));
    else dispatch(profileAddressAction.setSelectedStreet(event));
  }

  function setSelectedApartmentNo(event) {
    if (event) dispatch(profileAddressAction.setSelectedApartmentNo(event));
    else dispatch(profileAddressAction.setSelectedApartmentNo(event));
  }

  function setSelectedFlatNo(event) {
    if (event) dispatch(profileAddressAction.setSelectedFlatNo(event));
    else dispatch(profileAddressAction.setSelectedFlatNo(event));
  }

  function setSelectedAddressID(event) {
    dispatch(profileAddressAction.setSelectedAddressID(event));
  }

  async function setOrderAddress(value) {
    await dispatch(profileAddressAction.setOrderAddress(value, employeeFirm.id))
      .then(e => {
        showMessage("Sipariş Adresi Güncellendi");
        dispatch(
          profileAddressAction.getAddressByEmployee(employeeFirm.employee.id)
        );
        setShowAddresses(true);
        setShowNewAddress(false);
      })
      .catch(err => {
        showErrMessage("Sipariş Adresi Güncellenemedi");
      });
  }

  return (
    <div
      className="tab-pane fade show active"
      id="addressinfo"
      role="tabpanel"
      aria-labelledby="addressinfo-tab"
    >
      {showAddresses ? (
        <div
          className="user-content-item address-info"
          style={{ display: "block" }}
        >
          <div className="user-content-text">
            <p style={{ fontSize: "14px" }}>
              Adres alanına girdiğiniz bilgiler, hediye siparişlerinizin
              gönderimi için kullanılacağından bilgilerinizin doğruluğundan
              lütfen emin olunuz. Ev ya da işyeri adresi gibi birden fazla adres
              girişi yapabilirsiniz. Yalnızca siparişinizin gönderilmesini
              istediğiniz adresi "Sipariş Adresi" olarak seçmeniz gerekmektedir.
              Sipariş vermeden önce mutlaka doğru adresin sipariş adresi olarak
              seçildiğini kontrol etmelisiniz.
              <br /> <br />
            </p>
          </div>
          <div className="address-item-list">
            <div className="address-info-content">
              <div className="addressinfo-title">
                <h3>Adreslerim</h3>
                <div
                  className="add-address"
                  onClick={() => {
                    setShowAddresses(false);
                    setShowNewAddress(true);
                    getProvinceByCountry("TÜRKİYE");
                    setSelectedProvince("");
                    setAddressName("");
                    setSelectedCounty("");
                    setSelectedDistrict("");
                    setSelectedStreet("");
                    setSelectedApartmentNo("");
                    setSelectedFlatNo("");
                  }}
                >
                  <span>Yeni Adres Ekle</span>
                  <img src={NewAddressAddIcon} alt=""></img>
                </div>
              </div>
              <div className="address-item-container">
                {addressList && addressList.length > 0
                  ? addressList.map((item, index) => {
                      return (
                        <div key={index} className="address-item">
                          <div className="address-item-info-container">
                            <div className="address-item-info">
                              <h2>{item.name}</h2>
                              <p>
                                {item.districtName && item.districtName}{" "}
                                {item.street && item.street}{" "}
                                {item.apartmentNo && item.apartmentNo}{" "}
                                {item.flatNo && item.flatNo}
                              </p>
                              <p>
                                {item.countyName} / {item.provinceName}
                              </p>
                            </div>
                            <div className="address-item-control">
                              <button
                                type="submit"
                                className="address-item-control-btn"
                                onClick={() => {
                                  setShowAddresses(false);
                                  setShowNewAddress(true);
                                  getProvinceByCountry("TÜRKİYE");
                                  getCountyByProvince(item.provinceName);
                                  getDistrictByCounty(item.countyId);
                                  setAddressName(item.name);
                                  setSelectedProvince(item.provinceName);
                                  setSelectedCounty(item.countyName);
                                  setSelectedDistrict(item.districtId);
                                  setSelectedStreet(item.street);
                                  setSelectedApartmentNo(item.apartmentNo);
                                  setSelectedFlatNo(item.flatNo);
                                  setSelectedAddressID(item.addressId);
                                }}
                              >
                                <img
                                  src={AddressEditIcon}
                                  className="mx-auto"
                                  alt=""
                                ></img>
                                <span>Düzenle</span>
                              </button>
                              <button
                                type="submit"
                                className="address-item-control-btn"
                                onClick={() => {
                                  deleteAddress(item.addressId);
                                  setSelectedAddressID(null);
                                }}
                              >
                                <img
                                  src={AddressDeleteIcon}
                                  className="mx-auto"
                                  alt=""
                                ></img>
                                <span>Sil</span>
                              </button>
                              {item.orderAddress &&
                              item.orderAddress === true ? (
                                <button
                                  type="submit"
                                  className="address-item-control-btn"
                                >
                                  <img
                                    src={OrderAddressIcon}
                                    className="mx-auto"
                                    alt=""
                                  ></img>
                                  <span>Sipariş Adresi</span>
                                </button>
                              ) : (
                                <div
                                  className="address-item-control-btn"
                                  onClick={() =>
                                    setOrderAddress(item.addressId)
                                  }
                                >
                                  {/* <button
                                    type="submit"
                                    id="address-default-btn"
                                    style={{ visibility: "hidden" }}
                                  ></button> */}
                                  {/* <label htmlFor="address-default-btn"> */}
                                  <label>
                                    <div className="circle"></div>
                                    <span>Sipariş Adresi</span>
                                  </label>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showNewAddress ? (
        <div className="user-content-item address-info">
          <div className="user-content-text">
            <p>
              Aşağıdaki kritik bilgilerde (Bayi Tabela Adınız, Bayi Kodunuz,
              TAPDK Ruhsat Numaranız, Adınız, Soyadınız, Adresiniz ve Cep
              Telefonunuz) değişiklik yapabilmek için 0850 532 5 584 (JTI)
              numaralı Çağrı Merkezi’mizi arayabilir, TAPDK ruhsat numaranızı ve
              bayi kodunuzu kullanarak müşteri temsilcimizle görüşebilirsiniz.
              Bayi kodunuzu bilmiyorsanız JTI satış temsilcinizden
              öğrenebilirsiniz Diğer bilgilerinizi güncellemek için form
              alanında gerekli değişiklikleri yapıp, “Kaydet” butonuna tıklayın.
              Adres alanına girdiğiniz bilgiler, siparişlerinizin gönderimi için
              kullanılacağından bilgilerinizin doğruluğunu kontrol ediniz.
            </p>
          </div>
          <div
            className="add-address-form address-info-content"
            style={{ display: "block" }}
          >
            <div className="addressinfo-title">
              <h3>ADRES TANIM</h3>
            </div>
            <div className="form-container">
              <div className="form-row">
                <div className="form-el  form-el-full">
                  <div className={"mb-1"}>Adres Adı</div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Örnek: Ev Adresi, İş Adresi vb."
                    maxLength="20"
                    value={selectedAddress}
                    onChange={event => {
                      setAddressName(event.target.value);
                    }}
                  />
                </div>
                <div className="form-el select-bottom-border">
                  <div className={"mb-1"}>Şehir Adı</div>
                  <select
                    className="select2 select2-container"
                    value={selectedProvince}
                    onChange={value => {
                      getCountyByProvince(value);
                      setSelectedProvince(value);
                      Address.county = setSelectedCounty();
                    }}
                  >
                    {selectedProvince === "" ? (
                      <option value="">ŞEHİR</option>
                    ) : null}
                    {provinceList != null
                      ? provinceList.map((item, index) => {
                          return (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
                <div className="form-el select-bottom-border">
                  <div className={"mb-1"}>İlçe Adı</div>
                  <select
                    className="select2 select2-container countySelect"
                    value={
                      countyList != null && !selectedCounty
                        ? countyList[0].name
                        : selectedCounty
                    }
                    onChange={value => {
                      Address.county = setSelectedCounty(value);
                      getDistrictByCounty(value);
                    }}
                  >
                    {selectedCounty === "" ? (
                      <option value="">İLÇE</option>
                    ) : null}
                    {countyList != null
                      ? countyList.map((item, index) => {
                          return (
                            <option key={index} value={item.value} id={item.id}>
                              {item.name}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
                <div className="form-el form-el-full">
                  <div className={"mb-1"}>Mahalle</div>
                  <select
                    id="district"
                    className="select2 select2-container"
                    value={
                      districtList != null && !selectedDistrict
                        ? districtList[0].name
                        : selectedDistrict
                    }
                    onChange={value => {
                      Address.districtId = setSelectedDistrict(value);
                    }}
                  >
                    {selectedDistrict === "" ? (
                      <option value="">MAHALLE</option>
                    ) : null}
                    {districtList != null
                      ? districtList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
                <div className="form-el form-el-full">
                  <div className={"mb-1"}>Cadde/Sokak</div>
                  <input
                    type="text"
                    className="form-control"
                    maxLength="100"
                    value={selectedStreet}
                    onChange={event => {
                      setSelectedStreet(event.target.value);
                    }}
                  />
                </div>
                <div className="form-el form-el-full">
                  <div className={"mb-1"}>Apartman No/Adı</div>
                  <input
                    type="text"
                    className="form-control"
                    maxLength="100"
                    value={selectedApartmentNo}
                    onChange={event => {
                      setSelectedApartmentNo(event.target.value);
                    }}
                  />
                </div>
                <div className="form-el form-el-full">
                  <div className={"mb-1"}>Daire No</div>
                  <input
                    type="number"
                    maxLength={3}
                    className="form-control"
                    value={selectedFlatNo}
                    onChange={event => {
                      setSelectedFlatNo(event.target.value);
                    }}
                  />
                </div>
                <div className="submit">
                  <div
                    className="unsubmit"
                    onClick={() => {
                      setShowAddresses(true);
                      setShowNewAddress(false);
                      getAddressByEmployee(employeeFirm.employee.id);
                    }}
                  >
                    VAZGEÇ
                  </div>
                  <button type="submit" onClick={checkRequiredFields}>
                    KAYDET
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProfileAddressInfo;
