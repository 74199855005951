import * as topAnnouncementTypes from "./topAnnouncement.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getTopAnnouncementByEmployeeId = employeeId => async dispatch => {
  dispatch({
    type: topAnnouncementTypes.GET_ANNOUNCEMENT_BY_EMPLOYEEID_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/announcement/launch/${employeeId}`,
      config
    );
    dispatch({
      type: topAnnouncementTypes.GET_ANNOUNCEMENT_BY_EMPLOYEEID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: topAnnouncementTypes.GET_ANNOUNCEMENT_BY_EMPLOYEEID_REJECTED,
      payload: error
    });
  }
};
