import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { magazineAction } from "redux/modules";
import { imageShowUrl } from "../../../config/api.config";
import MagazineLeftMenu from "./MagazineLeftMenu";

const MagazineHashtag = props => {
  const topicCategory = useSelector(store => store.magazine.topicCategory);
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const articleHashtag = useSelector(store => store.magazine.articleHashtag);
  const categoryList = useSelector(store => store.magazine.categoryList);
  const actives = useSelector(store => store.magazine.actives);
  //const [hasHashtag, setHashtag] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (props.match.params.hashtag) {
      dispatch(magazineAction.getArticleHashtag(props.match.params.hashtag));
    }

    dispatch(
      magazineAction.getTopicCategory(employeeFirm.firm.id, "Bizim Dergi")
    );

    return () => {};
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });

    return () => {};
  }, [topicCategory]);

  const getArticleById = articleId => {
    dispatch(magazineAction.getArticleById(articleId));
    dispatch(magazineAction.setCategoryList(categoryList));
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  };

  return (
    <div className="root-page-content">
      <section id="dergi-liste">
        <div className="container">
          <div className="row" style={{ maxWidth: "none" }}>
            <MagazineLeftMenu />

            <div className="col-md-9">
              <div className="md-5 web-page-desc">
                {topicCategory && topicCategory != null
                  ? topicCategory.map((item, index) => {
                      return (
                        <div key={index} className="intro">
                          {props.match.params.hashtag && (
                            <h3 style={{ color: "#09027b" }}>
                              #{props.match.params.hashtag}
                            </h3>
                          )}
                        </div>
                      );
                    })
                  : null}
              </div>
              <div className="post-list mt-5">
                {articleHashtag && articleHashtag.length > 0
                  ? articleHashtag.map((item, index) => {
                      return (
                        <div key={index} className="post">
                          <div className="row" style={{ maxWidth: "none" }}>
                            <div className="col-md-4">
                              <Link
                                onClick={() => getArticleById(item.id)}
                                to={{
                                  pathname:
                                    "/userportal/magazine/detail/" + item.id,
                                  subCategoryId: item.topicSubCategoryId,
                                  articleList: articleHashtag,
                                  actives: actives,
                                  categoryList: categoryList
                                }}
                              >
                                <img
                                  className="img"
                                  src={imageShowUrl + item.icon}
                                />
                              </Link>
                            </div>
                            <div className="col-md-8 d-flex align-items-stretch">
                              <div className="d-flex flex-column">
                                <Link
                                  onClick={() => getArticleById(item.id)}
                                  to={{
                                    pathname:
                                      "/userportal/magazine/detail/" + item.id,
                                    subCategoryId: item.topicSubCategoryId,
                                    articleList: articleHashtag,
                                    actives: actives,
                                    categoryList: categoryList
                                  }}
                                >
                                  <h2>{item.name}</h2>

                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.description &&
                                        item.description.slice(0, 600)
                                    }}
                                  />
                                </Link>

                                <div className="d-flex flex-row flex-wrap">
                                  {item.listHashtag &&
                                    item.listHashtag.length > 0 &&
                                    item.listHashtag.map(elem => {
                                      return (
                                        <Link
                                          to={`/userportal/magazine/${elem.trim()}`}
                                          className="hashtag-name mr-1"
                                        >
                                          <span>{"#" + elem.trim()}</span>
                                        </Link>
                                      );
                                    })}
                                </div>

                                <div className="footer d-flex mt-auto justify-content-end">
                                  <div className="readmore ml-4">
                                    <Link
                                      onClick={() => getArticleById(item.id)}
                                      to={{
                                        pathname:
                                          "/userportal/magazine/detail/" +
                                          item.id,
                                        subCategoryId: item.topicSubCategoryId,
                                        articleList: articleHashtag,
                                        actives: actives,
                                        categoryList: categoryList
                                      }}
                                    >
                                      + DEVAMINI OKU
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MagazineHashtag;
