import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authAction } from "redux/modules";
import showMessage from "hooks/showMessage";
import showErrMessage from "hooks/showErrMessage";

import PasswordChecklist from "react-password-checklist";

import PassEye from "assets/portal-resource/img/pass-eye.png";
import PassEyeActive from "assets/portal-resource/img/pass-active-eye.png";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const tk = useSelector(store => store.auth.authUser.token);
  const tkS = localStorage.getItem("token");
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);

  const token = tk === undefined || !tk ? tkS : tk;
  const [eyeShown, setEyeShown] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleConfirmVisibility = () => {
    setPasswordShown2(passwordShown2 ? false : true);
  };

  useEffect(() => {
    if (password.length > 0) {
      setEyeShown(true);
    } else {
      setEyeShown(false);
    }
  }, [password]);

  const handleChangePassword = e => {
    e.preventDefault();

    if (password.length > 0 && confirmPassword.length > 0) {
      if (password !== confirmPassword) {
        showErrMessage("Şifreler Uyuşmuyor!");
      } else {
        dispatch(authAction.initPassword({ token, password, confirmPassword }))
          .then(e => {
            showMessage(" Şifre değiştirildi");
            setPassword("");
            setConfirmPassword("");
          })
          .catch(err => {
            showErrMessage(err);
          });
      }
    } else {
      showErrMessage(
        "Lütfen şifre değiştirmek için şifre şartlarının tamamını karşılayınız!"
      );
    }
  };

  return (
    <div
      className="tab-pane fade"
      id="changepassword"
      role="tabpanel"
      aria-labelledby="changepassword-tab"
    >
      <div className="form-container">
        <form
          id="changePasswordForm"
          name="changePasswordForm"
          className="change-password-form"
          onSubmit={e => handleChangePassword(e)}
        >
          <div className="form-row">
            <div className="form-el position-relative">
              <input
                type={passwordShown ? "text" : "password"}
                className="form-control"
                maxLength="12"
                placeholder="Yeni Şifre"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />

              <div
                className={
                  password.length > 0 ? "pass-icon pass-icon-change" : "d-none"
                }
                onClick={togglePasswordVisibility}
              >
                <img
                  className={
                    !passwordShown ? "opacity-1 d-inline" : "opacity-0 d-none"
                  }
                  src={PassEye}
                  alt="Şifre Göster"
                />
                <img
                  className={
                    passwordShown ? "opacity-1 d-inline" : "opacity-0 d-none"
                  }
                  src={PassEyeActive}
                  alt="Şifre Göster"
                />
              </div>
            </div>

            <div className="form-el position-relative">
              <input
                type={passwordShown2 ? "text" : "password"}
                className="form-control"
                maxLength="12"
                placeholder="Yeni Şifre (Tekrar)"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />
              <div
                className={
                  confirmPassword.length > 0
                    ? "pass-icon pass-icon-change"
                    : "d-none"
                }
                onClick={toggleConfirmVisibility}
              >
                <img
                  className={
                    !passwordShown2 ? "opacity-1 d-inline" : "opacity-0 d-none"
                  }
                  src={PassEye}
                  alt="Şifre Tekrar Göster"
                />
                <img
                  className={
                    passwordShown2 ? "opacity-1 d-inline" : "opacity-0 d-none"
                  }
                  src={PassEyeActive}
                  alt="Şifre Tekrar Göster"
                />
              </div>
            </div>
            <div className="submit justify-content-between flex-sm-row-reverse d-block d-sm-flex">
              <div>
                <button className="w-100 mb-3 w-sm-auto mb-sm-0" type="submit">
                  KAYDET
                </button>
              </div>

              <PasswordChecklist
                rules={[
                  "letter",
                  "minLength",
                  "maxLength",
                  "number",
                  "capital",
                  "match"
                ]}
                minLength={6}
                maxLength={12}
                value={password}
                valueAgain={confirmPassword}
                messages={{
                  letter: "Yeni şifreniz en az bir harf içermelidir.",
                  minLength: "Yeni şifreniz en az 6 karakter içermelidir.",
                  maxLength: "Yeni şifreniz en fazla 12 karakter olabilir.",
                  number: "Yeni şifreniz en az bir rakam içermelidir.",
                  capital: "Yeni şifreniz en az bir büyük harf içermelidir.",
                  match: "Yeni şifreniz ile yeni şifre tekrar uyuşmuyor."
                }}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
