export const GET_ADDRESS_PENDING = "GET_ADDRESS_PENDING";
export const GET_ADDRESS_FULLFILLED = "GET_ADDRESS_FULLFILLED";
export const GET_ADDRESS_REJECTED = "GET_ADDRESS_REJECTED";

export const GET_COUNTRYLIST_PENDING = "GET_COUNTRYLIST_PENDING";
export const GET_COUNTRYLIST_FULLFILLED = "GET_COUNTRYLIST_FULLFILLED";
export const GET_COUNTRYLIST_REJECTED = "GET_COUNTRYLIST_REJECTED";

export const DELETE_ADDRESS_PENDING = "DELETE_ADDRESS_PENDING";
export const DELETE_ADDRESS_FULLFILLED = "DELETE_ADDRESS_FULLFILLED";
export const DELETE_ADDRESS_REJECTED = "DELETE_ADDRESS_REJECTED";

export const GET_DISTRICTLIST_BY_COUNTY_ID_PENDING =
  "GET_DISTRICTLIST_BY_COUNTY_ID_PENDING";
export const GET_DISTRICTLIST_BY_COUNTY_ID_FULLFILLED =
  "GET_DISTRICTLIST_BY_COUNTY_ID_FULLFILLED";
export const GET_DISTRICTLIST_BY_COUNTY_ID_REJECTED =
  "GET_DISTRICTLIST_BY_COUNTY_ID_REJECTED";

export const GET_COUNTYLIST_BY_PROVINCE_ID_PENDING =
  "GET_COUNTYLIST_BY_PROVINCE_ID_PENDING";
export const GET_COUNTYLIST_BY_PROVINCE_ID_FULLFILLED =
  "GET_COUNTRLIST_BY_PROVINCE_ID_FULLFILLED";
export const GET_COUNTYLIST_BY_PROVINCE_ID_REJECTED =
  "GET_COUNTYLIST_BY_PROVINCE_ID_REJECTED";

export const GET_PROVINCELIST_BY_COUNTRY_ID_PENDING =
  "GET_PROVINCELIST_BY_COUNTRY_ID_PENDING";
export const GET_PROVINCELIST_BY_COUNTRY_ID_FULLFILLED =
  "GET_PROVINCELIST_BY_COUNTRY_ID_FULLFILLED";
export const GET_PROVINCELIST_BY_COUNTRY_ID_REJECTED =
  "GET_PROVINCELIST_BY_COUNTRY_ID_REJECTED";

export const SAVE_ADDRESSES_BY_EMPLOYEE_ID_PENDING =
  "SAVE_ADDRESSES_BY_EMPLOYEE_ID_PENDING";
export const SAVE_ADDRESSES_BY_EMPLOYEE_ID_FULLFILLED =
  "SAVE_ADDRESSES_BY_EMPLOYEE_ID_FULLFILLED";
export const SAVE_ADDRESSES_BY_EMPLOYEE_ID_REJECTED =
  "SAVE_ADDRESSES_BY_EMPLOYEE_ID_REJECTED";

export const SET_SELECTED_ADDRESS_PENDING = "SET_SELECTED_ADDRESS_PENDING";
export const SET_SELECTED_ADDRESS_FULLFILLED =
  "SET_SELECTED_ADDRESS_FULLFILLED";
export const SET_SELECTED_ADDRESS_REJECTED = "SET_SELECTED_ADDRESS_REJECTED";

export const SET_SELECTED_COUNTRY_PENDING = "SET_SELECTED_COUNTRY_PENDING";
export const SET_SELECTED_COUNTRY_FULLFILLED =
  "SET_SELECTED_COUNTRY_FULLFILLED";
export const SET_SELECTED_COUNTRY_REJECTED = "SET_SELECTED_COUNTRY_REJECTED";

export const SET_SELECTED_PROVINCE_PENDING = "SET_SELECTED_PROVINCE_PENDING";
export const SET_SELECTED_PROVINCE_FULLFILLED =
  "SET_SELECTED_PROVINCE_FULLFILLED";
export const SET_SELECTED_PROVINCE_REJECTED = "SET_SELECTED_PROVINCE_REJECTED";

export const SET_SELECTED_COUNTY_PENDING = "SET_SELECTED_COUNTY_PENDING";
export const SET_SELECTED_COUNTY_FULLFILLED = "SET_SELECTED_COUNTY_FULLFILLED";
export const SET_SELECTED_COUNTY_REJECTED = "SET_SELECTED_COUNTY_REJECTED";

export const SET_SELECTED_DISTRICT_PENDING = "SET_SELECTED_DISTRICT_PENDING";
export const SET_SELECTED_DISTRICT_FULLFILLED =
  "SET_SELECTED_DISTRICT_FULLFILLED";
export const SET_SELECTED_DISTRICT_REJECTED = "SET_SELECTED_DISTRICT_REJECTED";

export const SET_SELECTED_STREET_PENDING = "SET_SELECTED_STREET_PENDING";
export const SET_SELECTED_STREET_FULLFILLED = "SET_SELECTED_STREET_FULLFILLED";
export const SET_SELECTED_STREET_REJECTED = "SET_SELECTED_STREET_REJECTED";

export const SET_SELECTED_APARTMENTNO_PENDING =
  "SET_SELECTED_APARTMENTNO_PENDING";
export const SET_SELECTED_APARTMENTNO_FULLFILLED =
  "SET_SELECTED_APARTMENTNO_FULLFILLED";
export const SET_SELECTED_APARTMENTNO_REJECTED =
  "SET_SELECTED_APARTMENTNO_REJECTED";

export const SET_SELECTED_FLATNO_PENDING = "SET_SELECTED_FLATNO_PENDING";
export const SET_SELECTED_FLATNO_FULLFILLED = "SET_SELECTED_FLATNO_FULLFILLED";
export const SET_SELECTED_FLATNO_REJECTED = "SET_SELECTED_FLATNO_REJECTED";

export const SET_ORDER_ADDRESS_PENDING = "SET_ORDER_ADDRESS_PENDING";
export const SET_ORDER_ADDRESS_FULLFILLED = "SET_ORDER_ADDRESS_FULLFILLED";
export const SET_ORDER_ADDRESS_REJECTED = "SET_ORDER_ADDRESS_REJECTED";

export const GET_ADDRESS_BY_ID_PENDING = "GET_ADDRESS_BY_ID_PENDING";
export const GET_ADDRESS_BY_ID_FULLFILLED = "GET_ADDRESS_BY_ID_FULLFILLED";
export const GET_ADDRESS_BY_ID_REJECTED = "GET_ADDRESS_BY_ID_REJECTED";

export const SET_SELECTED_ADDRESS_ID_PENDING =
  "SET_SELECTED_ADDRESS_ID_PENDING";
export const SET_SELECTED_ADDRESS_ID_FULLFILLED =
  "SET_SELECTED_ADDRESS_ID_FULLFILLED";
export const SET_SELECTED_ADDRESS_ID_REJECTED =
  "SET_SELECTED_ADDRESS_ID_REJECTED";
