import * as widgetTypes from "./widget.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getWidget = () => async dispatch => {
  dispatch({
    type: widgetTypes.GET_WIDGET_PENDING
  });

  try {
    const response = await API.get(`${REQUEST_URL}/widgets`, config);
    dispatch({
      type: widgetTypes.GET_WIDGET_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: widgetTypes.GET_WIDGET_REJECTED,
      payload: error
    });
  }
};
