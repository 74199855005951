import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { disclaimerAction } from "redux/modules";
import { Redirect } from "react-router-dom";
import Modal from "react-responsive-modal";

import showMessage from "hooks/showMessage";
import showErrMessage from "hooks/showErrMessage";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";

const DisclaimerPhoto = () => {
  const dispatch = useDispatch();

  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const isLoading = useSelector(store => store.disclaimer.isLoading);
  const disclaimerPhoto = useSelector(
    store => store.disclaimer.disclaimerPhoto
  );

  const [open, setOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [iysShow, setIysShow] = useState(false);

  const [checkedImageConfirm, setCheckedImageConfirm] = useState(null);
  const [selectedChoice, setSelectedChoice] = useState(null);

  const closeModal = () => setOpen(!open);

  const savePhotoIys = e => {
    e.preventDefault();
    if (selectedChoice !== null) {
      dispatch(
        disclaimerAction.saveDisclaimerPhoto(
          employeeFirm.id,
          disclaimerPhoto[0].id,
          selectedChoice
        )
      ).then(res => {
        if (res.isHttpSuccess) {
          showMessage("Fotoğraf izinleriniz kaydedildi!");
          setIysShow(false);
          dispatch(disclaimerAction.getDisclaimerPhotoByFirmId(employeeFirm.id))
            .then(resPhoto => {
              if (resPhoto.isHttpSuccess) {
                setIysShow(false);
                setRedirect(true);
              }
            })
            .catch(error => {
              // Redirect to Home
              if (error.isHttpError) {
                setIysShow(false);
                setRedirect(true);
              }
            });
        }
      });
    } else {
      showErrMessage("Lütfen bir seçenek seçiniz!");
    }
  };

  useEffect(() => {
    if (employeeFirm.id) {
      dispatch(disclaimerAction.getDisclaimerPhotoByFirmId(employeeFirm?.id))
        .then(resPhoto => {
          if (resPhoto.isHttpSuccess) {
            if (resPhoto?.data?.length > 0) {
              setIysShow(true);
            } else {
              // Redirect to Home
              setIysShow(false);
              setRedirect(true);
            }
          }
        })
        .catch(error => {
          if (error.isHttpError) {
            // Redirect to Home
            setIysShow(false);
            setRedirect(true);
          }
        });
    }
  }, [employeeFirm]);

  useEffect(() => {
    if (employeeFirm?.id) {
      dispatch(
        disclaimerAction.getDisclaimerPhotoByFirmId(employeeFirm?.id)
      ).then(resPhoto => {
        if (resPhoto.isHttpSuccess && resPhoto?.data?.length > 0) {
          setCheckedImageConfirm(resPhoto?.data[0]?.confirmed);
        }
      });
    }
  }, [dispatch, employeeFirm]);

  useEffect(() => {
    if (checkedImageConfirm !== null) {
      setSelectedChoice(checkedImageConfirm);
    }
  }, [checkedImageConfirm]);

  const bg = {
    overlay: {
      background: "#424242"
    }
  };

  if (redirect) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <div>
      <GlobalLoader isLoading={isLoading} />
      {iysShow && (
        <Modal
          open={!open}
          onClose={closeModal}
          role="dialog"
          closeOnEsc={false}
          showCloseIcon={false}
          closeOnOverlayClick={false}
          styles={bg}
          modalId="disclaimer-modal-area"
        >
          <div className="max-h-[800px] p-[25px] overflow-y-scroll">
            <div>
              {disclaimerPhoto?.length > 0 &&
                disclaimerPhoto?.map(i => {
                  return (
                    <div key={i.id}>
                      <div
                        dangerouslySetInnerHTML={{ __html: i.description }}
                      />
                    </div>
                  );
                })}

              <form
                className="d-flex flex-col justify-center align-center w-full mt-4"
                onSubmit={e => savePhotoIys(e)}
              >
                <div className="text-center mb-3">
                  <label
                    className="mr-3 cursor-pointer"
                    checked={selectedChoice === true}
                    onClick={() => setSelectedChoice(true)}
                  >
                    <input type="radio" name="iys" />{" "}
                    <span>İzin veriyorum</span>
                  </label>

                  <label
                    className="cursor-pointer"
                    checked={selectedChoice === false}
                    onClick={() => setSelectedChoice(false)}
                  >
                    <input type="radio" name="iys" />{" "}
                    <span>İzin vermiyorum</span>
                  </label>
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-success text-white hover:opacity-70 rounded-[10px]"
                  >
                    Gönder
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default DisclaimerPhoto;
