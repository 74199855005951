import React from "react";
import PerformanceBanner2 from "../../../../assets/images/performance-banner-2.jpg";
import PerformanceBanner3 from "../../../../assets/images/performance-banner-3.jpeg";

const Percentage = ({ data }) => {
  console.log("data: ", data.data);

  return (
    <>
      <div className="!p-3">
        Alım miktarınızın geçen sene aynı dönem ile kıyaslı durumu
        gösterilmektedir.
      </div>

      <div className="relative">
        <img
          src={PerformanceBanner2}
          className="hidden lg:block lg:!h-[405px] m-auto"
          alt=""
        />
        <img
          src={PerformanceBanner3}
          className="block lg:hidden lg:!h-[405px] m-auto"
          alt=""
        />
        {data?.data?.length > 0
          ? data?.data?.map((item, index) => (
              <div
                key={index}
                className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center"
              >
                <div className="ml-[100px] lg:!ml-[160px] text-3xl lg:!text-4xl text-black !mt-[-20px] lg:!mt-[-50px]">
                  <div className="!py-1 lg:!py-3">
                    <span className="font-bold">
                      % {parseFloat(item.change).toFixed(1)}
                    </span>
                  </div>
                </div>
              </div>
            ))
          : "Data bulunamadı!"}
      </div>
    </>
  );
};

export default Percentage;
