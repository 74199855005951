import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { magazineAction } from "redux/modules";
import { Link } from "react-router-dom";
import { imageShowUrl } from "../../../config/api.config";

import "./MagazineDetail.scss";

import {
  IoMenuOutline,
  IoCloseOutline,
  IoChevronDown,
  IoArrowForward
} from "react-icons/io5";
("react-icons/fa");

const MagazineDetail = props => {
  const categories = useSelector(store => store.magazine.categoryList);
  const articleId = props.match.params.id;
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const article = useSelector(store => store.magazine.article);
  const actives2 = useSelector(store => store.magazine.actives);
  const mixedList = useSelector(store => store.magazine.mixedList);

  const subCategoryId = props.location.subCategoryId;
  const actives = props.location.actives;
  const categoryList = props.location.categoryList;

  const dispatch = useDispatch();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });

    dispatch(
      magazineAction.getSubCatList(employeeFirm.firm.id, "Bizim Dergi", actives)
    );

    if (article) {
      dispatch(
        magazineAction.getMixedList(
          employeeFirm.firm.id,
          article[0].topicSubCategoryId,
          article[0].id
        )
      );
    }

    return () => {};
  }, [
    actives,
    article,
    articleId,
    dispatch,
    employeeFirm.firm.id,
    subCategoryId
  ]);

  useEffect(() => {
    return () => {};
  }, [article]);

  useEffect(() => {
    if (articleId) dispatch(magazineAction.getArticleById(articleId));
    else dispatch(magazineAction.getArticleById(article[0].id));

    return () => {};
  }, []);

  function goToMagazine(subCategoryId) {
    if (categoryList)
      categoryList.map((item, index) => {
        item.id === subCategoryId
          ? (actives[index] = true)
          : (actives[index] = false);
      });
    else {
      if (categories)
        categories.map((item, index) => {
          item.id === subCategoryId
            ? (actives2[index] = true)
            : (actives2[index] = false);
        });
    }
    dispatch(magazineAction.setMagazineContent());
    if (actives) dispatch(magazineAction.setActives(actives));
    else dispatch(magazineAction.setActives(actives2));

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }

  return (
    <>
      <div className="md:col-span-8 lg:col-span-9">
        <div className="flex flex-col gap-2 lg:gap-4">
          {[1, 2, 3, 4, 5].map((item, index) => (
            <a
              href="#"
              className="grid grid-cols-9 items-center overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-200"
              key={index}
              onClick={() => {
                setShowItem(true);
                document.body.style.overflow = "hidden";
              }}
            >
              <div className="col-span-3 h-full">
                <img
                  src="https://placehold.co/300x200/F4F4F4/909090"
                  alt=""
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="col-span-6 p-4 sm:p-8 lg:px-16 lg:py-8">
                <span className="text-xs font-semibold text-blue-900">
                  Otomativ
                </span>
                <h5 className="mt-2 text-sm font-medium text-slate-700 sm:text-xl">
                  Ekonomik Kriz Dönemlerinde KOBİ'lerin Alabilecekleri Önlemler
                </h5>
                <span className="mt-4 flex items-center gap-2 text-sm text-primary-500">
                  Devamı
                  <IoArrowForward className="h-4 w-4" />
                </span>
              </div>
            </a>
          ))}
        </div>
      </div>

      <section id="dergi-detay" className="page-magazine-detail">
        {article && article.length > 0
          ? article.map((item, index) => {
              return (
                <div key={index}>
                  <div className="container">
                    <div className="row m-auto" style={{ maxWidth: "none" }}>
                      <div className="col-md-12">
                        <div className="overflow-hidden rounded-3">
                          <img
                            src={imageShowUrl + item.topIcon}
                            className="img-cover w-100"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="row m-auto justify-content-center"
                      style={{ maxWidth: "none" }}
                    >
                      <div className="col-md-10">
                        <div className="article">
                          <div className="article-head d-block">
                            {item.listHashtag &&
                              item.listHashtag.length > 0 &&
                              item.listHashtag.map(hashtag => {
                                return (
                                  <Link
                                    to={`/userportal/magazine/${hashtag.trim()}`}
                                    className="article-head-subtitle mr-1"
                                  >
                                    {"#" + hashtag.trim()}
                                  </Link>
                                );
                              })}

                            <h1>{item.name !== null ? item.name : null}</h1>
                            <p className="ml-auto mt-auto">
                              {item.date !== null ? item.date : null}
                            </p>
                          </div>
                          <div className="article-body">
                            {item.description !== null ? (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.description
                                }}
                              ></p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row m-auto" style={{ maxWidth: "none" }}>
                      <div className="col-md-12">
                        <div className="other-articles">
                          <h2 className="text-center mb-sm-8 mb-5">
                            İlginizi Çekebilir
                          </h2>

                          <div className="row g-sm-4 g-2 justify-content-start justify-content-lg-center">
                            {mixedList && mixedList.length > 0
                              ? mixedList.map((item, index) => {
                                  return (
                                    <div
                                      className="col-12 col-sm-6 col-md-6 col-lg-3 mb-4 mb-lg-0"
                                      key={index}
                                    >
                                      <div className="d-flex pb-3 flex-column justify-content-between bg-white text-decoration-none shadow rounded-3 overflow-hidden h-100">
                                        <Link
                                          to={
                                            "/userportal/magazine/detail/" +
                                            item.id
                                          }
                                        >
                                          <img
                                            src={imageShowUrl + item.icon}
                                            alt={item.name}
                                            className="img-fluid w-100"
                                          />

                                          <div className="p-sm-4 p-3">
                                            <h5
                                              className="fw-normal lh-base mb-0"
                                              style={{ color: "#424242" }}
                                            >
                                              {item.name}
                                            </h5>
                                          </div>
                                        </Link>

                                        <div className="d-flex flex-row ml-4 flex-wrap">
                                          {item.listHashtag &&
                                            item.listHashtag.length > 0 &&
                                            item.listHashtag.map(hashtag => {
                                              return (
                                                <Link
                                                  to={`/userportal/magazine/${hashtag.trim()}`}
                                                  className="article-head-subtitle mr-1"
                                                >
                                                  <span>
                                                    {"#" + hashtag.trim()}
                                                  </span>
                                                </Link>
                                              );
                                            })}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </section>
    </>
  );
};
export default MagazineDetail;
