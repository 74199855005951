import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Accordion from "../../../components/Accordion";

import "./Announcement.scss";

const Announcement = () => {
  function setClicked(id) {
    let collapseFlag = document.querySelectorAll(
      "a[data-target=" + "'#" + id + "'" + "]"
    )[0].ariaExpanded;
    if (collapseFlag === "false") {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }

  const accordionItemsUserGuide = [
    {
      title: `Duyurular`,
      content: `<div>
Değerli Bayimiz,
<br />
<br />
Benim Bayim'in fırsatlarla dolu keyifli dünyasına hoş geldiniz!
Haydi hemen sizinle iletişimimizi güçlendirecek bu dünyayı keşfe çıkın.
<br />
<br />
Rozet Macerası, Puan Avı ve Eşle Kazan yarışmalarımız ile eğlenirken puan kazanmanın keyfini çıkartın. Ayrıca Rozet Macerası'nda verdiğiniz doğru cevaplarla marka rozetleri toplayabilirsiniz. Her hafta sizler için hazırladığımız Anket sorularını cevaplayıp puan kazanabilir, diğer bayilerimizin o konuyla ilgili ne düşündüğünü görebilirsiniz. Aynı zamanda her hafta haftanın dükkanını seçip hem puanlarınızı yükseltebilir hem de bayilerimizin dükkan fotoğraflarından ilham alabilirsiniz. "Fikirlerinizi paylaşabileceğiniz Bizim Meydan, çeşitli konularda videolar ve makaleleri takip edebileceğiniz Benim Bayim Dergi ve Benim Bayim TV sayfaları ile zamanın nasıl geçtiğini anlamayacaksınız." Benim Bayim'de yıl boyu sürpriz yarışmalar ile birbirinden farklı hediyeler kazanma fırsatı yakalayabileceğinizi de unutmayın.
<br />
<br />
Nasıl Puan Kazanırım bölümünde Benim Bayim'deki tüm puan kazanma detaylarını öğrenebilirsiniz. Kullanma Kılavuzu'nda Benim Bayim'de yer alan tüm bölümlerle ilgili detaylı bilgiye ulaşabilirsiniz. Tüm sorularınız için önce Sıkça Sorulan Sorular alanına bakıp, cevabı olmayan sorularınız için hafta içi hergün 8:30-17:30 saatleri arasında 0850-532 5 584 numaralı Benim Bayim Çağrı Merkezi'ni arayabilirsiniz.
<br />
<br />
Keyifli zaman geçirmenizi dileriz.
<br />
<br />
<h3>Benim Bayim</h3>
</div>`
    }
  ];

  return (
    <section>
      <div className="container-fluid mx-auto text-center page-head">
        <h3>Duyurular</h3>
      </div>

      <div className="container mx-auto my-20">
        <div className="flex flex-col md:flex-row w-100">
          <div className="basis-full md:basis-3/12">
            <div className="detail-page-left-menu">
              <ul>
                <li>
                  <Link to={`/userportal/faq`} className="font-medium">
                    Sıkça Sorulan Sorular
                  </Link>
                </li>

                <li>
                  <Link to={`/userportal/tac`} className="font-medium">
                    Şartlar Koşullar
                  </Link>
                </li>

                <li>
                  <Link to={`/userportal/score`} className="font-medium">
                    Nasıl Puan Kazanırım
                  </Link>
                </li>

                <li>
                  <Link to={`/userportal/userGuide`} className="font-medium">
                    Kullanım Kılavuzu
                  </Link>
                </li>

                <li className="active">
                  <Link to={`/userportal/announcement`} className="font-medium">
                    Duyurular
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="basis-full md:basis-9/12 pl-10">
            <Accordion accordionItems={accordionItemsUserGuide} />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Announcement;
