import React, { useState } from "react";
import { useDispatch } from "react-redux";
import showErrMessage from "hooks/showErrMessage";
import showMessage from "hooks/showMessage";
import validatePasswords from "hooks/validatePasswords";
import { NumericFormat, PatternFormat } from "react-number-format";
import { authAction } from "redux/modules";
import { Link } from "react-router-dom";

import IllumintionText from "../../components/IlluminationText";

import ErcCode from "assets/portal-resource/img/erckodunedir.jpg";
import LoginLogo from "assets/portal-resource/img/login-logo.png";
// import QuestionFill from "assets/portal-resource/img/icon/question-fill.svg";
import JtiLogo2 from "assets/portal-resource/img/jti-logo2.png";

import { FaPhoneAlt, FaArrowRight, FaShieldAlt } from "react-icons/fa";

import "./SignUp.scss";

const SingUp = props => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [vendorName, setVendorName] = useState("");
  //  const [vendorERPCode, setVendorERPCode] = useState("");
  const [tapdkCode, setTapdkCode] = useState("");
  const [tapdkType, setTapdkType] = useState("");
  const [showKvkk, setShowKvkk] = useState(false);
  const [showEnlightenment, setShowEnlightenment] = useState(false);
  //  const [username, setUsername] = useState("");
  const [vendorERCCode, setVendorERCCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);

  const [showERC, setShowERC] = useState(false);
  const handleERCClose = () => setShowERC(false);
  const handleERCShow = () => setShowERC(true);

  const handleKvkkShow = () => setShowKvkk(true);
  const handleKvkkClose = () => setShowKvkk(false);

  const handleEnlightenmentShow = () => setShowEnlightenment(true);
  const handleEnlightenmentClose = () => setShowEnlightenment(false);

  const [confirmCode, setConfirmCode] = useState("");

  const checkRequiredFields = e => {
    setDisabledButton(true);

    if (name.length <= 0) {
      showErrMessage("Adınızı Giriniz");
      e.preventDefault();
      setDisabledButton(false);
    } else if (surname.length <= 0) {
      showErrMessage("Soyadınızı Giriniz");
      e.preventDefault();
      setDisabledButton(false);
    } else if (mobileNo.length < 9) {
      showErrMessage("Cep Telefonu Giriniz");
      e.preventDefault();
      setDisabledButton(false);
    } else if (vendorName.length <= 0) {
      showErrMessage("Bayi Tabela Adı Giriniz");
      e.preventDefault();
      setDisabledButton(false);
    }
    // else if (vendorERPCode.length <= 0) {
    //   showErrMessage("Bayi Kodu Giriniz");
    //   e.preventDefault();
    // }
    else if (tapdkCode.length <= 0) {
      showErrMessage("Satış Belgesi Numaranızı Giriniz");
      e.preventDefault();
      setDisabledButton(false);
    } else if (tapdkType.length <= 0) {
      showErrMessage("Satış Belgesi Tipi Seçiniz");
      e.preventDefault();
      setDisabledButton(false);
    } else if (!document.getElementById("infoCheck").checked) {
      showErrMessage(
        "Kişisel veriler hakkında bildirim ve aydınlatma metnini kabul ediniz."
      );
      e.preventDefault();
      setDisabledButton(false);
    }
    // else if (username.length <= 0) {
    //   showErrMessage("Kullanıcı Adı Giriniz");
    //   e.preventDefault();
    //}
    else if (vendorERCCode.length <= 0) {
      showErrMessage("ERC Kodu Giriniz");
      e.preventDefault();
      setDisabledButton(false);
    } else if (password.length <= 0) {
      showErrMessage("Şifre Giriniz");
      e.preventDefault();
      setDisabledButton(false);
    } else if (confirmPassword.length <= 0) {
      showErrMessage("Şifre (Tekrar) Giriniz");
      e.preventDefault();
      setDisabledButton(false);
    } else if (password.length !== confirmPassword.length) {
      showErrMessage("Şifreler eşleşmiyor!");
      e.preventDefault();
      setDisabledButton(false);
    } else if (
      password.length > 0 &&
      /^(?=.*\d)[0-9a-zA-Z]{6,}$/.test(password) === false
    ) {
      showErrMessage(
        "Girilen şifre en az 6 karakterli olmalıdır, 1 rakam içermelidir ve en az 1 büyük harf içermelidir!"
      );
      e.preventDefault();
      setDisabledButton(false);
    } else {
      if (validatePasswords(password, confirmPassword)) {
        dispatch(
          authAction.registerVendor({
            name,
            surname,
            mobileNo,
            vendorName,
            //vendorERPCode,
            tapdkCode,
            tapdkType,
            //username,
            vendorERCCode,
            password
          })
        )
          .then(e => {
            if (e.isHttpSuccess) {
              showMessage("Kayıt Başarıyla Oluşturuldu.");
              setName("");
              setSurname("");
              setMobileNo("");
              setVendorName("");
              //setVendorERPCode("");
              setTapdkCode("");
              setTapdkType("");
              //setUsername("");
              setVendorERCCode("");
              setPassword("");
              setConfirmPassword("");
            }
            setDisabledButton(false);
          })
          .catch(err => {
            if (err.error.response.data.errors) {
              err.error.response.data.errors.map(item => {
                showErrMessage(item.message);
              });
            }
            setDisabledButton(false);
          });
      }
    }
  };

  // Handle Confirm Code
  const handleConfirmCode = event => {
    setConfirmCode(event.target.value);
  };

  return (
    <>
      <section>
        <div className="max-w-[464px] w-100 mx-auto !pt-4 md:!pt-[48px]">
          <Link to="/">
            <img src={JtiLogo2} alt="Jti" className="mx-auto" />
          </Link>

          <div className="flex flex-col text-center bg-white !mx-4 md:!mx-0 !px-4 md:!px-0 !mt-4 md:!mt-[54px]">
            <div className="pt-[32px] pb-[28px]">
              <img src={LoginLogo} alt="" className="w-[123px] mx-auto" />
            </div>

            <h3 className="!px-4 md:!px-[78px] text-base text-jti pb-[56px]">
              Bilgilerinizi doldurarak kayıt olabilirsiniz.
            </h3>

            <form
              onSubmit={e => {
                e.preventDefault();
              }}
              className="select-none"
            >
              <div className="relative w-full max-w-[26rem] m-auto">
                <input
                  type="text"
                  placeholder="Üye Adı"
                  maxLength="50"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  className="input input-bordered w-full mb-3 px-5 h-[64px]"
                />

                <div className="absolute top-[24px] left-0 ml-3">
                  <FaShieldAlt className="h-4 w-4 text-primary-500" />
                </div>
              </div>

              <div className="relative w-full max-w-[26rem] m-auto">
                <input
                  type="text"
                  placeholder="Üye Soyadı"
                  maxLength="50"
                  value={surname}
                  onChange={e => setSurname(e.target.value)}
                  className="input input-bordered w-full mb-3 px-5 h-[64px]"
                />

                <div className="absolute top-[24px] left-0 ml-3">
                  <FaShieldAlt className="h-4 w-4 text-primary-500" />
                </div>
              </div>

              <div className="relative w-full max-w-[26rem] m-auto">
                <PatternFormat
                  allowEmptyFormatting
                  placeholder=" (__) ___ __ __"
                  format="0 (5##) ### ## ##"
                  mask="_"
                  value={mobileNo}
                  onValueChange={values => {
                    setMobileNo(values.value);
                  }}
                  className="input input-bordered w-full mb-3 px-5 h-[64px]"
                />

                <div className="absolute top-[24px] left-0 ml-3">
                  <FaShieldAlt className="h-4 w-4 text-primary-500" />
                </div>
              </div>

              <div className="relative w-full max-w-[26rem] m-auto">
                <input
                  type="text"
                  placeholder="ERC Kodu"
                  maxLength="50"
                  value={vendorERCCode}
                  onChange={e => setVendorERCCode(e.target.value)}
                  className="input input-bordered w-full mb-3 px-5 h-[64px]"
                />

                <div className="absolute top-[24px] left-0 ml-3">
                  <FaShieldAlt className="h-4 w-4 text-primary-500" />
                </div>
              </div>

              <div className="relative w-full max-w-[26rem] m-auto">
                <input
                  type="text"
                  maxLength="50"
                  placeholder="Bayi Tabela Adı"
                  value={vendorName}
                  onChange={e => setVendorName(e.target.value)}
                  className="input input-bordered w-full mb-3 px-5 h-[64px]"
                />

                <div className="absolute top-[24px] left-0 ml-3">
                  <FaShieldAlt className="h-4 w-4 text-primary-500" />
                </div>
              </div>

              <div className="relative w-full max-w-[26rem] m-auto">
                <NumericFormat
                  placeholder="Satış Belgesi No"
                  allowLeadingZeros="true"
                  value={tapdkCode}
                  autoComplete="off"
                  onChange={e => setTapdkCode(e.target.value)}
                  className="input input-bordered w-full mb-3 px-5 h-[64px]"
                />

                <div className="absolute top-[24px] left-0 ml-3">
                  <FaShieldAlt className="h-4 w-4 text-primary-500" />
                </div>
              </div>

              <div className="relative w-full max-w-[26rem] m-auto">
                <select
                  className="select select-bordered w-full mb-3 px-5 h-[64px]"
                  value={tapdkType}
                  onChange={e => setTapdkType(e.target.value)}
                >
                  <option disabled selected value="">
                    Satış Belgesi Tipi
                  </option>
                  <option value="P">P</option>
                  <option value="PT">PT</option>
                </select>
              </div>

              <div className="relative w-full max-w-[26rem] m-auto">
                <input
                  type="password"
                  placeholder="Şifre"
                  maxLength="12"
                  value={password}
                  autoComplete="off"
                  onChange={e => {
                    setPassword(e.target.value);
                  }}
                  className="input input-bordered w-full mb-3 px-5 h-[64px]"
                />

                <div className="absolute top-[24px] left-0 ml-3">
                  <FaShieldAlt className="h-4 w-4 text-primary-500" />
                </div>
              </div>

              <div className="relative w-full max-w-[26rem] m-auto">
                <input
                  type="password"
                  placeholder="Şifre (Tekrar)"
                  maxLength="12"
                  value={confirmPassword}
                  autoComplete="off"
                  onChange={e => {
                    setConfirmPassword(e.target.value);
                  }}
                  className="input input-bordered w-full mb-3 px-5 h-[64px]"
                />

                <div className="absolute top-[24px] left-0 ml-3">
                  <FaShieldAlt className="h-4 w-4 text-primary-500" />
                </div>
              </div>

              <div className="relative w-full max-w-[26rem] m-auto flex">
                <label
                  htmlFor="infoCheck"
                  className="text-left m-0 inline-block align-middle text-xs"
                >
                  <input
                    type="checkbox"
                    className="checkbox mr-3 inline-block align-middle"
                    id="infoCheck"
                  />
                  <span className="text-xs">
                    Aşağıda bulunan aydınlatma metnini okudum, anladım,
                    bilgilendirildim.
                  </span>
                </label>
              </div>

              <button
                id="submit"
                name="submit"
                type="submit"
                className="btn bg-primary-500 hover:bg-primary-700 text-white rounded-[26px] w-full max-w-[26rem] h-[52px] mt-[48px] normal-case"
                disabled={disabledButton}
                onClick={e => checkRequiredFields(e)}
              >
                Kayıt Ol <FaArrowRight className="h-4 w-4" />
              </button>

              <div className="w-full max-w-[26rem] mx-auto mt-[32px] mb-[48px] p-3 text-xs font-semibold">
                <span>Zaten üye misiniz? </span>
                <Link
                  to={{ pathname: "/login" }}
                  className="underline underline-offset-4 text-primary-500 hover:text-primary-700"
                >
                  Giriş Yap
                </Link>
              </div>
            </form>
          </div>
        </div>

        <div className="max-w-[464px] w-100 mx-auto py-[48px] text-center">
          <div className="flex flex-row justify-center align-items-center">
            <FaPhoneAlt className="h-6 w-6 mr-3" />
            <a href="tel:0850 532 5 584" className="text-base font-semibold">
              0850 532 5 584
            </a>
          </div>

          <p className="text-xs opacity-50 mt-[29px] mb-[25px]">
            JTI Benim Bayim portalı sigara satış ruhsatına sahip noktalar için
            hazırlanmıştır.
          </p>

          <div className="text-xs font-medium">
            <a
              onClick={() => window.modalPrivacyPolicy.showModal()}
              className="mr-4"
            >
              Aydınlatma Metni
            </a>

            <a onClick={() => window.modalKVKK.showModal()} className="mr-4">
              Kişisel Veriler Hakkında Bildirim
            </a>

            <a onClick={() => window.modalCookies.showModal()}>
              Çerez Politikası
            </a>
          </div>
        </div>

        <dialog id="modalErcCode" className="modal">
          <form method="dialog" className="modal-box bg-white h-auto">
            <button
              htmlFor="modalErcCode"
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
            >
              ✕
            </button>
            <img src={ErcCode} alt="Erc Kodu Nedir?" />
          </form>
        </dialog>

        <dialog id="modalKVKK" className="modal">
          <form method="dialog" className="modal-box bg-white h-auto">
            <button
              htmlFor="modalKVKK"
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
            >
              ✕
            </button>
            <h3>
              JTI BENİM BAYİM GİZLİLİK VE KİŞİSEL VERİLERİN KORUNMASI İLE İLGİLİ
              BİLDİRİM
            </h3>
            <div>
              <h6>Sayın Bayimiz,</h6>
              <p>
                Bildiğiniz üzere, JTI Benim Bayim vasıtasıyla siz bayilerimizle
                şirketimizin ürünlerini satmanız sebebiyle oluşan ilişkimizi
                geliştirmek, ürünlerimiz hakkında size bilgi vermek, sizlere boş
                zamanlarınızda hoş vakit geçirilebileniz için bir takım
                faaliyetlerde bulunma imkanı tanıyoruz. Bu kapsamda sizlerden
                bazı kişisel bilgilerinizi alıyor ve bu bilgileri işliyoruz.
              </p>
              <p>
                İşbu Gizlilik ve Kişisel Verilerin Korunması Bildirimi’nin
                (“Bildirim”) amacı, JTI Tütün Ürünleri Pazarlama A.Ş ve bağlı
                şirketleri, iştirakleri ve hissedarlarının (“JTI”) kişisel
                verilerinizi işlerken, 6698 sayılı Kişisel Verilerin Korunması
                Kanunu’nun (“Kanun”) düzenlemeleri ile uyumlu olmasını ve
                bilgilerinizin gizliliğini sağlamaktır.
              </p>
              <p>
                Kanunun ihlali JTI tarafından ciddi bir şekilde ele alınacak
                olup, JTI söz konusu ihlali disiplin prosedürleri kapsamında
                değerlendirebilecektir. Kanunun amaçları çerçevesinde, aşağıdaki
                tanımlar esas alınacaktır:
              </p>
              <ul>
                <li>
                  a. Kişisel Veri: Kimliği belirli veya belirlenebilir gerçek
                  kişiye ilişkin her türlü bilgiyi;
                </li>
                <li>
                  b. Kişisel Verilerin İşlenmesi: Kişisel Verilerin tamamen veya
                  kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin
                  parçası olmak kaydıyla otomatik olmayan yollarla elde
                  edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi,
                  değiştirilmesi, yeniden düzenlenmesi, açıklanması,
                  aktarılması, devralınması, elde edilebilir hâle getirilmesi,
                  sınıflandırılması ya da kullanılmasının engellenmesi gibi
                  veriler üzerinde gerçekleştirilen her türlü işlemi;
                </li>
                <li>
                  c. İlgili Kişi: Kimliği belirli veya belirlenebilir gerçek
                  kişiye ilişkin her türlü bilgiyi;
                </li>
                <li>
                  d. Veri Sorumlusu: Kişisel Verilerin işleme amaçlarını ve
                  vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından
                  ve yönetilmesinden sorumlu olan gerçek veya tüzel kişiyi;
                </li>
                <li>
                  e. Veri İşleyen: Veri Sorumlusunun verdiği yetkiye dayanarak
                  onun adına kişisel verileri işleyen gerçek veya tüzel kişiyi;
                </li>
                <li>
                  f. Veri Sorumlusuna Başvuru: Veri sorumlusuna başvuru yapma
                  hakkını;
                </li>
                <li>g. Kurul: Kişisel Verileri Koruma Kurulunu;</li>
                <li>
                  h. Kanun: 6698 sayılı Kişisel Verilerin Korunması Kanununu;
                </li>
                <li>
                  i. Aydınlatma: İlgili Kişinin Kişisel Verilerinin işlenmesi
                  ile ilgili olarak Veri Sorumlusu tarafından her türlü
                  bilgilendirmeyi;
                </li>
                <li>
                  j. Kişisel Verilerin İşlenmesine İlişkin Mevzuat: Kanun ile
                  kendisi veya çıkarlarıyla bağlantılı olan kişisel verileri
                  işleyen gerçek veya tüzel kişilere ilişkin mevzuatı;
                </li>
                <li>k. KVKK: Kişisel Verilerin Korunması Kanununu;</li>
                <li>
                  l. Veri Sahibi Başvuru Usul ve Esasları Hakkında Tebliğ: Veri
                  Sahibi Başvuru Usul ve Esasları Hakkında Tebliğ’i;
                </li>
                <li>
                  m. Veri Sahibi: Kimliği belirli veya belirlenebilir gerçek
                  kişiyi;
                </li>
                <li>
                  n. Açık Rıza: Belirli bir konuya ilişkin, bilgilendirilmeye
                  dayanan ve özgür iradeyle açıklanan rızayı;
                </li>
                <li>
                  o. Anonim Hale Getirme: Kişisel Verilerin, ilgili kişinin
                  kimliği belirli veya belirlenebilir olmaktan çıkarılarak,
                  gerçek kişinin kimliğinin belirlenebilmesi halinde dahi
                  kişisel veri niteliği taşımayan hâle getirilmesi işlemini;
                </li>
                <li>
                  p. Veri İhlali: Kişisel Verilerin hukuka aykırı olarak
                  işlenmesi veya hukuka aykırı olarak başkasının eline geçmesi
                  ya da veri sorumlusunun belirlediği amaçlar doğrultusunda
                  işlenmesine uygun olmayan biçimde değiştirilmesi, ifşa
                  edilmesi, yok edilmesi veya kaybolması hâllerini;
                </li>
                <li>
                  r. Veri Sorumlusunun Yeterli Önlemleri Alması: Kişisel
                  Verilerin Kanuna uygun olarak işlendiğini ve koruma altına
                  alındığını sağlamak için gerekli güvenlik önlemlerini alan
                  veri sorumlusunu.
                </li>
              </ul>
              <p>
                İşbu Bildirimde sizlere, işlenen kişisel verilerinizle ilgili
                olarak JTI’nın, başta Aydınlatma yükümlülüğü olmak üzere,
                Kanun’un öngördüğü kişisel veri işleme ilkelerine uygun hareket
                etmekte olduğunu ve veri sorumlusu sıfatıyla Kanun’un öngördüğü
                yükümlülükleri yerine getirdiğini beyan etmektedir.
              </p>
              <p>
                İlgili kişi olarak, Kanun’un 11. Maddesi kapsamında aşağıdaki
                haklara sahipsiniz:
              </p>
              <ul>
                <li>1. Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
                <li>
                  2. Kişisel verileriniz işlenmişse buna ilişkin bilgi talep
                  etme,
                </li>
                <li>
                  3. Kişisel verilerinizin işlenme amacını ve bunların amacına
                  uygun kullanılıp kullanılmadığını öğrenme,
                </li>
                <li>
                  4. Kişisel verilerinizin yurt içinde veya yurt dışında
                  aktarıldığı üçüncü kişileri bilme,
                </li>
                <li>
                  5. Kişisel verilerinizin eksik veya yanlış işlenmiş olması
                  hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan
                  işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere
                  bildirilmesini isteme,
                </li>
                <li>
                  6. Kanun ve diğer ilgili mevzuat hükümlerine uygun olarak
                  kişisel verilerinizin silinmesini veya yok edilmesini isteme
                  ve bu kapsamda yapılan işlemin kişisel verilerinizin
                  aktarıldığı üçüncü kişilere bildirilmesini isteme,
                </li>
                <li>
                  7. İşlenen verilerinizin münhasıran otomatik sistemler
                  vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun
                  ortaya çıkmasına itiraz etme,
                </li>
                <li>
                  8. Kişisel verilerinizin kanuna aykırı olarak işlenmesi
                  sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep
                  etme.
                </li>
              </ul>
              <p>
                Kanun’un 11. maddesi uyarınca veri sorumlusu JTI’ye başvurarak
                yukarıda belirtilen haklarınızı kullanabilirsiniz.
              </p>
            </div>
          </form>
        </dialog>

        <dialog id="modalPrivacyPolicy" className="modal">
          <form method="dialog" className="modal-box bg-white h-auto">
            <button
              htmlFor="modalPrivacyPolicy"
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
            >
              ✕
            </button>
            <IllumintionText />
          </form>
        </dialog>

        <dialog id="modalCookies" className="modal">
          <form method="dialog" className="modal-box bg-white h-auto">
            <button
              htmlFor="modalCookies"
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
            >
              ✕
            </button>

            <h3 className="font-bold">ÇEREZ POLİTİKASI</h3>

            <div>
              <p>
                JTI Tütün Ürünleri Pazarlama A.Ş. (“<strong>JTI</strong>”)
                olarak, internet sitesi ziyaretçilerimize ait kişisel verilerin,
                6698 sayılı Kişisel Verilerin Korunması Kanunu ve ilgili sair
                mevzuata (“KVK Mevzuatı”) uygun olarak işlenmesi için azami
                hassasiyeti göstermekte ve internet sitemiz aracılığıyla çerez
                kullanmakta olduğumuzu belirtmek isteriz.
              </p>
              <p>
                İşbu Çerez Politikasının amacı, tarafımızca işletilmekte olan
                https://www.jtibenimbayim.com adresinin (“<strong>Site</strong>
                ”), Site kullanıcıları / üyeleri / ziyaretçileri (“ilgili kişi”)
                tarafından ziyaret edilmesi ile elde edilen çerezlerin, türleri
                ve kullanım amaçları hakkında ilgili kişileri bilgilendirmektir.
              </p>
              <p>
                <strong>Çerez Nedir?</strong>
              </p>
              <p>
                Çerezler, bir internet sitesini ziyaret ettiğiniz zaman
                bilgisayarınızda veya mobil cihazınızda (“cihaz”) saklanan ve
                söz konusu cihazın tespit edilmesine olanak sağlayan çoğunlukla
                harf veya sayılardan oluşan küçük metin dosyalarıdır.{" "}
              </p>
              <p>
                <strong>
                  Çerezler Vasıtasıyla İşlenen Kişisel Verileriniz Nelerdir?
                </strong>
              </p>
              <p>
                Çerezler vasıtasıyla Site üzerindeki kullanım ve ziyaret
                alışkanlıklarınız, oturum bilgileriniz, geçmiş işlemleriniz ve
                gezinme bilgileriniz işlenmektedir.{" "}
              </p>
              <p>
                <strong>
                  Çerezler Hangi Yöntemle Toplanmakta ve Hangi Amaçlarla
                  Kullanılmaktadır?{" "}
                </strong>
              </p>
              <p>
                Şirket olarak günlük dosyalar, boş gif dosyaları ve/veya üçüncü
                taraf kaynakları yoluyla topladığımız çerezlerden oluşan kişisel
                verilerinizi, KVK Mevzuatına uygun olarak ve aşağıda belirtilen
                amaçlar ile sınırlı olmak kaydıyla işlemekteyiz:
              </p>
              <ul className="cookie-policy">
                <li>
                  Site ziyaretçilerimize daha iyi hizmet sağlayabilmek amacıyla
                  Site üzerinde gezinme bilgileriniz işlenmekte, bu amaç ile
                  sınırlı olarak üçüncü kişilerle paylaşılmakta ve güvenli
                  olarak saklanmaktadır.
                </li>
                <li>
                  Tercihlerinizle ilgili bir özet oluşturmak amacıyla geçmiş
                  işlemleriniz depolanmaktadır.
                </li>
                <li>
                  Pazarlama izniniz dikkate alınmak suretiyle size özel tanıtım
                  yapmak, promosyonlar ve pazarlama teklifleri sunmak, Site’nin
                  içeriğini size göre iyileştirmek ve/veya tercihlerinizi
                  belirlemek amaçlarıyla, Site üzerinde gezinme bilgileriniz
                  ve/veya Site üzerindeki kullanım geçmişiniz izlenebilmektedir.
                </li>
                <li>
                  Site’nin nasıl kullanıldığını analiz etmek ve Siteyi kullanım
                  deneyiminizi iyileştirmek amaçlarıyla işlevsel ve analitik
                  çerezler kullanılmaktadır.
                </li>
                <li>
                  İlgi alanlarınıza ve size daha uygun içerik ve reklamları
                  sunmak için reklam/tanıtım amacıyla reklam çerezleri
                  kullanılmaktadır. Bu şekilde, Site’yi kullandığınızda size
                  daha uygun içerikler, kişiye özel kampanya ve ürünler
                  sunulmakta ve daha önceden istemediğinizi belirttiğiniz içerik
                  veya fırsatlar bir daha sunulmamaktadır. Reklam teknolojisi,
                  size özel reklamlar sunabilmek için web sitesine ve web
                  sitesinin reklam verdiği web sitelerine yaptığınız önceki
                  ziyaretlerle ilgili bilgileri kullanır. Bu reklamları
                  sunarken, web sitesinin sizi tanıyabilmesi amacıyla
                  tarayıcınıza benzersiz bir üçüncü taraf çerezi
                  yerleştirilebilir.{" "}
                </li>
              </ul>
              <p>
                Şirket, çevrimiçi ve çevrimdışı olarak farklı yöntemlerle
                toplanan bilgileri veya farklı zamanlarda Site üzerinde sizden
                toplanan bilgileri eşleştirebilir ve bu bilgileri üçüncü
                taraflar gibi başka kaynaklardan alınan bilgilerle birlikte
                kullanabilir.
              </p>
              <p>
                <strong>Hangi Çerez Türleri Kullanılmaktadır? </strong>
              </p>
              <p>
                Sitemizde üçüncü parti çerezleri (ziyaret ettiğiniz site
                haricindeki sunucular tarafından yerleştirilen)
                kullanılmaktadır. Aşağıda Sitemizde kullandığımız farklı türdeki
                çerezleri bulabilirsiniz.
              </p>
              <p>
                <strong>Kullanım Amaçlarına Göre Çerez Türleri:</strong>
              </p>
              <ul>
                <li>
                  <strong>İşlevsel ve Analitik Çerezler:</strong>Siteye nereden
                  bağlandığınız, Site üzerinde hangi içeriği görüntülediğiniz ve
                  ziyaretinizin süresi gibi Siteyi nasıl kullandığınızın
                  izlenmesi dâhil olmak üzere, Siteyi nasıl kullandığınızı
                  belirlemek için kullanılan çerezlerdir.
                </li>
              </ul>
              <p>
                <strong>Saklanma Sürelerine Göre Çerezler:</strong>
              </p>

              <ul>
                <li>
                  <strong>Kalıcı Çerezler:</strong>Site’nin işlevselliğini
                  artırmak, ziyaretçilerimize daha hızlı ve iyi bir hizmet
                  sunmak amacıyla kullanılan ve tarayıcılar vasıtasıyla
                  tarafınızca silininceye dek veya son kullanım tarihine kadar
                  geçerliliğini koruyan çerezlerdir.
                </li>
                <li>
                  <strong>Oturum Çerezleri:</strong>Siteyi ziyaretiniz süresince
                  kullanılan, tarayıcı kapatıldıktan sonra silinen geçici
                  çerezlerdir. Bu tür çerezler, kişisel veri içermezler ve
                  kişisel verilerin internette aktarılma ihtiyacını azaltmaya
                  yardım eder. Bu çerezleri silebilir veya kullanılmalarını
                  engelleyebilirsiniz; ancak, bu çerezlerin devre dışı
                  bırakılması, Site performansının düşmesine ve kullanıcı
                  deneyiminizin kısıtlanmasına neden olabilir.
                </li>
              </ul>

              <p>
                <strong>Google Analytics</strong>
              </p>

              <ul>
                <li>
                  <strong>İşlevi:</strong>İzleme / Analiz
                </li>
                <li>
                  <strong>Kullanılma Amacı:</strong>Performans ve kullanıcı
                  deneyimini artırmak için, siteye gelen ziyaretçi hareketlerini
                  farklı açılardan ölçümlemek ve analiz etmek.
                </li>
                <li>
                  <strong>Çalışma Yöntemi:</strong>jtibenimbayim.com sitesi
                  içerisinde hangi ekranlarda gezindiğiniz ve neler yaptığınız
                  hakkında isimsiz (anonim) toplu veriler sağlar.
                </li>
                <li>
                  <strong>Çerez Türü:</strong>Üçüncü Parti, Kalıcı ve Oturum
                  çerezleri
                </li>
              </ul>

              <p>
                <strong>Google Tag Manager</strong>
              </p>
              <ul>
                <li>
                  <strong>İşlevi:</strong>Google Analytics parametre yönetimi
                </li>
                <li>
                  <strong>Kullanılma Amacı:</strong>Google Analytics
                  çerezlerinin işlevinin doğru ayarlanması
                </li>
                <li>
                  <strong>Çalışma Yöntemi:</strong>Google Analytics çerezlerinin
                  ihtiyaç duyduğu verileri doğru şekilde toplamasını sağlar.
                </li>
                <li>
                  <strong>Çerez Türü:</strong>Üçüncü Parti, Kalıcı ve Oturum
                  çerezleri
                </li>
              </ul>

              <p>
                <strong>Yandex Metrica</strong>
              </p>
              <ul>
                <li>
                  <strong>İşlevi:</strong>İzleme / Analiz
                </li>
                <li>
                  <strong>Kullanılma Amacı:</strong>Performans ve kullanıcı
                  deneyimini artırmak için, siteye gelen ziyaretçi hareketlerini
                  farklı açılardan ölçümlemek ve analiz etmek.
                </li>
                <li>
                  <strong>Çalışma Yöntemi:</strong>jtibenimbayim.com sitesi
                  içerisinde hangi ekranlarda gezindiğiniz ve neler yaptığınız
                  hakkında isimsiz (anonim) toplu veriler sağlar.
                </li>
                <li>
                  <strong>Çerez Türü:</strong>Üçüncü Parti, Kalıcı ve Oturum
                  çerezleri
                </li>
              </ul>

              <p>
                <strong>
                  Kişisel Verinizin Toplanmasındaki Hukuki Sebep Nedir?
                </strong>
              </p>
              <p>
                Teknik açıdan gerekli olma halinde kişisel veriler “İlgili
                kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
                veri sorumlusunun ve diğer tarafların meşru menfaatleri için
                veri işlenmesinin zorunlu olması” sebebine; gezinti
                alışkanlıklarının analizinde ve reklam/pazarlama süreçlerinde
                kullanılan kişisel veriler ise ilgili kişinin açık rıza sunması
                hukuki sebebine dayalı olarak işlenir. Kullanıcı analizlerde,
                reklam ve pazarlama faaliyetlerinde kullanılan kişisel
                verilerinin işlenmesini engelleyebilir.
              </p>
              <p>
                <strong>Çerez Kullanımını Nasıl Kontrol Edebilirsiniz?</strong>
              </p>
              <p>
                Çerezler, web sitemize iletildikleri kullanıcının bilgisayarında
                saklanır. Kullanıcı olarak, bu nedenle çerezlerin kullanımı
                üzerinde tam kontrole sahipsiniz. Tanımlama bilgilerinin
                kullanımına onay vermiş olsanız bile, onayınızı istediğiniz
                zaman ileride geçerli olacak şekilde iptal edebilirsiniz.
              </p>
              <p>
                Sitemizi kullanabilmek için zorunlu (temel) çerezler dışındaki
                çerezlerin kullanımı zorunlu değildir; tarayıcınızın ayarlarını
                değiştirerek çerezlere ilişkin tercihlerinizi kişiselleştirme
                imkânına sahipsiniz.
              </p>
              <p>
                Tarayıcınızı işlenmesini istemediğiniz çerezleri engelleyecek
                şekilde ayarlamanız halinde, Sitemizi kullanmaya devam
                edebilirsiniz; ancak bu ihtimalde Site’nin tüm işlevlerine
                erişemeyebilirsiniz veya erişiminiz sınırlı olabilir, kullanıcı
                deneyiminizin kalitesi düşebilir. Bazı tarayıcıların çerez ayar
                sayfaları şunlardır:
              </p>
              <table border="1">
                <tbody>
                  <tr>
                    <td>Adobe Analytics</td>
                    <td>
                      <a href="http://www.adobe.com/uk/privacy/opt-out.html">
                        http://www.adobe.com /uk/privacy/opt-out.html
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>AOL</td>
                    <td>
                      <a href="https://help.aol.com/articles/restore-security-settings-and-enable-cookie-settings-on-browser">
                        https://help.aol.com/
                        articles/restore-security-settings-and-
                        enable-cookie-settings-on-browser
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Google Adwords</td>
                    <td>
                      <a href="https://support.google.com/ads/answer/2662922?hl=en">
                        https://support.google.com/ ads/answer/2662922?hl=en
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Google Analytics</td>
                    <td>
                      <a href="https://tools.google.com/dlpage/gaoptout">
                        https://tools.google.com/ dlpage/gaoptout
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Google Chrome</td>
                    <td>
                      <a href="http://www.google.com/support/chrome/bin/answer.py?hl=en&answer=95647">
                        http://www.google.com/
                        support/chrome/bin/answer.py?hl=en&answer=95647
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Internet Explorer</td>
                    <td>
                      <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">
                        https://support.microsoft.com/
                        en-us/help/17442/windows-internet-
                        explorer-delete-manage-cookies
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Mozilla Firefox</td>
                    <td>
                      <a href="http://support.mozilla.com/en-US/kb/Cookies">
                        http://support.mozilla.com/ en-US/kb/Cookies
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Opera</td>
                    <td>
                      <a href="http://www.opera.com/browser/tutorials/security/privacy/">
                        http://www.opera.com/
                        browser/tutorials/security/privacy/
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Safari</td>
                    <td>
                      <a href="https://support.apple.com/kb/ph19214?locale=tr TR">
                        https://support.apple.com/ kb/ph19214?locale=tr TR
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </dialog>
      </section>
    </>
  );
};

export default SingUp;
