export const GET_WWPLIST_PENDING = "GET_WWPLIST_PENDING";
export const GET_WWPLIST_FULFILLED = "GET_WWPLIST_FULFILLED";
export const GET_WWPLIST_REJECTED = "GET_WWPLIST_REJECTED";

export const GET_BONUS_WWP_PENDING = "GET_BONUS_WWP_PENDING";
export const GET_BONUS_WWP_FULFILLED = "GET_BONUS_WWP_FULFILLED";
export const GET_BONUS_WWP_REJECTED = "GET_BONUS_WWP_REJECTED";

export const GET_START_WWP_PENDING = "GET_START_WWP_PENDING";
export const GET_START_WWP_FULFILLED = "GET_START_WWP_FULFILLED";
export const GET_START_WWP_REJECTED = "GET_START_WWP_REJECTED";

export const GET_CONTROL_WWP_PENDING = "GET_CONTROL_WWP_PENDING";
export const GET_CONTROL_WWP_FULFILLED = "GET_CONTROL_WWP_FULFILLED";
export const GET_CONTROL_WWP_REJECTED = "GET_CONTROL_WWP_REJECTED";

export const SAVE_WWP_ANSWER_PENDING = "SAVE_WWP_ANSWER_PENDING";
export const SAVE_WWP_ANSWER_FULFILLED = "SAVE_WWP_ANSWER_FULFILLED";
export const SAVE_WWP_ANSWER_REJECTED = "SAVE_WWP_ANSWER_REJECTED";

export const GET_AVAILABLEWWP_BY_FIRMID_PENDING =
  "GET_AVAILABLEWWP_BY_FIRMID_PENDING";
export const GET_AVAILABLEWWP_BY_FIRMID_FULFILLED =
  "GET_AVAILABLEWWP_BY_FIRMID_FULFILLED";
export const GET_AVAILABLEWWP_BY_FIRMID_REJECTED =
  "GET_AVAILABLEWWP_BY_FIRMID_REJECTED";

export const GET_RESULT_WWP_BY_FIRMID_PENDING =
  "GET_RESULT_WWP_BY_FIRMID_PENDING";
export const GET_RESULT_WWP_BY_FIRMID_FULFILLED =
  "GET_RESULT_WWP_BY_FIRMID_FULFILLED";
export const GET_RESULT_WWP_BY_FIRMID_REJECTED =
  "GET_RESULT_WWP_BY_FIRMID_REJECTED";

export const SELECTED_OPTION = "SELECTED_OPTION";

export const WWP_ANSWER = "WWP_ANSWER";
