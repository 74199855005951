import * as topAnnouncementTypes from "./topAnnouncement.type";

const initialState = {
  topAnnouncementList: null,
  error: null,
  isLoading: false
};

const reducer = (
  state = initialState,
  { type, payload, topAnnouncementList, ...params }
) => {
  switch (type) {
    case `${topAnnouncementTypes.GET_ANNOUNCEMENT_BY_EMPLOYEEID_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${topAnnouncementTypes.GET_ANNOUNCEMENT_BY_EMPLOYEEID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        topAnnouncementList: payload.data
      };
    case `${topAnnouncementTypes.GET_ANNOUNCEMENT_BY_EMPLOYEEID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        topAnnouncementList: null
      };

    default:
      return state;
  }
};

export default reducer;
