import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { profileAction } from "redux/modules";

import ProfileLeftMenu from "./ProfileLeftMenu";
import ProfileContent from "./ProfileContent";

import "./Profile.scss";

const Profile = props => {
  const currentContent = useSelector(store => store.profile.currentContent);
  const SelectedContentDisplay = ProfileContent(currentContent);
  // const isFromFooter = useSelector(store => store.profile.isFromFooter);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    return () => {};
  }, []);

  // ################################################
  // Scroll the window to the top on every navigation
  // ################################################
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  /* if (isFromFooter && isFromFooter === "a") {
    alert("Comming from footer");
  } else {
    alert("NOT from footer");
  } */
  if (props.location.from && props.location.from === "footer") {
    dispatch(profileAction.setProfileContent("message"));
    dispatch(profileAction.showContactUs("show"));
    props.location.from = null;
  }
  return (
    <section>
      <div className="container-fluid mx-auto text-center page-head">
        <h3>Profil</h3>
      </div>

      <div className="container mx-auto my-20">
        <div className="flex flex-col lg:flex-row w-100">
          <ProfileLeftMenu />

          <div className="basis-full lg:basis-9/12 pl-10 user-menu-container">
            <div className="user-menu-content flex-fill">
              <div className="tab-content">
                <SelectedContentDisplay />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
