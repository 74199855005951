import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ChangePassword from "./ChangePassword";
import LoginInfo from "./LoginInfo";
import Security from "./Security";
import { profileAction, authAction } from "redux/modules";
import moment from "moment";
import "moment/locale/tr";

// DatePicker
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import tr from "date-fns/locale/tr"; // the locale you want

import showErrMessage from "../../../hooks/showErrMessage";
import showMessage from "../../../hooks/showMessage";

// DatePicker Styles
import "react-datepicker/dist/react-datepicker.css";

const ProfileAccountInfo = () => {
  // DatePicker Defaults
  setDefaultLocale("tr");
  registerLocale("tr", tr);

  // Store
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const dispatch = useDispatch();

  const [sms, setSms] = useState(false);
  const [call, setCall] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [birthDay, setBirthDay] = useState(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    if (employeeFirm && isFirstLoad) {
      setIsFirstLoad(false);

      // sms
      if (sms === false) {
        setSms(employeeFirm.employee?.smsPermission || false);
      }

      // call
      if (call === false) {
        setCall(employeeFirm.employee?.callPermission || false);
      }

      if (employeeFirm.employee?.birthDate) {
        const formattedDate = moment(
          employeeFirm.employee.birthDate,
          "DD-MM-YYYY"
        ).toDate();
        setSelectedDate(formattedDate);
        setBirthDay(employeeFirm.employee.birthDate);
      }
    }
  }, [employeeFirm]);

  useEffect(() => {
    console.log("isFirstLoad: ", isFirstLoad);

    return () => {};
  }, [isFirstLoad]);

  // 18 Years Old
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 18);

  const changeStatus = type => {
    if (type === "message") {
      setSms(!sms);
    } else {
      setCall(!call);
    }
  };

  const sendStatus = () => {
    const data = {
      empId: employeeFirm.id,
      iysPartList: [
        {
          type: "message",
          status: sms
        },
        {
          type: "call",
          status: call
        }
      ]
    };

    dispatch(
      profileAction.setBirthDate(
        employeeFirm.employee.id,
        employeeFirm.id,
        birthDay
      )
    )
      .then(() => {
        dispatch(authAction.updateEmployee(birthDay));
        dispatch(profileAction.saveMessageManagement(data))
          .then(res => {
            if (res.isHttpSuccess) {
              showMessage("Bilgileriniz başarılı bir şekilde güncellenmiştir.");
              dispatch(
                authAction.returnNewScoreOfEmployee(
                  employeeFirm.firm.id,
                  employeeFirm.id
                )
              );
            }
          })
          .catch(err => {
            showErrMessage(err.error.response.data.errors[0].message);
          });
      })
      .catch(err => {
        showErrMessage(err.error.response.data.errors[0].message);
      });
  };

  const handleSelect = date => {
    const formattedDate = moment(date).format("DD-MM-YYYY");
    setSelectedDate(date);
    setBirthDay(formattedDate);
  };

  return (
    <div
      className="tab-pane fade show active"
      id="accountinfo"
      role="tabpanel"
      aria-labelledby="accountinfo-tab"
    >
      <div className="user-content-item account-info">
        <div className="user-content-text">
          <p style={{ fontSize: "14px" }}>
            Aşağıdaki bilgilerde değişiklik yapabilmek için 0850 532 5 584 (JTI)
            numaralı Çağrı Merkezi’mizi arayabilir, Satış Belgesi numaranızı ve
            bayi kodunuzu kullanarak müşteri temsilcimizle görüşebilirsiniz.
            Şifre değiştirme bölümü aracılığıyla şifrenizi değiştirebilirsiniz.
          </p>
        </div>

        <ul className="nav" role="tablist">
          <li>
            <a
              className="active"
              id="accountinfo-sub-tab"
              data-toggle="tab"
              href="#accountinfo-sub"
              role="tab"
              aria-controls="accountinfo-sub"
              aria-selected="true"
            >
              Hesap Bilgilerim
            </a>
          </li>
          <li className="nav-item">
            <a
              id="changepassword-tab"
              data-toggle="tab"
              href="#changepassword"
              role="tab"
              aria-controls="changepassword"
              aria-selected="false"
            >
              Şifre Değiştirme
            </a>
          </li>
          <li className="nav-item">
            <a
              id="logininfo-tab"
              data-toggle="tab"
              href="#logininfo"
              role="tab"
              aria-controls="logininfo"
              aria-selected="false"
            >
              Giriş Bilgileri
            </a>
          </li>
          <li className="nav-item">
            <a
              id="security-tab"
              data-toggle="tab"
              href="#security"
              role="tab"
              aria-controls="security"
              aria-selected="false"
            >
              Güvenlik
            </a>
          </li>
        </ul>

        <div className="tab-content">
          <div
            className="tab-pane fade show active"
            id="accountinfo-sub"
            role="tabpanel"
            aria-labelledby="accountinfo-sub-tab"
          >
            <div className="form-container">
              <form
                id="accountInfoForm"
                name="accountInfoForm"
                onSubmit={e => {
                  e.preventDefault();
                  sendStatus();
                }}
              >
                <div className="form-row">
                  {employeeFirm && employeeFirm.vendorName && (
                    <div className="form-el">
                      <div className={"mb-1"}>Bayi Tabela Adı</div>
                      <input
                        id="vendorName"
                        type="text"
                        className="form-control"
                        placeholder="Bayi Tabela Adı"
                        disabled
                        value={employeeFirm.vendorName}
                      />
                    </div>
                  )}

                  {employeeFirm?.employee?.name && (
                    <div className="form-el">
                      <div className={"mb-1"}>Üye Adı</div>
                      <input
                        id="name"
                        type="text"
                        className="form-control"
                        placeholder="Üye Adı"
                        disabled
                        value={employeeFirm.employee.name}
                      />
                    </div>
                  )}

                  {employeeFirm?.employee?.surname && (
                    <div className="form-el">
                      <div className={"mb-1"}>Üye Soyadı</div>
                      <input
                        id="surname"
                        type="text"
                        className="form-control"
                        placeholder="Üye Soyadı"
                        disabled
                        value={employeeFirm.employee.surname}
                      />
                    </div>
                  )}

                  {employeeFirm?.tapdkFull && (
                    <div className="form-el">
                      <div className={"mb-1"}>Satış Belgesi Numarası</div>
                      <input
                        id="tapdkNumber"
                        type="text"
                        className="form-control"
                        placeholder="Satış Belgesi Numarası"
                        disabled
                        value={employeeFirm.tapdkFull}
                      />
                    </div>
                  )}

                  {employeeFirm?.ercCode && (
                    <div className="form-el">
                      <div className={"mb-1"}>ERC Kodu</div>
                      <input
                        id="username"
                        type="text"
                        className="form-control"
                        placeholder="ERC Kodu"
                        disabled
                        value={employeeFirm.ercCode}
                      />
                    </div>
                  )}

                  {employeeFirm?.employee?.mobileNo && (
                    <div className="form-el">
                      <div className={"mb-1"}>Cep Telefonu</div>
                      <input
                        id="mobileno"
                        type="text"
                        className="form-control"
                        placeholder="Cep Telefonu"
                        disabled
                        value={employeeFirm.employee.mobileNo}
                      />
                    </div>
                  )}

                  {employeeFirm?.employee && (
                    <div className="form-el">
                      <div>İleti Yönetimi</div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          checked={sms}
                          className="form-check-input"
                          id="sms"
                          onChange={() => changeStatus("message")}
                        />
                        <label className="form-check-label" htmlFor="sms">
                          SMS
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          checked={call}
                          className="form-check-input"
                          id="call"
                          onChange={() => changeStatus("call")}
                        />
                        <label className="form-check-label" htmlFor="call">
                          Telefon
                        </label>
                      </div>
                    </div>
                  )}

                  <div className="form-el">
                    <div className={"mb-1"}>Doğum Günü</div>

                    <DatePicker
                      selected={selectedDate}
                      className="form-control"
                      onChange={handleSelect}
                      dateFormat="dd-MM-yyyy"
                      maxDate={maxDate}
                      placeholderText="Doğum Günü"
                      showMonthDropdown
                      showYearDropdown
                    />
                  </div>

                  <div className="pl-md-4 pr-md-4 pl-4 pr-4 w-100 d-none">
                    <p className="profile-account-info-desc">
                      Benim Bayim'den gelecek iletileri hangi kanaldan almayı
                      tercih ettiğinizi buradan belirleyebilirsiniz.
                    </p>
                  </div>

                  <div className="m-0 mt-3 pl-md-4 pr-md-4 w-100 submit d-block d-md-flex">
                    <button type="submit" className="ea-btn bg-green d-block">
                      KAYDET
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <ChangePassword />
          <LoginInfo />
          <Security />
        </div>
      </div>
    </div>
  );
};

export default ProfileAccountInfo;
