import React, { useState } from "react";
import { useSelector } from "react-redux";
import WeekStoreLeftMenu from "./WeekStoreLeftMenu";
import WeekStoreContent from "./WeekStoreContent";
import WeekVoting from "./WeekVoting";

import StoreIcon from "assets/portal-resource/img/games-icons/icon-store.png";
import HdIcon from "assets/images/new-design/hd-icon/hd-icon.png";

import { IoArrowDown } from "react-icons/io5";
import { FaInfoCircle, FaPlayCircle } from "react-icons/fa";

// Splash
import Splash from "assets/images/new-design/game/splash/splash-bb-dukkan.jpg";

import VideoPopup from "../../../components/VideoPopup";

const WeekStore = () => {
  const currentWeekStoreContent = useSelector(
    store => store.weekStore.currentWeekStoreContent
  );
  const SelectedContentDisplay = WeekStoreContent(currentWeekStoreContent);
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = e => {
    e.preventDefault();

    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    <div className="games" style={{ background: "none" }}>
      <section id="hafta-dukkan" style={{ display: "none" }}>
        <div className="intro">
          <div className="container">
            <div
              className="row hafta-dukkan-desc-row"
              style={{ maxWidth: "none" }}
            >
              <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 shop-of-the-weeek">
                <div className="games-content">
                  <div className="games-content-item">
                    <div className="games-content-header">
                      <img src={StoreIcon} className="img-fluid" />
                    </div>

                    <div className="games-content-body">
                      <h3>HAFTANIN DÜKKANI</h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 mt-5 mt-md-0">
                <div className="ml-md-5">
                  <h1 className="mt-md-3 mb-md-3">Haftanın Dükkanı</h1>
                  <p>
                    Bu yarışmamızda her hafta <strong>8</strong> farklı
                    bayimizin dükkan fotoğrafı oylarınızı bekliyor. En çok
                    beğendiğiniz fotoğrafa oy vererek her hafta{" "}
                    <strong>200 puan</strong> kazanabilirsiniz. Sadece ilk
                    oylamanızdan puan kazanacaksınız ama dilerseniz birden fazla
                    oy kullanabilir ve diğer bayilerimizin dükkanlarını da
                    destekleyebilirsiniz.
                  </p>
                  <p>
                    Her hafta en çok oy alan fotoğraf,{" "}
                    <strong>Haftanın Dükkanı </strong>
                    seçilecek ve <strong>Haftanın Kazananları</strong> bölümünde
                    ilan edilecektir. <strong>Haftanın Dükkanı</strong> seçilen
                    bayi, seçilmiş olduğu hafta boyunca dükkan fotoğrafını
                    güncelleyemez.
                  </p>
                  <p>
                    Her haftanın en çok oy alan dükkan fotoğraflarını görmek
                    için <strong>Haftanın Kazananları</strong> bölümünü
                    inceleyebilirsiniz.
                  </p>
                  <p>
                    <strong>Haftanın Dükkanı</strong>’nı belirlemek için oy
                    verebilirsiniz. Aşağıdaki en beğendiğiniz fotoğrafa
                    tıklayınız ve <strong>Oy Ver</strong> butonuna tıklayarak
                    oyunuzu verebilirsiniz.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="hafta-dukkan-liste" className="main-content gray">
          <div className="container">
            <div className="row" style={{ maxWidth: "none" }}>
              <WeekStoreLeftMenu />
              <SelectedContentDisplay />
            </div>
          </div>
        </div>
        <WeekVoting />
      </section>

      <div className="mx-auto px-4 pt-4 xl:container lg:pt-20">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
          <div className="col-span-4 flex flex-col justify-start rounded-xl text-center md:col-span-2 md:col-start-2 lg:col-span-1">
            <div className="min-h-[300px] mb-3 col-span-4 flex flex-col justify-center rounded-xl bg-[url('assets/images/new-design/hd-icon/hd-bg@2x.png')] p-6 text-center md:col-span-2 md:col-start-2 lg:col-span-1 lg:rounded-bl-[100px]">
              <h1 className="mb-4 text-2xl font-bold text-white md:mb-6 lg:text-4xl">
                Haftanın Dükkanı
              </h1>

              <img
                src={HdIcon}
                className="mx-auto max-h-[120px] lg:max-h-[200px]"
                alt=""
              />
            </div>

            <div>
              <WeekStoreLeftMenu />
            </div>
          </div>

          <div className="relative col-span-4 rounded-xl bg-slate-200 bg-opacity-75 p-6 text-[15px] leading-7 text-slate-500 lg:col-span-3 lg:px-12 lg:py-16">
            <h3 className="mb-8 inline-flex items-center gap-4 text-2xl font-semibold text-slate-700">
              <FaInfoCircle className="h-6 w-6" /> Haftanın Dükkanı'na
              Hoşgeldiniz
            </h3>
            <p className="mb-6">
              Bu yarışmamızda her hafta{" "}
              <span className="text-slate-700">8</span> farklı bayimizin dükkan
              fotoğrafı oylarınızı bekliyor. En çok beğendiğiniz fotoğrafa oy
              vererek her hafta 200 puan kazanabilirsiniz. Sadece ilk
              oylamanızdan puan kazanacaksınız ama dilerseniz birden fazla oy
              kullanabilir ve diğer bayilerimizin dükkanlarını da
              destekleyebilirsiniz.
            </p>

            <p className="mb-6">
              Her hafta en çok oy alan fotoğraf,{" "}
              <span className="text-slate-700">Haftanın Dükkanı</span> seçilecek
              ve <span className="text-slate-700">Haftanın Kazananları</span>{" "}
              bölümünde ilan edilecektir.{" "}
              <span className="text-slate-700">Haftanın Dükkanı</span> seçilen
              bayi, seçilmiş olduğu hafta boyunca dükkan fotoğrafını
              güncelleyemez.
            </p>

            <p>
              Her haftanın en çok oy alan dükkan fotoğraflarını görmek için
              Haftanın Kazananları bölümünü inceleyebilirsiniz. Haftanın
              Dükkanı'nı belirlemek için oy verebilirsiniz. Aşağıdaki en
              beğendiğiniz fotoğrafa tıklayınız ve{" "}
              <span className="text-slate-700">Oy Ver</span> butonuna tıklayarak
              oyunuzu verebilirsiniz.
            </p>
            <div className="absolute bottom-0 left-1/2 flex h-12 w-12 -translate-x-1/2 translate-y-1/2 transform items-center justify-center rounded-full bg-white shadow-lg">
              <IoArrowDown className="h-5 w-5" />
            </div>

            <div className="flex flex-col justify-center gap-6 !mt-4 items-center relative">
              <a
                href="#"
                onClick={openPopup}
                className="relative w-[240px] h-[160px] block rounded-xl"
              >
                <img src={Splash} className="rounded-xl" alt="" />
                <FaPlayCircle className="h-8 w-8 text-white absolute top-[50%] left-[50%] mt-[-16px] ml-[-16px]" />
              </a>

              <h2 className="text-slate-600 font-semibold text-xl">
                İzlemek için videoya tıklayınız
              </h2>
            </div>
          </div>
        </div>

        <div className="my-14 grid grid-cols-4 gap-4">
          <div className="col-span-4 lg:col-span-3 lg:col-start-2">
            <SelectedContentDisplay />
          </div>
        </div>
      </div>

      <VideoPopup
        videoUrl={process.env.REACT_APP_VIDEO_SHOW_URL + "JTI_BB_DUKKAN_03.mp4"}
        isOpen={isOpen}
        onClose={closePopup}
      />
    </div>
  );
};

export default WeekStore;
