import * as lookFindTypes from "./lookFind.type";
import { API } from "../../../services/http.service";
import showErrMessage from "hooks/showErrMessage";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getControlLookFindByFirmId = (
  id,
  employeeId
) => async dispatch => {
  dispatch({ type: lookFindTypes.GET_AVAILABLELOOKFIND_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/lookFind/${id}/${employeeId}`,
      config
    );
    dispatch({
      type: lookFindTypes.GET_AVAILABLELOOKFIND_BY_FIRMID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: lookFindTypes.GET_AVAILABLELOOKFIND_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};

export const getLookFindByFirmId = (id, employeeId) => async dispatch => {
  dispatch({ type: lookFindTypes.GET_LOOKFINDLIST_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/lookFindQuestion/${id}/${employeeId}`,
      config
    );
    dispatch({
      type: lookFindTypes.GET_LOOKFINDLIST_BY_FIRMID_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: lookFindTypes.GET_LOOKFINDLIST_BY_FIRMID_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const saveQuestionAnswer = (
  question,
  questionChoice,
  employeeFirmId,
  firmId
) => async dispatch => {
  // dispatch({ type: lookFindTypes.SAVE_LOOKFIND_ANSWER_PENDING });

  const data = { question, questionChoice, employeeFirmId };
  const response = await API.post(`/lookFind/save`, data);
  const interaction = {
    message: " ",
    type: "notification"
  };

  dispatch({
    type: lookFindTypes.SAVE_LOOKFIND_ANSWER_FULFILLED,
    payload: response,
    interaction
  });

  dispatch({
    type: lookFindTypes.SELECTED_OPTION,
    payload: null
  });

  return Promise.resolve(response);
};

export const getResultLookFind = (id, employeeId) => async dispatch => {
  dispatch({ type: lookFindTypes.GET_RESULT_LOOKFIND_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/lookFindAnswer/${id}/${employeeId}`,
      config
    );
    dispatch({
      type: lookFindTypes.GET_RESULT_LOOKFIND_BY_FIRMID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: lookFindTypes.GET_RESULT_LOOKFIND_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};

export const changeOption = selectedOption => async dispatch => {
  dispatch({ type: lookFindTypes.SELECTED_OPTION, payload: selectedOption });
};
