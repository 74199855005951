import React from "react";
import { useSelector } from "react-redux";
import { videoShowUrl } from "../../../config/api.config";

import "./DynamicTvPage.scss";

const DynamicTvPage = () => {
  const articleList = useSelector(store => store.magazine.articleList);

  return (
    <div className="row benim-bayim-video-row">
      {articleList && articleList.length > 0
        ? articleList.map((item, index) => {
            return (
              <div
                key={index}
                className="col-md-4 benim-bayim-video-item video-item"
              >
                <a
                  data-fancybox="video-3"
                  href={videoShowUrl + item.videoUrl}
                  className="package-video package-video-bg"
                ></a>
                <div className="tv-area-video-description">
                  <div className="description">{item.name}</div>
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default DynamicTvPage;
