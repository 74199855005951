export const GET_BRAND_STYLE_BY_ID_PENDING = "GET_BRAND_STYLE_BY_ID_PENDING";
export const GET_BRAND_STYLE_BY_ID_FULFILLED =
  "GET_BRAND_STYLE_BY_ID_FULFILLED";
export const GET_BRAND_STYLE_BY_ID_REJECTED = "GET_BRAND_STYLE_BY_ID_REJECTED";

export const SET_SLIDER_PENDING = "SET_SLIDER_PENDING";
export const SET_SLIDER_FULFILLED = "SET_SLIDER_FULFILLED";
export const SET_SLIDER_REJECTED = "SET_SLIDER_REJECTED";

export const SET_STYLE_INDEX_PENDING = "SET_STYLE_INDEX_PENDING";
export const SET_STYLE_INDEX_FULFILLED = "SET_STYLE_INDEX_FULFILLED";
export const SET_STYLE_INDEX_REJECTED = "SET_STYLE_INDEX_REJECTED";

export const SET_INDEX_BULLET_PENDING = "SET_INDEX_BULLET_PENDING";
export const SET_INDEX_BULLET_FULFILLED = "SET_INDEX_BULLET_FULFILLED";
export const SET_INDEX_BULLET_REJECTED = "SET_INDEX_BULLET_REJECTED";

export const SELECTED_DATA_LIST = "SELECTED_DATA_LIST";
