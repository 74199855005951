import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const User = () => {
  const employeeFirm = useSelector(store => store.auth.employeeFirm);

  return <div>{employeeFirm && employeeFirm.id}</div>;
};

export default User;
