import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pickFindAction } from "redux/modules";
import { Link } from "react-router-dom";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";
import { imageShowUrl } from "../../../config/api.config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import CardsIcon from "assets/portal-resource/img/games-icons/icon-cards.png";

import {
  IoMenuOutline,
  IoCloseOutline,
  IoChevronDown,
  IoArrowBack,
  IoArrowForward,
  IoInformationCircle
} from "react-icons/io5";

import PickFindIcon from "assets/images/new-design/game/card-icon.png";

const PickFindAnswer = props => {
  const pickFindList = useSelector(store => store.pickFind.pickFindList);
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const isLoading = useSelector(store => store.pickFind.isLoading);
  var resultPickFind = null;

  useEffect(() => {
    dispatch(
      pickFindAction.getPickFindByFirmId(employeeFirm.firm.id, employeeFirm.id)
    );
    return () => {};
  }, []);

  if (pickFindList) {
    resultPickFind = pickFindList.map((item, index) => {
      return (
        <div
          key={index}
          className="bg-slate-200 flex-1 flex justify-center rounded-[12px] flex-col py-8 px-8"
        >
          <h4 className="md:text-[48px] text-[24px] text-center font-semibold text-slate-700">
            🎉 Tebrikler
          </h4>
          <p className="md:text-[16px] text-[14px] text-center font-normal text-slate-500 md:pt-[16px] pt-[12px]">
            Tüm eşleştirmeleri doğru yaptınız ve <strong>{item.score}</strong>{" "}
            puan kazandınız.
          </p>

          {item.answeredNextDayMessage && (
            <p className="md:text-[16px] text-[14px] text-center font-normal text-slate-500 md:pt-[16px] pt-[12px]">
              {item.answeredNextDayMessage}
            </p>
          )}

          <Link
            to={{ pathname: "/userportal/competitions" }}
            className="mx-auto md:mt-[24px] mt-[16px] font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px]"
          >
            <IoArrowBack className="h-5 w-5" />
            Yarışmalar{" "}
          </Link>
        </div>
      );
    });
  }

  return (
    <div className="games games-detail-page">
      <GlobalLoader isLoading={isLoading} />

      {/* <div className="container-fluid">
        <div className="wrapper-games-content row justify-content-center">
          <div className="col-12 col-md-4 col-lg-3 choose-find d-flex flex-column">
            <div className="games-content">
              <div className="games-content-item">
                <div className="games-content-header">
                  <img src={CardsIcon} className="img-fluid" />
                </div>

                <div className="games-content-body games-content-badge-body">
                  <h3>Eşle Kazan</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-8 col-lg-9 d-flex flex-column justify-content-center">
            <div className="games-brands games-badges">{resultPickFind}</div>
          </div>
        </div>
      </div> */}

      <div className="container mx-auto py-20 px-6 md:px-0">
        <div className="flex gap-4 h-100 flex-col md:flex-row">
          <div className="relative h-[333px] w-[308px] gap-20 bg-[url('assets/images/new-design/game/card-bg.png')] bg-left-bottom bg-no-repeat mx-auto">
            <h2 className="md:text-[36px] text-[28px] text-center px-4 py-4 font-bold text-white">
              Eşle Kazan
            </h2>
            <img
              src={PickFindIcon}
              className="w-[220px] absolute bottom-0 left-1/2 -translate-x-1/2"
              alt=""
            />
          </div>

          {resultPickFind && resultPickFind}
        </div>
      </div>
    </div>
  );
};
export default PickFindAnswer;
