import * as arenaTypes from "./ourArena.type";
import SubjectOfWeek from "pages/UserPortal/OurArena/SubjectOfWeek";

const initialState = {
  currentArenaContent: SubjectOfWeek,
  error: null,
  isLoading: false,
  subjectList: [],
  subjectPastList: [],
  forumMessageList: []
};

const reducer = (
  state = initialState,
  { type, payload, actives, ...params }
) => {
  switch (type) {
    case `${arenaTypes.SET_ARENA_CONTENT_PENDING}`:
    case `${arenaTypes.GET_SUBJECTS_PENDING}`:
    case `${arenaTypes.SAVE_INITIAL_MESSAGE_PENDING}`:
    case `${arenaTypes.SAVE_LIKE_PENDING}`:
    case `${arenaTypes.GET_PAST_SUBJECTS_PENDING}`:
    case `${arenaTypes.GET_SELECTED_PAST_SUBJECTS_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case `${arenaTypes.SET_ARENA_CONTENT_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        currentArenaContent: payload
      };
    case `${arenaTypes.SET_ARENA_CONTENT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        currentArenaContent: null
      };

    case `${arenaTypes.SAVE_INITIAL_MESSAGE_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };
    case `${arenaTypes.SAVE_INITIAL_MESSAGE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${arenaTypes.SAVE_LIKE_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };
    case `${arenaTypes.SAVE_LIKE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${arenaTypes.GET_SUBJECTS_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        subjectList: payload.forumSubjectDTOS,
        totalPages: payload.totalPages
      };
    case `${arenaTypes.GET_SUBJECTS_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        subjectList: null
      };

    case `${arenaTypes.GET_PAST_SUBJECTS_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        subjectPastList: payload
      };
    case `${arenaTypes.GET_PAST_SUBJECTS_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        subjectPastList: null
      };
    case `${arenaTypes.GET_SELECTED_PAST_SUBJECTS_FULLFILLED}`:
      return {
        ...state,
        isLoading: false,
        forumMessageList: payload.forumMessageDTOS,
        totalPages: payload.totalPages
      };
    case `${arenaTypes.GET_SELECTED_PAST_SUBJECTS_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        forumMessageList: null
      };
    default:
      return state;
  }
};

export default reducer;
