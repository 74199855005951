import * as gameTypes from "./game.type";
import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: {
    "Content-Type": "application/json-application"
  }
};

export const getDisclaimerByFirmId = (id, employeeId) => async dispatch => {
  try {
    const response = await API.get(
      `${REQUEST_URL}/disclaimer/${id}/${employeeId}`,
      config
    );
    return Promise.resolve(response);
  } catch (error) {}
};

export const getCompetitionHowPlayByFirmId = (
  id,
  employeeId
) => async dispatch => {
  dispatch({
    type: gameTypes.GET_HOW_PLAY_BY_FIRMID_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/competitionHowPlay/${id}/${employeeId}`,
      config
    );
    dispatch({
      type: gameTypes.GET_HOW_PLAY_BY_FIRMID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: gameTypes.GET_HOW_PLAY_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};

export const getCompetetionGift = () => async dispatch => {
  dispatch({
    type: gameTypes.GET_HOW_PLAY_BY_FIRMID_PENDING
  });
  try {
    const response = await API.get(`${REQUEST_URL}/competitionGift/1`, config);
    dispatch({
      type: gameTypes.GET_HOW_PLAY_BY_FIRMID_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: gameTypes.GET_HOW_PLAY_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};

export const getCompetitionDisclaimerByFirmId = (
  id,
  employeeFirmId
) => async dispatch => {
  dispatch({
    type: gameTypes.GET_DISCLAIMER_BY_FIRMID_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/competitionDisclaimer/${id}/${employeeFirmId}`,
      config
    );
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: gameTypes.GET_DISCLAIMER_BY_FIRMID_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const saveCompetitionDisclaimer = (
  competitionDisclaimer,
  employeeFirmId,
  firmId
) => async dispatch => {
  dispatch({
    type: gameTypes.SAVE_COMPETITION_DISCLAIMER_PENDING
  });

  const data = {
    competitionDisclaimer,
    employeeFirmId,
    firmId
  };
  try {
    const response = await API.post(`/competitionDisclaimer/save`, data);

    dispatch({
      type: gameTypes.SAVE_COMPETITION_DISCLAIMER_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: gameTypes.SAVE_COMPETITION_DISCLAIMER_REJECTED,
      payload: error
    });
  }
};

export const getAllTrueAnswerByFirmId = (id, page) => async dispatch => {
  dispatch({
    type: gameTypes.GET_ALL_TRUE_ANSWER_BY_FIRMID_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/competitionParticipantOrder/${id}?page=${page}&size=15`,
      config
    );

    dispatch({
      type: gameTypes.GET_ALL_TRUE_ANSWER_BY_FIRMID_FULFILLED,
      payload: response.data
    });
    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: gameTypes.GET_ALL_TRUE_ANSWER_BY_FIRMID_REJECTED,
      payload: error
    });
    return Promise.reject();
  }
};

export const getAllTrueAnswersByFilter = (
  searchVendor,
  searchDate,
  firmId,
  page
) => async dispatch => {
  dispatch({
    type: gameTypes.GET_ALL_TRUE_ANSWER_BY_FIRMID_PENDING
  });

  const data = {
    searchVendor,
    searchDate,
    firmId
  };
  try {
    const response = await API.post(
      `${REQUEST_URL}/competitionOrder/search?page=${page}&size=15`,
      data,
      config
    );
    dispatch({
      type: gameTypes.GET_ALL_TRUE_ANSWER_BY_FIRMID_FULFILLED,
      payload: response.data
    });
    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: gameTypes.GET_ALL_TRUE_ANSWER_BY_FIRMID_REJECTED,
      payload: error
    });
    return Promise.reject();
  }
};

export const getWinnerByFirmId = id => async dispatch => {
  dispatch({
    type: gameTypes.GET_WINNER_BY_FIRMID_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/competitionWinner/${id}`,
      config
    );
    dispatch({
      type: gameTypes.GET_WINNER_BY_FIRMID_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: gameTypes.GET_WINNER_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};

export const getControlCompetitionByFirmId = (
  id,
  employeeFirmId
) => async dispatch => {
  dispatch({
    type: gameTypes.GET_AVAILABLE_COMPETITION_BY_FIRMID_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/competition/${id}/${employeeFirmId}`,
      config
    );
    dispatch({
      type: gameTypes.GET_AVAILABLE_COMPETITION_BY_FIRMID_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: gameTypes.GET_AVAILABLE_COMPETITION_BY_FIRMID_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getCompetitionByFirmId = (
  id,
  compId,
  employeeId,
  questionId
) => async dispatch => {
  dispatch({
    type: gameTypes.GET_COMPETITION_BY_FIRMID_PENDING
  });

  dispatch({
    type: gameTypes.GET_COMPETITION_BY_FIRMID_FULFILLED,
    payload: []
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/competitionQuestion/${id}/${compId}/${employeeId}/${questionId}`,
      config
    );
    dispatch({
      type: gameTypes.GET_COMPETITION_BY_FIRMID_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: gameTypes.GET_COMPETITION_BY_FIRMID_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const saveCompetitionAnswer = (
  question,
  questionChoice,
  employeeFirmId,
  firmId
) => async dispatch => {
  dispatch({
    type: gameTypes.SAVE_COMPETITION_ANSWER_PENDING
  });

  const data = {
    question,
    questionChoice,
    employeeFirmId,
    firmId: 1
  };
  try {
    const response = await API.post(`/competition/save`, data);
    const interaction = {
      message: "Yarışmaya katıldınız",
      type: "notification"
    };

    dispatch({
      type: gameTypes.SAVE_COMPETITION_ANSWER_FULFILLED,
      payload: response,
      interaction
    });

    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: gameTypes.SAVE_COMPETITION_ANSWER_PENDING,
      payload: error
    });
  }
};

export const getResultCompetition = (
  id,
  employeeId,
  questionId
) => async dispatch => {
  dispatch({
    type: gameTypes.GET_RESULT_COMPETITION_BY_FIRMID_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/competitionAnswer/${id}/${employeeId}/${questionId}`,
      config
    );
    dispatch({
      type: gameTypes.GET_RESULT_COMPETITION_BY_FIRMID_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: gameTypes.GET_RESULT_COMPETITION_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};

export const changeOption = selectedOption => async dispatch => {
  dispatch({
    type: gameTypes.SELECTED_OPTION,
    payload: selectedOption
  });
};

function returnIndexMenu(index) {
  return index;
}

export const setIndexMenu = index => async dispatch => {
  dispatch({ type: gameTypes.SET_ACTIVE_MENU_ITEM_PENDING });

  try {
    const returnComponent = returnIndexMenu(index);
    dispatch({
      type: gameTypes.SET_ACTIVE_MENU_ITEM_FULFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: gameTypes.SET_ACTIVE_MENU_ITEM_REJECTED,
      payload: error
    });
  }
};

export const getCompetitionDate = () => async dispatch => {
  dispatch({
    type: gameTypes.GET_COMPETITION_DATE_PENDING
  });

  try {
    const response = await API.get(`${REQUEST_URL}/competition/date`, config);
    dispatch({
      type: gameTypes.GET_COMPETITION_DATE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: gameTypes.GET_COMPETITION_DATE_REJECTED,
      payload: error
    });
  }
};

// Game Lucky Wheel
export const getLuckyWheel = () => async dispatch => {
  dispatch({ type: gameTypes.GET_LUCKY_WHEEL_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/luckyWheel`, config);
    dispatch({
      type: gameTypes.GET_LUCKY_WHEEL_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: gameTypes.GET_LUCKY_WHEEL_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Game Lucky Wheel - Control
export const getLuckyWheelControl = employeeFirmId => async dispatch => {
  dispatch({ type: gameTypes.GET_LUCKY_WHEEL_CONTROL_PENDING });
  try {
    const response = await API.get(
      `${REQUEST_URL}/control/luckyWheel/${employeeFirmId}`,
      config
    );
    dispatch({
      type: gameTypes.GET_LUCKY_WHEEL_CONTROL_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: gameTypes.GET_LUCKY_WHEEL_CONTROL_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Game Lucky Wheel - Start
export const getLuckyWheelStart = employeeFirmId => async dispatch => {
  dispatch({ type: gameTypes.GET_LUCKY_WHEEL_START_PENDING });
  try {
    const response = await API.get(
      `${REQUEST_URL}/start/luckyWheel/${employeeFirmId}`,
      config
    );
    dispatch({
      type: gameTypes.GET_LUCKY_WHEEL_START_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: gameTypes.GET_LUCKY_WHEEL_START_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Game Lucky Wheel - Save Reward
export const setLuckyWheelReward = (
  wheelPartId,
  employeeFirmId,
  choiceId
) => async dispatch => {
  dispatch({ type: gameTypes.SET_LUCKY_WHEEL_PENDING });

  if (choiceId) {
    choiceId = parseInt(choiceId);
  }

  const data = {
    wheelPartId: wheelPartId,
    employeeFirmId: employeeFirmId,
    choiceId: choiceId
  };

  try {
    const response = await API.post(`${REQUEST_URL}/saveReward`, data, config);
    dispatch({
      type: gameTypes.SET_LUCKY_WHEEL_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: gameTypes.SET_LUCKY_WHEEL_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Game Lucky Wheel - Save Reward Prize
export const setLuckyWheelRewardPrize = (
  employeeFirmId,
  prize,
  luckyWheelQuestionId
) => async dispatch => {
  dispatch({ type: gameTypes.SET_LUCKY_WHEEL_PRIZE_PENDING });

  const data = {
    employeeFirmId: employeeFirmId,
    prize: prize,
    luckyWheelQuestionId: luckyWheelQuestionId
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/saveReward/prize`,
      data,
      config
    );
    dispatch({
      type: gameTypes.SET_LUCKY_WHEEL_PRIZE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: gameTypes.SET_LUCKY_WHEEL_PRIZE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};
