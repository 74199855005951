export const GET_COOKIES_ALL_PENDING = "GET_COOKIES_ALL_PENDING";
export const GET_COOKIES_ALL_FULFILLED = "GET_COOKIES_ALL_FULFILLED";
export const GET_COOKIES_ALL_REJECTED = "GET_COOKIES_ALL_REJECTED";

export const GET_CLARIFICATION_TEXT_PENDING = "GET_CLARIFICATION_TEXT_PENDING";
export const GET_CLARIFICATION_TEXT_FULFILLED =
  "GET_CLARIFICATION_TEXT_FULFILLED";
export const GET_CLARIFICATION_TEXT_REJECTED =
  "GET_CLARIFICATION_TEXT_REJECTED";

export const SAVE_COOKIES_PENDING = "SAVE_COOKIES_PENDING";
export const SAVE_COOKIES_FULFILLED = "SAVE_COOKIES_FULFILLED";
export const SAVE_COOKIES_REJECTED = "SAVE_COOKIES_REJECTED";
