import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import showMessage from "hooks/showMessage";
import { authAction, gameAction } from "../../../redux/modules";

// Header - Footer
import UserPortalMenu from "../UserPortalMenu/UserPortalMenu";
import UserPortalInfo from "../UserPortalInfo/UserPortalInfo";
import UserPortalWidget from "../UserPortalWidget/UserPortalWidget";
import UserPortalFooter from "../UserPortalFooter/UserPortalFooter";

// Bootstrap
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

// CountdownCircleTimer
import { CountdownCircleTimer } from "react-countdown-circle-timer";

// Styles
import "./styles.scss";

// Wheel
import { Wheel } from "react-custom-roulette";

// Images
import WheelBanner from "assets/images/new-design/game/lucky-wheel/wheel-banner.png";
import ButtonEffect from "assets/images/new-design/game/lucky-wheel/button-bg.png";
import Triangle from "assets/images/new-design/game/lucky-wheel/pin.svg";
import Oval from "assets/images/new-design/game/lucky-wheel/oval@3x.png";

import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { FaInfoCircle, FaPlayCircle } from "react-icons/fa";

import { scroller } from "react-scroll";
import { animateScroll as scroll } from "react-scroll";

// Splash
import Splash from "assets/images/new-design/game/splash/splash-sans-carki.png";

import VideoPopup from "../../../components/VideoPopup";

const GameQuestionWheel = () => {
  // Dispatch
  const dispatch = useDispatch();

  // Store
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const luckyWheel = useSelector(store => store.game.luckyWheel);
  const luckyWheelControl = useSelector(store => store.game.luckyWheelControl);
  const luckyWheelReward = useSelector(store => store.game.reward);

  // States
  const [luckyWheelError, setLuckyWheelError] = useState(false);
  const [question, setQuestion] = useState([]);
  const [gameIsActive, setGameIsActive] = useState(false);
  const [gameIsStarted, setGameIsStarted] = useState(false);
  const [timerError, setTimerError] = useState(false);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(null);
  const [currentPrizeNumber, setCurrentPrizeNumber] = useState(null);
  const [spinned, setSpinned] = useState(false);
  const [wheelData, setWheelData] = useState([]);
  const [spinDuration, setSpinDuration] = useState(1);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [selectedPiece, setSelectedPiece] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [wheelWin, setWheelWin] = useState(null);
  const [questionWheelWin, setQuestionWheelWin] = useState(null);
  const [answered, setAnswered] = useState(false);
  const [questionError, setQuestionError] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const openPopup = e => {
    e.preventDefault();

    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  // Scroll to Questions
  const scrollFunction = () => {
    setTimeout(() => {
      if (document.getElementById("wrapper-question")) {
        scroller.scrollTo("wrapper-question", {
          duration: 800, // scroll süresi (ms cinsinden)
          smooth: true, // yumuşak geçiş
          offset: -200 // div'e scroll yaparken ne kadar offset verileceği (isteğe bağlı)
        });
      }
    }, 200);
  };

  // Scroll to Top
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  // Render Time
  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return (
        <div className="timer text-[16px] lg:text-[24px] font-bold text-white">
          Süre Doldu!
        </div>
      );
    }

    return (
      <div className="timer text-center">
        <div className="value text-[36px] lg:text-[48px] lg:leading-[58px] leading-[44px] font-bold text-white">
          {remainingTime}
        </div>
        <div className="text text-[12px] font-bold text-white">SANİYE</div>
      </div>
    );
  };

  const specialItemAddFunc = luckyItem => {
    if (luckyItem.wheelPartDTOList && luckyItem.wheelPartDTOList.length > 0) {
      let arr = [];
      let percentage = [];

      luckyItem.wheelPartDTOList.forEach(element => {
        percentage.push(element.percentage);

        if (element.text && element.color && element.textColor) {
          arr.push({
            option: element.text,
            style: {
              backgroundColor: "#" + element.color,
              textColor: "#" + element.textColor
            }
          });
        }
      });

      // Wheel Data Added To State - So Many Important!
      setWheelData(arr);

      // prizeNumber Calculate With Percentage -- If we dont have prizeNumber, this line will be worked! But we dont need else case!
      if (!prizeNumber) {
        // 1-100 Range Random Number
        let random = Math.floor(Math.random() * 100) + 1;

        // Percentage Calculate
        percentage.forEach((percent, index) => {
          if (index === 0) {
            if (random <= percent) {
              setPrizeNumber(index);
            }
          } else {
            const percentageArr = [];

            for (let i = 0; i < percentage.length; i++) {
              if (i === 0) {
                percentageArr.push(percentage[i]);
              } else {
                percentageArr.push(percentage[i] + percentageArr[i - 1]);
              }
            }

            if (
              random > percentageArr[index - 1] &&
              random <= percentageArr[index]
            ) {
              setPrizeNumber(index);
            }
          }
        });
      }
    }
  };

  // Lucky Wheel When Spin
  const handleSpinClick = () => {
    if (employeeFirm && employeeFirm.id) {
      if (luckyWheel) {
        specialItemAddFunc(luckyWheel);
      }

      // Lucky Wheel - Control
      dispatch(gameAction.getLuckyWheelControl(employeeFirm.id)).then(res => {
        if (res.data.remainTime > 0) {
          setGameIsActive(true);

          if (wheelData) {
            // Disabled Spinning
            setMustSpin(true);
            setSpinned(true);
          }
        } else {
          setGameIsActive(false);
        }
      });
    }
  };

  // CountdownTimer When Complete
  const handleTimerComplete = () => {
    setGameIsActive(false);
    setTimerError(true);
  };

  // Lucky Wheel Stop Spinning
  const handleStopSpinning = () => {
    // Lucky Wheel Start
    dispatch(gameAction.getLuckyWheelStart(employeeFirm.id)).then(res => {
      if (res?.data?.wheelPartDTOList?.length > 0) {
        // Default Wheel Data Array
        let wheelDataArr = [];

        // Game Started
        setGameIsStarted(true);

        if (luckyWheelControl?.prize > -1) {
          // forEach wheelPartDTOList
          res.data.wheelPartDTOList.forEach((element, index) => {
            if (element?.luckyWheelQuestionDTOList?.length > 0) {
              if (luckyWheelControl.prize === index) {
                setSelectedPiece(element.id);
                setQuestion([element]);
                scrollFunction();
              }
            }

            // Lucky Wheel Stylesheet Added Here
            if (element?.text && element?.color && element?.textColor) {
              wheelDataArr.push({
                option: element.text,
                style: {
                  backgroundColor: "#" + element.color,
                  textColor: "#" + element.textColor
                }
              });
            }
          });
        } else {
          let selectedElement = null;

          // forEach wheelPartDTOList
          res.data.wheelPartDTOList.forEach((element, index) => {
            if (element?.luckyWheelQuestionDTOList?.length > 0) {
              if (prizeNumber === index) {
                setSelectedPiece(element.id);
                setQuestion([element]);
                scrollFunction();
                selectedElement = element;
              }
            }

            // Lucky Wheel Stylesheet Added Here
            if (element?.text && element?.color && element?.textColor) {
              wheelDataArr.push({
                option: element.text,
                style: {
                  backgroundColor: "#" + element.color,
                  textColor: "#" + element.textColor
                }
              });
            }
          });

          // Save Prize Number
          dispatch(
            gameAction.setLuckyWheelRewardPrize(
              employeeFirm.id,
              prizeNumber,
              selectedElement?.luckyWheelQuestionDTOList[0]?.id
            )
          );
        }

        // Set Wheel Data State
        setWheelData(wheelDataArr);
      }
    });

    // Lucky Wheel Data's Added Here
    if (wheelData && wheelData.length > 0) {
      wheelData.forEach((elem, index) => {
        if (index === prizeNumber) {
          // Set Wheel Data State
          setWheelWin(elem.option);
        }
      });
    }

    // Disable Lucky Wheel Spinning
    setMustSpin(false);
  };

  // Answer the Question Event
  const handleClick = event => {
    event.preventDefault();
    setBtnDisabled(true);

    if (selectedAnswer) {
      // Lucky Wheel - Save Reward
      dispatch(
        gameAction.setLuckyWheelReward(
          selectedPiece,
          employeeFirm.id,
          selectedAnswer
        )
      )
        .then(res => {
          // Success
          if (res.isHttpSuccess) {
            var num = parseInt(res.data);
            if (num > 1) {
              setQuestionWheelWin(res.data);
              dispatch(
                authAction.returnNewScoreOfEmployee(
                  employeeFirm.firm.id,
                  employeeFirm.id
                )
              );
              showMessage(
                `Tebrikler! Soruya doğru cevap vererek ${num} puan kazandınız.`
              );
            } else if (num === 0) {
              setQuestionWheelWin(null);
              setQuestionError(true);
              setAnswered(true);
            } else if (num === 1) {
              dispatch(
                gameAction.setLuckyWheelReward(
                  selectedPiece,
                  employeeFirm.id,
                  null
                )
              );
            }
          }
        })
        .catch(err => {
          // Error
          if (err.isHttpError) {
            setQuestionWheelWin(null);
            setQuestionError(true);
            setAnswered(true);
          }
        });

      // Question is answered
      setAnswered(true);
    }
  };

  // Lucky Wheel Begin Game
  const handleBeginGame = e => {
    e.preventDefault();

    // Get Control LuckyWheel Control Time
    dispatch(gameAction.getLuckyWheelControl(employeeFirm.id)).then(res => {
      // Success
      if (res.isHttpSuccess) {
        if (res.data.remainTime > 0) {
          setGameIsActive(true);
        } else {
          setGameIsActive(false);
        }
      }
    });
  };

  // Lucky Wheel - Check
  useEffect(() => {
    dispatch(gameAction.getLuckyWheelControl(employeeFirm.id)).then(res => {
      if (res.data?.gameIsOn === true && res.data?.remainTime > 0) {
        if (res.data.prize > -1) {
          setCurrentPrizeNumber(res.data.prize);
          setPrizeNumber(res.data.prize);
          setSpinDuration(0.01);
          handleSpinClick();
        }

        setGameIsActive(true);
        setGameIsStarted(true);
        setLuckyWheelError(true);
      } else {
        setGameIsActive(false);
        setLuckyWheelError(false);
      }
    });

    dispatch(gameAction.getLuckyWheel());
    return () => {};
  }, []);

  // Lucky Wheel's Info Added
  useEffect(() => {
    if (luckyWheel) {
      specialItemAddFunc(luckyWheel);
    }

    return () => {};
  }, [luckyWheel]);

  useEffect(() => {
    if (timerError) {
      dispatch(gameAction.setLuckyWheelReward(selectedPiece, employeeFirm.id));
    }

    return () => {};
  }, [timerError]);

  return (
    <>
      <UserPortalWidget />
      <UserPortalMenu />
      <UserPortalInfo />

      <section className="bg-[#f8fafc] games-page page-container wheel-page">
        {/* <GlobalLoader isLoading={isLoading} /> */}
        <div className="container mx-auto py-20 px-6 lg:px-0">
          <div className="flex gap-4 h-100 flex-col lg:flex-row">
            {gameIsStarted === true &&
            timerError === false &&
            !answered &&
            luckyWheel &&
            luckyWheelControl ? (
              <div className="relative lg:sticky lg:top-[150px] h-[333px] w-[308px] lg:min-w-[308px] lg:min-h-[333px] gap-20 bg-[url('assets/images/new-design/game/lucky-wheel/wheel-bg.png')] bg-left-bottom bg-no-repeat mx-auto">
                <h2 className="lg:text-[36px] text-[28px] text-center px-4 py-4 font-bold text-white">
                  Kalan Süre
                </h2>

                {luckyWheelControl?.remainTime && luckyWheel?.remainTime && (
                  <div className="countdown flex justify-center">
                    <CountdownCircleTimer
                      isPlaying
                      size={156}
                      strokeLinecap="square"
                      duration={luckyWheel?.remainTime}
                      initialRemainingTime={luckyWheelControl?.remainTime}
                      colors="#FFFFFF"
                      onComplete={e => handleTimerComplete()}
                    >
                      {renderTime}
                    </CountdownCircleTimer>
                  </div>
                )}
              </div>
            ) : (
              <div className="relative w-[100%] h-[333px] sm:h-[333px] sm:w-[308px] lg:min-w-[308px] lg:min-h-[333px] gap-20 bg-[url('assets/images/new-design/game/lucky-wheel/wheel-bg.png')] bg-no-repeat bg-left-bottom mx-auto overflow-hidden">
                <h2 className="max-w-[308px] lg:text-[36px] text-[28px] text-center px-4 py-4 font-bold text-white">
                  {luckyWheel.leftTitle ? luckyWheel.leftTitle : "Şans Çarkı"}
                </h2>
                <img
                  src={WheelBanner}
                  className="w-[280px] absolute bottom-[-20px] left-0"
                  alt=""
                />
              </div>
            )}

            {luckyWheelControl &&
              luckyWheelControl.remainTime > 0 &&
              !luckyWheelError &&
              !gameIsActive &&
              !timerError && (
                <div className="bg-slate-200 flex-1 flex justify-center items-start rounded-[12px] flex-col py-8 lg:px-12 px-8">
                  {luckyWheel.title && (
                    <h3 className="mb-8 inline-flex items-center gap-4 text-2xl font-semibold text-slate-700">
                      <FaInfoCircle className="h-6 w-6" />{" "}
                      {luckyWheel.title && luckyWheel.title}
                    </h3>
                  )}

                  {luckyWheel.description && (
                    <div
                      className="mb-6"
                      dangerouslySetInnerHTML={{
                        __html: luckyWheel.description
                      }}
                    />
                  )}

                  <div className="flex flex-col justify-center gap-6 !mt-4 items-center relative mb-12 mx-auto">
                    <a
                      href="#"
                      onClick={openPopup}
                      className="relative w-[240px] h-[160px] block rounded-xl"
                    >
                      <img src={Splash} className="rounded-xl" alt="" />
                      <FaPlayCircle className="h-8 w-8 text-white absolute top-[50%] left-[50%] mt-[-16px] ml-[-16px]" />
                    </a>

                    <h3 className="text-slate-600 font-semibold text-xl">
                      İzlemek için videoya tıklayınız
                    </h3>
                  </div>
                </div>
              )}

            {luckyWheelControl &&
              luckyWheelControl.availableTodayQuestion === false &&
              !timerError && (
                <div className="bg-slate-200 flex-1 flex justify-center items-start rounded-[12px] flex-col py-8 lg:px-12 px-8">
                  <h3 className="mb-8 inline-flex items-center gap-4 text-2xl font-semibold text-slate-700">
                    <FaInfoCircle className="h-6 w-6" />{" "}
                    {luckyWheel.title
                      ? luckyWheel.title
                      : "Şans Çarkı'na Hoş Geldiniz!"}
                  </h3>

                  <div className="flex flex-col justify-center gap-6 !mt-4 items-center relative mb-12 mx-auto">
                    <a
                      href="#"
                      onClick={openPopup}
                      className="relative w-[240px] h-[160px] block rounded-xl"
                    >
                      <img src={Splash} className="rounded-xl" alt="" />
                      <FaPlayCircle className="h-8 w-8 text-white absolute top-[50%] left-[50%] mt-[-16px] ml-[-16px]" />
                    </a>

                    <h3 className="text-slate-600 font-semibold text-xl">
                      İzlemek için videoya tıklayınız
                    </h3>
                  </div>

                  <p className="lg:text-[16px] text-[14px] font-normal text-slate-500 !mb-8">
                    Şans Çarkı'nın yeni sorusunu Çarşamba günü
                    yanıtlayabilirsiniz.
                  </p>
                  <Link
                    to="/userportal/competitions"
                    className="font-medium flex items-center justify-between lg:text-[16px] text-[14px] gap-2 lg:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] !py-[16px] !pl-[24px] !pr-[24px] !lg:pr-[36px]"
                  >
                    <IoArrowBack className="h-4 w-4" />
                    Yarışmalar{" "}
                  </Link>
                </div>
              )}

            {luckyWheelControl &&
              luckyWheelControl.remainTime === 0 &&
              luckyWheelControl.availableTodayQuestion === true &&
              !timerError && (
                <div className="bg-slate-200 flex-1 flex justify-center items-start rounded-[12px] flex-col py-8 lg:px-12 px-8">
                  <h3 className="mb-8 inline-flex items-center gap-4 text-2xl font-semibold text-slate-700">
                    <FaInfoCircle className="h-6 w-6" />{" "}
                    {luckyWheel.title && luckyWheel.title}
                  </h3>

                  <div className="flex flex-col justify-center gap-6 !mt-4 items-center relative mb-12 mx-auto">
                    <a
                      href="#"
                      onClick={openPopup}
                      className="relative w-[240px] h-[160px] block rounded-xl"
                    >
                      <img src={Splash} className="rounded-xl" alt="" />
                      <FaPlayCircle className="h-8 w-8 text-white absolute top-[50%] left-[50%] mt-[-16px] ml-[-16px]" />
                    </a>

                    <h3 className="text-slate-600 font-semibold text-xl">
                      İzlemek için videoya tıklayınız
                    </h3>
                  </div>

                  <p className="lg:text-[16px] text-[14px] font-normal text-slate-500 !mb-8">
                    Daha önceden Şans Çarkı'na katıldınız. Lütfen daha sonra
                    tekrar deneyiniz.
                  </p>
                  <Link
                    to="/userportal/competitions"
                    className="font-medium flex items-center justify-between lg:text-[16px] text-[14px] gap-2 lg:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] !py-[16px] !pl-[24px] !pr-[24px] !lg:pr-[36px]"
                  >
                    <IoArrowBack className="h-4 w-4" />
                    Yarışmalar{" "}
                  </Link>
                </div>
              )}

            {timerError === true && (
              <div className="flex gap-4 w-100 h-100 flex-col lg:flex-row">
                {scrollToTop()}
                <div className="bg-slate-200 flex-1 flex justify-center rounded-[12px] flex-col py-8 px-8 min-h-[333px]">
                  <h4 className="lg:text-[48px] text-[24px] text-center font-semibold text-slate-700">
                    ⌛ Üzgünüz
                  </h4>
                  <p className="lg:text-[16px] text-[14px] text-center font-normal text-slate-500 lg:pt-[16px] pt-[12px]">
                    Size verilen süre içerisinde ({luckyWheel?.remainTime}{" "}
                    saniye) yanıt veremediniz ve puan kazanamadınız.
                  </p>
                  <Link
                    to="/userportal/competitions"
                    className="lg:mt-[24px] mt-[16px] font-medium mx-auto max-h-[52px] flex items-center gap-2 rounded-full border-2 border-slate-700 bg-white px-10 py-3  text-slate-700"
                  >
                    <IoArrowBack className="h-5 w-5" />
                    Yarışmalar{" "}
                  </Link>
                </div>
              </div>
            )}

            {questionWheelWin && (
              <div className="flex gap-4 w-100 h-100 flex-col lg:flex-row">
                {scrollToTop()}
                <div className="bg-slate-200 flex-1 flex justify-center rounded-[12px] flex-col py-8 px-8 min-h-[333px]">
                  <h4 className="lg:text-[48px] text-[24px] text-center font-semibold text-slate-700">
                    🎉 Tebrikler
                  </h4>
                  <p className="lg:text-[16px] text-[14px] text-center font-normal text-slate-500 lg:pt-[16px] pt-[12px]">
                    Doğru cevapladınız ve {parseInt(questionWheelWin)} Puan
                    kazandınız!
                  </p>
                  <Link
                    to="/userportal/competitions"
                    className="lg:mt-[24px] mt-[16px] font-medium mx-auto max-h-[52px] flex items-center gap-2 rounded-full border-2 border-slate-700 bg-white px-10 py-3  text-slate-700"
                  >
                    <IoArrowBack className="h-5 w-5" />
                    Yarışmalar{" "}
                  </Link>
                </div>
              </div>
            )}

            {questionError && (
              <div className="flex gap-4 w-100 h-100 flex-col lg:flex-row">
                {scrollToTop()}
                <div className="bg-slate-200 flex-1 flex justify-center rounded-[12px] flex-col py-8 px-8 min-h-[333px]">
                  <h4 className="lg:text-[48px] text-[24px] text-center font-semibold text-slate-700">
                    😞 Üzgünüz
                  </h4>
                  <p className="lg:text-[16px] text-[14px] text-center font-normal text-slate-500 lg:pt-[16px] pt-[12px]">
                    Yanlış cevapladınız! Lütfen bir sonraki oyun gününde tekrar
                    deneyiniz!
                  </p>
                  <Link
                    to="/userportal/competitions"
                    className="lg:mt-[24px] mt-[16px] font-medium mx-auto max-h-[52px] flex items-center gap-2 rounded-full border-2 border-slate-700 bg-white px-10 py-3  text-slate-700"
                  >
                    <IoArrowBack className="h-5 w-5" />
                    Yarışmalar{" "}
                  </Link>
                </div>
              </div>
            )}

            {luckyWheel &&
              gameIsActive &&
              luckyWheelControl.remainTime > 0 &&
              !answered && (
                <div className="flex flex-col w-100">
                  <div className="bg-slate-200 flex-1 flex justify-center items-start rounded-[12px] flex-col py-12 px-12 !mb-4">
                    <h3 className="mb-8 inline-flex items-center gap-4 text-2xl font-semibold text-slate-700">
                      <FaInfoCircle className="h-6 w-6" /> Şans Çarkı Nasıl
                      Oynanır?
                    </h3>

                    {luckyWheel.description && (
                      <div
                        className="tracking-wide"
                        dangerouslySetInnerHTML={{
                          __html: luckyWheel.inGameDescription
                        }}
                      />
                    )}
                  </div>

                  <div className="flex-1 flex justify-center items-start rounded-[12px] flex-col py-8 lg:px-12 px-2 md:px-8 games-page-introduction bg-[#0a9589] bg-cover bg-no-repeat lucky-wheel-wrap">
                    <Row className="justify-content-md-center align-items-center question-wrap-row w-100">
                      <Col
                        xs={12}
                        md={12}
                        lg={12}
                        className={answered ? "d-none" : "d-block"}
                      >
                        {luckyWheel?.wheelPartDTOList &&
                          wheelData?.length > 0 &&
                          spinDuration && (
                            <div className="wrapper-pin-orange absolute top-0 left-[50%] z-[10]">
                              <img src={Triangle} className="img-triangle" />
                            </div>
                          )}

                        {luckyWheel?.wheelPartDTOList &&
                          wheelData?.length > 0 &&
                          spinDuration && (
                            <div className="wheel-spin relative">
                              <div className="absolute top-0 left-0 z-[10] oval">
                                <img src={Oval} />
                              </div>

                              {currentPrizeNumber && wheelData && spinDuration && (
                                <Wheel
                                  mustStartSpinning={mustSpin}
                                  prizeNumber={currentPrizeNumber}
                                  data={wheelData}
                                  spinDuration={spinDuration}
                                  backgroundColors={["#3e3e3e", "#df3428"]}
                                  textColors={["#ffffff"]}
                                  radiusLineWidth={2}
                                  radiusLineColor={"#fff"}
                                  innerBorderWidth={2}
                                  innerBorderColor={["#fff"]}
                                  outerBorderWidth={0}
                                  outerBorderColor="#fff"
                                  perpendicularText={false}
                                  fontSize={16}
                                  onStopSpinning={() => handleStopSpinning()}
                                  style={{
                                    backgroundImage:
                                      "linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0))"
                                  }}
                                />
                              )}

                              {wheelData &&
                                spinDuration &&
                                !currentPrizeNumber &&
                                prizeNumber > -1 && (
                                  <Wheel
                                    mustStartSpinning={mustSpin}
                                    prizeNumber={prizeNumber}
                                    data={wheelData}
                                    spinDuration={spinDuration}
                                    backgroundColors={["#3e3e3e", "#df3428"]}
                                    textColors={["#ffffff"]}
                                    radiusLineWidth={2}
                                    radiusLineColor={"#fff"}
                                    innerBorderWidth={2}
                                    innerBorderColor={["#fff"]}
                                    outerBorderWidth={0}
                                    outerBorderColor="#fff"
                                    perpendicularText={false}
                                    fontSize={16}
                                    onStopSpinning={() => handleStopSpinning()}
                                    style={{
                                      backgroundImage:
                                        "linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0))"
                                    }}
                                  />
                                )}

                              <div className="text-center wheel-spin-button">
                                {spinned === true ? (
                                  <div className="relative select-none">
                                    <button className="btn btn-success btn-success-gray text-center text-[20px]">
                                      Çevir
                                      <br />
                                      Kazan
                                    </button>
                                  </div>
                                ) : (
                                  <div
                                    className="relative select-none"
                                    onClick={handleSpinClick}
                                  >
                                    <button className="btn btn-success text-center text-[20px]">
                                      Çevir
                                      <br />
                                      Kazan
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                      </Col>
                    </Row>
                  </div>

                  {spinned === true && question && question.length > 0 && (
                    <>
                      <div
                        className="bg-white flex-1 flex justify-center items-start rounded-[12px] xl:flex-row flex-col lg:py-12 py-8 lg:px-12 px-8 !mt-4"
                        id="wrapper-question"
                      >
                        <div className="left flex flex-col items-start xl:w-[40%] w-[100%]">
                          <div className="question-count">
                            <div className="text-[12px] text-center font-bold text-white bg-[#0d9488] px-[16px] py-[8px] rounded-[17px]">
                              SORU
                            </div>
                          </div>

                          <div className="questions mt-[12px]">
                            <div className="lg:text-[24px] text-[18px] font-bold text-[#64748b]">
                              {!answered && (
                                <div className="games-page-question">
                                  {question.map(item => {
                                    return (
                                      <div key={item.id}>
                                        {item.luckyWheelQuestionDTOList[0] &&
                                          item.luckyWheelQuestionDTOList[0]
                                            .description && (
                                            <div>
                                              {
                                                item
                                                  .luckyWheelQuestionDTOList[0]
                                                  .description
                                              }
                                            </div>
                                          )}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="right xl:w-[60%] w-[100%] xl:pl-[12%] pl-[0] xl:pt-[0] pt-[10%]">
                          {!answered && (
                            <Col
                              xs={12}
                              md={12}
                              lg={12}
                              className={
                                wheelWin ? "wheelWin active" : "wheelWin"
                              }
                            >
                              <div
                                className={
                                  wheelWin
                                    ? "wrapper-reward active"
                                    : "wrapper-reward"
                                }
                              >
                                <Col
                                  xs={12}
                                  md={12}
                                  className="align-items-center d-flex"
                                >
                                  <div className="games-page-question">
                                    {question &&
                                      question.length > 0 &&
                                      question.map((item, index) => {
                                        return (
                                          <div key={index}>
                                            <Form>
                                              <Form.Group as={Row}>
                                                <div>
                                                  {item
                                                    .luckyWheelQuestionDTOList[0] &&
                                                    item
                                                      .luckyWheelQuestionDTOList[0]
                                                      .luckyWheelChoiceDTOList &&
                                                    item
                                                      .luckyWheelQuestionDTOList[0]
                                                      .luckyWheelChoiceDTOList
                                                      .length > 0 &&
                                                    item.luckyWheelQuestionDTOList[0].luckyWheelChoiceDTOList.map(
                                                      (answer, aIndex) => {
                                                        return (
                                                          <Form.Check
                                                            key={aIndex}
                                                            type="radio"
                                                            label={
                                                              answer.description
                                                            }
                                                            name={"answer"}
                                                            value={answer.id}
                                                            id={answer.id}
                                                            className="!mb-4 !p-0"
                                                            onClick={() =>
                                                              setSelectedAnswer(
                                                                answer.id
                                                              )
                                                            }
                                                          />
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              </Form.Group>
                                            </Form>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </Col>
                              </div>
                            </Col>
                          )}
                        </div>
                      </div>

                      <div className="w-full bg-white flex-1 flex justify-between ml-auto items-start rounded-[12px] flex-col mt-4 py-6 lg:px-8 px-4">
                        <div className="action flex justify-between w-full">
                          <Link
                            to="/userportal/competitions"
                            className="font-medium flex items-center justify-center lg:text-[16px] text-[14px] gap-2 lg:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] !py-[16px] !pl-[24px] !pr-[24px] !lg:pr-[36px]"
                          >
                            <IoArrowBack className="h-5 w-5" />
                            Geri Git{" "}
                          </Link>

                          <a
                            href="#"
                            className="font-medium flex items-center justify-between lg:text-[16px] text-[14px] gap-2 lg:min-w-[135px] min-w-[120px] max-h-[52px] rounded-full border-2 bg-[#00b185] border-[#00b185] !py-[16px] !pl-[24px] !pr-[24px] !lg:pr-[36px] text-white"
                            disabled={btnDisabled}
                            onClick={e => handleClick(e)}
                          >
                            Cevapla{" "}
                            <IoArrowForward className="h-5 w-5 text-white" />
                          </a>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
          </div>

          {!luckyWheelError &&
            !gameIsActive &&
            !answered &&
            luckyWheelControl &&
            luckyWheelControl.remainTime > 0 && (
              <div className="gap-4 w-full lg:w-[calc(100%-324px)] bg-white flex-1 flex justify-between ml-auto items-start rounded-[12px] flex-col mt-4 py-6 lg:px-8 px-4">
                <div className="action flex justify-between w-full">
                  <Link
                    to="/userportal/competitions"
                    className="font-medium flex items-center justify-between lg:text-[16px] text-[14px] gap-2 lg:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] !py-[16px] !pl-[24px] !pr-[24px] !lg:pr-[36px]"
                  >
                    <IoArrowBack className="h-5 w-5" />
                    Geri Git{" "}
                  </Link>

                  <a
                    href="#"
                    className="font-medium flex items-center justify-between lg:text-[16px] text-[14px] gap-2 lg:min-w-[135px] min-w-[120px] max-h-[52px] rounded-full border-2 bg-[#00b185] border-[#00b185] !py-[16px] !pl-[24px] !pr-[24px] !lg:pr-[36px] text-white"
                    onClick={e => handleBeginGame(e)}
                  >
                    {luckyWheel.buttonText}{" "}
                    <IoArrowForward className="h-5 w-5 text-white" />
                  </a>
                </div>
              </div>
            )}
        </div>

        <VideoPopup
          videoUrl={
            process.env.REACT_APP_VIDEO_SHOW_URL + "JTI_BB_SANSCARKI_01.mp4"
          }
          isOpen={isOpen}
          onClose={closePopup}
        />
      </section>

      <UserPortalFooter />
    </>
  );
};

export default GameQuestionWheel;
