import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { disclaimerAction, authAction } from "redux/modules";
import { Redirect } from "react-router-dom";
import Modal from "react-responsive-modal";
import showMessage from "hooks/showMessage";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";

const Disclaimer = () => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const disclaimerList = useSelector(store => store.disclaimer.disclaimerList);
  const isLoading = useSelector(store => store.disclaimer.isLoading);

  const [open, setOpen] = useState(false);
  const [redirectPath, setRedirectPath] = useState(null);

  const closeModal = useCallback(() => setOpen(!open), [open]);

  useEffect(() => {
    dispatch(
      disclaimerAction.getDisclaimerByFirmId(
        employeeFirm.firm.id,
        employeeFirm.id
      )
    );
  }, [dispatch, employeeFirm.firm.id, employeeFirm.id]);

  const redirectPage = useCallback(page => {
    setTimeout(() => {
      setRedirectPath(page);
    }, 200);
  }, []);

  const saveDisclaimer = useCallback(
    (disclaimer, employeeFirmId, firmId) => {
      localStorage.setItem("saveDisclaimer", true);
      dispatch(
        disclaimerAction.saveDisclaimer(disclaimer, employeeFirmId, firmId)
      ).then(data => {
        if (data.scoreType) {
          const message =
            data.scoreType === "AYLIK"
              ? `Tebrikler! Bu ay giriş yaptığınız için ${data.diffScore} puan kazandınız.\nTebrikler! Bugün giriş yaptığınız için ${data.diffDayScore} puan kazandınız.`
              : `Tebrikler! Bugün giriş yaptığınız için ${data.diffScore} puan kazandınız.`;
          showMessage(message);
          dispatch(
            authAction.returnNewScoreOfEmployee(
              employeeFirm.firm.id,
              employeeFirm.id
            )
          );
        }

        dispatch(
          disclaimerAction.getIysDisclaimerByErc(employeeFirm.ercCode)
        ).then(dataIys => {
          if (dataIys.isHttpSuccess) {
            if (dataIys.data) {
              redirectPage("disclaimerIys");
            } else {
              redirectPage("/");
            }
          }
        });
      });
    },
    [dispatch, employeeFirm.firm.id, employeeFirm.id, redirectPage]
  );

  const bg = {
    overlay: {
      background: "#424242"
    }
  };

  if (redirectPath) {
    return <Redirect to={{ pathname: redirectPath }} />;
  }

  return (
    <div>
      <GlobalLoader isLoading={isLoading} />
      {disclaimerList && (
        <Modal
          open={!open}
          onClose={closeModal}
          role="dialog"
          closeOnEsc={false}
          showCloseIcon={false}
          closeOnOverlayClick={false}
          styles={bg}
          modalId="disclaimer-modal-area"
        >
          {disclaimerList.map(item => (
            <div key={item.id}>
              {item.checkedDisclaimerInTheWeek ? (
                redirectPage("/")
              ) : (
                <div>
                  <div
                    style={{
                      maxHeight: "650px",
                      overflowY: "scroll",
                      marginBottom: "25px"
                    }}
                  >
                    <div
                      style={{ padding: "20px" }}
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </div>
                  <button
                    id="disclaimer-modal-submit"
                    onClick={() => {
                      saveDisclaimer(
                        item.id,
                        employeeFirm.id,
                        employeeFirm.firm.id
                      );
                      setOpen(!open);
                    }}
                    style={{
                      borderRadius: "10px",
                      marginBottom: "20px",
                      marginRight: "20px"
                    }}
                    className="float-right btn btn-success"
                  >
                    Şart ve Koşulları Okudum, Anladım ve Kabul Ediyorum
                  </button>
                </div>
              )}
            </div>
          ))}
        </Modal>
      )}
    </div>
  );
};

export default Disclaimer;
