import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { scoreHuntAction } from "redux/modules";
import { Link } from "react-router-dom";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";

import {
  IoMenuOutline,
  IoCloseOutline,
  IoChevronDown,
  IoArrowBack
} from "react-icons/io5";
import BgBlue from "assets/images/new-design/game/card-icon-blue.png";

const ScoreHuntAnswer = props => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const resultScoreHunt = useSelector(store => store.scoreHunt.resultScoreHunt);
  var scoreHuntResult = null;
  const isLoading = useSelector(store => store.scoreHunt.isLoading);

  useEffect(() => {
    dispatch(scoreHuntAction.getResultScoreHunt(employeeFirm.id));
    return () => {};
  }, []);

  if (resultScoreHunt) {
    scoreHuntResult = resultScoreHunt.map(item => {
      return (
        <div className="container mx-auto py-20 px-6 md:px-0" key={item}>
          {item.answerTrue && (
            <div className="flex gap-4 h-100 flex-col md:flex-row">
              <div className="relative h-[333px] w-[308px] gap-20 bg-[url('assets/images/new-design/game/card-bg-blue.png')] bg-left-bottom bg-no-repeat mx-auto">
                <h2 className="md:text-[36px] text-[28px] text-center px-4 py-4 font-bold text-white">
                  Puan Avı
                </h2>
                <img
                  src={BgBlue}
                  className="w-[220px] absolute bottom-0 right-0 "
                  alt=""
                />
              </div>
              <div className="bg-slate-200 flex-1 flex justify-center rounded-[12px] flex-col py-8 px-8">
                <h4 className="md:text-[48px] text-[24px] text-center font-semibold text-slate-700">
                  🎉 Tebrikler
                </h4>
                <p className="md:text-[16px] text-[14px] text-center font-normal text-slate-500 md:pt-[16px] pt-[12px]">
                  Doğru yanıt verdiniz ve {item.score} puan kazandınız.{" "}
                  {item.nextDayMessage}
                </p>
                <Link
                  to="/userportal/competitions"
                  className="md:mt-[24px] mt-[16px] font-medium mx-auto flex items-center gap-2 rounded-full border-2 border-slate-700 bg-white px-10 py-3  text-slate-700"
                >
                  <IoArrowBack className="h-5 w-5" />
                  Yarışmalar{" "}
                </Link>
              </div>
            </div>
          )}

          {item.answerFalse && (
            <div className="flex gap-4 h-100 flex-col md:flex-row">
              <div className="relative h-[333px] w-[308px] gap-20 bg-[url('assets/images/new-design/game/card-bg-blue.png')] bg-left-bottom bg-no-repeat mx-auto">
                <h2 className="md:text-[36px] text-[28px] text-center px-4 py-4 font-bold text-white">
                  Puan Avı
                </h2>
                <img
                  src={BgBlue}
                  className="w-[220px] absolute bottom-0 right-0 "
                  alt=""
                />
              </div>
              <div className="bg-slate-200 flex-1 flex justify-center rounded-[12px] flex-col py-8 px-8">
                <h4 className="md:text-[48px] text-[24px] text-center font-semibold text-slate-700">
                  😞 Üzgünüz
                </h4>
                <p className="md:text-[16px] text-[14px] text-center font-normal text-slate-500 md:pt-[16px] pt-[12px]">
                  Yanlış yanıt verdiniz ve puan kazanamadınız.{" "}
                  {item.correctAnswer}
                  <br />
                  {item.nextDayMessage}
                </p>
                <Link
                  to="/userportal/competitions"
                  className="md:mt-[24px] mt-[16px] font-medium mx-auto flex items-center gap-2 rounded-full border-2 border-slate-700 bg-white px-10 py-3  text-slate-700"
                >
                  <IoArrowBack className="h-5 w-5" />
                  Yarışmalar{" "}
                </Link>
              </div>
            </div>
          )}

          {item.answerTimeOut && (
            <div className="flex gap-4 h-100 flex-col md:flex-row">
              <div className="relative h-[333px] w-[308px] gap-20 bg-[url('assets/images/new-design/game/card-bg-blue.png')] bg-left-bottom bg-no-repeat mx-auto">
                <h2 className="md:text-[36px] text-[28px] text-center px-4 py-4 font-bold text-white">
                  Puan Avı
                </h2>
                <img
                  src={BgBlue}
                  className="w-[220px] absolute bottom-0 right-0 "
                  alt=""
                />
              </div>
              <div className="bg-slate-200 flex-1 flex justify-center rounded-[12px] flex-col py-8 px-8">
                <h4 className="md:text-[48px] text-[24px] text-center font-semibold text-slate-700">
                  ⌛ Üzgünüz
                </h4>
                <p className="md:text-[16px] text-[14px] text-center font-normal text-slate-500 md:pt-[16px] pt-[12px]">
                  Size verilen süre içerisinde (90 saniye) yanıt veremediniz ve
                  puan kazanamadınız.  {item.correctAnswer}
                  <br />
                  {item.nextDayMessage}
                </p>
                <Link
                  to="/userportal/competitions"
                  className="md:mt-[24px] mt-[16px] font-medium mx-auto flex items-center gap-2 rounded-full border-2 border-slate-700 bg-white px-10 py-3  text-slate-700"
                >
                  <IoArrowBack className="h-5 w-5" />
                  Yarışmalar{" "}
                </Link>
              </div>
            </div>
          )}
        </div>
      );
    });
  }
  return (
    <section>
      <GlobalLoader isLoading={isLoading} />
      {scoreHuntResult}
    </section>
  );
};
export default ScoreHuntAnswer;
