import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

// Competition
// import Competition from "./modules/competition/index";
// import PriceList from "./modules/competition/priceList/priceList";
// import Gift from "./modules/competition/gift/gift";
// import CorrectResponder from "./modules/competition/correctResponder/correctResponder";
// import Winner from "./modules/competition/winner/winner";
// import CompetitionRule from "./modules/competition/competitionRule/competitionRule";
// import HowCompete from "./modules/competition/howCompete/howCompete";
// import Question from "./modules/competition/question/question";
// import ChooseCity from "./modules/competition/chooseCity/chooseCity";
// import gameLayout from "./modules/competition/layout";

// Components
import { PrivateRoute } from "../components";

// Pages

// Auth
import User from "./User/User";
import Login from "./Login/Login";
import SignUp from "./SignUp/SignUp";
import PasswordReset from "./PasswordReset/PasswordReset";

// 404 Not Found
import NotFound from "./NotFound/NotFound";

// Userportal
import Home from "./Home/Home";
import Profile from "./UserPortal/Profile/Profile";
import EmployeeFirm from "./employeeFirm/employeeFirm";
import Brand from "./UserPortal/Brand/Brand";
import Faq from "./UserPortal/Faq/Faq";
import Tac from "./UserPortal/Tac/Tac";
import UserGuide from "./UserPortal/UserGuide/UserGuide";
import About from "./UserPortal/About/About";
import SearchFind from "./UserPortal/SearchFind/SearchFind";
import SearchFindStart from "./UserPortal/SearchFind/SearchFindStart";
import SearchFindQuestion from "./UserPortal/SearchFind/SearchFindQuestion";
import SearchFindAnswer from "./UserPortal/SearchFind/SearchFindAnswer";
import LookFind from "./UserPortal/LookFind/LookFind";
import LookFindQuestion from "./UserPortal/LookFind/LookFindQuestion";
import LookFindAnswer from "./UserPortal/LookFind/LookFindAnswer";
import PickFind from "./UserPortal/PickFind/PickFind";
import PickFindQuestion from "./UserPortal/PickFind/PickFindQuestion";
import PickFindAnswer from "./UserPortal/PickFind/PickFindAnswer";

import ScoreHunt from "./UserPortal/ScoreHunt/ScoreHunt";
import ScoreHuntQuestion from "./UserPortal/ScoreHunt/ScoreHuntQuestion";
import ScoreHuntAnswer from "./UserPortal/ScoreHunt/ScoreHuntAnswer";

import WhoWantsPoints from "./UserPortal/WhoWantsPoints/WhoWantsPoints";

import WeekStore from "./UserPortal/WeekStore/WeekStore";
import WeekWinner from "./UserPortal/WeekStore/WeekWinner";
import OurArena from "./UserPortal/OurArena/OurArena";
import Catalog from "./UserPortal/Catalog/Catalog";
import Leaderboard from "./UserPortal/Leaderboard/Leaderboard";
import TvArea from "./UserPortal/TvArea/TvArea";
import DynamicTvPage from "./UserPortal/TvArea/DynamicTvPage";
import Magazine from "./UserPortal/Magazine/Magazine";
import MagazineHashtag from "./UserPortal/Magazine/MagazineHashtag";
import MagazineDetail from "./UserPortal/Magazine/MagazineDetail";
import Score from "./UserPortal/Howtoscore/Score";

// Games
import CompetitionMain from "./Game/CompetitionMain/CompetitionMain";

// Card
import Card from "./Card/Card";

// Disclaimer
import Disclaimer from "./Disclaimer/Disclaimer";

// DisclaimerIys
import DisclaimerIys from "./DisclaimerIys/DisclaimerIys";

// DisclaimerPhoto
import DisclaimerPhoto from "./DisclaimerPhoto/DisclaimerPhoto";

// E-Order Modules
// import EOrder from "./UserPortal/order/EOrder/EOrder";
// import EOrderSummary from "./UserPortal/Order/EOrderSummary/EOrderSummary";
// import EOrderHistory from "./UserPortal/Order/EOrderHistory/EOrderHistory";
// import EOrderBridge from "./UserPortal/Order/EOrderBridge/EOrderBridge";
// import EOrderDetail from "./UserPortal/Order/EOrderDetail/EOrderDetail";

import { ROUTES } from "../constants/routes.const";
import withLayout from "./UserPortal/withLayout";
import homeLayout from "./UserPortal/homeLayout";
import Announcement from "./UserPortal/Announcement/Announcement";
import GameQuestionWheel from "./UserPortal/GameQuestionWheel";

const Router = () => {
  const isAuthenticated = useSelector(store => store.auth.isAuthenticated);

  return (
    <Switch>
      <Route path="/login" component={Login} exact />
      <Route path="/signUp" component={SignUp} exact />
      <Route path="/passwordReset" component={PasswordReset} exact />

      {/* Competition */}
      {/* After The Competitions 3 Day Will Be Open */}
      {/* <PrivateRoute
        path="/competition/correct-responders"
        component={gameLayout(CorrectResponder)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/competition/winners"
        component={gameLayout(Winner)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/competition/rules"
        component={gameLayout(CompetitionRule)}
        exact
        isAuthenticated={isAuthenticated}
      /> */}
      {/* After The Competitions 3 Day Will Be Open */}

      {/* Competition */}
      {/* <PrivateRoute
        path="/competition"
        component={gameLayout(Competition)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/competition/chooseCity"
        component={gameLayout(ChooseCity)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/competition/question"
        component={gameLayout(Question)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/competition/price-list"
        component={gameLayout(PriceList)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/competition/gifts"
        component={gameLayout(Gift)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/competition/how-do-compete"
        component={gameLayout(HowCompete)}
        exact
        isAuthenticated={isAuthenticated}
      /> */}
      {/* End - Competition */}

      <PrivateRoute
        path="/"
        component={homeLayout(Home)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/user"
        component={withLayout(User)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/card"
        component={withLayout(Card)}
        exact
        isAuthenticated={isAuthenticated}
      />

      <PrivateRoute
        path="/userportal/announcement"
        component={withLayout(Announcement)}
        exact
        isAuthenticated={isAuthenticated}
      />

      {/* E-Order */}
      {/* <PrivateRoute
        path="/userportal/eorderBridge"
        component={withLayout(EOrderBridge)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/eorder"
        component={withLayout(EOrder)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/eorderSummary"
        component={withLayout(EOrderSummary)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/eorderHistory"
        component={withLayout(EOrderHistory)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/eorderDetail"
        component={withLayout(EOrderDetail)}
        exact
        isAuthenticated={isAuthenticated}
      /> */}
      {/* End E-Order */}

      <PrivateRoute
        path="/userportal/profile"
        component={withLayout(Profile)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/employeeFirm"
        component={EmployeeFirm}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/brand"
        component={Brand}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/brand/:id"
        component={Brand}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/faq"
        component={withLayout(Faq)}
        exact
        isAuthenticated={isAuthenticated}
      />

      <PrivateRoute
        path="/userportal/tac"
        component={withLayout(Tac)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/userGuide"
        component={withLayout(UserGuide)}
        exact
        isAuthenticated={isAuthenticated}
      />

      <PrivateRoute
        path="/userportal/score"
        component={withLayout(Score)}
        exact
        isAuthenticated={isAuthenticated}
      />

      <PrivateRoute
        path="/userportal/about"
        component={withLayout(About)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/magazine"
        component={withLayout(Magazine)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/magazine/:hashtag"
        component={withLayout(MagazineHashtag)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/ourArena"
        component={withLayout(OurArena)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/magazine/detail"
        component={withLayout(MagazineDetail)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/magazine/detail/:id"
        component={withLayout(MagazineDetail)}
        exact
        isAuthenticated={isAuthenticated}
      />

      {/* Games */}
      <PrivateRoute
        path="/userportal/searchFind"
        component={withLayout(SearchFind)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/searchFindStart"
        component={withLayout(SearchFindStart)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/searchFindQuestion"
        component={withLayout(SearchFindQuestion)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/searchFindAnswer"
        component={withLayout(SearchFindAnswer)}
        exact
        isAuthenticated={isAuthenticated}
      />

      <PrivateRoute
        path="/userportal/lookFind"
        component={withLayout(LookFind)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/lookFindQuestion"
        component={withLayout(LookFindQuestion)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/lookFindAnswer"
        component={withLayout(LookFindAnswer)}
        exact
        isAuthenticated={isAuthenticated}
      />

      <PrivateRoute
        path="/userportal/pickFind"
        component={withLayout(PickFind)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/pickFindQuestion"
        component={withLayout(PickFindQuestion)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/pickFindAnswer"
        component={withLayout(PickFindAnswer)}
        exact
        isAuthenticated={isAuthenticated}
      />

      <PrivateRoute
        path="/userportal/scoreHunt"
        component={withLayout(ScoreHunt)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/scoreHuntQuestion"
        component={withLayout(ScoreHuntQuestion)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/scoreHuntAnswer"
        component={withLayout(ScoreHuntAnswer)}
        exact
        isAuthenticated={isAuthenticated}
      />

      <PrivateRoute
        path="/userportal/whoWantsPoints"
        component={withLayout(WhoWantsPoints)}
        exact
        isAuthenticated={isAuthenticated}
      />

      <PrivateRoute
        path="/userportal/weekStore"
        component={withLayout(WeekStore)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/weekWinner"
        component={withLayout(WeekWinner)}
        exact
        isAuthenticated={isAuthenticated}
      />

      <PrivateRoute
        path="/userportal/luckywheel"
        component={GameQuestionWheel}
        exact
        isAuthenticated={isAuthenticated}
      />
      {/* End Games */}

      {/* Benim Bayim Dükkanı */}
      <PrivateRoute
        path="/userportal/catalog/"
        component={Catalog}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/userportal/catalog/:category/:subcategory"
        component={Catalog}
        exact
        isAuthenticated={isAuthenticated}
      />
      {/* End Benim Bayim Dükkanı */}

      <PrivateRoute
        path="/userportal/tvArea"
        component={withLayout(TvArea)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/disclaimer"
        component={Disclaimer}
        exact
        isAuthenticated={isAuthenticated}
      />

      <PrivateRoute
        path="/disclaimerIys"
        component={DisclaimerIys}
        exact
        isAuthenticated={isAuthenticated}
      />

      <PrivateRoute
        path="/disclaimerPhoto"
        component={DisclaimerPhoto}
        exact
        isAuthenticated={isAuthenticated}
      />

      <PrivateRoute
        path="/userportal/DynamicTvPage"
        component={withLayout(DynamicTvPage)}
        exact
        isAuthenticated={isAuthenticated}
      />

      {/* Yarışmalar */}
      <PrivateRoute
        path="/userportal/competitions"
        component={withLayout(CompetitionMain)}
        exact
        isAuthenticated={isAuthenticated}
      />
      {/* End Yarışmalar */}

      {/* <PrivateRoute
        path="/userportal/tenth-year"
        component={TenthYear}
        exact
        isAuthenticated={isAuthenticated}
      /> */}

      {/* Liderler Tablosu */}
      <PrivateRoute
        path="/userportal/leaderboard"
        component={withLayout(Leaderboard)}
        exact
        isAuthenticated={isAuthenticated}
      />
      {/* End Liderler Tablosu */}

      <Route path={ROUTES.NOT_FOUND} component={NotFound} exact />
      <Route component={() => <Redirect to={ROUTES.NOT_FOUND} />} />
    </Switch>
  );
};

export default Router;
