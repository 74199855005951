import React, { useState } from "react";
import Modal from "react-responsive-modal";
import ReactPlayer from "react-player";

const VideoPopup = ({ isOpen, onClose, videoUrl }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      center
      classNames={{
        overlay: "videoPlayerModalOverlay",
        modal: "videoPlayerModal"
      }}
    >
      <ReactPlayer
        url={videoUrl}
        controls
        width={"100%"}
        height={"auto"}
        playing={true}
      />
    </Modal>
  );
};

export default VideoPopup;
