import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { profileAction } from "redux/modules";

// import Monthly from './Charts/Monthly';
import Monthly2 from "./Charts/Monthly2";

// import MonthlyIncome from './Charts/MonthlyIncome';
import MonthlyIncome2 from "./Charts/MonthlyIncome2";

import ProductFound from "./Charts/ProductFound";
import Percentage from "./Charts/Percentage";
import FocusInfo from "./Charts/FocusInfo";

// import { imageShowUrl } from "../../../config/api.config";
// import GlobalLoader from "../../GlobalLoader/GlobalLoader";
// import showMessage from "../../../hooks/showMessage";
// import showErrMessage from "../../../hooks/showErrMessage";

const ProfilePerformanceAnalysis = () => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const monthlyBuy = useSelector(store => store.profile.monthlyBuy);
  const monthlyIncome = useSelector(store => store.profile.monthlyIncome);
  const productFound = useSelector(store => store.profile.productFound);
  const percentage = useSelector(store => store.profile.percentage);
  const focusInfo = useSelector(store => store.profile.focusInfo);

  const handleGetMonthly = () => {
    dispatch(profileAction.getMonthlyPerformance(employeeFirm.ercCode));
  };

  const handleGetMonthlyIncome = () => {
    dispatch(profileAction.getMonthlyIncomePerformance(employeeFirm.ercCode));
  };

  const handleGetProductFound = () => {
    dispatch(profileAction.getProductFoundPerformance(employeeFirm.ercCode));
  };

  const handleGetPercentage = () => {
    dispatch(profileAction.getPercentagePerformance(employeeFirm.ercCode));
  };

  const handleGetFocusInfo = () => {
    dispatch(profileAction.getFocusInfoPerformance(employeeFirm.ercCode));
  };

  useEffect(() => {
    handleGetMonthly();

    return () => {};
  }, []);

  useEffect(() => {
    if (monthlyBuy?.data?.length > 0) {
      console.log("monthlyBuy11: ", monthlyBuy);
    }

    return () => {};
  }, [monthlyBuy]);

  useEffect(() => {
    if (monthlyIncome?.data?.length > 0) {
      console.log("monthlyIncome: ", monthlyIncome);
    }

    return () => {};
  }, [monthlyIncome]);

  useEffect(() => {
    if (productFound?.data?.length > 0) {
      console.log("productFound: ", productFound);
    }

    return () => {};
  }, [productFound]);

  return (
    <div
      className="tab-pane fade show active"
      role="tabpanel"
      aria-labelledby="accountinfo-tab"
    >
      <div className="user-content-item">
        <ul className="nav ml-0" role="tablist">
          <li className="nav-item">
            <a
              className="active"
              id="performance-1-tab"
              data-toggle="tab"
              href="#performance-1"
              role="tab"
              aria-controls="performance-1-tab"
              aria-selected="false"
              onClick={() => handleGetMonthly()}
            >
              Aylık Ürün Alımı
            </a>
          </li>

          <li className="nav-item">
            <a
              id="performance-2-tab"
              data-toggle="tab"
              href="#performance-2"
              role="tab"
              aria-controls="performance-2-tab"
              aria-selected="false"
              onClick={() => handleGetMonthlyIncome()}
            >
              Aylık Kazanç Miktarı
            </a>
          </li>

          <li className="nav-item">
            <a
              id="performance-3-tab"
              data-toggle="tab"
              href="#performance-3"
              role="tab"
              aria-controls="performance-3-tab"
              aria-selected="false"
              onClick={() => handleGetProductFound()}
            >
              Ürün Bulunurluk
            </a>
          </li>

          <li className="nav-item">
            <a
              id="performance-4-tab"
              data-toggle="tab"
              href="#performance-4"
              role="tab"
              aria-controls="performance-4-tab"
              aria-selected="false"
              onClick={() => handleGetPercentage()}
            >
              Alım Miktarının Değişim Oranı
            </a>
          </li>

          <li className="nav-item">
            <a
              id="performance-5-tab"
              data-toggle="tab"
              href="#performance-5"
              role="tab"
              aria-controls="performance-5-tab"
              aria-selected="false"
              onClick={() => handleGetFocusInfo()}
            >
              Focus Ürünlere Göre
            </a>
          </li>
        </ul>

        <div className="tab-content tab-content-performance">
          <div
            className="tab-pane fade active show"
            id="performance-1"
            role="tabpanel"
            aria-labelledby="performance-1-sub-tab"
            aria-selected="true"
          >
            {monthlyBuy ? (
              <Monthly2 data={monthlyBuy} />
            ) : (
              <div className="flex flex-col justify-center items-center min-h-[405px] !p-[20px]">
                Yükleniyor ...
              </div>
            )}

            {/* {monthlyBuy ?
              <Monthly data={monthlyBuy} /> : "Yükleniyor ..."
            } */}
          </div>

          <div
            className="tab-pane fade"
            id="performance-2"
            role="tabpanel"
            aria-labelledby="performance-2-sub-tab"
          >
            {monthlyIncome ? (
              <MonthlyIncome2 data={monthlyIncome} />
            ) : (
              <div className="flex flex-col justify-center items-center min-h-[405px] !p-[20px]">
                Yükleniyor ...
              </div>
            )}
          </div>

          <div
            className="tab-pane fade"
            id="performance-3"
            role="tabpanel"
            aria-labelledby="performance-3-sub-tab"
          >
            {productFound ? (
              <ProductFound data={productFound} />
            ) : (
              <div className="flex flex-col justify-center items-center min-h-[405px] !p-[20px]">
                Yükleniyor ...
              </div>
            )}
          </div>

          <div
            className="tab-pane fade"
            id="performance-4"
            role="tabpanel"
            aria-labelledby="performance-4-sub-tab"
          >
            {percentage ? (
              <Percentage data={percentage} />
            ) : (
              <div className="flex flex-col justify-center items-center min-h-[405px] !p-[20px]">
                Yükleniyor ...
              </div>
            )}
          </div>

          <div
            className="tab-pane fade"
            id="performance-5"
            role="tabpanel"
            aria-labelledby="performance-5-sub-tab"
          >
            {focusInfo ? (
              <FocusInfo data={focusInfo} />
            ) : (
              <div className="flex flex-col justify-center items-center min-h-[405px] !p-[20px]">
                Yükleniyor ...
              </div>
            )}
          </div>
        </div>

        <div className="user-content-text !mx-0 !mb-0 !mt-10">
          <p className="text-sm !px-0 !leading-5">
            Performans analizinizi aşağıdaki sekmeler arasında gezerek
            görebilirsiniz. <br />
            Aşağıdaki bilgilerde yanlışlık olduğunu düşünüyorsanız 0850 532 5
            584 (JTI) numaralı Çağrı Merkezi’mizi arayabilir, ilgili bilgilerin
            doğruluklarını teyit edebilirsiniz.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfilePerformanceAnalysis;
