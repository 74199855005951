import React, { Component } from "react";
import UserPortalMenu from "./UserPortalMenu/UserPortalMenu";
import UserPortalInfo from "./UserPortalInfo/UserPortalInfo";
import UserPortalFooter from "./UserPortalFooter/UserPortalFooter";
import UserPortalWidget from "./UserPortalWidget/UserPortalWidget";

const withLayout = OriginalComponent =>
  class extends Component {
    render() {
      return (
        <>
          <UserPortalMenu />
          <UserPortalInfo />
          <OriginalComponent {...this.props} />
          <UserPortalWidget />
          <UserPortalFooter />
        </>
      );
    }
  };

export default withLayout;
