import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homeAction, authAction } from "redux/modules";
import showMessage from "hooks/showMessage";
import showErrMessage from "hooks/showErrMessage";
import { IoArrowForward } from "react-icons/io5";

import Poll from "assets/images/new-design/bg-section-poll.png";

import "./HomeSurvey.scss";

const HomeSurvey = () => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const surveyList = useSelector(store => store.home.surveyList);
  const [selectedChoice, setSelectedChoice] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);

  useEffect(() => {
    dispatch(
      homeAction.getSurveyByFirmId(employeeFirm.firm.id, employeeFirm.id)
    );
    return () => {};
  }, []);

  const handleSelectChoice = choiceId => {
    setSelectedChoice([choiceId]);
  };

  const saveSurvey = (
    surveys,
    surveyChoice,
    employeeFirmId,
    firmId,
    score,
    e
  ) => {
    e.preventDefault();
    if (surveyChoice.length <= 0) {
      showErrMessage("Seçenek seçiniz.");
    } else {
      setDisabledButton(true);
      dispatch(
        homeAction.saveSurveyAnswer(
          surveys,
          surveyChoice,
          employeeFirmId,
          firmId
        )
      )
        .then(e => {
          showMessage(
            `Tebrikler! Ankete cevap vererek ${score} puan kazandınız.`
          );
          dispatch(
            authAction.returnNewScoreOfEmployee(
              employeeFirm.firm.id,
              employeeFirm.id
            )
          );
        })
        .catch(err => {
          showErrMessage("Seçenek seçiniz.");
        });
    }
  };

  return (
    <section className="mt-6">
      <div className="container mx-auto max-w-[1280px]">
        {surveyList &&
          surveyList.map(item => (
            <div key={item.id}>
              {/* Survey Result - Survey */}
              {item.answeredSurvey ? (
                <div className="relative overflow-hidden rounded-xl bg-slate-100 py-8 max-lg:px-6 lg:rounded-br-[100px] lg:rounded-tl-[100px] lg:py-20">
                  <div className="relative z-10 grid grid-cols-12 max-lg:gap-y-12">
                    <div className="col-span-12 max-lg:text-center lg:col-span-5 lg:col-start-2 lg:px-12 xl:col-span-4 xl:col-start-3">
                      <h2 className="text-3xl font-semibold text-slate-700 lg:text-[48px]">
                        Anket
                      </h2>
                      <p className="mt-4 text-[18px] text-slate-400 lg:text-[24px]">
                        {item.description}
                      </p>
                    </div>

                    <div className="col-span-12 lg:col-span-5 lg:px-12 xl:col-span-5">
                      <div className="flex flex-col gap-6 text-slate-500">
                        {item.surveyChoices?.map(choice => (
                          <div
                            key={choice.id}
                            className="flex cursor-pointer flex-col gap-2"
                          >
                            <div className="inline-flex gap-2">
                              <span className="inline-block rounded-lg bg-slate-500 px-2 py-1 text-sm text-white min-w-[66px] text-center">
                                %{" "}
                                {(
                                  (choice.markedValue / item.totalParticipant) *
                                  100
                                ).toFixed(1)}
                              </span>
                              {choice.name}
                            </div>

                            <div className="h-2 w-full rounded-full bg-white">
                              <div
                                className="h-2 rounded-full bg-green-500"
                                style={{
                                  width:
                                    (choice.markedValue /
                                      item.totalParticipant) *
                                      100 +
                                    "%"
                                }}
                              ></div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-[url('assets/images/new-design/pattern.png')] opacity-40"></div>

                    <img
                      src={Poll}
                      alt=""
                      className="absolute bottom-0 left-0 z-0 transform max-xl:scale-75 max-lg:hidden lg:-translate-x-[44%] lg:translate-y-[45%] xl:-translate-x-[42%] xl:translate-y-[40%]"
                    />
                  </div>
                </div>
              ) : (
                <div className="relative overflow-hidden rounded-xl bg-slate-100 py-8 max-lg:px-6 lg:rounded-br-[100px] lg:rounded-tl-[100px] lg:py-20">
                  <div className="relative z-10 grid grid-cols-12 max-lg:gap-y-12">
                    <div className="col-span-12 max-lg:text-center lg:col-span-5 lg:col-start-2 lg:px-12 xl:col-span-4 xl:col-start-3">
                      <h2 className="text-3xl font-semibold text-slate-700 lg:text-[48px]">
                        Anket
                      </h2>
                      <p className="mt-4 text-[18px] text-slate-400 lg:text-[24px]">
                        {item.description}
                      </p>
                    </div>

                    <div className="col-span-12 lg:col-span-5 lg:px-12 xl:col-span-4">
                      <div className="flex flex-col gap-6 text-slate-500">
                        <form
                          name="anket"
                          className="survey-form"
                          onSubmit={e =>
                            saveSurvey(
                              item.id,
                              selectedChoice,
                              employeeFirm.id,
                              employeeFirm.firm.id,
                              item.score,
                              e
                            )
                          }
                        >
                          {item.surveyChoices?.map(choice => (
                            <div
                              className="flex cursor-pointer gap-4 mb-4"
                              key={choice.id}
                            >
                              <input
                                type="radio"
                                name="survey"
                                id={`choice-${choice.id}`}
                                className={
                                  selectedChoice &&
                                  selectedChoice.length > 0 &&
                                  choice.id === selectedChoice[0]
                                    ? "radio radio-success"
                                    : "radio"
                                }
                                value={choice.id}
                                onClick={() => handleSelectChoice(choice.id)}
                              />
                              <label
                                className="mb-0"
                                onClick={() => handleSelectChoice(choice.id)}
                                htmlFor={`choice-${choice.id}`}
                              >
                                {choice.name}
                              </label>
                            </div>
                          ))}
                          <div className="max-lg:text-center">
                            <button
                              type="submit"
                              className="!mt-8 inline-flex items-center gap-2 rounded-full border-2 border-slate-700 bg-white !px-6 !py-3 text-sm text-slate-700 !lg:mt-12"
                              disabled={disabledButton}
                            >
                              Oy Ver <IoArrowForward className="h-4 w-4" />
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="absolute left-0 top-0 z-0 h-full w-full bg-[url('assets/images/new-design/pattern.png')] opacity-40"></div>

                  <img
                    src={Poll}
                    alt=""
                    className="absolute bottom-0 left-0 z-0 transform max-xl:scale-75 max-lg:hidden lg:-translate-x-[44%] lg:translate-y-[45%] xl:-translate-x-[42%] xl:translate-y-[40%]"
                  />
                </div>
              )}
            </div>
          ))}
      </div>
    </section>
  );
};

export default HomeSurvey;
