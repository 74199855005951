import React from "react";
import { Link } from "react-router-dom";

// New Design Imports
import Atropos from "atropos/react";

import Atropos11 from "assets/images/new-design/atropos/1-1.png";
import Atropos12 from "assets/images/new-design/atropos/1-2.png";
import Atropos13 from "assets/images/new-design/atropos/1-3.png";
import Atropos14 from "assets/images/new-design/atropos/1-4.png";
import Atropos15 from "assets/images/new-design/atropos/1-5.png";

import Atropos20 from "assets/images/new-design/atropos/2-0.png";
import Atropos21 from "assets/images/new-design/atropos/2-1.png";
import Atropos22 from "assets/images/new-design/atropos/2-2.png";
import Atropos23 from "assets/images/new-design/atropos/2-3.png";
import Atropos24 from "assets/images/new-design/atropos/2-4.png";
import Atropos25 from "assets/images/new-design/atropos/2-5.png";
import Atropos26 from "assets/images/new-design/atropos/2-6.png";

import Atropos30 from "assets/images/new-design/atropos/3-0.png";
import Atropos31 from "assets/images/new-design/atropos/3-1.png";

import Atropos40 from "assets/images/new-design/atropos/4-0.png";
import Atropos41 from "assets/images/new-design/atropos/4-1.png";
import Atropos42 from "assets/images/new-design/atropos/4-2.png";
import Atropos43 from "assets/images/new-design/atropos/4-3.png";
import Atropos44 from "assets/images/new-design/atropos/4-4.png";

import Atropos60 from "assets/images/new-design/atropos/6-0.png";

import Atropos70 from "assets/images/new-design/atropos/7-0.png";
import Atropos71 from "assets/images/new-design/atropos/7-1.png";
import Atropos72 from "assets/images/new-design/atropos/7-2.png";
import Atropos73 from "assets/images/new-design/atropos/7-3.png";

const HomeGame = () => {
  return (
    <section className="mt-6">
      <div className="container mx-auto max-w-[1280px] px-4">
        <div className="grid grid-cols-6 gap-2 lg:gap-4">
          <Atropos
            shadowScale={1.05}
            className="col-span-6 h-[380px] cursor-pointer max-lg:h-[180px] md:col-span-3 lg:col-span-2"
          >
            <Link to={`/userportal/searchFind`}>
              <div className="relative h-full overflow-hidden rounded-xl p-12 lg:rounded-r-[64px]">
                <div
                  data-atropos-offset="10"
                  className="absolute z-10 max-lg:left-[24px] max-lg:top-[32px] lg:left-0 lg:top-6 lg:w-full lg:text-center"
                >
                  <span className="mb-2 inline-block rounded-full bg-black/25 px-3 py-1 text-[12px] text-sm font-medium uppercase text-white">
                    Yarışma
                  </span>
                  <h2 className="text-[28px] font-bold text-white drop-shadow-lg max-lg:text-[24px]">
                    Rozet <br className="max-lg:block lg:hidden" /> Macerası
                  </h2>
                </div>

                <div className="absolute left-0 top-0 h-full w-full bg-[#38C086]"></div>
                <div className="absolute left-0 top-0 h-full w-full bg-[url('assets/images/new-design/pattern.png')]"></div>
                <div
                  className="absolute left-0 top-[60px] h-full w-full max-lg:hidden"
                  style={{
                    background:
                      "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 65%)"
                  }}
                ></div>

                <div className="absolute -bottom-[60px] w-[400px] scale-[80%] max-lg:-bottom-[10px] max-lg:-right-[100px] max-lg:scale-[50%] lg:left-1/2 lg:h-full lg:-translate-x-[50%] lg:transform">
                  <img
                    src={Atropos11}
                    alt=""
                    data-atropos-offset="10"
                    className="absolute -bottom-[10px] left-[55px] z-10"
                  />
                  <img
                    src={Atropos12}
                    alt=""
                    data-atropos-offset="20"
                    className="absolute bottom-[155px] left-[75px] z-10"
                  />
                  <img
                    src={Atropos13}
                    alt=""
                    data-atropos-offset="100"
                    className="absolute bottom-[160px] left-[270px] z-10"
                  />
                  <img
                    src={Atropos14}
                    alt=""
                    data-atropos-offset="300"
                    className="absolute bottom-[280px] left-[300px] z-10"
                  />
                  <img
                    src={Atropos15}
                    alt=""
                    data-atropos-offset="150"
                    className="absolute bottom-[70px] left-[80px] z-10"
                  />
                </div>
              </div>
            </Link>
          </Atropos>

          <Atropos
            shadowScale={1.05}
            className="col-span-6 h-[380px] cursor-pointer max-lg:h-[180px] md:col-span-3 lg:col-span-2"
          >
            <Link to={`/userportal/pickFind`}>
              <div className="relative h-full overflow-hidden rounded-xl p-12 lg:rounded-r-[64px]">
                <div
                  data-atropos-offset="10"
                  className="absolute z-10 max-lg:left-[24px] max-lg:top-[32px] lg:left-0 lg:top-6 lg:w-full lg:text-center"
                >
                  <span className="mb-2 inline-block rounded-full bg-black/25 px-3 py-1 text-[12px] text-sm font-medium uppercase text-white">
                    Yarışma
                  </span>
                  <h2 className="text-[28px] font-bold text-white drop-shadow-lg max-lg:text-[24px]">
                    Eşle <br className="max-lg:block lg:hidden" /> Kazan
                  </h2>
                </div>

                <div className="absolute left-0 top-0 h-full w-full bg-[#F4BF00]"></div>
                <div className="absolute left-0 top-0 h-full w-full bg-[url('assets/images/new-design/pattern.png')] opacity-50"></div>
                <div
                  className="absolute left-0 top-[60px] h-full w-full opacity-75 max-lg:hidden"
                  style={{
                    background:
                      "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 65%)"
                  }}
                ></div>

                <div className="absolute -bottom-[50px] w-[400px] scale-[70%] max-lg:-bottom-[10px] max-lg:-right-[100px] max-lg:scale-[50%] lg:left-1/2 lg:h-full lg:-translate-x-[50%] lg:transform">
                  <img
                    src={Atropos20}
                    alt=""
                    data-atropos-offset="0"
                    className="absolute bottom-[240px] left-[60px] z-10"
                  />
                  <img
                    src={Atropos21}
                    alt=""
                    data-atropos-offset="10"
                    className="absolute bottom-[90px] left-[100px] z-10"
                  />
                  <img
                    src={Atropos22}
                    alt=""
                    data-atropos-offset="20"
                    className="absolute -bottom-[20px] left-[240px] z-10"
                  />
                  <img
                    src={Atropos23}
                    alt=""
                    data-atropos-offset="20"
                    className="absolute bottom-[160px] left-[145px] z-10"
                  />
                  <img
                    src={Atropos24}
                    alt=""
                    data-atropos-offset="80"
                    className="absolute bottom-[160px] left-[280px] z-10"
                  />
                  <img
                    src={Atropos25}
                    alt=""
                    data-atropos-offset="100"
                    className="absolute bottom-[70px] left-[80px] z-10"
                  />
                  <img
                    src={Atropos26}
                    alt=""
                    data-atropos-offset="150"
                    className="absolute bottom-[280px] left-[160px] z-10"
                  />
                </div>
              </div>
            </Link>
          </Atropos>

          <Atropos
            shadowScale={1.05}
            className="col-span-6 h-[380px] cursor-pointer max-lg:h-[180px] md:col-span-3 lg:col-span-2"
          >
            <Link to={`/userportal/scoreHunt`}>
              <div className="relative h-full overflow-hidden rounded-xl p-12 lg:rounded-r-[64px]">
                <div
                  data-atropos-offset="10"
                  className="absolute z-10 max-lg:left-[24px] max-lg:top-[32px] lg:left-0 lg:top-6 lg:w-full lg:text-center"
                >
                  <span className="mb-2 inline-block rounded-full bg-black/25 px-3 py-1 text-[12px] text-sm font-medium uppercase text-white">
                    Yarışma
                  </span>
                  <h2 className="text-[28px] font-bold text-white drop-shadow-lg max-lg:text-[24px]">
                    Puan <br className="max-lg:block lg:hidden" /> Avı
                  </h2>
                </div>

                <div className="absolute left-0 top-0 h-full w-full bg-[#00A9EF]"></div>
                <div className="absolute left-0 top-0 h-full w-full bg-[url('assets/images/new-design/pattern.png')]"></div>
                <div
                  className="absolute left-0 top-[60px] h-full w-full opacity-75 max-lg:hidden"
                  style={{
                    background:
                      "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 65%)"
                  }}
                ></div>

                <div className="absolute -bottom-[10px] w-[400px] max-lg:-bottom-[10px] max-lg:-right-[100px] max-lg:scale-[65%] lg:left-1/2 lg:h-full lg:-translate-x-[50%] lg:transform">
                  <img
                    src={Atropos30}
                    alt=""
                    data-atropos-offset="10"
                    className="absolute bottom-[60px] left-[100px] z-10"
                  />
                  <img
                    src={Atropos31}
                    alt=""
                    data-atropos-offset="20"
                    className="absolute -bottom-[20px] left-[200px] z-10"
                  />
                </div>
              </div>
            </Link>
          </Atropos>

          <Atropos
            shadowScale={1.05}
            className="col-span-6 h-[380px] cursor-pointer max-lg:h-[180px] md:col-span-3 lg:col-span-2"
          >
            <Link to={`/userportal/luckywheel`}>
              <div className="relative h-full overflow-hidden rounded-xl p-12 lg:rounded-r-[64px]">
                <div
                  data-atropos-offset="10"
                  className="absolute z-10 max-lg:left-[24px] max-lg:top-[32px] lg:left-0 lg:top-6 lg:w-full lg:text-center"
                >
                  <span className="mb-2 inline-block rounded-full bg-black/25 px-3 py-1 text-[12px] text-sm font-medium uppercase text-white">
                    Yarışma
                  </span>
                  <h2 className="text-[28px] font-bold text-white drop-shadow-lg max-lg:text-[24px]">
                    Şans <br className="max-lg:block lg:hidden" /> Çarkı
                  </h2>
                </div>

                <div className="absolute left-0 top-0 h-full w-full bg-[#0D9488]"></div>
                <div className="absolute left-0 top-0 h-full w-full bg-[url('assets/images/new-design/pattern.png')]"></div>
                <div
                  className="absolute left-0 top-[60px] h-full w-full opacity-75 max-lg:hidden"
                  style={{
                    background:
                      "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 65%)"
                  }}
                ></div>

                <div className="absolute -bottom-[50px] w-[400px] max-lg:-bottom-[10px] max-lg:-right-[100px] max-lg:scale-[50%] lg:left-1/2 lg:h-full lg:-translate-x-[50%] lg:transform">
                  <img
                    src={Atropos42}
                    alt=""
                    data-atropos-offset="20"
                    className="absolute bottom-[240px] left-[300px] z-10"
                  />
                  <img
                    src={Atropos41}
                    alt=""
                    data-atropos-offset="10"
                    className="absolute -bottom-[5px] left-[80px] z-10"
                  />
                  <img
                    src={Atropos40}
                    alt=""
                    data-atropos-offset="30"
                    className="absolute bottom-[140px] left-[135px] z-10"
                  />
                  <img
                    src={Atropos43}
                    alt=""
                    data-atropos-offset="75"
                    className="absolute bottom-[80px] left-[310px] z-10"
                  />
                  <img
                    src={Atropos44}
                    alt=""
                    data-atropos-offset="100"
                    className="absolute bottom-[120px] left-[50px] z-10"
                  />
                </div>
              </div>
            </Link>
          </Atropos>

          <Atropos
            shadowScale={1.05}
            className="col-span-6 h-[380px] cursor-pointer max-lg:h-[180px] md:col-span-3 lg:col-span-2"
          >
            <a href="/userportal/whoWantsPoints">
              <div className="relative h-full overflow-hidden rounded-xl p-12 lg:rounded-r-[64px]">
                <div
                  data-atropos-offset="10"
                  className="absolute z-10 max-lg:left-[24px] max-lg:top-[32px] lg:left-0 lg:top-6 lg:w-full lg:text-center"
                >
                  <span className="mb-2 inline-block rounded-full bg-black/25 px-3 py-1 text-[12px] text-sm font-medium uppercase text-white">
                    Yarışma
                  </span>
                  <h2 className="text-[28px] font-bold text-white drop-shadow-lg max-lg:text-[24px]">
                    Kim 1000 Puan İster{" "}
                    <br className="max-lg:block lg:hidden" />
                  </h2>
                </div>

                <div className="absolute left-0 top-0 h-full w-full bg-[#f59e0b]"></div>
                <div className="absolute left-0 top-0 h-full w-full bg-[url('assets/images/new-design/pattern.png')] opacity-50"></div>
                <div
                  className="absolute left-0 top-[60px] h-full w-full opacity-75 max-lg:hidden"
                  style={{
                    background:
                      "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 65%)"
                  }}
                ></div>

                <div className="absolute -bottom-[50px] w-[400px] scale-[70%] max-lg:-bottom-[10px] max-lg:-right-[100px] max-lg:scale-[50%] lg:left-1/2 lg:h-full lg:-translate-x-[50%] lg:transform">
                  <img
                    src={Atropos71}
                    alt=""
                    data-atropos-offset="40"
                    className="absolute bottom-[250px] left-[70px] z-20 w-[153px]"
                  />

                  <img
                    src={Atropos72}
                    alt=""
                    data-atropos-offset="20"
                    className="absolute -bottom-[-100px] left-[20px] z-20 w-[131px]"
                  />
                  <img
                    src={Atropos72}
                    alt=""
                    data-atropos-offset="20"
                    className="absolute -bottom-[-90px] left-[-40px] z-10 w-[101px]"
                  />

                  <img
                    src={Atropos72}
                    alt=""
                    data-atropos-offset="20"
                    className="absolute -bottom-[-100px] right-[20px] z-20 w-[131px]"
                  />
                  <img
                    src={Atropos72}
                    alt=""
                    data-atropos-offset="20"
                    className="absolute -bottom-[-90px] right-[-40px] z-10 w-[101px]"
                  />

                  <img
                    src={Atropos70}
                    alt=""
                    data-atropos-offset="10"
                    className="absolute bottom-[40px] left-[50%] ml-[-100px] z-40 w-[200px]"
                  />

                  <img
                    src={Atropos73}
                    alt=""
                    data-atropos-offset="40"
                    className="absolute bottom-[40px] left-[70px] z-30 w-[259px]"
                  />
                </div>
              </div>
            </a>
          </Atropos>

          <Atropos
            shadowScale={1.05}
            className="col-span-6 h-[380px] cursor-pointer max-lg:h-[180px] md:col-span-3 lg:col-span-2"
          >
            <Link to={`/userportal/weekStore`}>
              <div className="relative h-full overflow-hidden rounded-xl p-12 lg:rounded-r-[64px]">
                <div
                  data-atropos-offset="10"
                  className="absolute z-10 max-lg:left-[24px] max-lg:top-[32px] lg:left-0 lg:top-6 lg:w-full lg:text-center"
                >
                  <span className="mb-2 inline-block rounded-full bg-black/25 px-3 py-1 text-[12px] text-sm font-medium uppercase text-white">
                    Yarışma
                  </span>
                  <h2 className="text-[28px] font-bold text-white drop-shadow-lg max-lg:text-[24px]">
                    Haftanın <br className="max-lg:block lg:hidden" /> Dükkanı
                  </h2>
                </div>

                <div className="absolute left-0 top-0 h-full w-full bg-[#004F96]"></div>
                <div className="absolute left-0 top-0 h-full w-full bg-[url('assets/images/new-design/pattern.png')]"></div>
                <div
                  className="absolute left-0 top-[60px] h-full w-full opacity-25 max-lg:hidden"
                  style={{
                    background:
                      "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 65%)"
                  }}
                ></div>

                <div className="absolute -bottom-[10px] w-[400px] max-lg:-bottom-[10px] max-lg:-right-[100px] max-lg:scale-[65%] lg:left-1/2 lg:h-full lg:-translate-x-[50%] lg:transform">
                  <img
                    src={Atropos60}
                    alt=""
                    data-atropos-offset="10"
                    className="absolute bottom-[10px] left-[100px] z-10"
                  />
                </div>
              </div>
            </Link>
          </Atropos>
        </div>
      </div>
    </section>
  );
};
export default HomeGame;
